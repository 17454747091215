import { useContext } from 'react';

import * as Constants from '../utils/constants';
import { OidcRoutesContext } from '../contexts';

export const useLicense = () => {
  const oidcRoutesContext = useContext(OidcRoutesContext);
  const hasLicense = licenseId =>
    !!oidcRoutesContext?.userProfile?.customerUserAccess?.productFeatureAccessSelections?.find(
      x => x.productId === licenseId
    );

  return {
    hasSmartFormsLicense: () => {
      return hasLicense(Constants.licenses.smartForms);
    },
    hasDigitalSigningLicense: () => {
      return hasLicense(Constants.licenses.digitalSigning);
    },
    hasLicense: licenseId => {
      return !licenseId || hasLicense(licenseId);
    }
  };
};
