import React, { useEffect, useState, useRef } from 'react';
import { format } from "date-fns";

import * as TemplateFileApi from "../../../api/interactTemplateFile.js";

import TemplateFileTableRow from "./templateFileTableRow"

import MenuContextList from "../../../components/MenuContextList/index.js";
import { TwTable } from "../../../components/TailwindTable/index.js";
import { Tabs as CmpTabs } from "../../../components/Tabs/index.js";
import GenericModal from '../../../components/Modals/genericModal.js';

export default function FileInfoDialog({fileInfo, openShowFileInfo, setOpenShowFileInfo, hasCrudAccess, loadBaseData}) {
  const [activeTab, setActiveTab] = useState("versions");

  const refMenuContainer = useRef();

  const tabs = [{
    name: "Versions",
    tab: "versions",
    current: activeTab === "versions"
  },{
    name: "Downloads",
    tab: "downloads",
    current: activeTab === "downloads"
  }];

  const [currentDialogHistoryData, setCurrentDialogHistoryData] = useState(null);
  const [currentDialogDownloadsData, setCurrentDialogDownloadsData] = useState(null);

  let defTotCnt = {};
  let defCurrPg = {};
  tabs.map(tx => {
    defTotCnt[tx.tab] = 0;
    defCurrPg[tx.tab] = 0;
  });

  const [paginationTotalCount, setPaginationTotalCount] = useState(defTotCnt);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(defCurrPg);
  const [paginationItemsPerPage, setPaginationItemsPerPage] = useState(3);

  const loadData = async () => {
    var file = fileInfo?.file;

    var versions = await TemplateFileApi.getInteractTemplateFileVersions(file.id);
    setCurrentDialogHistoryData({file, versions, pageData: versions.slice(0, paginationItemsPerPage)});
    console.log("FileInfoDialog", "loadData", "file", file, "versions", versions, );
    console.log("FileInfoDialog", "storageIds", [{t: "f", fileId: file.id, versionId: "", desc: file.comment, versionInfo: file.versionInfo, storageId: file.storageId}]
      .concat(versions?.map(v => { return {t: "v", fileId: v.record.id, versionId: v.id, desc: v.record.comment, versionInfo: v.record.versionInfo, storageId: v.record.storageId}})));

    let downloads = await TemplateFileApi.getDownloadsFromTemplateFile(file.id);
    downloads = downloads.map(d => {
      d["serverName"] = fileInfo?.toTemplateEnvironment?.template?.servers.find(s => s.serverId === d.templateServertId)?.name;
      return d;
    });
    setCurrentDialogDownloadsData({file, collection: downloads, pageData: downloads.slice(0, paginationItemsPerPage)});

    console.log("FileInfoDialog", "loadData", "versions", versions?.length || 0, "downloads", downloads?.length || 0);

    setPaginationCurrentPage(prevState => ({
      ...prevState,
      ["versions"]: 0,
      ["downloads"]: 0
    }));
    setPaginationTotalCount(prevState => ({
      ...prevState,
      ["versions"]: versions?.length || 0,
      ["downloads"]: downloads?.length || 0
    }));
  }

  useEffect(() => {
    if (openShowFileInfo) {
      loadData();
    }
    else {
      setCurrentDialogHistoryData(null);
      setCurrentDialogDownloadsData(null);
    }
  }, [openShowFileInfo]);

  useEffect(() => {
    if (activeTab === "versions") {
      const start = paginationCurrentPage[activeTab] * paginationItemsPerPage;
      setCurrentDialogHistoryData(prevState => ({
        ...prevState,
        ["pageData"]: prevState?.versions?.slice(start, start + paginationItemsPerPage)
      }));
    }
    if (activeTab === "downloads") {
      const start = paginationCurrentPage[activeTab] * paginationItemsPerPage;
      setCurrentDialogDownloadsData(prevState => ({
        ...prevState,
        ["pageData"]: prevState?.collection?.slice(start, start + paginationItemsPerPage)
      }));
    }
  }, [paginationCurrentPage, paginationItemsPerPage]);

  const setPaginationCurrentPageEx = (key, value) => {
    setPaginationCurrentPage(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  const handleVersionRollback = async (versionId) => {
    await TemplateFileApi.rollbackInteractTemplateFileVersion(currentDialogHistoryData.file.id, versionId);
    setOpenShowFileInfo(false);
    await loadBaseData();
  }
  
  const downloadVersionFile = async (version) => {
    const fileName = `${version.record.fileName}_${version.record.versionInfo.replace('.', '-')}`;
    await TemplateFileApi.downloadInteractTemplateVersionFile(version.record.id, version.id, fileName);
  }

  const renderActiveTab = () => {
    if (!currentDialogHistoryData && !currentDialogDownloadsData)
      return <></>;

    switch (activeTab) {
      case "versions":
        {
          return (
            <div className="flex gap-8 mt-4 flow-root">
              <div className='flex-auto mb-4' ref={refMenuContainer}>
                <TwTable>
                  <TwTable.Head>
                    <TwTable.Header additionalClass={'sm:pl-6 h-14'}>
                      Name
                    </TwTable.Header>
                    <TwTable.Header additionalClass={'sm:pl-6'}>
                      Updated
                    </TwTable.Header>
                    <TwTable.Header/>
                  </TwTable.Head>
                  <TwTable.Body additionalClass='text-left' noDivider={true}>
                    {currentDialogHistoryData?.pageData && currentDialogHistoryData.pageData.map((version, index) => (
                          <TemplateFileTableRow
                          fileId={version.id}
                          fileData={version.record}
                          addDivider={index < currentDialogHistoryData.length - 1}
                          menuElem={
                            <>
                              {hasCrudAccess && (
                                <MenuContextList
                                  actions={[{
                                      name: "Rollback",
                                      onClick: () => handleVersionRollback(version.id),
                                    }, {
                                      name: "Download",
                                      onClick: () => downloadVersionFile(version),
                                    }]
                                  }
                                  handleDelete="false"
                                  refContainer={refMenuContainer}
                                />
                              )}
                              {!hasCrudAccess && (
                                <></> 
                              )}

                            </>
                          }
                        />
                      // <>
                      // <TwTable.BodyRow key={`ff${version.id}-m`} additionalClass="h-12" useHover={false}>
                      //   <TwTable.BodyCol additionalClass='pb-0'>
                      //     {version.record.fileName}
                      //   </TwTable.BodyCol>
                      //   <TwTable.BodyCol additionalClass='pb-0'>
                      //     {version.record.fileDate && format(
                      //       new Date(version.record.fileDate),
                      //       "MM/dd/yyyy - HH:mm"
                      //     )}
                      //   </TwTable.BodyCol>
                      //   <TwTable.BodyCol additionalClass={'w-2'} rowSpan="2">
                      //     {hasCrudAccess && (
                      //       <MenuContextList
                      //         actions={[{
                      //             name: "Rollback",
                      //             onClick: () => handleVersionRollback(version.id),
                      //           }]
                      //         }
                      //         handleDelete="false"
                      //         refContainer={refMenuContainer}
                      //       />
                      //     )}
                      //   </TwTable.BodyCol>
                      // </TwTable.BodyRow>
                      // <TwTable.BodyRow key={`ff${version.id}-cm`} additionalClass="h-6" addDivider={index < currentDialogHistoryData.length - 1} useHover={false}>
                      //   <TwTable.BodyCol colSpan="2" additionalClass='pt-0 italic'>
                      //     {version.record.comment}
                      //   </TwTable.BodyCol>
                      // </TwTable.BodyRow>
                      // </>
                    ))}
                  </TwTable.Body>
                  {/* {/* {paginationTotalCount[activeTab] > paginationItemsPerPage && ( */}
                    <TwTable.Foot>
                      <TwTable.FootCol colSpan="20">
                        <TwTable.Pagination 
                          numPages={Math.ceil(paginationTotalCount[activeTab] / paginationItemsPerPage)} 
                          currPage={paginationCurrentPage[activeTab]} 
                          setCurrPage={(v) => setPaginationCurrentPageEx(activeTab, v)} 
                          itemsPerPage={paginationItemsPerPage} 
                          setItemPerPage={setPaginationItemsPerPage} 
                          maxItems={paginationTotalCount[activeTab]} 
                          boundaryCount={2} 
                        />
                      </TwTable.FootCol>
                    </TwTable.Foot>
                </TwTable>
              </div>
            </div>          
          );
        }
      case "downloads":
        {
          return (
            <div className="flex gap-8 mt-4 flow-root">
              <div className='flex-auto mb-4'>
                <TwTable>
                  <TwTable.Head>
                    <TwTable.Header additionalClass={'sm:pl-6 h-14'}>
                      Server
                    </TwTable.Header>
                    <TwTable.Header additionalClass={'sm:pl-6'}>
                      Downloaded
                    </TwTable.Header>
                  </TwTable.Head>
                  <TwTable.Body additionalClass='text-left'>
                    {currentDialogDownloadsData?.pageData && currentDialogDownloadsData.pageData.map((file, index) => (
                      <>
                      <TwTable.BodyRow key={`ff${file.id}-m`} additionalClass="h-12" useHover={false}>
                        <TwTable.BodyCol>
                          {file.serverName}
                        </TwTable.BodyCol>
                        <TwTable.BodyCol>
                          {file.updatedDate && format(
                            new Date(file.updatedDate),
                            "MM/dd/yyyy - HH:mm"
                          )}
                        </TwTable.BodyCol>
                      </TwTable.BodyRow>
                      </>
                    ))}
                  </TwTable.Body>
                  {paginationTotalCount > paginationItemsPerPage && (
                    <TwTable.Foot>
                      <TwTable.FootCol colSpan="20">
                        <TwTable.Pagination 
                          numPages={Math.ceil(paginationTotalCount[activeTab] / paginationItemsPerPage)} 
                          currPage={paginationCurrentPage[activeTab]} 
                          setCurrPage={(v) => setPaginationCurrentPageEx(activeTab, v)} 
                          itemsPerPage={paginationItemsPerPage} 
                          setItemPerPage={setPaginationItemsPerPage} 
                          maxItems={paginationTotalCount[activeTab]} 
                          boundaryCount={2} 
                        />
                      </TwTable.FootCol>
                    </TwTable.Foot>
                  )}
                </TwTable>
              </div>
            </div>
          );
        }
      default:
        return <></>;
    }
  };

  return (
    <>
      {/* File information */}
      <GenericModal open={openShowFileInfo} setOpen={setOpenShowFileInfo}
        onConfirm={() => {
          setCurrentDialogDownloadsData(null);
          setOpenShowFileInfo(false);
        }}
        showCancelButton={false}
        confirmButtonText={'Ok'}
          title={`File information: ${fileInfo?.file?.fileName || ""}`}
      >
        {!!currentDialogHistoryData?.versions && (
          <>
          <CmpTabs tabs={tabs} onSelectedTab={(tab) => setActiveTab(tab)} />
          {renderActiveTab()}
          </>
        )}

      </GenericModal>
    </>
  );
}
