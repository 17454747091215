import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { OidcRoutesContext } from "../../../contexts";
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';

import { useEnvironmentGroup } from '../../../hooks/useEnvironmentGroup';

import { getAttributesForEnvironment, publish, load, save, deleteDocuments } from "../../../api/attributeStore";

import Properties from "./properties";
import { History } from "./history";
import { Compare } from "./compare";

import { Tabs as CmpTabs } from "../../../components/Tabs";
import { TwPage } from "../../../components/TailwindPage";
import { TwFormEx } from "../../../components/TailwindForm";

export const AttributeStoreDocument = () => {
  const authContext = useContext(OidcRoutesContext);
  const { environment } = useParams();
  const { documentId } = useParams();
  const history = useHistory();
  const { currentEnvironmentGroupId, dispatchEnableEnvironmentGroupPanelEvent } = useEnvironmentGroup();

  const [activeTab, setActiveTab] = useState("properties");
  const [allDocuments, setAllDocuments] = useState([]);
  const [activeDocument, setActiveDocument] = useState();

  const canEdit = environment === "development";

  const tabs = [
    {
      name: "Properties",
      tab: "properties",
      current: activeTab === "properties",
    },
    {
      name: "History",
      tab: "history",
      current: activeTab === "history",
    },
    {
      name: "Compare",
      tab: "compare",
      current: activeTab === "compare",
    }
  ];

  useEffect(() => {
    dispatchEnableEnvironmentGroupPanelEvent(false);

    const date = new Date();
    date.setDate(date.getDate() - 1);

    fetchDocuments();

    return () => dispatchEnableEnvironmentGroupPanelEvent(true);
  }, [environment]);

  const handleChange = e => {
    const { name, value } = e.target;
    
    setActiveDocument(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const getEnviromentForApi = () => {
    switch (environment) {
      case "production":
        return 3;
      case "test":
        return 2;
      case "development":
      default:
        return 1
      // code block
    }
  }

  const enviromentForApi = getEnviromentForApi();

  const fetchDocuments = async () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    const data = await getAttributesForEnvironment(enviromentForApi);

    //setLastPublished(data.lastPublished)
    setAllDocuments(data ? data.documents : [])
    if (documentId === "new")
    {
      const newDocument = {
        "id": uuidv4(),
        "type": "",
        "category": "",
        "name": "",
        "topic": "",
        "internalOnly": "",
        "changesAllowed": "",
        "retentionPolicy": "",
        "adminCompany": "",
        "plexmode": "",
        "colormode": "",
        "postmode": "",
        "distributionMethod": "",
        "comment": "",
        "active": true,
        "updatedAt": new Date(),
        "updatedBy": "You",
        "updatedById": authContext?.userProfile?.id,
        "updatedByName": authContext?.userProfile?.name,
        "selected": false,
        "environmentGroupId": currentEnvironmentGroupId
      }

      setActiveDocument(newDocument);
    }
    else
      setActiveDocument((data ? data.documents : []).find((m) => m.id === documentId));
  }

  const renderActiveTab = () => {
    if (!activeDocument)
      return <></>;

    switch (activeTab) {
      case "properties":
        return <Properties allDocuments={allDocuments} document={activeDocument} onChange={handleChange} canEdit={canEdit} onCancel={handleCancel} onSubmit={handleSave} />;
      case "history":
        return <History document={activeDocument} onCancel={handleCancel} onSubmit={handleSave} />;
      case "compare":
        return <Compare document={activeDocument} onCancel={handleCancel} onSubmit={handleSave} />;
      default:
        return <></>;
    }
  };

  const handleCancel = async () => {
    history.goBack()
  }

  
  const handleSave = async () => {
    const documentsToSave = [];
    documentsToSave.push(activeDocument);

    const data = await save(documentsToSave)
    setAllDocuments(data.documents)
    // setOpenSidebar(false)

    toast.success("Document saved")
    history.goBack()
  }

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed
            title={activeDocument?.type === "" ? `New document ` : `Document - ${activeDocument?.type ?? ""}`}
          />
        </TwPage.Header>

        <TwFormEx
          buttons = {[]}
        >
          <CmpTabs tabs={tabs} onSelectedTab={(tab) => setActiveTab(tab)} />

          {renderActiveTab()}
        </TwFormEx>

      </TwPage>
    </>
  );
}