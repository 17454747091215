import { get, put, post, destroy, BaseUrl } from "./api";

const controller = 'TemplateEnvironment';

export const getInteractTemplateEnvironments = async (environmentGroupId) => {
  return await get(!!environmentGroupId 
    ? `${controller}/all/${environmentGroupId}`
    : `${controller}/all`);
}

export const getInteractTemplateEnvironmentsNoFiles = async (environmentGroupId) => {
  return await get(!!environmentGroupId 
    ? `${controller}/nf/all/${environmentGroupId}`
    : `${controller}/nf/all`);
}

export const getInteractTemplateEnvironment = async (id) => {
  return await get(`${controller}/${id}`);
}

export const getInteractTemplateEnvironmentNoFiles = async (id) => {
  return await get(`${controller}/nf/${id}`);
}

export const createInteractTemplateEnvironment = async (template) => {
  return await post(`${controller}`, template);
}

export const updateInteractTemplateEnvironment = async (template) => {
  return await put(`${controller}/${template.id}`, template);
}

export const updateInteractTemplateEnvironmentOrder = async (template1, template2, direction) => {
  return await put(`${controller}/order`, {
    templateId1: template1.id,
    templateId2: template2.id,
    directionUp: direction < 0
  });
}

export const validateInteractTemplateEnvironmentOrder = async (environmentGroupId) => {
  return await put(`${controller}/vorder`, {
    templateId1: environmentGroupId,
    templateId2: "",
    directionUp: false
  });
}

export const deleteInteractTemplateEnvironment = async (templateId) => {
  return await destroy(`${controller}/${templateId}`);
}

export const publishFileBetweenTemplateEnvironments = async (files, fromTemplate, toTemplate) => {
  const model = { files: files.map(x => { return {fromId: x.from.id, toId: (x.to?.id || "")}; })};
  return await put(`${controller}/${toTemplate.id}/files/publish/${fromTemplate.id}`, model);
}

export const resetFailedDownloads = async (templateId, serverId) => {
  return await put(`${controller}/reset/${templateId}/${serverId}`);
}

export const getInteractTemplateServerBackups = async (templateId, serverId) => {
  return await get(`${controller}/${templateId}/backup/${serverId}`);
}

export const getInteractTemplateServerFiles = async (depType, depId) => {
  return await get(`${controller}/files/${depType}/${depId}`);
}

export const copyFileToLocalGroup = async (templateId, fileId, toEnvGrpId) => {
  // console.log("copyFileToLocalGroup", "templateId", templateId, "fileId", fileId, "toEnvGrpId", toEnvGrpId);
  return await post(`${controller}/copy/${templateId}/${fileId}/${toEnvGrpId}`);
}