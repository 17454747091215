import axios from 'axios'
import { config } from '../utils/config'
import { apiErrorHandler } from './apiErrorHandler'
import { saveAs } from 'file-saver';

/**
 * Custom handler for status code
 * @callback StatusCodeHandler
 */

/**
 * @typedef {Object} StatusCodeHandlers
 * @prop {number} statusCode
 * @prop {StatusCodeHandler} handler
 */

/**
 * @typedef {Object} ApiOptions
 * @prop {Ojbect} axiosParams - see axios documentation for possible values
 * @prop {StatusCodeHandlers[]} statusCodeHandlers
 */

export function setAuthHeader(token) {
  axios.defaults.headers.common['Authorization'] = token ? 'Bearer ' + token : ''
}

export const BaseUrl = {
  Api: config.apiBaseUrl,
  Identity: config.identityBaseUrl,
  Centerpoint: config.centerpointBaseUrl
}

/**
 * @param {string} path 
 * @param {string} baseUrl 
 * @param {ApiOptions} options  
 */
export const get = async (path, baseUrl = BaseUrl.Api, options = null) => {
  // options = addSafeMessageExceptionHandlerToOptions(options);
  const axiosParams = options?.axiosParams || {}
    try {
    const result = await axios({
      method: "get",
      url: `${baseUrl}/${path}`,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    console.log("get - err:", `${baseUrl}/${path}`, err);
    apiErrorHandler(err, options?.statusCodeHandlers)
  }
}

/** 
 * @param {string} path 
 * @param {string} filename 
 * @param {string} baseUrl 
 * @param {ApiOptions} options 
 */
export const download = async (path, filename, baseUrl = BaseUrl.Api, options = null) => {
  const axiosParams = options?.axiosParams || {}
  try {
    const result = await axios({
      method: "get",
      responseType: 'blob', // important
      url: `${baseUrl}/${path}`,
      ...axiosParams
    })

    saveAs(result.data, filename);
  }
  catch (err) {
    apiErrorHandler(err, options?.statusCodeHandlers)
  }
}

/** 
 * @param {string} path 
 * @param {string} filename 
 * @param {function} fnHandleData 
 * @param {string} baseUrl 
 * @param {ApiOptions} options 
 */
export const downloadEx = async (path, filename, fnHandleData = null, baseUrl = BaseUrl.Api, options = null) => {
  const axiosParams = options?.axiosParams || {}
  try {
    const result = await axios({
      method: "get",
      responseType: 'blob', // important
      url: `${baseUrl}/${path}`,
      ...axiosParams
    })

    if (!!fnHandleData)
      fnHandleData(result.data);
    saveAs(result.data, filename);

    return result.data
  }
  catch (err) {
    apiErrorHandler(err, options?.statusCodeHandlers)
  }
}

/**
 * @param {string} path 
 * @param {object} data 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const put = async (path, data, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {}

  try {
    const result = await axios({
      method: "put",
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    apiErrorHandler(err, options?.statusCodeHandlers)
  }
}

/**
 * @param {string} path 
 * @param {Object} data 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const post = async (path, data, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {}
  

  try {
    const result = await axios({
      method: "post",
      url: `${baseUrl}/${path}`,
      data,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    console.log("post - err", `${baseUrl}/${path}`, err);
    apiErrorHandler(err, options?.statusCodeHandlers)
  }
}

/** 
 * @param {string} path 
 * @param {ApiOptions} options 
 * @param {string} baseUrl 
 * @returns 
 */
export const destroy = async (path, options = null, baseUrl = BaseUrl.Api) => {
  const axiosParams = options?.axiosParams || {}
  try {
    const result = await axios({
      method: "delete",
      url: `${baseUrl}/${path}`,
      ...axiosParams
    })
    return result.data
  }
  catch (err) {
    apiErrorHandler(err, options?.statusCodeHandlers)
  }
}

// const addSafeMessageExceptionHandlerToOptions = (options) => {
//   let safeMessageExceptionHandler = {
//     statusCode: 500
//   }
//   if (options === null || options === undefined)
//     options = {statusCodeHandlers: []};
//   if (options.HasOwnProperty("statusCodeHandlers")) {
//     options.push(safeMessageExceptionHandler);
//   } else {

//   }
// }