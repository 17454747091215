import React, { useEffect, useContext, Fragment } from 'react';
//import { Link, useNavigate } from "react-router-dom";
import * as _ from 'lodash';
import { Menu, Disclosure, Transition, Popover } from "@headlessui/react";
import {
  XIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import {
  faHome,
  faListUl,
} from '@fortawesome/free-solid-svg-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context';

import { config } from '../../utils/config';
import { setAuthHeader } from '../../api/api'
import { getCustomerDetails } from '../../api/customer';

import { TwNavBar } from "../../components/TailwinNavBar";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ErrorLayout({children}) {
  // const navigate = useNavigate();
  
  const navigation = [
    { /*icon: faHome,*/ name: 'Home', route: `/admin`, featureEnabled: true },
    { /*icon: faListUl,*/ name: 'Smart forms', route: `/admin/smartforms`, featureEnabled: true },
  ]

  const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
  ];



  // const validateLicense = async () => {
  //   const customerDetails = await getCustomerDetails()
  //   const hasLicense = customerDetails?.activeProducts?.find(a => a.id === config.productLicense)
  //   if (hasLicense) {
  //     navigate('/'); // redirects to root to prevent site from hanging on a blank screen
  //   }
  //   else {
  //     navigate('/missing-license');
  //   }
  // }

  return (
    <div className="relative min-h-screen flex flex-col bg-white dark:bg-gray-900">
      <TwNavBar navigation={navigation} userNavigation={userNavigation} helpUrl={config.docsUrl} />
      <main className="main-container bg-gray-50 flex-1 z-0 focus:outline-none">
        <div className="max-w-screen-3xl mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col pb-10">
            <div className="max-w-screen-3xl">
            <p className="text-2xl font-bold mt-6">{children}</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}