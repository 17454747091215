import toast from 'react-hot-toast';

export const useErrorHandler = () => {

  const onToast = (message, toastId) => {
    if (toastId) {
      toast.error(message, {
        id: toastId
      })
    }
    else {
      toast.error(message);
    }
  }

  return {
    handleApiError: (error, message, showValidationErrors = false, toastId = null) => {
      if (error.customErrorMessage) {
        onToast(error.customErrorMessage, toastId);
      }
      else if (error.validationErrors && showValidationErrors) {
        onToast(
          <>
            <div>
              {message}
            </div>
            {
              error.validationErrors?.map((item, index) => {
                return (
                  <div key={`errorItem-${index}`}>
                    {
                      item.errors?.map((e, i) => {
                        return (
                          <div key={`error-${index}-${i}`}>
                            {e}
                          </div>
                        );
                      })
                    }
                  </div>
                );
              })
            }
          </>, toastId)
      }
      else {
        onToast(message instanceof Function ? message() : message, toastId);
      }
    },
    errorHasValidationErrors: (error) => {
      return error.validationErrors
    }
  }
}