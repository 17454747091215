import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import  { CheckIcon, ExclamationIcon, XCircleIcon, InformationCircleIcon } from '@heroicons/react/solid'

import { useClassNames, useModalHelper, useToastAction } from "@metaforcelabs/metaforce-core";

import './styles.scss';

import { InteractStatusContext, EnvironmentGroupContext, CurrentDataContext } from '../../../contexts/index.js';

import { useFeature } from '../../../hooks/useFeature.js';

import * as Constants from '../../../utils/constants'

import * as InteractApi from "../../../api/interact.js";
import * as TemplateApi from "../../../api/interactTemplateEnvironment.js";

import { LOGICFILESTATUSES, LOGICFILEORIGINS, getFileStatusesBetweenTemplates } from "../../../utils/LogicFileUtilities";
import { renderSvgIcon, getEnvironmentIconKey } from "../../../utils/svgIconUtils";

import DashboardEventModal from "./dashboardEventModal.js";
import { EnvironmentGroupContainerCard } from "../Environments/environmentGroupContainerCard.js"

import { Panel as CmpPanel } from "../../../components/Panel/index.js";
import { Button as CmpButton } from "../../../components/Button/index.js";
import { Label as CmpLabel, TwCollapsableLabel } from "../../../components/Label/index.js";
import { TwPage } from "../../../components/TailwindPage/index.js";


export default function Dashboardv2() {
  const pageId = "6BDAA1A9-2B00-4563-A791-8C7B6880C51D";

  const { hasFeature } = useFeature();
  // const { environmentGroups } = useEnvironmentGroup();
  const {environmentGroups} = useContext(EnvironmentGroupContext);
  const { classNames } = useClassNames();

  const { getViewSettings, dispatchViewSettingsUpdate } = useContext(CurrentDataContext);

  const [viewSettings, setViewSettings] = useState(getViewSettings(pageId));

  const [hasCrudAccess, setHasCrudAccess] = useState(false);
  const [loading, setLoading] = useState(true)
  const [initialized, setInitialized] = useState(false)

  const [allCustomerEnvironments, setAllCustomerEnvironments] = useState([])
  const [allEnvironmentGroupData, setAllEnvironmentGroupData] = useState([])
  const [allEnvironmentGroups, setAllEnvironmentGroups] = useState([])
  const [hasMultipleGroups, setHasMultipleGroups] = useState([])
  const loadAction = useToastAction();

  const {validationResults} = useContext(InteractStatusContext);

  const {hasValidStatus} = useContext(InteractStatusContext);
    
  const loadBaseData = async () => {
    loadAction.execute(async () => {
      setLoading(true);
      const environments = await InteractApi.getInteractEnvironments();
      setAllCustomerEnvironments(environments);

      var hasCrudAccess = hasFeature(Constants.templatesConfigurationFeatureCode);
      setHasCrudAccess(hasCrudAccess);

      const envGrps = environmentGroups.filter(g => g.isActive);
      setAllEnvironmentGroups(envGrps);

      let envGrpData = {};
      await Promise.all(envGrps.map(async group => {
        const tplEnvironments = await TemplateApi.getInteractTemplateEnvironments(group.id);
        if (!!tplEnvironments && tplEnvironments.length > 0)
          envGrpData[group.id] = {group: group, tplEnvironments: tplEnvironments};
      }));
      setAllEnvironmentGroupData(envGrpData);

      setHasMultipleGroups(!!envGrpData
        ? Object.keys(envGrpData).length > 1 
        : false);

      if (validationResults != null)
        setInitialized(true);
      setLoading(false);
    }, "Failed to load (Dashboardv2)")
  }

  // useEffect(() => {
  //   console.log("Dashboardv2", "loadBaseData", "environmentGroups", environmentGroups);
  //   loadBaseData();
  // }, []);

  useEffect(() => {
    if (!!environmentGroups)
      loadBaseData();
  }, [environmentGroups]);

  useEffect(() => {
    if (validationResults != null)
      setInitialized(true);
  }, [validationResults]);

  const setVs = (settings) => {
    setViewSettings(settings);
    dispatchViewSettingsUpdate(pageId, settings);
    // console.log("Dashboardv2", "setVs", "settings", settings);
  }

  // useEffect(() => {
  //   console.log("Dashboardv2", "viewSettings", viewSettings);
  // }, [viewSettings]);

  return (
    <>
      <TwPage>
        {loading && (
          <div className='flex flex-wrap'>
            <CmpLabel text={"Loading dashboard..."} additionalClass='text-lg font-bold h-6' />
          </div>
        )}

        {!loading && initialized && allEnvironmentGroups && allEnvironmentGroupData && (
          <>
            {!hasValidStatus && (
              <CmpLabel type={CmpLabel.types.info} additionalClass='text-lg' text="Validating server statuses..." />
            )}
            <div className={classNames('grid grid-cols-1 mt-8 mb-4', hasMultipleGroups ? "dashboard:grid-cols-2 gap-8" : "")}>
              {allEnvironmentGroups/*.filter(g => !g.isVirtual)*/.map((group, i) => (
                <>
                  {allEnvironmentGroupData.hasOwnProperty(group.id) && (
                    <Dashboardv2Card
                      group={allEnvironmentGroupData[group.id].group}
                      templateEnvironments={allEnvironmentGroupData[group.id].tplEnvironments}
                      hasMultipleGroups={hasMultipleGroups}
                      defaultCollapsed={viewSettings && viewSettings.hasOwnProperty(group.id) ? viewSettings[group.id].isCollapsed : false}
                      allCustomerEnvironments={allCustomerEnvironments}
                      hasCrudAccess={hasCrudAccess}
                      validationResults={validationResults}
                      viewSettings={viewSettings}
                      setViewSettings={setVs}
                      collapsable={false}
                    />
                  )}
                </>
              ))}
            </div>
          </>
        )}
      </TwPage>
    </>
  );
}

function Dashboardv2Card({
  group,
  templateEnvironments,
  hasMultipleGroups,
  defaultCollapsed,
  allCustomerEnvironments,
  hasCrudAccess,
  validationResults,
  viewSettings,
  setViewSettings,
  collapsable = true
 }) {
  const labelId = "924F07F4-FFA5-493C-8A97-5B7C656FF552";

  const history = useHistory();
  const { hasFeature } = useFeature();
  const loadAction = useToastAction();
  const { classNames } = useClassNames();
  const showEventModalHelper = useModalHelper();

  const {hasValidStatus, validationSeverity} = useContext(InteractStatusContext);
  const [allTemplateEnvironments, setAllTemplateEnvironments] = useState(templateEnvironments)

  useEffect(() => {
    loadBaseData();
  }, []);

  const loadBaseData = async () => {  
    loadAction.execute(async () => {
      const tplEnvironments = !!templateEnvironments ? templateEnvironments : await TemplateApi.getInteractTemplateEnvironments(group.id);

      let prevTemplate = null;
      const updTplEnvs = sortTemplateEnviromentsOnOrder(tplEnvironments?.filter(x => x.active)).map(template => {
        prevTemplate = addImageAndUpdatedFileCountToTemplateItem(template, prevTemplate);
        return prevTemplate;
      });

      setAllTemplateEnvironments(updTplEnvs);
    }, "Failed to load (Dashboardv2Card)")
  }


  const sortTemplateEnviromentsOnOrder = (tplEnvironments) => {
    return tplEnvironments.sort((a, b) => a.order > b.order ? 1 : -1);
  }

  const addImageAndUpdatedFileCountToTemplateItem = (template, prevTemplate) => {
    let newTemplate = { ...template };
    const updateFilesCount = getUpdateFilesCount(template, prevTemplate);
    newTemplate.imageData = getEnvironmentIconKey(template.customerEnvironment);
    newTemplate.hasUpdateFiles = updateFilesCount !== 0;
    newTemplate.updatedFilesCount = updateFilesCount;

    return newTemplate;
  }

  const getUpdateFilesCount = (template, prevTemplate) => {
    if (template.order === 1)
      return 0;
    if (!prevTemplate)
      return 0;

    const fileStatus = getFileStatusesBetweenTemplates(prevTemplate, template);
    if (fileStatus)
      return fileStatus.filter(status => status.status === LOGICFILESTATUSES.fromNewer || status.status === LOGICFILESTATUSES.fromOnly).length;

    return 0;
  }

  const renderImage = ({key, colorClass='text-gray-900 dark:text-gray-300', additionalClass='mt-6 mx-auto', useStroke=false, useFill=true} = {}) => {
    return renderSvgIcon({key: key, sizeClass: 'h-10 w-10', colorClass: colorClass, additionalClass: additionalClass, useStroke: useStroke, useFill: useFill})
  }

  const renderButtonContent = (upperLabel, imageKey, useStroke=false, useFill=true) => {
    return (
      <div>
        <CmpLabel additionalClass='text-xs' text={upperLabel} />
        {renderImage({key: imageKey, useStroke: useStroke, useFill: useFill})}
        <CmpLabel additionalClass='text-sm mt-6' text="&nbsp;" />
      </div>
    );
  }

  const onCollapseChanged = (isCollapsed) => {

    //console.log("Dashboardv2Card", "onCollapseChanged", "group", group, "isCollapsed", isCollapsed);

    if (isCollapsed === null || isCollapsed === undefined)
      return;

    const grp = group;

    let vs = viewSettings;
    if (!vs)
      vs = {};
    if (vs.hasOwnProperty(grp.id))
    {
      const oldVs = vs[grp.id];
      vs[grp.id] = {...oldVs, isCollapsed: isCollapsed}
    }
    else
    {
      vs[grp.id] = {isCollapsed: isCollapsed}
    }
    setViewSettings(vs);
  }

  const onLabelCollapseChanged = (lblId, isCollapsed) => {
    let vs = viewSettings;
    if (!vs)
      vs = {};

    // console.log("Dashboardv2Card", "onLabelCollapseChanged", "group", group);

    if (vs.hasOwnProperty(group.id))
    {
      const oldVs = vs[group.id];
      oldVs[lblId] = {isCollapsed: isCollapsed};
      vs[group.id] = {...oldVs}
    }
    else
    {
      let labelData = vs.hasOwnProperty(lblId) ? vs[lblId] : {};
      labelData[lblId] = {isCollapsed: isCollapsed};
      vs[group.id] = labelData
    }
    setViewSettings(vs);
  }

  const isLabelCollapsed = (lblId) => {
    if (viewSettings?.hasOwnProperty(group.id))
    {
      const ld = viewSettings[group.id];
      if (ld.hasOwnProperty(lblId))
      {
        return ld[lblId].isCollapsed || false;
      }
    }
    return false;
  }

  const renderTemplatesOverview = () => {

    //console.log("allTemplateEnvironments", "allTemplateEnvironments", allTemplateEnvironments);

    return ((!allTemplateEnvironments || allTemplateEnvironments.length <= 0)
      ? <></>
      : <>
        <TwCollapsableLabel label="Templates" additionalClass='' defaultCollapsed={isLabelCollapsed(labelId)} onCollapseChanged={(v) => onLabelCollapseChanged(labelId, v)}>
          <div className="mt-0 flow-root pt-4">
            <div className={`-my-2`}>
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="flex gap-2 justify-center">
                  {/* Info images */}
                  <div className="flex justify-center text-gray-600 dark:text-gray-400">
                    <div className="flex-initial px-4 w-12">
                      <div className="flex h-26 relative inline-flex">
                        <div>
                          <CmpLabel type={CmpLabel.types.simple} additionalClass='text-sm' text="&nbsp;" />
                          {renderImage({key: "document-content"})}
                          <CmpLabel type={CmpLabel.types.simple} additionalClass='text-xs mt-2 ml-2' text="MetaTool" />
                        </div>
                      </div>
                    </div>
                    <div className="flex-initial px-4 w-12">
                      <div className="h-28 relative inline-flex">
                        <div>
                          <CmpLabel type={CmpLabel.types.simple} additionalClass='text-lg' text="&nbsp;" />
                          {renderImage({key: "arrow-right", useStroke: true, useFill: false})}
                          <CmpLabel type={CmpLabel.types.simple} additionalClass='text-sm mt-6' text="&nbsp;" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Template Environments */}
                  {/* <div className="flex gap-2 flex-wrap"> */}
                  <div className="flex gap-2 flex-wrap">
                    {allTemplateEnvironments.map((template, i) => (
                      <div className="flex-none w-28">
                        <CmpButton
                          id={`updbtn${i}}`}
                          variant={CmpButton.variants.custom}
                          className="w-28 h-32 bg-white text-gray-900 hover:bg-gray-200 hover:bg-text-white dark:bg-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:bg-text-gray-300 ring-black ring-opacity-5 dark:ring-gray-700 relative inline-flex"
                          onClick={() => history.push(`/templates/publish/${group.id}/${template.order}`)}
                        >
                          {renderButtonContent(allCustomerEnvironments.find(({ value }) => value === template.customerEnvironment)?.name, template.imageData)}
                          {hasCrudAccess && template.hasUpdateFiles &&
                            <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-0 border-white rounded-full -top-2 -right-2 border-red-500">
                              {template.updatedFilesCount}
                            </div>
                          }
                        </CmpButton>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TwCollapsableLabel>
      </>
    );
  }

  const hasSettingSeverity = (envGrpId, customerEnvironment, severity) => {
    return !!validationResults 
    && validationResults.hasOwnProperty(envGrpId)
    && validationResults[envGrpId].find(x => x.customerEnvironment === customerEnvironment).severity === severity; 
  }

  const hasStatus = (envGrpId, severity) => {
    return hasValidStatus 
      && !!validationResults 
      && validationResults.hasOwnProperty(envGrpId)
      && ((Array.isArray(severity) && validationResults[envGrpId].filter(x => severity.includes(x.severity)).length > 0)
        || (!Array.isArray(severity) && validationResults[envGrpId].filter(x => x.severity === severity).length > 0));
  }

  const renderStatusIcon = (envGrpId, setting) => {
    // console.log("renderStatusIcon", "envGrpId", envGrpId, "setting", setting, "validationResults", validationResults);
    if (hasSettingSeverity(envGrpId, setting.customerEnvironment, validationSeverity.error))
      return <XCircleIcon className='h-5 w-5 text-red-500'/>;
    if (hasSettingSeverity(envGrpId, setting.customerEnvironment, validationSeverity.warning))
      return <ExclamationIcon className='h-5 w-5 text-yellow-500'/>;
    if (hasSettingSeverity(envGrpId, setting.customerEnvironment, validationSeverity.info))
      return <><CheckIcon className='h-5 w-5 text-green-500'/><InformationCircleIcon className='h-5 w-5 text-blue-500'/></>;
    if (hasSettingSeverity(envGrpId, setting.customerEnvironment, validationSeverity.good))
      return <CheckIcon className='h-5 w-5 text-green-500'/>;
    return <></>;
  }

  const showEvents = (envGrpId, customerEnvironment) => {
    const setting = !!validationResults 
      && validationResults.hasOwnProperty(envGrpId)
      && validationResults[envGrpId]?.find(x => x.customerEnvironment === customerEnvironment);
    if (!setting)
      return;

    //console.log("Dashboardv2", "showEvents", "setting", setting);

    const envName = allCustomerEnvironments.find(x => x.value === customerEnvironment)?.name || "";
    showEventModalHelper.setActivetItem({
      environmentGroupId: envGrpId,
      setting: setting,
      title: `Events for ${envName}`,
    });
    showEventModalHelper.open();
  }

  const renderLightText = (envGrpId, severity, text1, text2) => {
    if (hasStatus(envGrpId,severity))
      return (
        <>
          <CmpLabel additionalClass='my-auto'>
            <p>{text1}</p>
            <p>{text2}</p>
          </CmpLabel>
        </>
      );
    else
      return <><p></p></>;
  }

  return (
    <>
      <div className={classNames(/*hasMultipleGroups ? "" :*/ " w-full mx-auto")} style={{maxWidth: "56.25rem"}}>
        <EnvironmentGroupContainerCard
          group={group}
          defaultCollapsed={defaultCollapsed}
          collapsable={true}
          visibleSettings={{showActiveState: false}}
          onCollapseChanged={onCollapseChanged}
        >
          {group.isVirtual && (
            <>
              <CmpLabel type={CmpLabel.types.info}>
                <p>Status of virtual groups are not supported</p>
                <p>Please go to the centerpoint installation for the group</p>
              </CmpLabel>
            </>
          )}
          {/* <div className='flex flex-col md:flex-row mx-auto mt-4 w-max'> */}
          {!group.isVirtual && (
            <>
              <div className='flex flex-col md:flex-row mt-4'>
                <div className="flex flex-1 flex-row" style={{height: "260px"}}>
                  <div class={classNames("flex-none trafficLight", hasValidStatus ? "" : " wait")}>
                    <span class={classNames("red", hasStatus(group.id, validationSeverity.error) ? " active" : "")}></span>
                    <span class={classNames("yellow", hasStatus(group.id, validationSeverity.warning) ? " active" : "")}></span>
                    <span class={classNames("green", hasStatus(group.id, [validationSeverity.good, validationSeverity.info]) ? " active" : "")}></span>
                  </div>
                  <div className='flex-auto ml-6 min-w-72'>
                    <div className='grid grid-rows-3 h-full' style={{minWidth: "16.75rem"}}>
                      {hasValidStatus && renderLightText(group.id, validationSeverity.error, "We have detected one or more errors in your environments.", "Please go to the environment for how to fix it.")}
                      {hasValidStatus && renderLightText(group.id, validationSeverity.warning, "We have detected one or more warnings in your environments.", "Please go to the environment for how to fix it.")}
                      {hasValidStatus && renderLightText(group.id, [validationSeverity.good, validationSeverity.info], "One or more environments are reporting to run OK.", "A good health for the system is to only have a green light.")}
                    </div>
                  </div>
                </div>
                <div className='flex-none w-60 mx-auto mt-4 md:ml-6 md:mt-0 float-none md:float-right '>
                  <CmpPanel additionalClass='w-full h-full'>
                  <CmpLabel text="Click to access environment:" textClass='text-xs' additionalClass='text-blue-500 dark:text-blue-500' />
                  {validationResults && validationResults.hasOwnProperty(group.id) && validationResults[group.id].map((setting) => (
                    <div className='flex my-4 cursor-pointer' onClick={() => showEvents(group.id, setting.customerEnvironment)}>
                      {renderSvgIcon({key: getEnvironmentIconKey(setting.customerEnvironment), sizeClass: 'h-5 w-5', additionalClass: 'mr-2'})}
                      <CmpLabel text={allCustomerEnvironments.find(({ value }) => value === setting.customerEnvironment)?.name} additionalClass='flex-1 text-base font-bold' />
                      {hasValidStatus && renderStatusIcon(group.id, setting)}
                    </div>
                  ))}
                  </CmpPanel>
                </div>
              </div>
              <div className='mt-10 mb-4'>
              {allTemplateEnvironments && allTemplateEnvironments.length > 0 && renderTemplatesOverview()}
              </div>
            </>
          )}
        </EnvironmentGroupContainerCard>
      </div>

      <DashboardEventModal modalHelper={showEventModalHelper} />

    </>
  );
}
