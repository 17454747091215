
import { v4 as uuidv4 } from 'uuid';

const ButtonGroupButton = ({buttonData, index, dataCount}) => {
  const common = buttonData.current 
    ? "bg-brand-blue text-sm font-semibold text-gray-100 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 dark:ring-gray-600 dark:hover:bg-gray-800 "
    :"bg-white text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 dark:bg-gray-800 dark:text-white dark:ring-gray-600 dark:hover:bg-gray-800 ";
  let className = common + "relative -ml-px inline-flex items-center px-3 py-2"
  if (dataCount === 1)
  {
    className = common + "relative inline-flex items-center rounded-md px-3 py-2"
  }
  else
  {
    if (index === 0)
      className = common + "relative inline-flex items-center rounded-l-md px-3 py-2"
    if (index === (dataCount - 1))
      className = common + "relative -ml-px inline-flex items-center rounded-r-md px-3 py-2"
  }
  if (buttonData.className)
    className += " " + buttonData.className;
  const id = uuidv4();
  return (
    <button
      id={id}
      key={id}
      type="button"
      className={className}
      onClick={() => buttonData.onClick()}
    >
      {buttonData?.content}
    </button>
  );
};

const ButtonGroup = ({
  buttons = []
}) => {
  return (
    <>
      <span className="isolate inline-flex rounded-md shadow-sm">
      {buttons && buttons.map((buttonData, index, data) => (
        <ButtonGroupButton buttonData={buttonData} index={index} dataCount={data.length} />
        ))}
      </span>
    </>
  );
};


export { ButtonGroup }
