import React, { useEffect, Fragment } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Menu, Disclosure, Transition, Popover } from "@headlessui/react";
import {
  XIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import {
  faHome,
} from '@fortawesome/free-solid-svg-icons'
import { useReactOidc } from '@axa-fr/react-oidc-context';
import './styles.scss';
import { ViewGridIcon } from '@heroicons/react/outline';
import { config } from '../../utils/config';
import { setAuthHeader } from '../../api/api'
import { getCustomerDetails } from '../../api/customer';

import { TailwindUiContext } from '../../contexts';
import { TwNavBar } from "../../components/TailwinNavBar";
import { tailwindUiSettings } from '../../tailwindUiSettings.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Oidc = () => {
  const history = useHistory();
  const { oidcUser } = useReactOidc();

  useEffect(() => {
    if (oidcUser) {
      setAuthHeader(oidcUser.access_token)
      validateLicense()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oidcUser])
  
  // fontawesome icons not supported at the moment in the TwNavBar component
  const navigation = [
    { /*icon: faHome,*/ name: 'Dashboard', route: `/`, featureEnabled: true },
  ]


  const userNavigation = [
    { name: "Your Profile", href: "#" },
    { name: "Settings", href: "#" },
    /* { name: "Change Company", onClick: changeCompany },
    { name: "Sign out", href: "#", onClick: logout }, */
  ];



  const validateLicense = async () => {
    const customerDetails = await getCustomerDetails()
    const hasLicense = customerDetails?.activeProducts?.find(a => a.id === config.productLicense)
    if (hasLicense) {
      history.push('/'); // redirects to root to prevent site from hanging on a blank screen
    }
    else {
      history.push('/missing-license');
    }
  }

  const GridIconSendBaseUrl = () => (
    <a href={config.centerpointUiBaseUrl} rel="noreferrer" target="_blank" className={""}>
      <ViewGridIcon className="h-8 w-auto text-gray-800 hover:text-gray-600 cursor-pointer" />
    </a>
  )

  return (
    <TailwindUiContext.Provider value={tailwindUiSettings}>
      <div className="relative min-h-screen flex flex-col bg-white dark:bg-gray-900">
        <TwNavBar navigation={navigation} userNavigation={userNavigation} helpUrl={config.docsUrl} />

        <main className="main-container bg-brand-background flex-1 z-0 focus:outline-none dark:bg-gray-800">
          <div className={`${tailwindUiSettings.fullwidth ? "max-w-screen-3xl" : "max-w-screen-3xl"} h-full mx-auto inset-0 py-6 px-4 sm:px-6 lg:px-8`}>
            <div className="flex flex-col pb-10 h-full">
              <div className={`${tailwindUiSettings.fullwidth ? "max-w-screen-3xl" : "max-w-screen-3xl"} h-full`}>
                <p className="text-2xl font-bold mt-6 dark:text-white">Loading...</p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </TailwindUiContext.Provider>
  )
}

export default Oidc;