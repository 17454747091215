import { useClassNames } from "../../hooks/useClassNames";

/**
 * @param {*} RadioButton 
 */
export const RadioButton = ({ id, name, label, onClick, enabled, checked, value }) => {
  const classNames = useClassNames();

  return (
    <div key={id} className="flex items-center">
      <input
        id={id}
        name={name}
        type="radio"
        value={value}
        className={classNames.classNames(
          'bg-white focus:ring-brand-blue h-4 w-4 text-brand-blue border-gray-300 dark:ring-brand-blue dark:ring-offset-gray-800 focus:ring-2 dark:border-gray-600',
        )}
        disabled={!enabled}
        onClick={onClick}
        checked={checked}
      />
      <label
        htmlFor={id}
        className={classNames.classNames(
          enabled
            ? 'text-gray-700 dark:text-gray-300'
            : 'text-gray-300 dark:text-gray-700',
          ' ml-3 block text-xs font-medium ',
        )}
      >
        {label}
      </label>
    </div>
  );
}