import { Field, ErrorMessage } from 'formik';

export const FormikSelectInput = ({
  name,
  label,
  formikProps,
  options,
  type = null,
  placeholder = null,
  required = false,
}) => {
  const hasError = formikProps?.errors && formikProps.errors[name];
  const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500'
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 dark:text-gray-300">
        {label}
        {required === true && <span className="text-red-400"> *</span>}
      </label>
      <div className="mt-1">
        <Field
          as={type || "select"}
          name={name}
          className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-brand-blue dark:focus:border-brand-blue ${hasError ? errorClasses : ''}`}
          placeholder={placeholder}
        >
        {
          options.map(option => {
            return (
              <option key={option.value} value={option.value}>{option.name}</option>
            );
          })
        }
        </Field>
        <ErrorMessage name={name} component="div" className="text-red-500" />
      </div>
    </div>
  )
}