import React, { useEffect, useState, useContext } from 'react';

import {EnvironmentGroupContextProvider} from './environmentGroupContextProvider.js'
import {InteractStatusContextProvider} from './interactStatusContextProvider.js'
import {CurrentDataContextProvider} from './currentDataContextProvider.js'
import {DebugContextProvider} from "./debugContextProvider.js"

export const InteractAdminAppContextProvider = ({ children }) => {
  return (
    <EnvironmentGroupContextProvider>
      <InteractStatusContextProvider>
        <CurrentDataContextProvider>
          <DebugContextProvider>
            {children}
          </DebugContextProvider>
        </CurrentDataContextProvider>
      </InteractStatusContextProvider>
    </EnvironmentGroupContextProvider>
  );
}