import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useToastAction } from '../../../hooks/useToastAction';
import { useEnvironmentGroup } from '../../../hooks/useEnvironmentGroup';
import { useFeature } from '../../../hooks/useFeature';

import { DebugContext } from '../../../contexts';
import { CurrentDataContext } from '../../../contexts';

import * as Constants from '../../../utils/constants'

import * as InteractApi from "../../../api/interact";
import * as TemplateApi from "../../../api/interactTemplateEnvironment.js";
import * as TemplateFileApi from "../../../api/interactTemplateFile.js";

import { LOGICFILESTATUSES, LOGICFILEORIGINS, getFileStatusesBetweenTemplates } from "../../../utils/LogicFileUtilities";
import { renderSvgIcon, getEnvironmentIconKey } from "../../../utils/svgIconUtils";

import { EnvironmentGroupContainerCard } from "../Environments/environmentGroupContainerCard.js"

import  UploadLogicFileDialog from "./uploadLogicFileDialog.js"

import { Select as CmpSelect } from "../../../components/Select";
import { Button as CmpButton } from "../../../components/Button";
import { Textbox as CmpTextbox } from "../../../components/Textbox";
import { Label as CmpLabel } from "../../../components/Label";
import { TwPage } from "../../../components/TailwindPage";
import GenericModal from '../../../components/Modals/genericModal';
import { FileUpload as CmpFileUpload } from "../../../components/FileUpload";

export default function TemplatesMaintainance() {
  const pageId = "8BB4F642-E994-4CC4-AAB7-CEF6A10D3886";

  const { hasFeature } = useFeature();
  const { environmentGroups } = useEnvironmentGroup();

  const { getViewSettings, dispatchViewSettingsUpdate } = useContext(CurrentDataContext);

  const [viewSettings, setViewSettings] = useState(getViewSettings(pageId));

  const [hasCrudAccess, setHasCrudAccess] = useState(false);

  const [allCustomerEnvironments, setAllCustomerEnvironments] = useState([]);
  const [allEnvironmentGroups, setAllEnvironmentGroups] = useState([]);
  const [allEnvironmentGroupData, setAllEnvironmentGroupData] = useState([])

  const loadAction = useToastAction();

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      const environments = await InteractApi.getInteractEnvironments();
      setAllCustomerEnvironments(environments);

      var hasCrudAccess = hasFeature(Constants.templatesConfigurationFeatureCode);
      setHasCrudAccess(hasCrudAccess)

      const envGrps = environmentGroups.filter(g => g.isActive);
      setAllEnvironmentGroups(envGrps);

      let envGrpData = {};
      await Promise.all(envGrps.map(async group => {
        const tplEnvironments = await TemplateApi.getInteractTemplateEnvironments(group.id);
        if (!!tplEnvironments && tplEnvironments.length > 0)
          envGrpData[group.id] = {group: group, tplEnvironments: tplEnvironments};
      }));
      setAllEnvironmentGroupData(envGrpData);
    }, "Failed to load (TemplatesUpdate)")
  }

  useEffect(() => {
    if (!!environmentGroups)
      loadBaseData();
  }, [environmentGroups]);

  // useEffect(() => {
  //   console.log("TemplatesMaintainance", "viewSettings", viewSettings);
  // }, [viewSettings]);

  const setVs = (settings) => {
    setViewSettings(settings);
    dispatchViewSettingsUpdate(pageId, settings);
    // console.log("TemplatesMaintainance", "setVs", "settings", settings);
  }

  const onReloadTemplateEvironments = async (groupId) => {
    let envGrpData = allEnvironmentGroupData;
    const group = allEnvironmentGroups.find(g => g.id === groupId);
    if (!!group) {
      const tplEnvironments = await TemplateApi.getInteractTemplateEnvironments(group.id);
      if (!!tplEnvironments && tplEnvironments.length > 0)
        envGrpData[group.id] = {group: group, tplEnvironments: tplEnvironments};
      setAllEnvironmentGroupData(envGrpData);
    }
    return envGrpData[groupId]?.tplEnvironments;
  }

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed 
            title="Environment maintainance of logic files" 
            description="Publish logic files between environments, or manage files within an environment"
          />
        </TwPage.Header>

        <div className="mt-4 space-y-4">
          {allEnvironmentGroups && allEnvironmentGroupData && allEnvironmentGroups.map((g, i) => {
              return (
                <>
                  {allEnvironmentGroupData.hasOwnProperty(g.id) && (
                    <TemplatesMaintainanceCard
                      group={allEnvironmentGroupData[g.id].group}
                      templateEnvironments={allEnvironmentGroupData[g.id].tplEnvironments}
                      defaultCollapsed={viewSettings && viewSettings.hasOwnProperty(g.id) ? viewSettings[g.id].isCollapsed : i !== 0}
                      allCustomerEnvironments={allCustomerEnvironments}
                      hasCrudAccess={hasCrudAccess}
                      viewSettings={viewSettings}
                      setViewSettings={setVs}
                      onReloadTemplateEvironments={onReloadTemplateEvironments}
                  />
                )}
               </>
               )
            })
          }
        </div>
      </TwPage>
    </>
  );
}

function TemplatesMaintainanceCard({
  group,
  templateEnvironments,
  defaultCollapsed,
  allCustomerEnvironments,
  hasCrudAccess,
  viewSettings,
  setViewSettings,
  onReloadTemplateEvironments}) {
  const debugContext = useContext(DebugContext);

  // Only in debug - Start
  const [debugMode, ] = useState(debugContext.debug);
  const [openBackupFileUpload, setOpenBackupFileUpload] = useState(false);
  const [uploadTemplateEnvironment, setUploadTemplateEnvironment] = useState(null);
  const [uploadTemplateServer, setUploadTemplateServer] = useState(null);
  // Only in debug - End

  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileComment, setFileComment] = useState("");

  const [openFileUpload, setOpenFileUpload] = useState(false);

  const history = useHistory();
  const [allTemplateEnvironments, setAllTemplateEnvironments] = useState([])
  const loadAction = useToastAction();

  hasCrudAccess = hasCrudAccess && !group.isVirtual;

  const loadBaseData = async (reloadEnvironments = false) => {
    loadAction.execute(async () => {
      // console.log("TemplatesMaintainanceCard", "loadBaseData", "reloadEnvironments", reloadEnvironments);

      const tplEnvironments = reloadEnvironments
        ? await onReloadTemplateEvironments(group.id)
        : (!!templateEnvironments ? templateEnvironments : await TemplateApi.getInteractTemplateEnvironments(group.id));

      // console.log("TemplatesMaintainanceCard", "loadBaseData", "tplEnvironments", tplEnvironments);

      let prevTemplate = null;
      const updTplEnvs = sortTemplateEnviromentsOnOrder(tplEnvironments?.filter(x => x.active)).map(template => {
        prevTemplate = addImageAndUpdatedFileCountToTemplateItem(template, prevTemplate);
        return prevTemplate;
      });

      // console.log("TemplatesMaintainanceCard", "loadBaseData", "updTplEnvs", updTplEnvs);

      setAllTemplateEnvironments(updTplEnvs);
    }, "Failed to load (TemplatesUpdateCard)")
  }

  const sortTemplateEnviromentsOnOrder = (tplEnvironments) => {
    return tplEnvironments.sort((a, b) => a.order > b.order ? 1 : -1);
  }

  const addImageAndUpdatedFileCountToTemplateItem = (template, prevTemplate) => {
    let newTemplate = { ...template };
    const updateFilesCount = getUpdateFilesCount(template, prevTemplate);
    newTemplate.imageData = getEnvironmentIconKey(template.customerEnvironment);
    newTemplate.hasUpdateFiles = updateFilesCount !== 0;
    newTemplate.updatedFilesCount = updateFilesCount;

    return newTemplate;
  }

  useEffect(() => {
    loadBaseData();
  }, []);

  const renderImage = ({key, colorClass='text-gray-900 dark:text-gray-300', additionalClass='mt-6 mx-auto', useStroke=false, useFill=true} = {}) => {
    return renderSvgIcon({key: key, sizeClass: 'h-20 w-20', colorClass: colorClass, additionalClass: additionalClass, useStroke: useStroke, useFill: useFill})
  }

  const getUpdateFilesCount = (template, prevTemplate) => {
    // console.log("TemplatesUpdateCard", "getUpdateFilesCount", "template", template, "prevTemplate", prevTemplate);

    if (template.order === 1)
      return 0;
    if (!prevTemplate)
      return 0;

    const fileStatus = getFileStatusesBetweenTemplates(prevTemplate, template);

    // console.log("TemplatesUpdateCard", "getUpdateFilesCount", "fileStatus", fileStatus);

    if (fileStatus)
      return fileStatus.filter(status => status.status === LOGICFILESTATUSES.fromNewer || status.status === LOGICFILESTATUSES.fromOnly).length;

    return 0;
  }

  const getFileContentAsBase64String = file => {
    return new Promise(resolve => {
      let content = "";
      let reader = new FileReader();

      reader.onload = () => {
        content = reader.result;
        resolve(content);
      };

      reader.readAsDataURL(file);
    });
  };

  const onFileSelected = async (file) => {
    setFileToUpload(file);
  }

  const onFileComment = async (e) => {
    setFileComment(e.target.value);
  }

  const onFileUpload = async (fileState, comment) => {
    var file = fileState.fileToUpload;
    try
    {
      const result = await getFileContentAsBase64String(file);

      const endpointOrder = 1;
      const prevTemplateEnvironment = allTemplateEnvironments.find(x => x.order === endpointOrder);

      // await TemplateFileApi.uploadInteractTemplateFile(
      await TemplateFileApi.uploadInteractTemplateFileChunked(
        prevTemplateEnvironment.id,
        file.name,
        file.size,
        new Date(file.lastModified),
        comment || "",
        result.split(',')[1], LOGICFILEORIGINS.manual);
      await loadBaseData(true);
    }
    catch (err)
    {
      console.log("ERROR:", err);
    }
    finally
    {
      setFileToUpload(null);
      setOpenFileUpload(false);
    }
  };

  const uploadFile = async () => {
    setOpenFileUpload(true);
  }

  // Only in debug - Start

  const uploadBackupFile = async () => {
    setUploadTemplateEnvironment(null);
    setUploadTemplateServer(null);
    setOpenBackupFileUpload(true);
  }

  const onBackupFileUpload = async (fileState, templateId, serverId) => {
    var file = fileState.fileToUpload;
    try
    {
      let utf8Encode = new TextEncoder();

      const result = await getFileContentAsBase64String(file);

      const tpl = allTemplateEnvironments.find(t => t.id === templateId);
      const srv = tpl?.servers?.find(s => s.serverId.toString() === serverId);
      const machineIdentity = srv?.name;

      await TemplateFileApi.uploadInteractServerBackupFileChunked(
        templateId,
        machineIdentity,
        file.name,
        file.size,
        new Date(file.lastModified),
        result.split(',')[1], LOGICFILEORIGINS.manual);
      await loadBaseData();
    }
    catch (err)
    {
      console.log("ERROR:", err);
    }
    finally
    {
      setFileToUpload(null);
      setOpenBackupFileUpload(false);
    }
  };
  // Only in debug - End

  const renderButtonContent = (upperLabel, imageKey, lowerLabel, imageClass='', useStroke=false, useFill=true) => {
    return (
      <div>
        <CmpLabel additionalClass='text-lg' text={upperLabel} />
        {renderImage({key: imageKey, useStroke: useStroke, useFill: useFill})}
        <CmpLabel additionalClass='text-sm mt-6' text={lowerLabel} />
      </div>
    );
  }

  const onCollapseChanged = (isCollapsed) => {
    let vs = viewSettings;
    if (!vs)
      vs = {};
    if (vs.hasOwnProperty(group.id))
    {
      const oldVs = vs[group.id];
      vs[group.id] = {...oldVs, isCollapsed: isCollapsed}
    }
    else
    {
      vs[group.id] = {isCollapsed: isCollapsed}
    }
    setViewSettings(vs);
  }

  const onButtonClick = (order) => {
    //var encoded = btoa(JSON.stringify(viewSettings));
    history.push(`/templates/publish/${group.id}/${order}`);
  }

  const startEnvironment = allTemplateEnvironments?.length > 0 && allCustomerEnvironments?.length > 0
    ? allCustomerEnvironments.find(({ value }) => value === allTemplateEnvironments[0].customerEnvironment)?.name
    : null;

  return (
    <>
      <EnvironmentGroupContainerCard
        group={group}
        defaultCollapsed={defaultCollapsed}
        visibleSettings={{showActiveState: false}}
        onCollapseChanged={onCollapseChanged}
      >
        {allTemplateEnvironments?.length > 0 && (
          <>
            <div className="mt-12 flow-root mb-8">
            {/* <div className={`-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 `}> */}
              <div className={`-mx-4 -my-2 sm:-mx-6 lg:-mx-8 `}>
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="flex gap-8 justify-center">
                    {/* Info images */}
                    <div className="flex justify-center text-gray-600 dark:text-gray-400">
                      <div className="flex-initial px-4 py-2 w-20">
                        <div className="flex h-52 relative inline-flex">
                          <div>
                            <CmpLabel type={CmpLabel.types.simple} additionalClass='text-lg' text="&nbsp;" />
                            {renderImage({key: "document-content"})}
                            <CmpLabel type={CmpLabel.types.simple} additionalClass='text-sm mt-6 ml-2' text="MetaTool" />
                          </div>
                        </div>
                      </div>
                      <div className="flex-initial px-4 py-2 w-20">
                        <div className="h-52 relative inline-flex">
                          <div>
                            <CmpLabel type={CmpLabel.types.simple} additionalClass='text-lg' text="&nbsp;" />
                            {renderImage({key: "arrow-right", useStroke: true, useFill: false})}
                            <CmpLabel type={CmpLabel.types.simple} additionalClass='text-sm mt-6' text="&nbsp;" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Template Environments */}
                    <div className="flex gap-8 flex-wrap">
                      {allTemplateEnvironments.map((template, i) => (
                        <div className="flex-0 w-40">
                          <CmpButton
                            id={`updbtn${i}}`}
                            variant={CmpButton.variants.custom}
                            className="w-40 h-52 bg-white text-gray-900 hover:bg-gray-200 hover:bg-text-white dark:bg-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:bg-text-gray-300 ring-black ring-opacity-5 dark:ring-gray-700 relative inline-flex"
                            onClick={() => onButtonClick(template.order)}
                          >
                            {renderButtonContent(allCustomerEnvironments.find(({ value }) => value === template.customerEnvironment)?.name, template.imageData, `${template.files.length} logic files`)}
                            {hasCrudAccess && template.hasUpdateFiles &&
                              <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-red-500">
                                {template.updatedFilesCount}
                              </div>
                            }
                          </CmpButton>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {hasCrudAccess && (
              <>
                <CmpLabel text="&nbsp;" additionalClass='border-b border-gray-300 dark:border-gray-600 h-2' />
                <div className='flex mt-10'>
                  <CmpLabel additionalClass="mr-8 my-auto">
                    Make a manual file upload to <b>{startEnvironment}</b>
                  </CmpLabel>
                  <CmpButton
                    variant={CmpButton.variants.secondary}
                    className="justify-center mr-8"
                    onClick={uploadFile}
                    disabled={allTemplateEnvironments.length === 0}
                  >
                    Upload logic file
                  </CmpButton>
                  {debugMode && (
                    <CmpButton
                      variant={CmpButton.variants.secondary}
                      className="justify-center mr-8"
                      onClick={uploadBackupFile}
                      disabled={allTemplateEnvironments.length === 0}
                    >
                      Upload backup file
                    </CmpButton>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </EnvironmentGroupContainerCard>

      <UploadLogicFileDialog
        openFileUpload={openFileUpload}
        setOpenFileUpload={setOpenFileUpload}
        onFileUpload={onFileUpload}
        fileToUpload={fileToUpload}
        setFileToUpload={setFileToUpload}
        fileComment={fileComment}
        onFileComment={onFileComment}
        onFileSelected={onFileSelected}
      />

      {/* Only in debug - Start */}
      <GenericModal open={openBackupFileUpload} setOpen={setOpenBackupFileUpload}
        onConfirm={() => onBackupFileUpload({fileToUpload}, uploadTemplateEnvironment, uploadTemplateServer)}
        onCancel={() => {
          setOpenBackupFileUpload(false);
          setFileToUpload(null);
        }}
        showCancelButton={true}
        confirmButtonDisabled={!fileToUpload || !uploadTemplateEnvironment || !uploadTemplateServer}
        confirmButtonText={'Upload'}
        title="Upload backup file">
        <div className="mt-4">
          <CmpFileUpload
            name="backupFile"
            label={"Server Backup file"}
            fileExtension=".zip"
            onChange={onFileSelected}
          />
          {fileToUpload &&
            <CmpLabel text={`Selected file: ${fileToUpload.name}`} />
          }
          <div className='mt-2'>
            <CmpSelect
              name="templateEnvironments"
              value={uploadTemplateEnvironment}
              label={"Template Environment"}
              onChange={e => { setUploadTemplateEnvironment(e.target.value); var servers = (allTemplateEnvironments.find(x => x.id === e.target.value)?.servers); if (!!servers) setUploadTemplateServer(servers[0].serverId); }}
              options={[{name: "Select environment", value: null}].concat(allTemplateEnvironments.map(x => { return {name: allCustomerEnvironments.find(({ value }) => value === x.customerEnvironment)?.name, value: x.id};}))}
            />
            <CmpSelect
              name="templateServers"
              value={uploadTemplateServer}
              label={"Server"}
              onChange={e => setUploadTemplateServer(e.target.value)}
              options={!!uploadTemplateEnvironment ? allTemplateEnvironments.find(x => x.id === uploadTemplateEnvironment)?.servers?.map(x => { return {name: x.name, value: x.serverId};}) ?? [] : []}
            />
          </div>

        </div>
      </GenericModal>
      {/* Only in debug - End */}
      </>
  );
}