import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { EnvironmentGroupContext, InteractStatusContext, DebugContext } from '../../../contexts/index.js';

import { useToastAction } from '../../../hooks/useToastAction.js';

import * as DeploymentTemplateApi from "../../../api/deployTemplate.js";
import * as DeploymentApi from "../../../api/deployment.js";
import * as TemplateApi from "../../../api/interactTemplateEnvironment.js";
import * as TemplateFileApi from "../../../api/interactTemplateFile.js";

import './styles.scss';

import {wizardStepUserInput_LoadRequiredData, wizardStepUserInput_UpdateProps} from '../Environments/WizardSteps/deploymentUserInput.js'
import WizardStepKubernetesDeploy from '../Environments/WizardSteps/deploymentKubernetesDeploy.js'
import WizardStepResult from '../Environments/WizardSteps/deploymentResult.js'

import { EnvironmentGroupContainerCard } from "../Environments/environmentGroupContainerCard.js"
import CreateDocumentDialog from "./createDocumentDialog.js"

import { Panel as CmpPanel } from "../../../components/Panel/index.js";
import { Label as CmpLabel } from "../../../components/Label/index.js";
import { Button as CmpButton } from "../../../components/Button/index.js";
import Wizard, {WizardNav} from '../../../components/Wizard/index.js'
import { TwPage } from "../../../components/TailwindPage/index.js";
import { TwTable } from "../../../components/TailwindTable/index.js";
import { object } from 'yup';

export default function ExtrasDeployments() {
  const history = useHistory();

  const {environmentGroups} = useContext(EnvironmentGroupContext);
  const {interactEnvironments, dispatchUpdateInteractEnvironments} = useContext(InteractStatusContext);
  const { debugData, dispatchSetValue, dispatchToogleBool} = useContext(DebugContext);

  const [allDeploymentTemplates, setAllDeploymentTemplates] = useState([]);
  const [allDeploymentTemplateItems, setAllDeploymentTemplateItems] = useState([]);
  const [deploymentResult, setDeploymentResult] = useState(null);
  const [deploymentError, setDeploymentError] = useState(null);
  const [deploymentData, setDeploymentData] = useState([]);
  const [deployStatus, setDeployStatus] = useState({active: false, update: false, forceRestart: false});

  const [allCustomerEnvironments, setAllCustomerEnvironments] = useState([])
  const [allSoapSettings, setAllSoapSettings] = useState([]);
  const [soapSettings, setSoapSettings] = useState(null);
  const [environmentOptions, setEnvironmentOptions] = useState([]);

  const [loadingBaseData, setLoadingBaseData] = useState(true);
  const [loadingSessionData, setLoadingSessionData] = useState(true);

  const [wizardData, setWizardData] = useState({action: "update"});
  const [wizardCurrentStep, setWizardCurrentStep] = useState(1);

  const [openCrDocDialog, setOpenCrDocDialog] = useState(false);
  const [metaFiles, setMetaFiles] = useState([]);
  const [sessionId, setSessionId] = useState([]);

  const refWizard = useRef();

  const resetDeploymentData = () => {
    setDeploymentResult(null);
    setDeploymentError(null);
    setDeployStatus({active: false, update: false, forceRestart: false});
  }

  const loadAction = useToastAction();
  const deployAction = useToastAction();

  const hasCrudAccess = true;

    const loadBaseData = async () => {
    loadAction.execute(async () => {
      console.log("ExtrasDeployments", "loadBaseData", "debugData", debugData);

      const tst_uuid = uuidv4("Blargh");
      console.log("ExtrasDeployments", "loadBaseData", "tst_uuid", tst_uuid);

      // Force reload (and revalidation) of the InteractEnvironments
      await dispatchUpdateInteractEnvironments();

      const templateItems = await DeploymentTemplateApi.getDeploymentTemplateItems();

      const templates = await DeploymentTemplateApi.getDeploymentTemplates();
      
      setAllDeploymentTemplates(templates);
      setAllDeploymentTemplateItems(templateItems);
      setLoadingBaseData(false);
    }, "Failed to load (ExtrasDeployments)")
  }

  const loadSessionData = async () => {
    loadAction.execute(async () => {
      let grpDplyData = [];
      setLoadingSessionData(true);
      environmentGroups.map(group =>  {
        grpDplyData.push({});
      });

      await Promise.all(environmentGroups.map(async (group, idx) => {
        const data = await wizardStepUserInput_LoadRequiredData(group.id);

        setAllCustomerEnvironments(data.allEnvironments);
        setEnvironmentOptions(data.customerEnvironments);
        setSoapSettings(data.currSoapSetting);
        setAllSoapSettings(data.allSoapSettings);  // This must be set after setSoapSettings

        const tplEnvironments = await TemplateApi.getInteractTemplateEnvironmentsNoFiles(group.id);

        const deploySessions = await DeploymentApi.getDeploymentSessions(group.id);

        const deployData = deploySessions.map(s => {
          const tplenv = tplEnvironments.find(t => !!(t.servers.find(x => x.deploymentSessionId === s.id)));
          const server = tplenv?.servers.find(x => x.deploymentSessionId === s.id);
          return {session: s, templateEnvironment: tplenv, server: server};
        });

        // console.log("ExtrasDeployments", "loadSessionData", "deployData", deployData, "tplEnvironments", tplEnvironments);

        if (deployData.length > 0)
          grpDplyData[idx] = ({group: group, deployData: deployData});
      }));
      // Remove empty environment group data items (no data for the group)
      grpDplyData = grpDplyData.filter(d => Object.keys(d).length > 0);

      setDeploymentData(grpDplyData);
      setLoadingSessionData(false);
    }, "Failed to load session data (ExtrasDeployments)")
  }

  useEffect(() => {
    resetDeploymentData();
    loadBaseData();
  }, []);

  useEffect(async () => {
    if (!!environmentGroups)
    {
      loadSessionData();
    }
  }, [environmentGroups]);

  useEffect(async () => {
    if (deployStatus.active === true) {
      if (deployStatus.forceRestart === true) {
        setDeployStatus(prev => {
          return { ...prev, ["active"]: false }
        });
      }
    } else {
      if (deployStatus.forceRestart === true) {
        setDeployStatus(prev => {
          return { ...prev, ["active"]: true, ["forceRestart"]: false }
        });
      }
    }
  }, [deployStatus]);
 
  const handleDeleteSession = (id) => {
    if (!!id)
    {
      deployAction.execute(async () => {
        var result = await DeploymentApi.removeDeployment(id);
        await loadSessionData();
      }, "Failed to remove deployment");
    }
  }

  const handleCreateTestDocument = (id) => {
    if (!!id)
    {
      deployAction.execute(async () => {
        var result = await DeploymentApi.createDeploymentTestDocument(id);
        let pdfWindow = window.open("");
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64," + result +"'></iframe>");
      }, "Failed to create test document");
    }
  }

  const openCreateDocument = async (data) => {
    console.log("openCreateDocument", "data", data);
    if (!!data)
    {
      console.log("openCreateDocument", "data", data);
      const tpl = await TemplateApi.getInteractTemplateEnvironment(data.templateEnvironment.id);
      console.log("openCreateDocument", "tpl", tpl);
      setSessionId(data.session.id);
      setMetaFiles(tpl.files);
      setOpenCrDocDialog(true);
      
    }
  }

  const handleCreateDocument = async (id, metafile, document) => {
    setOpenCrDocDialog(false);
    if (!!id && !!metafile && !!document)
    {
      console.log("handleCreateDocument", "id", id, "metafile", metafile, "document", document);
      let errorMsg = "Failed to create document";
      deployAction.execute(async () => {
        let result = [];
        try {
          result = await DeploymentApi.createDeploymentDocument(id, metafile, document);
          console.log("handleCreateDocument", "result.length", !!result ? result.length.toString() : "null");
        } catch (err) {
          if (!!err.customErrorMessage)
            errorMsg = err.customErrorMessage;
          throw err;
        }
        let pdfWindow = window.open("");
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64," + result +"'></iframe>");
      }, errorMsg);
    }
  }

  const handleValidateDocuments = async (data) => {
    console.log("handleValidateDocuments", "data", data);
    if (!!data)
    {
      const tpl = await TemplateApi.getInteractTemplateEnvironment(data.templateEnvironment.id);
      Promise.all(tpl.files.map(async (file) => {
        const docs = await TemplateFileApi.getTemplateDocuments(file.id);
        Promise.all(docs.map(async (doc, idx) => {
          let hasError = false;
          let errorMsg = "Failed to create document";
          let result = [];
          try {
            result = await DeploymentApi.createDeploymentDocument(data.session.id, file.fileName, doc);
          } catch (err) {
            hasError = true;
            if (!!err.customErrorMessage)
              errorMsg = err.customErrorMessage;
          }

          if (idx === 0)
            console.log(file.fileName);
          console.log("  ", doc, hasError ? errorMsg : "OK");
        }));
      }));
    }
  }

  const handleUpdateSession = async (sessionId, tplEnvId, serverId) => {
    if (!!sessionId && !!tplEnvId && !!serverId)
    {
      deployAction.execute(async () => {
        setWizardData({action: "update"});

        const depData = deploymentData.find(d => d.templateEnvironment.id === tplEnvId);
        console.log("handleUpdateSession", "depData", depData);
        const tplEnvironment = depData?.templateEnvironment;
        //console.log("handleUpdateSession", "tplEnvironment", tplEnvironment);
        const server = tplEnvironment?.servers.find(s => s.serverId === serverId);
        console.log("handleUpdateSession", "server", server);

        const deploymentName = depData.session.deploymentName;
        // console.log("handleUpdateSession", "deploymentName", deploymentName);
        const ceNum = Number(tplEnvironment.customerEnvironment);
        // console.log("handleUpdateSession", "ceNum", ceNum);
        const serverIdentifier = "";
        // console.log("handleUpdateSession", "serverIdentifier", serverIdentifier);
        const soapSettingsTmp = allSoapSettings.find(settings => Number(settings.customerEnvironment) === ceNum);
        // console.log("handleUpdateSession", "soapSettingsTmp", soapSettingsTmp);
        const customerEnvironment = Number(soapSettings?.customerEnvironment ?? 0);
        // console.log("handleUpdateSession", "customerEnvironment", customerEnvironment);

        const extraKeyValueMap = [
          {key: "tplEnvironment", value: tplEnvironment},
          {key: "server", value: server},
        ]

        // console.log("handleUpdateSession", "extraKeyValueMap", extraKeyValueMap);

        wizardStepUserInput_UpdateProps({data: wizardData, update: updateWizardData},
          tplEnvironment.environmentGroupId,
          environmentOptions,
          allDeploymentTemplates[0],  // Change this
          deploymentName,
          serverIdentifier,
          soapSettingsTmp,
          extraKeyValueMap
        );
    
        setDeployStatus({active: true, update: true, forceRestart: deployStatus.active});
      }, "Failed to update deployment");
    }
  }

  const updateWizardData = (key, value) => 
  {
    setWizardData(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  const hideWizard = () => {
    console.log("hideWizard", "wizardCurrentStep", wizardCurrentStep);

    setWizardData({action: "update"});
    setDeployStatus({active: false, update: false, forceRestart: false});
  }

  const onBeforeClose = async () => {
    console.log("onBeforeClose", "wizardCurrentStep", wizardCurrentStep);
    hideWizard();
    await loadSessionData();
  }

  const postDeploy = async (props, deploymentResult) => {
    const data = props.data;

    console.log("postDeploy", "data", data, "deploymentResult", deploymentResult);

    setDeploymentResult(deploymentResult);
    }

  const handleStepChange = (stats) => {
    console.log("handleStepChange", "stats.activeStep", stats.activeStep);
    setWizardCurrentStep(stats.activeStep);
  }

  const wizardSteps = [WizardStepKubernetesDeploy, WizardStepResult];

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed 
            title="Deployments" 
          />
        </TwPage.Header>

        {deployStatus.active && (
          <CmpPanel>
            <Wizard
              ref={refWizard}
              nav={
                <WizardNav 
                  steps={wizardSteps}
                  currentStep={wizardCurrentStep}
                />
              }
              onStepChange={handleStepChange}
              isLazyMount
            >
              <WizardStepKubernetesDeploy
                currentStep={wizardCurrentStep}
                data={wizardData}
                update={updateWizardData}
                postDeploy={postDeploy} 
                onBeforeClose={onBeforeClose}
              />
              <WizardStepResult
                currentStep={wizardCurrentStep}
                data={wizardData}
                history={history}
                onBeforeClose={onBeforeClose} 
              />
            </Wizard>
          </CmpPanel>
        )}

        <CmpLabel type={CmpLabel.types.warn} additionalClass="mb-8">
          <p className="font-bold">
              This page is intended as a Metaforce administration page and should not be used by others.
          </p>
          <p>
              Clicking a red button updates the database and should only be used by someone that understands the backend/database structure.
          </p>
        </CmpLabel>

        {loadingSessionData && (
          <div className='flex flex-wrap'>
            <CmpLabel text={"Loading deployments..."} additionalClass='font-bold h-6' />
          </div>
        )}
        {deploymentData && deploymentData.map(data => (
          <div className='mb-4'>
            <ExtrasDeploymentsCard
              group={data.group}
              deploymentData={data.deployData}
              hasCrudAccess={hasCrudAccess}
              handleCreateTestDocument={handleCreateTestDocument}
              handleCreateDocument={openCreateDocument}
              handleValidateDocuments={handleValidateDocuments}
              handleUpdateSession={handleUpdateSession}
              handleDeleteSession={handleDeleteSession}
              defaultCollapsed={false}
            />
          </div>
        ))}

        <CreateDocumentDialog
          sessionId={sessionId}
          metaFiles={metaFiles}
          handleCreateDocument={handleCreateDocument}
          openCrDocDialog={openCrDocDialog}
          setOpenCrDocDialog={setOpenCrDocDialog}
        />
      </TwPage>
    </>
  );
}

function ExtrasDeploymentsCard({
  group,
  deploymentData,
  hasCrudAccess,
  defaultCollapsed,
  handleCreateTestDocument,
  handleCreateDocument,
  handleValidateDocuments,
  handleUpdateSession,
  handleDeleteSession,
  collapsable = true
 }) {
  return (
    <>
      <div className="" >
        <EnvironmentGroupContainerCard
          group={group}
          defaultCollapsed={defaultCollapsed}
          collapsable={true}
          visibleSettings={{showActiveState: false}}
        >
          {deploymentData && deploymentData.length > 0 && (
              <TwTable additionalClass='mt-2 mb-6'>
                <TwTable.Head>
                  <TwTable.Header additionalClass={'uppercase md:pl-6'}>
                    Session Id 
                  </TwTable.Header>
                  {/* <TwTable.Header additionalClass={'uppercase md:pl-6'}>
                    DeploymentName
                  </TwTable.Header> */}
                  <TwTable.Header additionalClass={'uppercase md:pl-6'}>
                    Image Version
                  </TwTable.Header>
                  <TwTable.Header additionalClass={'uppercase md:pl-6'}>
                    Storage container
                  </TwTable.Header>
                  <TwTable.Header additionalClass={'uppercase md:pl-6'}>
                    Kubernetes Namespace
                  </TwTable.Header>
                  <TwTable.Header additionalClass={'uppercase md:pl-6'}>
                    Server Id
                  </TwTable.Header>
                  <TwTable.Header />
                </TwTable.Head>
                <TwTable.Body>
                  {deploymentData.map((data) => (
                    <TwTable.BodyRow key={data.session.id} useHover={hasCrudAccess}>
                      <TwTable.BodyCol additionalClass={'font-medium'}>
                        {data?.session?.sessionId}
                      </TwTable.BodyCol>
                      {/* <TwTable.BodyCol>
                        {data.session.deploymentName}
                      </TwTable.BodyCol> */}
                      <TwTable.BodyCol>
                        {data?.session?.configuration?.values[".Values.image.version"]}
                      </TwTable.BodyCol>
                      <TwTable.BodyCol>
                        {data?.session?.storage?.storageName}
                      </TwTable.BodyCol>
                      <TwTable.BodyCol>
                        {data?.session?.configuration?.values['.Values.namespace']}
                      </TwTable.BodyCol>
                      <TwTable.BodyCol>
                        {data?.server?.name}
                      </TwTable.BodyCol>
                      <TwTable.BodyCol>
                        <div className='grid grid-cols-3 gap-2'>
                          <CmpButton
                            variant={CmpButton.variants.secondary}
                            className="justify-center"
                            onClick={() => handleCreateTestDocument(data.session.id)}
                          >
                            Create Test Document
                          </CmpButton>
                          <CmpButton
                            variant={CmpButton.variants.secondary}
                            className="justify-center"
                            onClick={() => handleCreateDocument(data)}
                          >
                            Create Document
                          </CmpButton>
                          <CmpButton
                            variant={CmpButton.variants.custom}
                            className="justify-center bg-yellow-600 text-gray-200 hover:bg-yellow-500 hover:bg-text-white ring-yellow-900 ring-opacity-5"
                            onClick={() => handleValidateDocuments(data)}
                          >
                            Validate Documents
                          </CmpButton>
                          <CmpButton
                            variant={CmpButton.variants.custom}
                            className="justify-center bg-red-500 text-gray-200 hover:bg-red-600 hover:bg-text-white ring-red-900 ring-opacity-5"
                            disabled={!data.server}
                            onClick={() => handleUpdateSession(data.session.id, data.templateEnvironment.id, data.server.serverId)}
                          >
                            <div className='h-full flex flex-col'>
                              <div className='flex-1' />  
                                Update
                              <div className='flex-1' />  
                            </div>
                          </CmpButton>
                          <CmpButton
                            variant={CmpButton.variants.custom}
                            className="justify-center bg-red-500 text-gray-200 hover:bg-red-600 hover:bg-text-white ring-red-900 ring-opacity-5"
                            onClick={() => handleDeleteSession(data.session.id)}
                          >
                            <div className='h-full flex flex-col'>
                              <div className='flex-1' />  
                                Delete
                              <div className='flex-1' />  
                            </div>
                          </CmpButton>
                        </div>
                      </TwTable.BodyCol>
                    </TwTable.BodyRow>
                  ))}
                </TwTable.Body>
              </TwTable>
          )}
        </EnvironmentGroupContainerCard>
      </div>
    </>
  );
 }


