import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useParams, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
import toast from 'react-hot-toast';
import { format } from "date-fns";

import "./styles.scss";

import { useClassNames } from "../../../hooks/useClassNames";
import { useEnvironmentGroup } from '../../../hooks/useEnvironmentGroup';

import { SearchIcon } from "@heroicons/react/outline";

import { getAttributesForEnvironment, publish, load, save, deleteDocuments } from "../../../api/attributeStore";

import { OidcRoutesContext } from "../../../contexts";

import ActivateConfirmation from "./activateConfirmation";
import PublishConfirmation from "./publishConfirmation";
import DeleteConfirmation from "./deleteConfirmation";
import LoadMenu from "./loadMenu";

import { Checkbox as CmpCheckbox } from "../../../components/Checkbox";
import { Search as CmpSearch } from "../../../components/Search";
import { Button as CmpButton } from "../../../components/Button";
import { TwTable } from "../../../components/TailwindTable";
import { TwPage } from "../../../components/TailwindPage";

export const AttributeStoreDocumentList = () => {
  const authContext = useContext(OidcRoutesContext);
  const history = useHistory();
  const { environment } = useParams();
  const { classNames } = useClassNames();
  const { environmentGroups, currentEnvironmentGroupId } = useEnvironmentGroup();

  const [documentsToShow, setDocumentsToShow] = useState([]);
  const [allDocuments, setAllDocuments] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [lastPublished, setLastPublished] = useState(Date.now);
  // const [openSidebar, setOpenSidebar] = useState(false);
  const [activeDocument, setActiveDocument] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [onlyShowEdit, setOnlyShowEdit] = useState(false);
  const [onlyShowActive, setOnlyShowActive] = useState(false);
  const [openPublishConfirmation, setOpenPublishConfirmation] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openActivateConfirmation, setOpenActivateConfirmation] = useState(false);

  const getEnviromentForApi = () => {
    switch (environment) {
      case "production":
        return 3;
      case "test":
        return 2;
      case "development":
      default:
        return 1
      // code block
    }
  }

  const enviromentForApi = getEnviromentForApi();

  useEffect(() => {
    const date = new Date();
    date.setDate(date.getDate() - 1);

    setOnlyShowActive(false);
    setOnlyShowEdit(false);

    setLastPublished(date)
    fetchDocuments();
  }, [environment, currentEnvironmentGroupId]);

  useEffect(() => {
    setDocumentsToShow(allDocuments);
  }, [allDocuments]);

  useEffect(() => {

    setCsvData(documentsToShow
      .map(row => ({
        ...row,
        updatedAtFormatted: format(new Date(row.updatedAt), "MM/dd/yyyy - HH:mm"),
        updatedByFormatted: row.updatedByName || 'System',
        activeAtFormatted: row.active.toString(),
      }))
    );
  }, [documentsToShow]);

  const fetchDocuments = async () => {
    const date = new Date();
    date.setDate(date.getDate() + 1);

    const data = await getAttributesForEnvironment(enviromentForApi, currentEnvironmentGroupId);
    setLastPublished(data.lastPublished)
    setAllDocuments(data ? data.documents : [])
  }

  // const handleSidebar = () => {
  //   if (openSidebar) {
  //     setActiveDocument(null);
  //   }

  //   setOpenSidebar(!openSidebar);
  // };

  const handleSelectAll = () => {
    documentsToShow.forEach((item) => {
      item.selected = !selectAll;
    });

    setDocumentsToShow([...documentsToShow])
    setSelectAll(!selectAll)
  }

  const handleItemSelected = (document) => {
    documentsToShow.forEach((item) => {
      if (item.id === document.id) {
        item.selected = !document.selected
      }
    });

    setDocumentsToShow([...documentsToShow])
  }

  const handleCreate = async () => {
    history.push(`/admin/attributestoredoc/new/${environment}`);
  }

  const handleLoad = async (environmentToLoad) => {
    const data = await load(enviromentForApi, environmentToLoad)
    setAllDocuments(data.documents)

    toast.success("Documents loaded")
  }

  const handleShowOnlyEdited = () => {

    if (!onlyShowEdit) {
      setDocumentsToShow(
        allDocuments.filter(m => m.hasChanged && (onlyShowActive ? m.active : true))
      )
    }
    else {
      setDocumentsToShow(
        allDocuments.filter(m => onlyShowActive ? m.active : true)
      )
    }

    setOnlyShowEdit(!onlyShowEdit);
  }

  const handleShowOnlyActive = () => {
    if (!onlyShowActive) {
      setDocumentsToShow(
        allDocuments.filter(m => m.active && (onlyShowEdit ? m.hasChanged : true))
      )
    }
    else {
      setDocumentsToShow(
        allDocuments.filter(m => onlyShowEdit ? m.hasChanged : true)
      )
    }

    setOnlyShowActive(!onlyShowActive);
  }

  const handleSave = async () => {
    const documentsToSave = [];
    documentsToSave.push(activeDocument);

    const data = await save(documentsToSave)
    setAllDocuments(data.documents)

    toast.success("Document saved")
  }

  const handleDocumentRow = (key) => {
    history.push(`/admin/attributestoredoc/${key}/${environment}`);
  };

  const handlePublish = async () => {
    var selectedDocuments = documentsToShow.filter(m => m.selected);
    await publish(enviromentForApi, selectedDocuments)
    fetchDocuments();

    toast.success("Documents published")
  }

  const handleDelete = async () => {
    var documentsToDelete = documentsToShow.filter(m => m.selected);
    await deleteDocuments(documentsToDelete)
    fetchDocuments();

    toast.success("Documents deleted")
  }

  const handleActivation = async (activate) => {
    var documentsToActivate = documentsToShow.filter(m => m.selected);
    documentsToActivate.forEach((item) => item.active = activate);
    const data = await save(documentsToActivate);
    setAllDocuments(data.documents)

    toast.success(`Documents ${activate ? "activated" : "deactivated"}`)
  }

  const headers = [
    { label: "Type", key: "type" },
    { label: "Category", key: "category" },
    { label: "Name", key: "name" },
    { label: "Topic", key: "topic" },
    { label: "Internal Only", key: "internalOnly" },
    { label: "Changes Allowed", key: "changesAllowed" },
    { label: "Retention Policy", key: "retentionPolicy" },
    { label: "Admin Company", key: "adminCompany" },
    { label: "PlexMode", key: "plexmode" },
    { label: "ColorMode", key: "colormode" },
    { label: "PostMode", key: "postmode" },
    { label: "DistributionMethod", key: "distributionMethod" },
    { label: "Comment", key: "comment" },
    { label: "UpdatedAt", key: "updatedAtFormatted" },
    { label: "UpdatedBy", key: "updatedByFormatted" },
    { label: "Active", key: "activeAtFormatted" },
  ];

  const search = (e) => {
    const { value } = e.target;
    const regExp = new RegExp(value, "i");
    setDocumentsToShow(
      value
        ? allDocuments.filter(
          (m) =>
            m.type.toString().match(regExp) ||
            m.category.toString().match(regExp) ||
            m.name.toString().match(regExp) ||
            m.retentionPolicy.toString().match(regExp) ||
            m.adminCompany.toString().match(regExp) ||
            m.topic.toString().match(regExp) ||
            m.distributionMethod.toString().match(regExp)
        )
        : allDocuments
    );

    setOnlyShowEdit(false);
    setOnlyShowActive(false);
  };

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed title={`Documents - ${environment}`} />
          <TwPage.Header.Hdr.Buttons>
            <div className="flex items-center gap-6">
              <div className="relative flex items-start">
                <CmpCheckbox
                  id="onlyShowActive"
                  name="onlyShowActive"
                  label="Only show active"
                  value={onlyShowActive}
                  onChange={e => handleShowOnlyActive(true)}
                />
              </div>
              <div className="relative flex items-start">
                <CmpCheckbox
                  id="onlyShowEdit"
                  name="onlyShowEdit"
                  label="Only show edited"
                  value={onlyShowEdit}
                  onChange={e => handleShowOnlyEdited(true)}
                />
              </div>
              <div className="relative flex items-start">
                <CmpSearch
                    id="search"
                    name="search"
                    placeholder="Search"
                    onChange={search}
                />
              </div>
            </div>
          </TwPage.Header.Hdr.Buttons>
        </TwPage.Header>

        {/* Buttons Area */}
        <div className="mt-5 sm:flex sm:items-center sm:justify-between">
            <div className="mt-3 flex sm:mt-0">
              {
                environment !== 'production' &&
                <CmpButton
                  variant={CmpButton.variants.primary}
                  className="justify-center mr-2"
                  disabled={documentsToShow.filter(m => m.selected).length === 0}
                  onClick={() => setOpenPublishConfirmation(true)}
                >
                  Publish
                </CmpButton>
              }
              {
                environment === 'development' &&
                <CmpButton
                  variant={CmpButton.variants.primary}
                  className="justify-center mr-2"
                  disabled={documentsToShow.filter(m => m.selected).length === 0}
                  onClick={() => setOpenActivateConfirmation(true)}
                >
                  Activate/Deactivate
                </CmpButton>
              }
              {
                environment === 'development' &&
                <CmpButton
                  variant={CmpButton.variants.primary}
                  className="justify-center mr-2"
                  onClick={handleCreate}
                >
                  New
                </CmpButton>
              }
              {
                environment === 'development' &&
                <CmpButton
                  variant={CmpButton.variants.secondary}
                  className="justify-center mr-2"
                  disabled={documentsToShow.filter(m => m.selected).length === 0}
                  onClick={() => setOpenDeleteConfirmation(true)}
                >
                  Delete
                </CmpButton>
              }
              <CSVLink
                data={csvData}
                headers={headers}
                separator={";"}
                filename={"attribute-export-file.csv"}
                className="mr-2 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 "
              >
                Export to Excel
              </CSVLink>

              {
                environment !== 'production' &&
                <LoadMenu environment={enviromentForApi} handleLoad={handleLoad} />
              }

            </div>
          </div>

          {/* Start main area*/}
          <TwTable>
            <TwTable.Head>
              <TwTable.Header additionalClass={'sm:pl-6'}>
                <CmpCheckbox
                  id="selectAll"
                  name="selectAll"
                  value={selectAll}
                  onChange={e => handleSelectAll()}
                />
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Type
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Category 
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Name
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Topic
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Retention policy
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Admin company
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Distribution method
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Active
              </TwTable.Header>
            </TwTable.Head>
            <TwTable.Body>
              {documentsToShow && documentsToShow.map((document) => (
                <TwTable.BodyRow key={document.id} additionalClass={classNames(
                  document.id === activeDocument?.id
                    ? "bg-gray-100"
                    : "",
                    "hover:bg-gray-100 cursor-pointer",
                    document.hasChanged
                      ? "bg-yellow-100 dark:bg-yellow-600"
                      : "")}
                >
                  <TwTable.BodyCol additionalClass={'w-12 px-6 py-4'}>
                    <CmpCheckbox
                      id="selected"
                      name="selected"
                      value={document.selected || false}
                      onChange={e => handleItemSelected(document)}
                    />
                  </TwTable.BodyCol>
                  <TwTable.BodyCol additionalClass="font-medium text-gray-900 dark:text-gray-100" onClick={() => handleDocumentRow(document.id)}>
                    {document.type}
                  </TwTable.BodyCol>
                  <TwTable.BodyCol  onClick={() => handleDocumentRow(document.id)}>
                    {document.category}
                  </TwTable.BodyCol>
                  <TwTable.BodyCol  onClick={() => handleDocumentRow(document.id)}>
                    {document.name}
                  </TwTable.BodyCol>
                  <TwTable.BodyCol  onClick={() => handleDocumentRow(document.id)}>
                    {document.topic}
                  </TwTable.BodyCol>
                  <TwTable.BodyCol  onClick={() => handleDocumentRow(document.id)}>
                    {document.retentionPolicy}
                  </TwTable.BodyCol>
                  <TwTable.BodyCol  onClick={() => handleDocumentRow(document.id)}>
                    {document.adminCompany}
                  </TwTable.BodyCol>
                  <TwTable.BodyCol  onClick={() => handleDocumentRow(document.id)}>
                    {document.distributionMethod}
                  </TwTable.BodyCol>
                  <TwTable.BodyCol  onClick={() => handleDocumentRow(document.id)}>
                    {document.active.toString()}
                  </TwTable.BodyCol>
                </TwTable.BodyRow>
              ))}
            </TwTable.Body>
          </TwTable>
      </TwPage>

      <PublishConfirmation
        open={openPublishConfirmation}
        moveFromEnvironment={environment}
        setOpen={setOpenPublishConfirmation}
        onSubmit={handlePublish}
      />

      <DeleteConfirmation
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        onSubmit={handleDelete}
      />

      <ActivateConfirmation
        open={openActivateConfirmation}
        environment={environment}
        setOpen={setOpenActivateConfirmation}
        onSubmit={handleActivation}
        disabled={documentsToShow.filter(m => m.selected).length === 0 ? true : false}
      />
    </>
  );
}
