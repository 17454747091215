import { useRef } from 'react'

import GenericModal from '../../../components/Modals/genericModal';

export default function PublishConfirmation({open, setOpen, moveFromEnvironment, onSubmit}) {
  const cancelButtonRef = useRef(null);

  const handleOnSubmit = () => {
    onSubmit();
    setOpen(false);
  }

  return (
    <GenericModal open={open} setOpen={setOpen}
      onConfirm={handleOnSubmit}
      onCancel={() => setOpen(false)}
      showCancelButton={true}
      confirmButtonText={'Publish'}
      title="Publish">
      <div className="mt-4">
        <p className="text-sm text-gray-500">
          Are you sure you want to publish the selected documents from <span className="font-bold">{moveFromEnvironment}</span>. 
        </p>
      </div>
    </GenericModal>
   )
}