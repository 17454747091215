import React, { useContext } from "react";
import { Button as CmpButton } from "../Button";
import { TailwindUiContext } from '../../contexts';
import { useClassNames } from "../../hooks/useClassNames";

const TYPES = {
  stacked: 'stacked',
  twoColumn: 'twoColumn',
  twoColumnFramed: 'twoColumnFramed',
};

const TwUiFormSettings = (type) => {
  return {
    type
  };
}
TwUiFormSettings.types = TYPES;


const TwFormSectionLabelDesc = ({ children, settings, label, description }) => {
  switch (settings?.type)
  {
  case TYPES.twoColumn:
    return (
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-300">{label}</h2>
        {description ?
          <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-500">{description}</p>
          : null
        }
      </div>
    );
  default:
    return (
      <div className="pb-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">{label}</h3>
      </div>
    );
  }
}

const TwFormSectionChildContainer = ({ children, settings }) => {
  switch (settings?.type)
  {
  case TYPES.twoColumn:
    return (
      <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
        <div className="sm:col-span-4">
          {children}
        </div>
      </div>
    );
    case TYPES.twoColumnFramed:
      return (
        <div className="bg-white shadow-sm ring-1 ring-gray-200 sm:rounded-xl md:col-span-2 dark:ring-gray-900 dark:bg-gray-700">
          <div className="px-4 py-6 sm:p-8">
            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-4">
                {children}
              </div>
            </div>
          </div>
        </div>
      );
    default:
    return (
      <>
        {children}
      </>
    );
  }
}

const TwFormSection = ({ children, label, description, first = false, ...props }) => {
  const tailwindUiContext = useContext(TailwindUiContext);
  
  switch (tailwindUiContext?.form?.type)
  {
  case TYPES.twoColumn:
  case TYPES.twoColumnFramed:
    const clsNames = 'grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-8 md:grid-cols-3 dark:border-gray-700';
    return (
      <div className={clsNames}>
        <TwFormSectionLabelDesc settings={tailwindUiContext?.form} label={label} description={description} />
        <TwFormSectionChildContainer settings={tailwindUiContext?.form}>
          {children}
        </TwFormSectionChildContainer>
      </div>
    );
    default:
      return (
        <>
        <div className="py-5 border-b border-gray-900/10 pb-12 dark:border-gray-700">
          <div className="pb-5">
            <TwFormSectionLabelDesc settings={tailwindUiContext?.form} label={label} />
          </div>
          {children}
        </div>
      </>
    );
  }
}

const TwFormSectionFull = ({ children, first = false, ...props }) => {
  const tailwindUiContext = useContext(TailwindUiContext);
  
  switch (tailwindUiContext?.form?.type)
  {
  case TYPES.twoColumn:
  case TYPES.twoColumnFramed:
    const clsNames = 'grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 dark:border-gray-700';
    return (
      <div className="py-5 border-b border-gray-900/10 pb-12 dark:border-gray-700">
        {children}
      </div>
    );
    default:
      return (
        <>
        <div className="py-5 border-b border-gray-900/10 pb-12 dark:border-gray-700">
          {children}
        </div>
      </>
    );
  }
}

// grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3
const TwFormActions = ({ buttons = [] }) => {
  return (
    <div className="flex-shrink-0 mt-6 mb-6">
      <div className="space-x-3 flex justify-end">
        {
          Array.isArray(buttons) ? buttons?.map((b, index) => {
            return (
              <CmpButton
                key={`form-action-${index}`}
                variant={b.variant || CmpButton.variants.primary}
                className="justify-center"
                disabled={b.disabled ?? false}
                onClick={() => b.onClick()}
              >
                {b.text}
              </CmpButton>
            );
          }) : null
        }      
      </div>
    </div>
  )
}


const TwFormInt = ({ children, buttons = [], ...props }) => {
  return (
    // <div className="flex w-full overflow-y-auto h-full">
    <div className="flex w-full h-full">
      <div className="w-full">
        <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
          <div className="border-0">
            <form className="h-full flex flex-col" {...props}>
              <div className="space-y-12">
                {children}
              </div>
              { buttons &&
                <TwForm.Actions buttons={buttons} />
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const TwForm = ({ children, ...props }) => {
  return (
    <TwFormInt {...props}>
      {children}
    </TwFormInt>
  );
}
TwForm.Section = TwFormSection;
TwForm.Section.Full = TwFormSectionFull;
TwForm.Actions = TwFormActions;

const TwFormEx = ({ children, buttons = [], ...props }) => {
  return (
    <TwFormInt {...props} buttons={buttons}>
      {children}
    </TwFormInt>
  );
}
TwFormEx.Section = TwFormSection;
TwFormEx.Section.Full = TwFormSectionFull;
TwFormEx.Actions = TwFormActions;

export { TwUiFormSettings, TwForm, TwFormEx }