import React, { useEffect, useState, useContext } from 'react';

import { featureFlags } from '../../utils/features.js'

import { DebugContext } from '..';

export const DebugContextProvider = ({ children }) => {
  const [debugData, setDebugData] = useState({debug: featureFlags.debugFeatures});

  const dataKeys = {
    debug: "debug",
    mf_test: "mf_test",
    exSession: 'exSession'
  }
  // useEffect(() => {
  //   console.log("DebugContextProvider", "useEffect[debugData]", "debugData", debugData);
  // }, [debugData]);

  function getValue(key) {
    return debugData[key];
  }

  function dispatchSetValue(key, value) {
    //console.log("DebugContextProvider", "dispatchSetValue", "debugData", debugData);
    let newData = {...debugData};
    newData[key] = value;
    //console.log("DebugContextProvider", "dispatchSetValue", "newData", newData);
    setDebugData(newData);
  }

  function dispatchSetValues(values) {
    //console.log("DebugContextProvider", "dispatchSetValue", "debugData", debugData);
    let newData = {...debugData};
    values.map(kvp => newData[kvp.key] = kvp.value)
    //console.log("DebugContextProvider", "dispatchSetValue", "newData", newData);
    setDebugData(newData);
  }

  function dispatchToogleBool(key) {
    // console.log("DebugContextProvider", "dispatchToogleBool", "key", key, "debugData", debugData);
    const active = debugData[key] || false;
    // console.log("DebugContextProvider", "dispatchToogleBool", "active", active);
    dispatchSetValue(key, !active);
  }

  return (
    <DebugContext.Provider
      value={{
        debugData,
        debugDataKeys: dataKeys,
        getValue: getValue,
        dispatchSetValue,
        dispatchSetValues,
        dispatchToogleBool
      }}
    >
      {children}
    </DebugContext.Provider>
  );
}