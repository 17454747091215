import React, { Fragment, useState, useEffect } from 'react';
import StepWizard from "react-step-wizard";

// import Nav from './nav';

import styles from './wizard.less';
import transitions from './transitions.less';

export const WizardNav = (props) => {
    // useEffect(() => {
    //     console.log("WizardNav", "props", props);
    // }, []);

    return (<></>);
}

// https://www.npmjs.com/package/react-step-wizard
const Wizard = ({children, title, ...props}) => {
  const [state, updateState] = useState({
      form: { test: 'test'},
      transitions: {
          enterRight: `${transitions.animated} ${transitions.enterRight}`,
          enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
          exitRight: `${transitions.animated} ${transitions.exitRight}`,
          exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
          intro: `${transitions.animated} ${transitions.intro}`,
      },
      demo: true, // uncomment to see more
  });

//   // Do something on step change
//   const onStepChange = (stats) => {
//       // console.log(stats);
//   };

  const setInstance = SW => updateState({
      ...state,
      SW,
  });

  const { SW, demo } = state;

  return (
      <div>
          {title && <h3>{title}</h3>}
          <div>
              <div>
                  <div className={`col-12 col-sm-6 offset-sm-3 ${styles['rsw-wrapper']}`}>
                      <StepWizard
                        //onStepChange={onStepChange}
                        //transitions={state.transitions} // comment out for default transitions
                        instance={setInstance}
                        {...props}
                      >
                        {children}
                      </StepWizard>
                  </div>
              </div>
          </div>
          {/* { (demo && SW) && <InstanceDemo SW={SW} /> } */}
      </div>
  );
};

export default Wizard;
