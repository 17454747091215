import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useClassNames, useModalHelper /*, useToastAction*/ } from "@metaforcelabs/metaforce-core";
import { useToastAction } from "../../../hooks/useToastAction";

import { EnvironmentGroupContext, CurrentDataContext, InteractStatusContext } from "../../../contexts";

import { useFeature } from "../../../hooks/useFeature";

import { getEnvironmentGroupsByCustomerId, getInteractEnvironments, toggleEnvironmentGroupStatus, deletetEnvironmentGroups } from "../../../api/interact";
import { deleteEnvironment, getAll } from "../../../api/interactEnvironment";
import { updateInteractTemplateEnvironment } from "../../../api/interactTemplateEnvironment";

import * as Constants from '../../../utils/constants'

import GenericModal from '../../../components/Modals/genericModal';
import { Button } from "../../../components/Button";
import { TwPage } from "../../../components/TailwindPage";

import EnvironmentGroupModal from "./environmentGroupModal";
import { EnvironmentGroupCard } from "./environmentGroupCard";

export default function Environmentsv2() {
    const pageId = "33EAE093-28A8-4BA5-A88A-C445F3922891";

    const history = useHistory();
    const { hasFeature } = useFeature();
    // const { templateId } = useParams();


    const createGroupModalHelper = useModalHelper();
    const { environmentGroups, dispatchEnvironmentGroupsReloadEvent, ...environmentCtx } = useContext(EnvironmentGroupContext);
    const { getViewSettings, dispatchViewSettingsUpdate } = useContext(CurrentDataContext);
    const { dispatchUpdateInteractEnvironments } = useContext(InteractStatusContext);

    const [viewSettings, setViewSettings] = useState(getViewSettings(pageId));

    const [mappedEnvironmentGroups, setMappedEnvironmentGroups] = useState([])
    const [allEnvironments, setAllEnvironments] = useState([]);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([])

    const loadAction = useToastAction();
    const updateAction = useToastAction();
    const deleteAction = useToastAction();
  
    const load = async () => {
        if (!environmentGroups)
            return;

        loadAction.execute(async () => {
            const [srvEnvironments, srvAllEnvironments] = await Promise.all([getAll(), getInteractEnvironments()]);

            //console.log("Environmentsv2", "load", "environmentGroups", environmentGroups);
            const mapped = environmentGroups.map(g => ({ ...g, environments: (srvEnvironments[g.id] || []) }))
            setAllEnvironments(srvAllEnvironments);
            setMappedEnvironmentGroups(mapped)

            //console.log("Environmentsv2", "load", "mapped", mapped);
        }, 'Failed to load')
    }

    useEffect(() => {
        //console.log("Environmentsv2", "useEffect[environmentGroups]", "calling load...");
        load();
    }, [environmentGroups])

    const handleCreateGroup = (values) => {
        //console.log(values);
        createGroupModalHelper.close();
    }

    const handleCreate = async () => {
        handleEdit("new");
    }

    const handleEdit = (id) => {
        if (hasFeature(Constants.environmentsConfigurationFeatureCode)) {
            history.push(`/environments/group/${id}`);
        }
    }

    const handleAddEnvironment = async (group) => {
        await dispatchEnvironmentGroupsReloadEvent();
        dispatchUpdateInteractEnvironments();
    }

    const handleOrderChange = (groupId) => {
        updateAction.execute(async () => {
            const groupIdx = mappedEnvironmentGroups.findIndex(x => x.id === groupId);
            const updatedEnvs = await getAll();
            const group = mappedEnvironmentGroups[groupIdx];
            const updatedGroup = { ...group, environments: updatedEnvs[groupId] };
            mappedEnvironmentGroups[groupIdx] = updatedGroup;
            const updatedGroups = [...mappedEnvironmentGroups];
            setMappedEnvironmentGroups(updatedGroups);
        }, 'Failed to load environments')
    }

    const handleTemplateServerDelete = async (env, template, server) => {
        updateAction.execute(async () => {
            //console.log("handleTemplateServerDelete", "template", template, "server", server);
            template.servers = template.servers.filter(s => s.serverId !== server.serverId);
            //console.log("handleTemplateServerDelete", "template", template);
            try
            {
                await updateInteractTemplateEnvironment({...template, customerEnvironment: env.customerEnvironment, environmentGroupId: env.environmentGroupId, order: env.order});
                await dispatchUpdateInteractEnvironments();
            }
            catch (err)
            {
                console.log("handleTemplateServerDelete", "err", err);
                throw err;
            }
        }, 'Failed to delete template server')
    }

    const handleEnvironmentDelete = (env) => {
        updateAction.execute(async () => {
            // empty server hack
            if (!env.template?.files) {
                env.template.files = [];
            }

            await deleteEnvironment(env);
            await dispatchUpdateInteractEnvironments();

            const groupIdx = mappedEnvironmentGroups.findIndex(x => x.id === env.environmentGroupId);
            const group = mappedEnvironmentGroups[groupIdx];
            const environments = group.environments.filter(x => x.customerEnvironment !== env.customerEnvironment);
            const updatedGroup = { ...group, environments: [...environments] };

            mappedEnvironmentGroups[groupIdx] = updatedGroup;
            const updatedGroups = [...mappedEnvironmentGroups];
            setMappedEnvironmentGroups(updatedGroups);
        }, 'Failed to delete environment')
    }

    const openGroupDelete = (groupId) => {
        if (groupId) {
            setSelectedGroups([groupId]);      
            setOpenDeleteConfirmation(true);
        }
    }

    const handleGroupDelete = () => {
        let errorMessage = "Failed to delete Interact Environement Group";
        deleteAction.execute(async () => {
          setOpenDeleteConfirmation(false);
          //console.log("handleGroupDelete", "selectedGroups", selectedGroups);

          var groupIdsToDelete = selectedGroups.map(g => g);
    
          //console.log("handleGroupDelete", "groupIdsToDelete", groupIdsToDelete);
    
          setSelectedGroups([]);
    
          var result = await deletetEnvironmentGroups(groupIdsToDelete);

          //console.log("handleGroupDelete", "result", result);

          if (!result.some(r => !r.isOk))
          {
            await dispatchEnvironmentGroupsReloadEvent(async () => {
                await dispatchUpdateInteractEnvironments();
            });
          }
          else
          {
            errorMessage = createErrorMessage(result);
            throw new Error();
          }
        }, () => errorMessage, "Interact Environement Group deleted")
    }

    const createErrorMessage = (result) =>
    {
        return (
            <>
                <div className="mb-2 text-base">Failed to delete Interact Environement Group</div>
                {result.map(delResult => (
                !delResult.isOk && (
                    <>
                    <div>{delResult.name}</div>
                    <ul style={{listStyleType: 'disc'}} className="ml-4 mb-2">
                    {delResult.children.map(delErr => (
                        <li>{delErr.info}</li>
                    ))}
                    </ul>
                    </>
                )
                ))}
            </>
        );
    }

    const handleToggleGroupStatus = (group, isActive) => {
        updateAction.execute(async () => {
            const updatedGroup = await toggleEnvironmentGroupStatus({ id: group.id, isActive: isActive });
            const groupIdx = mappedEnvironmentGroups.findIndex(x => x.id === group.id);
            const updatedGroups = [...mappedEnvironmentGroups];
            const copy = { ...group, isActive: updatedGroup.isActive, createdDate: updatedGroup.createdDate, updatedDate: updatedGroup.updatedDate };
            updatedGroups[groupIdx] = { ...copy }
            setMappedEnvironmentGroups(prev => {
                return updatedGroups
            })
            await dispatchEnvironmentGroupsReloadEvent();
            dispatchUpdateInteractEnvironments();
        }, 'Failed to set status');
    }

    const setVs = (settings) => {
        // console.log("Environmentsv2", "setVs", "settings", settings);
        setViewSettings(settings);
        dispatchViewSettingsUpdate(pageId, settings);
    }
    
    return mappedEnvironmentGroups && (
        <TwPage>
            <TwPage.Header>
                <TwPage.Header.Hdr.Fixed title="Environment Groups" />
                <TwPage.Header.Hdr.Buttons>
                    <div className="flex">
                        <div className="flex-1" />
                        <Button
                            variant={Button.variants.primary}
                            className="justify-center"
                            disabled={false}
                            onClick={() => {
                                handleCreate();
                            }}
                        >
                            Add Group
                        </Button>
                    </div>
                </TwPage.Header.Hdr.Buttons>
            </TwPage.Header>
            {/* <EnvironmentTable data={data} /> */}

            <div className="mt-4 space-y-4">
                {
                    mappedEnvironmentGroups.map((g, i) => {
                        return (
                            <EnvironmentGroupCard group={g}
                                defaultCollapsed={viewSettings && viewSettings.hasOwnProperty(g.id) ? viewSettings[g.id].isCollapsed : i !== 0}
                                onEdit={handleEdit}
                                allEnvironments={allEnvironments}
                                onToggleGroupStatus={handleToggleGroupStatus}
                                onAddEnvironment={handleAddEnvironment}
                                onOrderChange={handleOrderChange}
                                onTemplateServerDelete={handleTemplateServerDelete}
                                onEnvironmentDelete={handleEnvironmentDelete}
                                onGroupDelete={openGroupDelete}
                                viewSettings={viewSettings}
                                setViewSettings={setVs}                           
                            />
                        )
                    })
                }
            </div>

            <EnvironmentGroupModal title={'Environment Group'} onSubmit={handleCreateGroup} modalHelper={createGroupModalHelper} />


            <GenericModal open={openDeleteConfirmation} setOpen={setOpenDeleteConfirmation}
                onConfirm={handleGroupDelete}
                onCancel={() => {
                    setOpenDeleteConfirmation(false);
                    setSelectedGroups([]);
                }}
                showCancelButton={true}
                confirmButtonText={'Delete'}
                title="Delete Interact Envioronement Group">
                <div className="mt-4">
                <p className="text-sm text-gray-500">
                    Are you sure you want to delete the selected Interact Envioronement Group?
                </p>
                </div>
            </GenericModal>

        </TwPage >
    )
}