import React from "react";
import Modal from "../../../components/Modal";
import { Button } from "../../../components/Button";
import { Form, Formik } from "formik";
import FormikInput from "../../../components/Form/Formik/FormikInput";

export default function EnvironmentGroupModal({ modalHelper, title, onSubmit, group }) {

    const handleSubmit = (values) => {
        onSubmit(values);
    }

    return (
        <Modal
            isOpen={modalHelper.isOpen}
            onClose={() => { modalHelper.close() }}
            size={'medium'}
            title={title}
        >
            <>
                <Formik
                    initialValues={{
                        name: group?.name || ''
                    }}
                    onSubmit={(values, actions) => {
                        handleSubmit(values)
                    }}

                >
                    {
                        props => (
                            <Form>
                                <div>
                                    <FormikInput
                                        label={'Name'}
                                        name={'name'}
                                    />
                                </div>
                                <div className="flex justify-end mt-5 sm:mt-6 space-x-2">
                                    <Button
                                        variant={Button.variants.primary}
                                        className="justify-center"
                                        disabled={false}
                                        type={"submit"}
                                    >
                                        Create
                                    </Button>
                                    <Button
                                        variant={Button.variants.secondary}
                                        className="justify-center"
                                        disabled={false}
                                        onClick={() => {
                                            modalHelper.close();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </>
        </Modal>
    )
}