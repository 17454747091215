import { get, put, post, destroy, BaseUrl } from "./api";

const controller = 'InteractEnvironment';

export const getAll = async () => {
  return await get(`api/${controller}/all`);
}

export const getAllFromEvironmentGroup = async (environmentGroupId) => {
  return await get(`api/${controller}/all/${environmentGroupId}`);
}

export const getUnusedEvironments = async (environmentGroupId) => {
  return await get(`api/${controller}/environments/unused/${environmentGroupId}`);
}

export const updateEnvironment = async (templateId, values) => {
  return await put(`api/${controller}/${templateId}`, values);
}

export const createEnvironment = async (values) => {
  return await post(`api/${controller}`, values);
}

export const updateEnvironmentOrder = async (values) => {
  return await put(`api/${controller}/order`, values);
}

export const deleteEnvironment = async (values) => {
  return await post(`api/${controller}/delete`, values);
}
