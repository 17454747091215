import React from 'react';

export const FormContext = React.createContext();
export const ShowHideContext = React.createContext();
export const ElementContext = React.createContext();
export const ElementPropertiesContext = React.createContext();
export const RowContext = React.createContext();
export const ColumnContext = React.createContext();
export const ContainerContext = React.createContext();

export const DesignActionbarContext = React.createContext();
export const OidcRoutesContext = React.createContext();

export const TailwindUiContext = React.createContext();
export const TwTableContext = React.createContext();

export const CurrentDataContext = React.createContext();
export const TooltipContext = React.createContext();
export const DebugContext = React.createContext();
export const EnvironmentGroupContext = React.createContext();
export const InteractStatusContext = React.createContext();
