import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";

import { InteractStatusContext, CurrentDataContext } from '../../../contexts/index.js';

import Modal from "../../../components/Modal";
import { Button } from "../../../components/Button";
import { Password } from "../../../components/Password";

export default function CreateExtrasSessionDialog({ modalHelper, onConfirm }) {
  const history = useHistory();

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(modalHelper.activeItem);

  useEffect(() => {
    // if (modalHelper.isOpen)
    //   setValues({password: ""});
  }, [modalHelper.isOpen]);

  const onChange = ({ name, value }) => {
    if (value.endsWith("µ"))
      value = value.slice(0, value.length - 1);
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <Modal
        isOpen={modalHelper.isOpen}
        onClose={() => { modalHelper.close() }}
        size={'medium'}
        title={modalHelper.activeItem?.title || "Log in to Extras"}
    >
      <>
        <div className="flex flex-col gap-2" style={{maxHeight: "500px"}}>
        <Password
          name="password"
          value={values?.password || ""}
          label={"Password"}
          onChange={e => {
            onChange(e.target); 
          }}
          error={errors["password"]}
        />
        </div>
        <div className="flex justify-end mt-5 sm:mt-6 space-x-2">
          <Button
            variant={Button.variants.secondary}
            className="justify-center"
            disabled={false}
            onClick={() => {
              modalHelper.close();
            }}
          >
            Close
          </Button>
          <Button
            variant={Button.variants.primary}
            className="justify-center"
            disabled={false}
            onClick={() => {
              onConfirm(values.password);
            }}
          >
            Login
          </Button>
        </div>
      </>
    </Modal>
  );
}