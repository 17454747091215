import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { EnvironmentGroupContext, InteractStatusContext, DebugContext } from '../../../contexts/index.js';

import { useToastAction } from '../../../hooks/useToastAction.js';

import * as InteractApi from "../../../api/interact";
import * as TemplateApi from "../../../api/interactTemplateEnvironment.js";

import './styles.scss';

import { EnvironmentGroupContainerCard } from "../Environments/environmentGroupContainerCard.js"

import { Label as CmpLabel } from "../../../components/Label/index.js";
import { Button as CmpButton } from "../../../components/Button/index.js";
import { TwPage } from "../../../components/TailwindPage/index.js";
import { TwTable } from "../../../components/TailwindTable/index.js";

export default function ExtrasEnvironments() {
  const history = useHistory();

  const {environmentGroups} = useContext(EnvironmentGroupContext);
  const {dispatchUpdateInteractEnvironments} = useContext(InteractStatusContext);
  const { debugData} = useContext(DebugContext);

  const [allCustomerEnvironments, setAllCustomerEnvironments] = useState([])
  const [environmentData, setEnvironmentData] = useState([]);
  const [loadingBaseData, setLoadingBaseData] = useState(true);

  const loadAction = useToastAction();

  const hasCrudAccess = true;

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      console.log("ExtrasEnvironments", "loadBaseData", "debugData", debugData);

      // Force reload (and revalidation) of the InteractEnvironments
      await dispatchUpdateInteractEnvironments();

      const environments = await InteractApi.getInteractEnvironments();

      let grpEnvData = [];
      environmentGroups.map(group =>  {
        grpEnvData.push({group: group, environments: null});
      });
      await Promise.all(environmentGroups.map(async (group, idx) => {
        const tplEnvironments = await TemplateApi.getInteractTemplateEnvironmentsNoFiles(group.id);
        let orderOk = true;
        tplEnvironments.map((t, i) => {
          if (t.order != (i + 1)) {
            orderOk = false;
          }
        });
        grpEnvData[idx] = {group: group, environments: tplEnvironments, orderOk: orderOk};
      }));

      console.log("ExtrasEnvironments", "loadBaseData", "grpEnvData", grpEnvData);

      setAllCustomerEnvironments(environments);
      console.log("ExtrasEnvironments", "loadBaseData", "1");
      setEnvironmentData(grpEnvData);
      console.log("ExtrasEnvironments", "loadBaseData", "2");
      setLoadingBaseData(false);
      console.log("ExtrasEnvironments", "loadBaseData", "3");
    }, "Failed to load (ExtrasEnvironments)")
  }

  useEffect(() => {
    if (!!environmentGroups)
      loadBaseData();
  }, [environmentGroups]);

  const handleValidateOrder = async (environmentGroupId) => {
    await TemplateApi.validateInteractTemplateEnvironmentOrder(environmentGroupId);
    loadBaseData();
  }

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed 
            title="Environments" 
          />
        </TwPage.Header>

        <CmpLabel type={CmpLabel.types.warn} additionalClass="mb-8">
          <p className="font-bold">
              This page is intended as a Metaforce administration page and should not be used by others.
          </p>
          <p>
              Clicking a red button updates the database and should only be used by someone that understands the backend/database structure.
          </p>
        </CmpLabel>

        {loadingBaseData && (
          <div className='flex flex-wrap'>
            <CmpLabel text={"Loading environments..."} additionalClass='font-bold h-6' />
          </div>
        )}
        {environmentData && environmentData.map(data => (
          <div className='mb-4'>
            <EnvironmentsTestCard
              group={data.group}
              interactEnvironments={data.environments}
              orderOk={data.orderOk}
              allCustomerEnvironments={allCustomerEnvironments}
              hasCrudAccess={hasCrudAccess}
              handleValidateOrder={handleValidateOrder}
              defaultCollapsed={false}
            />
          </div>
        ))}
      </TwPage>
    </>
  );
}

function EnvironmentsTestCard({
  group,
  interactEnvironments,
  allCustomerEnvironments,
  orderOk,
  hasCrudAccess,
  defaultCollapsed,
  handleValidateOrder,
  collapsable = true
 }) {
  return (
    <>
      <div className="" >
        <EnvironmentGroupContainerCard
          group={group}
          defaultCollapsed={defaultCollapsed}
          collapsable={true}
          visibleSettings={{showActiveState: false}}
        >
          {interactEnvironments && interactEnvironments.length > 0 && (
            <>
              {!orderOk && (
                <div className='flex'>
                  <div className='flex-1'/>
                  <CmpButton
                    variant={CmpButton.variants.custom}
                    className="justify-center bg-red-500 text-gray-200 hover:bg-red-600 hover:bg-text-white ring-red-900 ring-opacity-5"
                    //className="justify-center"
                    onClick={() => handleValidateOrder(group.id)}
                  >
                    Update Order
                  </CmpButton>
                </div>
              )}
              <TwTable additionalClass='mt-2 mb-6'>
                <TwTable.Head>
                  <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                    Name
                  </TwTable.Header>
                  <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                    Order
                  </TwTable.Header>
                  <TwTable.Header />
                </TwTable.Head>
                <TwTable.Body>
                  {interactEnvironments.map((data) => (
                    <TwTable.BodyRow key={data.id} useHover={hasCrudAccess}>
                      <TwTable.BodyCol additionalClass={'font-medium'}>
                        {allCustomerEnvironments.find(({ value }) => value === data.customerEnvironment)?.name}
                      </TwTable.BodyCol>
                      <TwTable.BodyCol>
                        {data?.order}
                      </TwTable.BodyCol>
                    </TwTable.BodyRow>
                  ))}
                </TwTable.Body>
              </TwTable>
            </>
          )}
        </EnvironmentGroupContainerCard>
      </div>
    </>
  );
 }


