import React, { Fragment, useState, useEffect } from 'react';
//import { NavLink } from 'react-router-dom';
//import { Menu, Transition } from '@headlessui/react'
import { Menu, MenuButton } from '../../components/Menu';
import  { DotsVerticalIcon } from '@heroicons/react/solid'
import { useClassNames } from '../../hooks/useClassNames';
import Modal from '../Modal/index'

export default function MenuContextList({actions, element, refContainer, deleteType = element, handleDelete = true, colorClass = 'text-gray-600 dark:text-gray-300', ...props}) {
    const classNames = useClassNames();      

    return (
    <>
    <Menu 
      as="div"
      actions={actions}
      handleDelete={handleDelete}
      refContainer={refContainer}
      buttonElement={
        <MenuButton>
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon className={classNames.classNames("h-5 w-5", colorClass)} aria-hidden="true" />
        </MenuButton>
        }
      className="ml-3 relative inline-block text-left"
      anchororigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      deleteType={deleteType}
      transformorigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    />
     
  </>
  );
}