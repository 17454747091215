import React, { useEffect, useState, useContext } from 'react';

import { InteractStatusContext, EnvironmentGroupContext } from '../index.js';

import { useUpdateServerStatusTimer } from "../../hooks/useUpdateServerStatusTimer.js";

import * as InteractEnvironmentApi from "../../api/interactEnvironment.js";
import * as TemplateApi from "../../api/interactTemplateEnvironment.js";
import * as DeploymentApi from "../../api/deployment.js";

import { filterServerStatus, getServerStatusTimeout, TEMPLATESERVERSTATUSES } from "../../utils/templateServerIconTooltipUtils.js";
import { fireEvent } from '@testing-library/react';

export const InteractStatusContextProvider = ({ children }) => {
  const [hasValidStatus, setHasValidStatus] = useState(false);
  const [serversNeedUpdate, setServersNeedUpdate] = useState({});
  const [validationResults, setValidationResults] = useState({});
  const [latestImageVersion, setLatestImageVersion] = useState("");
  const [deployEnabled, setDeployEnabled] = useState(false);
  const [interactEnvironments, setInteractEnvironments] = useState(null);

  const {environmentGroups} = useContext(EnvironmentGroupContext);

  const { serverStatuses, start, pause, running, setTemplates, stop, tickDate } = useUpdateServerStatusTimer();

  let loadingImageVersion = false;
  let loadingInteractEnvironments = false;

  useEffect(() => {
    if (!latestImageVersion)
      getLatestImageVer();  // Don't await this....
    //loadEnvironmentStatus();
    return () => stop();
  }, []);

  useEffect(() => {
    if (!!environmentGroups) {
      //console.log("InteractStatusContextProvider", "useEffect[environmentGroups]", "calling loadEnvironmentStatus");
      loadEnvironmentStatus();
    }
  }, [environmentGroups]);
  
  //#region Environment status Check

  const validationStatus = {
    running: 0,
    serverTimeout: 1,
    serverStatusUnknown: 2,
    serverStatusOk: 3,
    serverStatusDownloadPending: 4,
    serverStatusDownloading: 5,
    serverStatusDownloadFailed: 6,
    missingSoapSettings: 7,
    newK8sImageAvailable: 8,
    noActiveServers: 9
  }

  const validationSeverity = {
    good: 0,
    warning: 1,
    error: 2,
    info: 3,
  }

  const validationObjectTypes = {
    template: 0,
    templateServer: 1,
    integration: 2,
    webeditor: 3,
  }

  useEffect(() => {
    if (!!tickDate && !hasValidStatus)
      setHasValidStatus(true);
  }, [tickDate]);

  useEffect(() => {
    //console.log("InteractStatusContextProvider", "useEffect[serverStatuses]", "environmentGroups", environmentGroups, "serverStatuses", serverStatuses, "interactEnvironments", interactEnvironments);

    // if (!(environmentGroups?.length > 0) || !(serverStatuses?.length > 0) || !!interactEnvironments)
    if (!environmentGroups || environmentGroups.length === 0 
      || !serverStatuses || serverStatuses.length === 0 
      || !interactEnvironments)
      return;

    pause();

    let updatedData = false;

    Object.keys(interactEnvironments).map(groupId => {
      interactEnvironments[groupId].map(x => {
        let interactTemplate = x.template;
        let servers = x.template.servers;

        const srvStatuses = serverStatuses.find(x => x.template === interactTemplate.id)?.statuses;
        const srvFileCount = serverStatuses.find(x => x.template === interactTemplate.id)?.fileCount;
  
        if (srvStatuses) {
          let updatedTpl = false;
          srvStatuses.map(ss => {
            servers = servers.map(srv => {
              if (srv.serverId === ss.id)
              {
                if (srv.enabled !== ss.enabled) {
                  srv.enabled = ss.enabled;
                  updatedTpl = true;
                }
                if (srv.name !== ss.name) {
                  srv.name = ss.name;
                  updatedTpl = true;
                }
                if (srv.status !== ss.status) {
                  srv.status = ss.status;
                  srv.statusMessage = ss.statusMessage;
                  updatedTpl = true;
                }
              }
              return srv;
            });
          });
          if (updatedTpl) {
            interactTemplate.servers = servers;
            updatedData = true;
          }
          if (srvFileCount !== interactTemplate.fileCount)
            interactTemplate.fileCount = srvFileCount;
        }
        });
    });

    // console.log("InteractStatusContextProvider", "useEffect[serverStatuses]", "updatedData", updatedData);

    if (updatedData) {
      setInteractEnvironments(interactEnvironments);
      validate(interactEnvironments);
    }
    start();
  }, [serverStatuses]);

  useEffect(() => {
    validate(interactEnvironments);
  }, [serversNeedUpdate]);

  const loadEnvironmentStatus = async () => {  
    if (!loadingInteractEnvironments) {
      loadingInteractEnvironments = true;

      try {
        pause();
        //console.log("InteractStatusContextProvider", "loadEnvironmentStatus", "environmentGroups", environmentGroups);

        const environments = await InteractEnvironmentApi.getAll();

        // console.log("InteractStatusContextProvider", "loadEnvironmentStatus", "environments", environments);

        var keys = Object.keys(environments);
        if (keys.length > 0) {
          setInteractEnvironments(environments);
          validate(environments);
          const tplEnvironments = [];
          Object.keys(environments).map(groupId => {
            environments[groupId].map(x => {
              tplEnvironments.push(x.template);
            });
          });

          //console.log("InteractStatusContextProvider", "loadEnvironmentStatus", "tplEnvironments", tplEnvironments);

          setTemplates(tplEnvironments.filter(x => x.active && !x.isVirtual));
          start();
        }
        else {
          stop();
          //console.log("InteractStatusContextProvider", "loadEnvironmentStatus", "keys.length === 0");
        }
      }
      finally
      {
        loadingInteractEnvironments = false;
        //console.log("InteractStatusContextProvider", "loadEnvironmentStatus", "done");
      }
    }
  }
  
  const dispatchUpdateInteractEnvironments = async () => {
    // console.log("InteractStatusContextProvider", "dispatchUpdateInteractEnvironments", "called...")
    await loadEnvironmentStatus();
  }

  const validate = (environments) => {
    if (!environments)
      return;

    let validationResult = {};
    Object.keys(environments).map(groupId => {
      let results = [];
      environments[groupId].map(setting => {
        const result = validateEnvironmentSettings(setting);
        results.push(result);
      });
      if (results.length > 0)
        validationResult[groupId] = results;
    });

    setValidationResults(validationResult);
  }

  const validateEnvironmentSettings = (setting) => {
    let result = {};

    // Templates
    //
    if (setting.template && setting.template.servers)
    {
      const updatableServers = serversNeedUpdate?.hasOwnProperty(setting.environmentGroupId) ? serversNeedUpdate[setting.environmentGroupId] : {};

      let data = [];
      const servers = setting.template.servers.filter(x => x.enabled === true);
      if (!servers || servers.length === 0) {
        data.push({
          status: validationStatus.noActiveServers,
          severity: validationSeverity.warning,
          objectType: validationObjectTypes.template,
          object: setting.template
        });
      }
      else {
        servers.map(s => {
          if (updatableServers.hasOwnProperty(s.serverId.toString()))
          {
            data.push({
              status: validationStatus.newK8sImageAvailable,
              severity: validationSeverity.info,
              objectType: validationObjectTypes.templateServer,
              object: {tpl: setting.template, srv: s}
            });
          }
          const ts = getServerStatusTimeout(s.status);
          if (ts > 0)
          {
            data.push({
              status: validationStatus.serverTimeout,
              severity: ts > 1 ? validationSeverity.error : validationSeverity.warning,
              objectType: validationObjectTypes.templateServer,
              object: {tpl: setting.template, srv: s}
            });
          }
          switch (filterServerStatus(s.status)) {
            case TEMPLATESERVERSTATUSES.unknown: // Unknown
              data.push({
                status: validationStatus.serverStatusUnknown,
                severity: validationSeverity.warning,
                objectType: validationObjectTypes.templateServer,
                object: {tpl: setting.template, srv: s}
              });
              break;
            case TEMPLATESERVERSTATUSES.downloadsPending: // downloadPending
              data.push({
                status: validationStatus.serverStatusDownloadPending,
                severity: validationSeverity.good,
                objectType: validationObjectTypes.templateServer,
                object: {tpl: setting.template, srv: s}
              });
              break;
            case TEMPLATESERVERSTATUSES.downloading: // downloading
              data.push({
                status: validationStatus.serverStatusDownloading,
                severity: validationSeverity.good,
                objectType: validationObjectTypes.templateServer,
                object: {tpl: setting.template, srv: s}
              });
              break;
            case TEMPLATESERVERSTATUSES.downloadOk: // DownloadOk
              data.push({
                status: validationStatus.serverStatusOk,
                severity: validationSeverity.good,
                objectType: validationObjectTypes.templateServer,
                object: {tpl: setting.template, srv: s}
              });
              break;
            case TEMPLATESERVERSTATUSES.downloadFailed: // DownloadFailed
              data.push({
                status: validationStatus.serverStatusDownloadFailed,
                severity: validationSeverity.error,
                objectType: validationObjectTypes.templateServer,
                object: {tpl: setting.template, srv: s}
              });
              break;
            default:
              break;
          }
        });
      }
      if (!!data)
        result[validationObjectTypes.templateServer] = data;
    }

    // Integration
    //
    if (!setting.soap && (setting.template || setting.webeditor))
    {
      let data = [];
      if (setting.template)
      {
        data.push({
          status: validationStatus.missingSoapSettings,
          severity: validationSeverity.error,
          objectType: validationObjectTypes.template,
          object: setting.template
        });
        if (!!data)
          result[validationObjectTypes.template] = data;
      }
      if (setting.webeditor)
      {
        data.push({
          status: validationStatus.missingSoapSettings,
          severity: validationSeverity.error,
          objectType: validationObjectTypes.webeditor,
          object: setting.webeditorSetting
        });
        if (!!data)
          result[validationObjectTypes.webeditor] = data;
      }
    }

    // Severity
    //
    var severity = validationSeverity.good;
    Object.keys(result).map(x => {
      result[x]?.map(d => {
        if (d.severity > severity)
          severity = d.severity;
      });
    });

    var res = {customerEnvironment: setting.customerEnvironment, severity: severity, result };

    //console.log("InteractStatusContextProvider", "validateEnvironmentSettings", "res", res);

    return res;
  }

  //#endregion Environment status Check

  //#region Kubernetes Image Version Check

  useEffect(async () => {
    if (!!latestImageVersion && !!environmentGroups)
    {
      try 
      {
        await Promise.all(environmentGroups.filter(g => !g.isVirtual).map(async (envGrp) => {
          // console.log("InteractStatusContextProvider", "useEffect[latestImageVersion]", "envGrp", envGrp);
          const templates = await TemplateApi.getInteractTemplateEnvironmentsNoFiles(envGrp.id);

          var serversToCheck = [];
          templates.map(tpl => {
            var srvs = tpl.servers.filter(s => !!s.deploymentSessionId);
            srvs.map(s => serversToCheck.push(s));
          });

          await Promise.all(serversToCheck.map(async (srv) => {
            var depId = srv.deploymentSessionId;
            var session = await DeploymentApi.getDeploymentSession(depId);
            var currImageVer = session?.configuration?.values[".Values.image.version"];

            if (currImageVer !== latestImageVersion)
            {
              setServersNeedUpdate(prevState => ({
                ...prevState,
                [envGrp.id]: { ...prevState[envGrp.id], [srv.serverId]: currImageVer}
              }));
            }
          }));
        }));
      }
      catch (err) {
      }
    }
  }, [latestImageVersion])

  const getLatestImageVer = async () => {
    if (!loadingImageVersion) {
      loadingImageVersion = true;
      try
      {
        const enabled = await DeploymentApi.isDeployEnabled();
        setDeployEnabled(enabled);
        if (enabled) {
          const ver = await DeploymentApi.getCurrentDeploymentImageVersion();
          setLatestImageVersion(ver);
        }
      } catch {
        // TODO: Log error here!!
      }
      finally {
        loadingImageVersion = false;
      }
    }
  }

  const updatedServer = (environmentGroupId, server) => {
    const {[server.serverId]: _, ...newObj} = serversNeedUpdate.hasOwnProperty(environmentGroupId) ? serversNeedUpdate[environmentGroupId] : {};
    setServersNeedUpdate(prevState => ({
      ...prevState,
      [environmentGroupId]: newObj
    }));
  }

  //#endregion Kubernetes Image Version Check

  return (
    <InteractStatusContext.Provider
      value={{
        hasValidStatus: hasValidStatus,
        serversNeedUpdate: serversNeedUpdate,
        latestImageVer: latestImageVersion,
        deployEnabled : deployEnabled,
        updatedServer,
        interactEnvironments,
        dispatchUpdateInteractEnvironments,
        validationResults,
        validationStatus,
        validationSeverity,
        validationObjectTypes
      }}
    >
      {children}
    </InteractStatusContext.Provider>
  );
}