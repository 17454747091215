import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from "yup";
import { useToastAction } from '@metaforcelabs/metaforce-core'

import { EnvironmentGroupContext, InteractStatusContext } from '../../../contexts';

import { createEnvironment, getAll, getAllFromEvironmentGroup, updateEnvironment, getUnusedEvironments } from '../../../api/interactEnvironment';
import { getLanguages } from '../../../api/language';
import { getRetentionPoliciesApi } from '../../../api/retentionPolicies';
import { getInteractEnvironments } from '../../../api/interact';

import SoapSettingsForm from './soapSettingsForm';
import WebeditorSettingsForm from './webeditorSettingsForm';

import { TextInput } from '../../../components/Form/TextInput';
import { TwFormEx } from '../../../components/TailwindForm';
import { Button } from '../../../components/Button';
import { Select } from '../../../components/Select';
import { TwPage } from '../../../components/TailwindPage'

export default function EditEnvrionment() {
    const [environment, setEnvironment] = useState([])
    const { groupId, environmentId } = useParams();
    const isNew = environmentId === 'new';
    // const [webEditorSettings, setWebEditorSettings] = useState(null);
    const [environmentOptions, setEnvironmentOptions] = useState([]);
    const [retentionPolicies, setRetentionPolicies] = useState([]);
    const [languages, setLanguages] = useState([]);
    const environmentGroupContext = useContext(EnvironmentGroupContext);
    const {dispatchUpdateInteractEnvironments} = useContext(InteractStatusContext);
    const laodAction = useToastAction();
    const saveAction = useToastAction();
    const [loaded, setLoaded] = useState(false);
    const history = useHistory();
    const [allCustomerEnvs, setAllCustomerEnvs] = useState([])
    const [environmentsNotInUse, setEnvironmentsNotInUse] = useState([])
    const [currentEnvironments, setCurrentEnvironments] = useState([])
    const [allEnvironments, setAllEnvironments] = useState([])

    const load = () => {
        laodAction.execute(async () => {
            const allEnvs = await getInteractEnvironments();
            setAllCustomerEnvs(allEnvs);
            const currentEnvs = (await getAll())[groupId]?.map(x => x.customerEnvironment);
            setCurrentEnvironments(currentEnvs);
            const remainingEnvs = await getUnusedEvironments(groupId);

            //console.log("EditEnvrionment", "load", "allEnvs", allEnvs, "remainingEnvs", remainingEnvs);

            setEnvironmentsNotInUse(remainingEnvs);
            const srvData = await getAllFromEvironmentGroup(groupId);
            let srvRetention = await getRetentionPoliciesApi();
            srvRetention = srvRetention.map(policy => { return { name: policy.name, value: policy.id } });
            srvRetention.unshift({ name: 'Select retention policy', value: '' });
            setRetentionPolicies(srvRetention);
            const srvLanguages = await getLanguages();
            setLanguages(srvLanguages.map(x => { return { name: x.name, value: x.languageCode } }));
            setEnvironment(srvData.find(x => x.customerEnvironment === +environmentId));
            setAllEnvironments(srvData);

            //console.log("EditEnvrionment", "load", "srvData", srvData, srvData.find(x => x.customerEnvironment === +environmentId))
            setLoaded(true);
        }, 'Failed to load')
    }

    useEffect(() => {
        load();
    }, [])

    const handleValues = (values) => {
        const soapCertificatePassword = { ...values.soap.certificatePassword, valueChanged: !!values.soap.certificatePassword.value };
        const mfdxClientSecret = { ...values.webEditor.mfdxClientSecret, valueChanged: !!values.webEditor.mfdxClientSecret.value };
        values = {
            ...values,
            soap: { ...values.soap, certificatePassword: soapCertificatePassword },
            webEditor: {
                ...values.webEditor,
                pdfAttachmentColorDepth: Number(values.webEditor.pdfAttachmentColorDepth),
                mfdxClientSecret: mfdxClientSecret
            }
        };
        return values;
    }

    const handleSubmit = (values) => {
        values = handleValues(values);

        if (environmentId === 'new') {
            saveAction.execute(async () => {
                // debugger;
                const maxOrder = allEnvironments.length === 0 ? 0 : allEnvironments.sort((a, b) => a.order > b.order ? -1 : 1)[0].order;
                const order = !!maxOrder ? +maxOrder + 1 : 1

                //console.log("handleSubmit", "allEnvironments", allEnvironments, "maxOrder", maxOrder, "order", order);

                await createEnvironment({ ...values, order: order, customerEnvironment: +values.customerEnvironment, template: { servers: [] } });
                await dispatchUpdateInteractEnvironments();
                history.goBack();
            }, 'Save failed');

        } else {

            //console.log("handleSubmit", "values", values);

            const templateId = environment.template.id;
            saveAction.execute(async () => {
                await updateEnvironment(templateId, values);
                await dispatchUpdateInteractEnvironments();
                history.goBack();
            }, 'Save failed');
        }
    }

    return loaded && (
        <TwPage>
            <TwPage.Header>
                <TwPage.Header.Hdr.Fixed title="Environment" description={"Description"} />
            </TwPage.Header>

            <div className="space-y-10 divide-y divide-gray-900/10">
                <Formik
                    // enableReinitialize={true}
                    initialValues={{
                        customerEnvironment: isNew ? environmentsNotInUse[0]?.value : environment.customerEnvironment,
                        soap: environment?.soap || { certificatePassword: {} },
                        order: environment?.order,
                        webEditor: environment?.webEditor || {
                            mfdxClientSecret: {},
                            endFormEnabled: true,
                            centralDistributionEnabled: true,
                            centralDistributionKivraEnabled: true,
                            kivraMethod: 0,
                            centralDistributionDigipostEnabled: true,
                            digipostMethod: 0,
                            centralDistributionCentralprintEnabled: true,
                            centralprintMethod: 0,
                            archiveOnlyEnabled: true,
                            archiveMethod: 0,
                            localPrintEnabled: true,
                            emailEnabled: true,
                            textLibraryEnabled: false,
                            previewHtmlEnabled: false,
                            pdfAttachmentEnabled: true,
                            pdfAttachmentDPI: 150,
                            pdfAttachmentColorDepth: 1,
                            pdfAttachmentDuplex: true,
                            environmentScope: '',
                            languageCode: 'en',
                        },
                        template: environment?.template || {},
                        environmentGroupId: groupId,
                    }}
                    validationSchema={yup.object().shape({
                        soap: yup.object().shape({
                            endpoint: yup.string().required("Required"),
                            certificateEnabled: yup.boolean(),
                            certificate: yup.string().when("certificateEnabled", {
                                is: (value) => {
                                    return value === true
                                },
                                then: yup.string().test("certificate", 'Required', (value => {
                                    return !!value;
                                })),
                                otherwise: yup.string().nullable(),
                            }).nullable(),
                            certificatePassword:
                                yup.object().when("certificateEnabled", {
                                    is: true,
                                    then: yup.object().shape({
                                        hasValue: yup.boolean(),
                                        valueChanged: yup.boolean(),
                                        value: yup.string().nullable().test("value", 'Required', (value, { parent, ...ctx }) => {

                                            //console.log("certificatePassword", "value", value, "parent", parent);

                                            const shouldValidate = !parent?.hasValue
                                            const hasValue = !!value;
                                            const isValid = shouldValidate ? hasValue : true;
                                            return isValid;

                                        }),
                                    }),
                                })
                        }),
                        webEditor: yup.object().shape({
                            centralDistributionEnabled: yup.boolean(),
                            centralDistributionKivraEnabled: yup.boolean(),
                            centralDistributionDigipostEnabled: yup.boolean(),
                            centralDistributionCentralprintEnabled: yup.boolean(),
                            localPrintEnabled: yup.boolean(),
                            emailEnabled: yup.boolean(),
                            archiveOnlyEnabled: yup.boolean(),
                            pdfAttachmentEnabled: yup.boolean(),
                            pdfAttachmentDPI: yup.number().when("pdfAttachmentEnabled", {
                                is: (value) => value === true,
                                then: yup.number().min(72, "DPI must be greater than or equal to 72.").max(300, "DPI must be less than or equal to 300").integer("DPI must be an integer"),
                                otherwise: yup.number().notRequired()
                            }),
                            languageCode: yup.string().required('Required'),
                            mfdxClientSecret: yup.object().shape({
                                hasValue: yup.boolean(),
                                valueChanged: yup.boolean(),
                                value: yup.string().nullable().test("value", 'Required', (value, { parent, ...ctx }) => {
                                    //console.log("mfdxClientSecret", "value", value, "parent", parent);

                                    const shouldValidate = !parent?.hasValue
                                    const hasValue = !!value;
                                    const isValid = shouldValidate ? hasValue : true;
                                    return isValid;

                                }),
                            })
                        })
                            .test(
                                'distributionTest',
                                null,
                                (obj) => {
                                    let distributionCount = 0;
                                    if (obj.centralDistributionEnabled) {
                                        distributionCount += 1;
                                    }
                                    if (obj.localPrintEnabled) {
                                        distributionCount += 1;
                                    }
                                    if (obj.emailEnabled) {
                                        distributionCount += 1;
                                    }
                                    if (obj.archiveOnlyEnabled) {
                                        distributionCount += 1;
                                    }
                                    if (!obj.endFormEnabled || distributionCount >= 1) {
                                        return true;
                                    }

                                    return new yup.ValidationError(
                                        'Select at least one option for Distribution',
                                        null,
                                        'webEditor.emailEnabled'
                                    );
                                })
                            .test(
                                'centralDistributionTest',
                                null,
                                (obj) => {
                                    if (!obj.endFormEnabled || !obj.centralDistributionEnabled ||
                                        (obj.centralDistributionKivraEnabled || obj.centralDistributionDigipostEnabled || obj.centralDistributionCentralprintEnabled)) {
                                        return true;
                                    }

                                    return new yup.ValidationError(
                                        'Select at least one option for Central distribution',
                                        null,
                                        'webEditor.centralDistributionEnabled'
                                    );
                                })
                    })
                    }
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, ...props }) => (
                        <Form >
                            <div className="space-y-8">
                                <div className='border-b pb-4 border-gray-200 dark:border-gray-700 '>
                                    <h2 className="text-lg font-semibold leading-7 text-gray-900 dark:text-gray-100">Customer Environment</h2>
                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600 dark:text-gray-400">
                                        desc
                                    </p>
                                </div>
                                {/* <div>{JSON.stringify(errors)}</div> */}
                                <div>
                                    <TwFormEx.Section label="Customer Environment" separator={false}>
                                        {
                                            isNew ? (
                                                <Select
                                                    className={"px-0"}
                                                    name="customerEnvironment"
                                                    value={values?.customerEnvironment}
                                                    onChange={props.handleChange}
                                                    options={[...environmentsNotInUse]}
                                                    error={errors?.customerEnvironment}
                                                />
                                            ) : (
                                                <TextInput name={"customerEnvironment"} value={allCustomerEnvs.find(x => x.value == environment.customerEnvironment)?.name} disabled={true} />
                                            )
                                        }
                                    </TwFormEx.Section>
                                </div>
                            </div>

                            <div className="pt-4">
                                <div className='border-b mb-4 pb-4 border-gray-200 dark:border-gray-700'>
                                    <h2 className="text-lg font-semibold leading-7 text-gray-900 dark:text-gray-100">Interact SOAP Client Settings</h2>
                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600 dark:text-gray-400">
                                        Desc
                                    </p>
                                </div>
                                <SoapSettingsForm />
                            </div>
                            <div className="pt-4">
                                <div className='border-b mb-4 pb-4 border-gray-200 dark:border-gray-700'>
                                    <h2 className="text-lg font-semibold leading-7 text-gray-900 dark:text-gray-100">WebEditor Settings</h2>
                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600 dark:text-gray-400">
                                        Desc
                                    </p>
                                </div>
                                <div className='space-y-4'>
                                    <WebeditorSettingsForm retentionPolicies={retentionPolicies} languages={languages} />
                                </div>
                            </div>
                            <TwFormEx.Section>
                                <div className='mt-4 flex gap-x-4 justify-end'>
                                    <Button
                                        variant={Button.variants.secondary}
                                        className="justify-center"
                                        disabled={false}
                                        onClick={() => {
                                            history.goBack()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant={Button.variants.primary}
                                        className="justify-center"
                                        disabled={false}
                                        type={"submit"}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </TwFormEx.Section>
                        </Form>
                    )}

                </Formik>
            </div>

        </TwPage >
    )
}
