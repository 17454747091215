import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { EnvironmentGroupContext, InteractStatusContext } from '../../../contexts/index.js';

import * as TemplateApi from "../../../api/interactTemplateEnvironment.js";
import * as InteractApi from "../../../api/interact.js";
import * as InteractEnvironmentApi from "../../../api/interactEnvironment.js";

import WizardStepUserInput from './WizardSteps/deploymentUserInput.js'
import WizardStepKubernetesDeploy from './WizardSteps/deploymentKubernetesDeploy.js'
import WizardStepResult from './WizardSteps/deploymentResult.js'
import { setTemporatyDeploymentIdToServer } from "./integrationCreateDeploymentSrv.js"

import { Panel as CmpPanel } from "../../../components/Panel/index.js";
import Wizard, {WizardNav} from '../../../components/Wizard/index.js'
import { TwPage } from "../../../components/TailwindPage/index.js";

export default function EnvironmentsCreateDeploymentEnv() {
  const { envGrpId } = useParams();
 
  const history = useHistory();
  
  // const {currentEnvironmentGroupId} = useContext(EnvironmentGroupContext);
  const { dispatchUpdateInteractEnvironments } = useContext(InteractStatusContext);
  const currentEnvironmentGroupId = envGrpId;

  const [wizardData, setWizardData] = useState("create");
  const [wizardCurrentStep, setWizardCurrentStep] = useState(1);

  const refWizard = useRef();

  const loadBaseData = async () => {
    updateWizardData("currentEnvironmentGroupId", currentEnvironmentGroupId);
  }

  useEffect(async () => {
    await loadBaseData();
  }, []);

  const onBeforeContinue = async (props) => {
    //console.log("EnvironmentsCreateDeploymentEnv", "onBeforeContinue", "props", props);

    const data = props.data;

    const soapSettings = data.soapSettings;
    const tplEnvironments = await TemplateApi.getInteractTemplateEnvironmentsNoFiles(currentEnvironmentGroupId);
    const maxOrder = tplEnvironments.length === 0 ? 0 : tplEnvironments.sort((a, b) => a.order > b.order ? -1 : 1)[0].order;

    const serverId = uuidv4();

    const request = {
      customerEnvironment: data.customerEnvironment,
      environmentGroupId: data.currentEnvironmentGroupId,
      order: maxOrder + 1,
      soap: {
        endpoint: soapSettings.endpoint,
        certificateEnabled: soapSettings.certificateEnabled,
        certificate: soapSettings.certificate,
        certificatePassword: soapSettings.certificatePassword,
        forcePDFAOnAllDocuments: soapSettings.forcePDFAOnAllDocuments
      },
      template: {
        files: [],
        servers: [
          {
            serverId: serverId,
            name: `${serverId}`,
            description: `Pages server in Kubernetes container`,
            type: 1,
            status: 5, // Deploying
            enabled: false,
            deploymentSessionId : ""
          }
        ]
      },
      webEditor: null
    };

    const interactEnvironment = await InteractEnvironmentApi.createEnvironment(request);
    // await dispatchUpdateInteractEnvironments();

    updateWizardData("interactEnvironment", interactEnvironment);
    updateWizardData("tplEnvironment", interactEnvironment.template);
    updateWizardData("tplServerId", serverId);
  }

  const onDeployError = async (props) => {
    //console.log("EnvironmentsCreateDeploymentEnv", "onDeployError", "props", props);

    const data = props.data;

    if (!!data.interactEnvironment) {
      data.interactEnvironment.template.files = [];
      await InteractEnvironmentApi.deleteEnvironment(data.interactEnvironment);
      await dispatchUpdateInteractEnvironments();
    }
  }

  const onDeploymentIdSet = async (props, deploymentId) => {
    const data = props.data;
    if (!!data.tplEnvironment && !!data.tplServerId) {
      await setTemporatyDeploymentIdToServer(data.tplEnvironment, data.tplServerId, deploymentId);
    }
  }

  const postDeploy = async (props, deploymentResult) => {
    //console.log("EnvironmentsCreateDeploymentEnv", "postDeploy", "props", props, "deploymentResult", deploymentResult);

    const data = props.data;

    const interactEnvironment = data.interactEnvironment;

    // Update soap settings
    interactEnvironment.soap.endpoint = deploymentResult.sessionResult.endpoint;
    await InteractApi.updateSoapSettings(interactEnvironment.soap);

    // Update the newly created server
    const server = interactEnvironment.template.servers.find(s => s.serverId === data.tplServerId);
    interactEnvironment.template.files = [];
    if (!!server) {
      server.name = deploymentResult.interactResult.computerName;
      server.description = `Pages server (version ${deploymentResult.interactResult.engineVersion}) in Kubernetes container`;
      server.deploymentSessionId = deploymentResult.savedDbSessionId
      server.enabled = true;

      await TemplateApi.updateInteractTemplateEnvironment(interactEnvironment.template);
    }

    await dispatchUpdateInteractEnvironments();

    props.update("deploymentResult", deploymentResult);
    //props.update("soapSettings", soapSettingsNew);
    props.update("interactTemplate", interactEnvironment.template);
    props.update("webEditorSetting", interactEnvironment.webEditor);
  }

  const updateWizardData = (key, value) => 
  {
    //console.log("EnvironmentsCreateDeploymentEnv", "updateData", "key", key, "value", value);
    setWizardData(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  const handleStepChange = (stats) => {
    //console.log("handleStepChange", "stats.activeStep", stats.activeStep);
    setWizardCurrentStep(stats.activeStep);
  }

  const onBeforeClose = async () => {
    //console.log("onBeforeClose", "wizardCurrentStep", wizardCurrentStep);
  }

  const wizardSteps = [WizardStepUserInput, WizardStepKubernetesDeploy, WizardStepResult];

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed title="Deploy Pages Kubernetes Container and Create Environments" />
        </TwPage.Header>
        
        <CmpPanel>
          <Wizard
            ref={refWizard}
            nav={
              <WizardNav 
                steps={wizardSteps}
                currentStep={wizardCurrentStep}
              />
            }
            onStepChange={handleStepChange}
            isLazyMount
          >
            <WizardStepUserInput
              currentStep={wizardCurrentStep}
              data={wizardData}
              onBeforeContinue={onBeforeContinue}
              update={updateWizardData}
           />
            <WizardStepKubernetesDeploy
              data={wizardData}
              update={updateWizardData}
              onDeployError={onDeployError}
              onDeploymentIdSet={onDeploymentIdSet}
              postDeploy={postDeploy}
              currentStep={wizardCurrentStep}
              onBeforeClose={onBeforeClose}
          />
            <WizardStepResult
              data={wizardData}
              history={history}
              currentStep={wizardCurrentStep}
              onBeforeClose={onBeforeClose} 
          />
          </Wizard>
        </CmpPanel>

      </TwPage>
    </>
  );
}

