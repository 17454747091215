import { get, post, put, download, destroy } from "./api";

const controller = 'Deployment';

export const isDeployEnabled = async () => {
  return await get(`${controller}/enabled`);
}

export const initDeployFromTemplate = async (deployTemplate, environmentGroupId, requestData) => {
  return await put(`${controller}/init/${deployTemplate.id}/${environmentGroupId}`, requestData);
}

export const initUpdateDeployFromTemplate = async (templateEnvironmentId, requestData) => {
  return await put(`${controller}/update/init/${templateEnvironmentId}`, requestData);
}

export const executeDeployStep = async (step, deployId) => {
  return await put(`${controller}/step/${step}/${deployId}`);
}

export const pollDeployStep = async (step, deployId) => {
  return await put(`${controller}/step/poll/${step}/${deployId}`);
}

export const getDeploymentSessions = async (environmentGroupId) => {
  return await get(`${controller}/all/${environmentGroupId}`);
}

export const getDeploymentSession = async (sessionId) => {
  return await get(`${controller}/${sessionId}`);
}

export const getDeploymentSessionVersions = async (sessionId) => {
  return await get(`${controller}/${sessionId}/versions`);
}

export const removeDeployment = async (deployId) => {
  return await put(`${controller}/remove/${deployId}`);
}

export const downloadDeploymentTestDocument = async (deployId) => {
  return await download(`${controller}/testdoc/${deployId}`, `Test_${deployId}.pdf`);
}

export const createDeploymentTestDocument = async (deployId) => {
  return await get(`${controller}/testdoc/b64/${deployId}`);
}

export const createDeploymentDocument = async (deployId, metafile, docName) => {
  // console.log("createDeploymentDocument", `${controller}/doc/b64/${deployId}/${Buffer.from(metafile).toString('base64')}/${Buffer.from(docName).toString('base64')}`)
  return await get(`${controller}/doc/b64/${deployId}/${Buffer.from(metafile).toString('base64')}/${Buffer.from(docName).toString('base64')}`);
}

export const getCurrentDeploymentImageVersion = async () => {
  return await get(`${controller}/image/ver`);
}

export const getDeploymentAbbrevations = async () => {
  return await get(`${controller}/abbr`);
}

export const setDeploymentAbbrevation = async (abbrevation, type, itemId) => {
  var itemIdPart = !!itemId ? `/${itemId}`: "";
  return await post(`${controller}/abbr/${abbrevation}/${type}${itemIdPart}`);
}

export const getEditDeploymentSettings = async (deployId) => {
  return await get(`${controller}/mod/${deployId}`);
}

export const saveEditDeploymentSettings = async (deployId, modifiedValues) => {
  return await post(`${controller}/mod/${deployId}`, modifiedValues);
}
