import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useToastAction } from '../../../hooks/useToastAction';

import { TwTableContext } from '../../../contexts';

import * as InteractApi from "../../../api/interact";
import * as TemplateApi from "../../../api/interactTemplateEnvironment.js";

import { Label as CmpLabel } from "../../../components/Label";
import { TwPage } from "../../../components/TailwindPage";
import { TwTable } from "../../../components/TailwindTable/index.js";

export default function TemplatesServerTroubleShoot() {
  const { envId } = useParams();
  const { settingsId } = useParams();
  const history = useHistory();

  const loadAction = useToastAction();

  const [values, setValues] = useState([]);
  const [template, setTemplate] = useState([]);
  const [server, setServer] = useState([]);
  
  
  const tableSettings = {
    type: TwTable.types.simple
  }

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      const allEnvironments = await InteractApi.getInteractEnvironments();

      // console.log("TemplatesServerTroubleShoot", "loadBaseData", "envId", envId, "settingsId", settingsId);

      const tpl = await TemplateApi.getInteractTemplateEnvironment(envId);
      // console.log("TemplatesServerTroubleShoot", "loadBaseData", "template", tpl);
      const srv = tpl?.servers.find(({ serverId }) => serverId === settingsId);
      // console.log("TemplatesServerTroubleShoot", "loadBaseData", "server", srv);
      var custEnv = allEnvironments.find(({value}) => value === parseInt(tpl.customerEnvironment)).name;

      if (!!tpl && !!srv) {
        setTemplate(tpl);
        setServer(srv);
        setValues(
          [
            {id: uuidv4(), name: "Template", value: custEnv},
            {id: uuidv4(), name: "Server", value: srv.name}
          ]);
      }
    }, "Failed to load (TemplatesUpdate)")
  }

  useEffect(() => {
    if (!settingsId && !envId)
      return;
    loadBaseData();
  }, [settingsId, envId]);

  const renderTbs = () => {
    return (
      <div className="">
      {server.type === 0 ? renderTbsVmware() : renderTbsKubernetes()};
      </div>
    );
  }

  const renderTbsKubernetes = () => {
    return (
      <>
        <CmpLabel text="Troubleshooting for Kubernetes server" additionalClass='text-lg font-bold' />
      </>
    );
  }

  const renderTbsVmware = () => {
    return (
      <>
        <CmpLabel text="Troubleshooting for VMWare server" additionalClass='text-lg font-bold' />
      </>
    );
  }

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed title="Interact Server Troubleshoot" />
        </TwPage.Header>

        <TwTableContext.Provider value={tableSettings}>
          <TwTable additionalClass='mt-2 mb-6 max-w-3xl'>
            <TwTable.Head>
              <TwTable.Header additionalClass={'uppercase md:pl-6'}>
                Data 
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase md:pl-6'}>
                Value
              </TwTable.Header>
            </TwTable.Head>
            <TwTable.Body>
              {values.map((data) => (
                <TwTable.BodyRow key={data.id} useHover={false}>
                  <TwTable.BodyCol additionalClass={'font-medium'}>
                    {data.name}
                  </TwTable.BodyCol>
                  <TwTable.BodyCol additionalClass={'font-medium'}>
                    {data.value}
                  </TwTable.BodyCol>
                </TwTable.BodyRow>
              ))}
              </TwTable.Body>
          </TwTable>
        </TwTableContext.Provider>

        {renderTbs()}

      </TwPage>
    </>
  );
}