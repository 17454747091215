import { BaseUrl, download, downloadEx, put } from "./api";

const controller = 'Storage';

export const downloadZip = async (customerId, environmentId, serverId) => {
  return await download(`api/${controller}/download/${environmentId}/${serverId}`, "MetaDir.zip");
}

export const downloadZipEx = async (customerId, environmentId, serverId, fnHandleData) => {
  return await downloadEx(`api/${controller}/download/${environmentId}/${serverId}`, "MetaDir.zip", fnHandleData);
}

export const setDownloadStatus = async (sessionId, data) => {
  return await put(`api/${controller}/status/${sessionId}`, data);
}

