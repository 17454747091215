import { CheckIcon, DocumentAddIcon, PencilAltIcon } from "@heroicons/react/outline"
import { useEffect, useState } from "react";
import { compareDocument } from "../../../api/attributeStore";
import { useClassNames } from "../../../hooks/useClassNames";

import { Button as CmpButton } from "../../../components/Button";

import { TwFormEx } from "../../../components/TailwindForm";
import { TwTable } from "../../../components/TailwindTable";

export const Compare = ({ document, onCancel, onSubmit }) => {
  const { classNames } = useClassNames();
  const [changes, setChanges] = useState();
  const [openChanges, setOpenChanges] = useState(false);
  
  useEffect(() => {
    fetchCompare()
  }, [document]);

  const fetchCompare = async () => {
    const data = await compareDocument(document);
    setChanges(data);
  }
  
  const handleCancel = async () => {
    onCancel();
  }
  
  const formButtons = [
    {text: "Cancel", variant: CmpButton.variants.secondary, onClick: handleCancel},
    {text: "Ok", disabled: true}
  ];



  return (
    <>
      <TwFormEx
        buttons = {formButtons}
      >
        <TwFormEx.Section.Full first={true}>

          <TwTable>
            <TwTable.Head>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Property
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Development 
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Test
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Production
              </TwTable.Header>
            </TwTable.Head>
            <TwTable.Body>
              {changes && changes.map((change) => (
                <TwTable.BodyRow key={change.property} >
                  <TwTable.BodyCol additionalClass="font-medium text-gray-900 dark:text-gray-100" >
                    {change.property}
                  </TwTable.BodyCol>
                  <TwTable.BodyCol additionalClass={
                        classNames(
                          change.development !== change.production
                            ? 'bg-yellow-100 dark:bg-yellow-600'
                            : '',
                          'w-1/4'
                        )}>
                    {change.development}
                  </TwTable.BodyCol>
                  <TwTable.BodyCol additionalClass={
                        classNames(
                          change.test !== change.production
                            ? 'bg-yellow-100 dark:bg-yellow-600'
                            : '',
                          'w-1/4'
                        )}>
                    {change.test}
                  </TwTable.BodyCol>
                  <TwTable.BodyCol additionalClass="w-1/4">
                    {change.production}
                  </TwTable.BodyCol>
                </TwTable.BodyRow>
              ))}
            </TwTable.Body>
          </TwTable>

        </TwFormEx.Section.Full>
      </TwFormEx>
    </>
  )
}