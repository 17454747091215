import { useRef } from 'react'

import GenericModal from '../../../components/Modals/genericModal';

export default function DeleteConfirmation({open, setOpen, onSubmit}) {
  const cancelButtonRef = useRef(null);

  const handleOnSubmit = () => {
    onSubmit();
    setOpen(false);
  }

  return (
    <GenericModal open={open} setOpen={setOpen}
      onConfirm={handleOnSubmit}
      onCancel={() => setOpen(false)}
      showCancelButton={true}
      confirmButtonText={'Delete'}
      title="Delete documents">
      <div className="mt-4">
        <p className="text-sm text-gray-500">
          Are you sure you want to delete the selected documents from all the environments? 
        </p>
        <p className="mt-2 text-sm text-gray-500">
          <span className="font-bold">Note: </span>All documents will be soft deleted so they can be retrieved at a later date. 
        </p>
      </div>
    </GenericModal>
  );
}