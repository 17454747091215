
import { get, post, BaseUrl } from "./api";

export const getWebEditorSettingsByCustomerId = async (environmentGroupId) => {
    return await get(!!environmentGroupId 
        ? `api/WebEditorSettings/all/${environmentGroupId}`
        : `api/WebEditorSettings/all`
    );
}

export const getWebEditorSettings = async (id) => {
    return await get(
        `api/WebEditorSettings/${id}`
    );
}

export const createWebEditorSettings = async (settings) => {
    return await post(
        `api/WebEditorSettings`,
        settings
    );
}

export const updateWebEditorSettings = async (settings) => {
    return await post(
        `api/WebEditorSettings/update`,
        settings
    );
}

export const deleteWebEditorSettings = async (ids) => {
    return await post(
        `api/WebEditorSettings/delete`,
        ids
    );
}