import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import  { CheckIcon, PencilIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

import './styles.scss';

import { useToastAction } from '../../../hooks/useToastAction';
import { useFeature } from '../../../hooks/useFeature';
import { useEnvironmentGroup } from '../../../hooks/useEnvironmentGroup';
import { useUpdateServerStatusTimer } from "../../../hooks/useUpdateServerStatusTimer";

import * as InteractApi from "../../../api/interact";
import * as TemplateApi from "../../../api/interactTemplateEnvironment.js";
import * as WebEditorApi from "../../../api/webEditor";
import { getRetentionPoliciesApi } from "../../../api/retentionPolicies";

import Dashboard_Template from "./template";
import Dashboard_Integration from "./integration";
import Dashboard_WebEditor from "./webeditor";

import { renderHtmlTooltip } from '../../../utils/tooltipUtils';
import { filterServerStatus, getServerStatusTimeout, TEMPLATESERVERSTATUSES } from "../../../utils/templateServerIconTooltipUtils";
import { renderSvgIcon, getEnvironmentIconKey } from "../../../utils/svgIconUtils";

import { Panel as CmpPanel } from "../../../components/Panel";
import { Label as CmpLabel, ScrollingLabel } from "../../../components/Label";
import GenericModal from '../../../components/Modals/genericModal';
import { TwPage } from "../../../components/TailwindPage";

export default function Dashboard() {
  const history = useHistory();
  const { hasFeature } = useFeature();
  const { environmentGroups, currentEnvironmentGroupId } = useEnvironmentGroup();

  const [allCustomerEnvironments, setAllCustomerEnvironments] = useState([])
  const [allTemplateEnvironments, setAllTemplateEnvironments] = useState([])

  const [initialized, setInitialized] = useState(false)

  const [allSettings, setAllSettings] = useState([])
  const [retentionPolicies, setRetentionPolicies] = useState([]);
  const [contractedIntegrations, setContractedIntegrations] = useState({});
  const [tooltipData, setTooltipData] = useState({});

  const [openWarnInformation, setOpenWarnInformation] = useState(false);
  const [warnInformationData, setWarnInformationData] = useState(null);

  const [statusData, setStatusData] = useState(null /*{ok: 0, okMsg: [], warn: 0, warnMsg: [], error: 0, errorMsg: []}*/)

  const loadAction = useToastAction();

  const { serverStatuses, start, pause, running, setTemplates, stop } = useUpdateServerStatusTimer();

  const loadBaseData = async () => {  
    //console.log("DashboardCurr", "loadBaseData");

    loadAction.execute(async () => {
      const environments = await InteractApi.getInteractEnvironments();
      const webeditorSettings = await WebEditorApi.getWebEditorSettingsByCustomerId(currentEnvironmentGroupId);
      const soapSettings = await InteractApi.getSoapSettingsByCustomerId(currentEnvironmentGroupId);
      const tplEnvironments = await TemplateApi.getInteractTemplateEnvironmentsNoFiles(currentEnvironmentGroupId);
      setAllCustomerEnvironments(environments);
      setAllTemplateEnvironments(tplEnvironments.filter(x => x.active));

      let retentionPoliciesResult = await getRetentionPoliciesApi();
      retentionPoliciesResult = retentionPoliciesResult.map(policy => { return { name: policy.name, value: policy.id } });
      setRetentionPolicies(retentionPoliciesResult);

      const settings = environments.map((customerEnvironment) => {
        const currWe = webeditorSettings.find(wes => wes.customerEnvironment === customerEnvironment.value);
        const currSoap = soapSettings.find(ss => ss.customerEnvironment === customerEnvironment.value);
        const currTpl = tplEnvironments.filter(x => x.active).find(ts => ts.customerEnvironment === customerEnvironment.value);

        const currSoapTableData = !currSoap ? [] : [
          {key: "Endpoint", value: currSoap.endpoint},
          {key: "Certificate", value: currSoap.certificate ? <CheckIcon className='h-5 w-5'/> : <></>},
          {key: "Force PDF/A", value: currSoap.forcePDFAOnAllDocuments ? <CheckIcon className='h-5 w-5'/> : <></>}
        ];

        const currWeTableData = !currWe ? [] : [
          {key: "Retention Policies", value: retentionPoliciesResult.find(r => r.value === currWe.retentionPolicyId)?.name || ""},
          {key: "Environment scope", value: currWe.environmentScope},
          {key: "Show Text Library", value: currWe.textLibraryEnabled ? <CheckIcon className='h-5 w-5'/> : <></>},
          {key: "Show Preview Html", value: currWe.previewHtmlEnabled ? <CheckIcon className='h-5 w-5'/> : <></>},
          {key: "Enable PDF Attachment", value: currWe.pdfAttachmentEnabled ? <CheckIcon className='h-5 w-5'/> : <></>},
          {key: "Enable Distribution", value: currWe.endFormEnabled ? <CheckIcon className='h-5 w-5'/> : <></>}
        ];

        return {
          customerEnvironment: customerEnvironment,
          webeditorSettings: currWe  ? {data: currWe, tableData: currWeTableData} : undefined,
          soapSettings: currSoap ? {data: currSoap, tableData: currSoapTableData} : undefined,
          templateSettings: currTpl
        }
      });
      setAllSettings(settings);

      if (tplEnvironments?.length > 0)
      {
        setTemplates(tplEnvironments.filter(x => x.active));
        start();
      }

      setInitialized(true);
    }, "Failed to load (Dashboard)")
  }
    
  useEffect(() => {
    if (!currentEnvironmentGroupId)
      return;
    loadBaseData();

    return () => stop();
  }, [currentEnvironmentGroupId]);

  useEffect(() => {
    if (!(allTemplateEnvironments?.length > 0))
      return;

    pause();

    allTemplateEnvironments.map(interactTemplate => {
      let servers = interactTemplate.servers;
      const srvStatuses = serverStatuses.find(x => x.template === interactTemplate.id)?.statuses;
      const srvFileCount = serverStatuses.find(x => x.template === interactTemplate.id)?.fileCount;

      if (srvStatuses) {
        let updatedTpl = false;
        srvStatuses.map(ss => {
          servers = servers.map(srv => {
            if (srv.serverId === ss.id && srv.status !== ss.status)
            {
              srv.status = ss.status;
              srv.statusMessage = ss.statusMessage;
              updatedTpl = true;
            }
            return srv;
          });
        });
        if (updatedTpl)
          interactTemplate.servers = servers;
        if (srvFileCount !== interactTemplate.fileCount)
          interactTemplate.fileCount = srvFileCount;
      }
    });

    setAllTemplateEnvironments(allTemplateEnvironments);
    updateTooltipData(false);

    start();
  }, [serverStatuses])
  
  useEffect(() => {
    updateTooltipData(true);
  }, [allSettings]);

  const updateTooltipData = (updateExpand) => {
    let ttData = {};
    let okCount = 0;
    let okMsg = [];
    let warnCount = 0;
    let warnMsg = [];
    let errCount = 0;
    let errorMsg = [];

    allSettings.map(setting => {
      var result = getEnvironmentWarnings(setting);
      let type = CmpLabel.types.warn;

      okCount += result.ok;
      okMsg = [...okMsg, ...result.okMsg];
      warnCount += result.warn;
      warnMsg = [...warnMsg, ...result.warnMsg];
      errCount += result.error;
      errorMsg = [...errorMsg, ...result.errorMsg];

      ttData[setting.customerEnvironment.value] = { key: setting.customerEnvironment.value};

      [
        {key: environmentWarningKeys.template, id: setting.templateSettings?.id || undefined},
        {key: environmentWarningKeys.integration, id: setting.soapSettings?.data.id || undefined},
        {key:  environmentWarningKeys.webeditor, id: setting.webeditorSettings?.data.id || undefined},
      ].map(data => {
        const expand = false; //(data.key in result);
        if (expand)
        {
          ttData[setting.customerEnvironment.value][data.key] = result[data.key];
          if (result[environmentWarningKeys.type] === CmpLabel.types.error)
            type = CmpLabel.types.error;
        }
        if (data.id && updateExpand /*&& data.key !== environmentWarningKeys.template*/)
          onChangeExpand(setting.customerEnvironment.value, data.id, expand);
      });

      ttData[setting.customerEnvironment.value][environmentWarningKeys.type] = type;
    });

    setStatusData({ok: okCount, okMsg: okMsg, warn: warnCount, warnMsg: warnMsg, error: errCount, errorMsg: errorMsg});
    setTooltipData(ttData);
  }

  const isExpanded = (key, itemId) => {
    let data = (key in contractedIntegrations) ? contractedIntegrations[key] : {};
    if (itemId in data)
      return !data[itemId];
    return true;
  }

  const onChangeExpand = (key, itemId, expand) => {
    let data = (key in contractedIntegrations) ? contractedIntegrations[key] : {};
    data[itemId] = !expand;

    setContractedIntegrations(prevState => ({
      ...prevState,
      [key]: data
    }));
    contractedIntegrations[key] = data;
  }

  const createButtons = (key, item, path, requiredFeatureCode = null)  => {
    const expanded = isExpanded(key, item.id);

    let buttons = [];
    if (expanded && hasFeature(requiredFeatureCode))
    {
      buttons.push({
        content: <>
          <span className="sr-only">Edit</span>
          <PencilIcon className="h-5 w-5" aria-hidden="true" />
          </> ,
        onClick: () => history.push(`/${path}/${item.id}`),
        item: {item}
      });
    }
    buttons.push({
      content: <>
        <span className="sr-only">Edit</span>
        {expanded &&
          <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
        }
        {!expanded &&
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        }
        </> ,
      onClick: () => onChangeExpand(key, item.id, !expanded),
      item: {item}
    });

    return buttons;
  }

  const environmentWarningKeys = {
    type: 0,
    template: 1,
    integration: 2,
    webeditor: 3,
  }

  const getEnvironmentWarnings = (setting) => {
    let type = CmpLabel.types.warn;
    let result = {};

    let okCount = 0;
    let okMsg = [];
    let warnCount = 0;
    let warnMsg = [];
    let errCount = 0;
    let errorMsg = [];

    let msg = '';

    /* Templates */
    if (setting.templateSettings && setting.templateSettings.servers)
    {
      let ttx = [];
      const servers = setting.templateSettings.servers.filter(x => x.enabled === true);
      servers.map(s => {
        const ts = getServerStatusTimeout(s.status);
        let serverType = CmpLabel.types.default;
        if (ts > 0)
        {
          msg = `${s.name}: Timeout`;
          ttx.push(msg);
          serverType = ts === 2 ? CmpLabel.types.error : CmpLabel.types.warn;
          (ts === 2 ? errorMsg : warnMsg).push(setting.customerEnvironment.name + ": " + msg);

        }
        switch (filterServerStatus(s.status)) {
          case TEMPLATESERVERSTATUSES.unknown: // Unknown
            msg = `${s.name}: No status (client connected?)`;
            ttx.push(msg);
            warnMsg.push(setting.customerEnvironment.name + ": " + msg);
            serverType = CmpLabel.types.warn;
            break;
          case TEMPLATESERVERSTATUSES.downloading: // downloading
          case TEMPLATESERVERSTATUSES.downloadOk: // DownloadOk
            break;
          case TEMPLATESERVERSTATUSES.downloadFailed: // DownloadFailed
            msg = `${s.name}: Download failed`;
            ttx.push(msg);
            errorMsg.push(setting.customerEnvironment.name + ": " + msg);
            serverType = CmpLabel.types.error;
            break;
          default:
            break;
        }
        if (serverType !== CmpLabel.types.default)
          type = serverType;
        switch (serverType)
        {
          case CmpLabel.types.error:
            ++errCount;
            break;
          case CmpLabel.types.warn:
            ++warnCount;
            break;
          case CmpLabel.types.default:
            ++okCount;
            break;
        }
      });
      if (ttx.length > 0)
        result[environmentWarningKeys.template] = ttx;
    }

    /* Integration */
    if (!setting.soapSettings && (setting.templateSettings || setting.webeditorSettings))
    {
      let ttx = [];
      if (setting.templateSettings)
      {
        msg = "Templates is configured and requires Integration";
        ttx.push(msg)
        warnMsg.push(setting.customerEnvironment.name + ": " + msg);
        ++warnCount;
      }
      if (setting.webeditorSettings)
        ttx.push("Webeditor is configured and requires Integration")
        ++warnCount;
      {
        msg = "Webeditor is configured and requires Integration";
        ttx.push(msg)
        warnMsg.push(setting.customerEnvironment.name + ": " + msg);
        ++warnCount;
      }
      if (ttx.length > 0)
      {
        result[environmentWarningKeys.integration] = ttx;
      }
    }
    result[environmentWarningKeys.type] = type;

    result['ok'] = okCount;
    result['okMsg'] = okMsg;
    result['warn'] = warnCount;
    result['warnMsg'] = warnMsg;
    result['error'] = errCount;
    result['errorMsg'] = errorMsg;

    return result;
  }

  const createEnvWarnTooltipContent = (dataTip, ttData, tooltipMode = true) => {
    if (dataTip === null || dataTip === undefined) 
      return null;

    const ttCurrData = tooltipData ?? ttData;
    const data = dataTip in ttCurrData ? ttCurrData[dataTip] : undefined;

    const flattenElement = (array, betweenEl) => {
      return (
        <>
        {array.map((item, index) => 
          <>
          {index > 0 && item ? betweenEl : null}
          {item}
          </>
        )}
        </>
      );
    }

    if (!data)
      return null;

    let tipData = [
      {key: environmentWarningKeys.template, value: "Template"},
      {key: environmentWarningKeys.integration, value: "Integration"},
      {key: environmentWarningKeys.webeditor, value: "Webeditor"}
    ].map((keyData) => {
      if (data && keyData.key in data) {
        const list = data[keyData.key].map((el) => 
          <li>{el}</li>
        );
        return (
          <div>
            <h2 className='text-base'>{keyData.value}</h2>
            <ul style={{ listStyleType: 'disc' }} className='text-left'>
              {list}
            </ul>
          </div>
        );
      } else {
        return null;
      }
    });
    
    tipData = flattenElement(tipData, <><div className={tooltipMode ? "h-2 border-t-2 border-gray-500" : "h-6"} /></>);

    return (
      <>
      {tipData && (
        <>{tipData}</>
      )}
      </>
    );
  }

  const openWarnInfo = (id) => {
    setWarnInformationData(id);
    setOpenWarnInformation(true);
  }

  const renderEnvironmentWarnings = (setting) => {
    let icons = [];

    if (!tooltipData || !(setting.customerEnvironment.value in tooltipData))
      return;

    const ew = tooltipData[setting.customerEnvironment.value];
    const type = ew[environmentWarningKeys.type];
    let tt = [];

    var keydata = [
      {key: environmentWarningKeys.template, value: "Template:"},
      {key: environmentWarningKeys.integration, value: "Integration:"},
      {key: environmentWarningKeys.webeditor, value: "Webeditor:"},
    ];

    keydata.map(kd => {
      if (kd.key in ew)
      {
        const ttx = ew[kd.key];
        if (ttx.length > 0)
        {
          tt.push(kd.value);
          tt = tt.concat(ttx);
        }
      }
      });

    if (tt.length > 0)
    {
      icons.push(<CmpLabel additionalClass='ml-4' type={type} data-for="html-tooltip" data-tip={setting.customerEnvironment.value} onClick={() => openWarnInfo(setting.customerEnvironment.value)} />);
    }
    return (<div className='flex gap-2'>
      {icons.map((icn) => (
        icn
      ))}
    </div>);
  }

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed title="Pages Manager Dashboard" />
        </TwPage.Header>

        <div className='flex flex-wrap gap-5'>
          {!initialized && (
            <CmpLabel text="Loading dashboard..." additionalClass='text-lg font-bold' />
          )}

          {statusData && initialized && (
          <div className='grid grid-cols-3 gap-14 h-60 w-full border-b-2 border-gray-900/10 dark:border-gray-700 pb-8 mb-4'>
            <CmpPanel variant={CmpPanel.variants.colored} color={CmpPanel.colors.green}>
              <div className='flex items-center justify-center'>
                <CmpLabel text="Running" type={CmpLabel.types.simple} additionalClass='text-lg font-bold' />
              </div>
              <div className='flex items-center justify-center my-auto'>
                <CmpLabel text={statusData.ok} type={CmpLabel.types.simple} additionalClass='text-8xl font-bold' />
              </div>
              <div className='overflow-hidden mt-4'>
                <ScrollingLabel autoScroll={true} additionalClass='w-full' type={ScrollingLabel.types.simple} messages={statusData.okMsg}></ScrollingLabel>
              </div>
            </CmpPanel>
            <CmpPanel variant={CmpPanel.variants.colored} color={CmpPanel.colors.yellow}>
              <div className='flex items-center justify-center'>
                <CmpLabel text="Warnings" type={CmpLabel.types.simple} additionalClass='text-lg font-bold' />
              </div>
              <div className='flex items-center justify-center my-auto'>
                <CmpLabel text={statusData.warn} type={CmpLabel.types.simple} additionalClass='text-8xl font-bold' />
              </div>
              <div className='overflow-hidden mt-4'>
                <ScrollingLabel autoScroll={true} additionalClass='w-full' type={ScrollingLabel.types.simple} messages={statusData.warnMsg}></ScrollingLabel>
              </div>
            </CmpPanel>
            <CmpPanel variant={CmpPanel.variants.colored} color={CmpPanel.colors.red}>
              <div className='flex items-center justify-center'>
                <CmpLabel text="Errors" type={CmpLabel.types.simple} additionalClass='text-lg font-bold' />
              </div>
              <div className='flex items-center justify-center my-auto'>
                <CmpLabel text={statusData.error} type={CmpLabel.types.simple} additionalClass='text-8xl font-bold' />
              </div>
              <div className='overflow-hidden mt-4'>
                <ScrollingLabel autoScroll={true} additionalClass='w-full' type={ScrollingLabel.types.simple} messages={statusData.errorMsg}></ScrollingLabel>
              </div>
            </CmpPanel>
          </div>
          )}

          {allSettings && initialized && allSettings.filter((setting) => setting.soapSettings || setting.webeditorSettings || setting.templateSettings).map((setting) => (
            // <CmpPanel additionalClass='flex-grow max-w-min'>
            <CmpPanel additionalClass='flex-grow max-w-lg'>
              <div>
                <div className='flex h-10'>
                  {renderSvgIcon({key: getEnvironmentIconKey(setting.customerEnvironment.value), sizeClass: 'h-8 w-8', additionalClass: 'mr-2'})}
                  <CmpLabel text={allCustomerEnvironments.find(({ value }) => value === setting.customerEnvironment.value)?.name} additionalClass='flex-1 text-lg font-bold' />
                  {renderEnvironmentWarnings(setting)}
                  {renderHtmlTooltip(createEnvWarnTooltipContent)}
                </div>
              </div>

              {/* Integration (SOAP settings) */}
              <Dashboard_Integration setting={setting} createButtons={createButtons} isExpanded={isExpanded} history={history} />

              {/* Templates */}
              <Dashboard_Template setting={setting} createButtons={createButtons} isExpanded={isExpanded} history={history} />

              {/* WebEditor */}
              <Dashboard_WebEditor setting={setting} createButtons={createButtons} isExpanded={isExpanded} history={history} />


            </CmpPanel>
          ))}
          </div>
      </TwPage>

      <GenericModal open={openWarnInformation} setOpen={setOpenWarnInformation}
        onConfirm={() => {
          setOpenWarnInformation(false);
          setWarnInformationData(null)
        }}
        onCancel={() => {
          setOpenWarnInformation(false);
          setWarnInformationData(null)
        }}
        confirmButtonText={'Close'}
        title={`Warning/Error info for ${allCustomerEnvironments.find(({ value }) => value === warnInformationData)?.name}`}>
        <div className="flex justify-start mt-4 text-gray-800 dark:text-gray-300">
          <div className='mx-auto'>
          {createEnvWarnTooltipContent(warnInformationData, null, false)}
          </div>
        </div>
      </GenericModal>
    </>
  );
}
