import React, { useState, useEffect, useContext } from "react";
import { format } from "date-fns";

import { DebugContext } from '../../../contexts/index.js';

import { useToastAction } from '../../../hooks/useToastAction.js';

import * as TemplateFileApi from "../../../api/interactTemplateFile.js";
import * as DeploymentTemplateApi from "../../../api/deployTemplate.js";

import {renderServerUpdateLabel} from "../../../utils/tooltipUtils.js"

import MenuContextList from "../../../components/MenuContextList/index.js";
import GenericModal from '../../../components/Modals/genericModal.js';
import { Label as CmpLabel } from '../../../components/Button/index.js';
import { Button as CmpButton } from '../../../components/Button/index.js';
import { Select as CmpSelect } from "../../../components/Select/index.js";

export default function CreateDocumentDialog({sessionId,
  metaFiles,
  handleCreateDocument,
  openCrDocDialog,
  setOpenCrDocDialog}) {

  const [documents, setDocuments] = useState(["Loading..."]);
  const [metafileId, setMetafileId] = useState(null);
  const [document, setDocument] = useState(null);
  const [loadingDocuments, setLoadingDocuments] = useState(true);

  useEffect(async () => {
    if (!metaFiles)
      return;
    if (!!metafileId)
      return;

    const fileId = metaFiles.length > 0 ? metaFiles[0].id : null;
    setMetafileId(fileId);
    await loadDocuments(fileId);
  }, [metaFiles]);

  useEffect(async () => {
    if (!metafileId || !openCrDocDialog)
      return;

    await loadDocuments(metafileId);
  }, [metafileId]);

  useEffect(() => {
    if (!documents || !openCrDocDialog)
      return;
    setDocument(documents.length > 0 ? documents[0] : null);
  }, [documents]);

  const loadDocuments = async (fileId) => {
    if (!fileId)
      return;

    setLoadingDocuments(true);
    setDocuments(["Loading..."]);
    const docs = await TemplateFileApi.getTemplateDocuments(fileId);
    setDocuments(!!docs ? docs : []);
    setLoadingDocuments(false);
  }


  // 

  return (
    <>
      {/* Server Backup dialog */}
      <GenericModal open={openCrDocDialog} setOpen={setOpenCrDocDialog}
        onConfirm={() => {
          const file = metaFiles.find(f => f.id === metafileId);

          //console.log("onConfirm", "file", file)

          handleCreateDocument(sessionId, file.fileName, document);
        }}
        onCancel={() => {
          setOpenCrDocDialog(false);
        }}
        showCancelButton={true}
        confirmButtonText={'Ok'}
        title="Create document"
      >
        <div className="flex gap-8 mt-4 flow-root">
          <div className='flex-auto mb-4'>
            <CmpSelect
              name="metafileId"
              value={metafileId}
              label={"Logic file"}
              onChange={e => { /*console.log("e.target", e.target);*/ setMetafileId(e.target.value); }}
              // error={errors.metafileId}
              options={metaFiles.map((f) => { return {name: f.fileName, value: f.id}; })}
            />
            <CmpSelect
                name="document"
                value={document}
                label={"Document"}
                disabled={loadingDocuments}
                onChange={e => { /*console.log("e.target", e.target);*/ setDocument(e.target.value); }}
                // error={errors.metafileId}
                options={documents.map((d) => { return {name: d, value: d}; })}
            />
          </div>
        </div>
      </GenericModal>
    </>
  );
}
