import { useClassNames } from "../../hooks/useClassNames";

/**
 * @param {*} Checkbox 
 */
export const Checkbox = ({ id, label, name, value, onChange, disabled, error }) => {
  const { classNames } = useClassNames();

  return (
    <>
      <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3 sm:py-1">
        <div className="sm:col-span-2">
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                type="checkbox"
                id={id}
                name={name}
                checked={value}
                disabled={disabled}
                onChange={onChange}
                className={classNames(
                  "bg-white block focus:ring-brand-blue focus:border-brand-blue h-4 w-4 text-brand-blue border-gray-300 rounded disabled:opacity-50 dark:ring-brand-blue dark:ring-offset-gray-800 focus:ring-2 dark:border-gray-600",
                  error
                    ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300"
                    : ""
                )}
              />
            </div>
            { label &&
            <div className="ml-3 text-sm">
              <label htmlFor="centraldistribution" className="font-medium text-gray-700 dark:text-gray-300">
                {label}
              </label>
            </div>
            }
          </div>
          {
            error &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {error}
            </p>
          }
        </div>
      </div>
    </>
  );
}