import React, { useState, useEffect, useContext, Fragment } from "react";
import { useHistory, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { useModalHelper } from "@metaforcelabs/metaforce-core";

import { InteractStatusContext } from "../../../contexts";

import { useClassNames } from "../../../hooks/useClassNames";
import { useToastAction } from '../../../hooks/useToastAction';
import { useEnvironmentGroup } from '../../../hooks/useEnvironmentGroup';

import * as yup from "yup";

import * as InteractApi from "../../../api/interact";

import Modal from '../../../components/Modal';

import { Label as CmpLabel } from "../../../components/Label";
import { Button as CmpButton } from "../../../components/Button";
import { Textbox as CmpTextbox } from "../../../components/Textbox";
import { TwToggle as CmpToggle } from "../../../components/Toggle";
import { TwPage } from "../../../components/TailwindPage";
import { TwFormEx } from "../../../components/TailwindForm";
import { TwTable } from "../../../components/TailwindTable/index.js";

export default function EnvironmentsGroup() {
  const { settingsId } = useParams();
  const history = useHistory();
  const { dispatchEnvironmentGroupsReloadEvent } = useEnvironmentGroup();
  const { dispatchUpdateInteractEnvironments } = useContext(InteractStatusContext);

  const [errors, setErrors] = useState({});
  const [environmentGroups, setEnvironmentGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState([]);
  const [isNew, setIsNew] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const loadAction = useToastAction();
  const saveAction = useToastAction();

  const selectVirtualGroupModalHelper = useModalHelper();

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      const allGroups = await InteractApi.getEnvironmentGroupsByCustomerId();
      setEnvironmentGroups(allGroups);

      const currGroup = settingsId !== "new"
      ? allGroups.filter((s) => s.id === settingsId)[0] 
      : {
          name: "",
          description: "",
          isActive: false,
          isVirtual: false,
          virtualEndpoint: "",
          virtualIdentityEndpoint: "",
          virtualClientId: "",
          virtualClientSecret: "",
          virtualGroupId: ""
        };
        setCurrentGroup(currGroup);
        setIsNew(settingsId === "new");
        if (settingsId !== "new") {
          const groups = await InteractApi.getAllVirtualGroups(currGroup.virtualIdentityEndpoint, currGroup.virtualEndpoint, currGroup.virtualClientId, currGroup.virtualClientSecret, currGroup.id || "");
          // console.log("EnvironmentsGroup", "loadBaseData", "groups", groups, "currentGroup.virtualGroupId", currGroup.virtualGroupId);
          setSelectedGroup(groups.find(g => g.id === currGroup.virtualGroupId))
        }
    }, "Failed to load")
  }

  useEffect(() => {
    loadBaseData();
  }, [])

  const onChange = ({ name, value }) => {
    //console.log("EnvironmentsGroup", "onChange", "name", name, "value", value);
    setCurrentGroup(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  let schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string()
  });

  const validate = async () => {
    var isValid = false

    await schema
        .validate(currentGroup, { abortEarly: false })
        .then(function () {
            isValid = true;
            setErrors({});
        })
        .catch(function (err) {
            var newErrors = {}

            err?.inner?.forEach(element => {
                newErrors[element.path] = `${element.errors[0]}`
            });

            // console.log("newErrors", newErrors);
            setErrors(newErrors);
        });

    return isValid;
  }

  const handleSave = async () => {
    if (currentGroup.id) {
        const currsettings = await InteractApi.getEnvironmentGroup(currentGroup.id);
        if (currsettings && currsettings.updatedDate !== currentGroup.updatedDate) {
            // tailwindToast.error("It can not save because Interact SOAP Client settings has been updated by others. Please refresh the page.");
            alert("It can not save because Interact Environment Group has been updated by others. Please refresh the page.");
            return;
        }
    }

    saveAction.execute(async () => {
        if (currentGroup.id) {
          //console.log("updateEnvironmentGroup", "currentGroup", currentGroup);
          await InteractApi.updateEnvironmentGroup(currentGroup);
        } else {
          //console.log("createEnvironmentGroup", "currentGroup", currentGroup);
          await InteractApi.createEnvironmentGroup(currentGroup);
        }
        //console.log("dispatchEnvironmentGroupsReloadEvent");
        await dispatchEnvironmentGroupsReloadEvent();
        await dispatchUpdateInteractEnvironments();
        //console.log("history.goBack()");
        history.goBack();
    }, "Failed to save Interact Envioronement Group settings", "Interact Envioronement Group settings saved")
  }
 
  const handleSubmit = async () => {
    var isValid = await validate();
    if (isValid) {
      handleSave();
    }
  }

  const selectVirtualEnv = async () => {
    selectVirtualGroupModalHelper.setActivetItem({currentGroup: currentGroup});
    selectVirtualGroupModalHelper.open();
    // console.log("selectVirtualEnv", "currentGroup", currentGroup);
  }

  const handleSelectedVirtualGroup = async (selGrp) => {
    setSelectedGroup(selGrp);
    onChange({name: "virtualGroupId", value: selGrp.id });
    selectVirtualGroupModalHelper.close();
  }

  const formButtons = [
    {text: "Back", variant: CmpButton.variants.secondary, onClick: () => history.goBack()},
    {text: "Save", onClick: handleSubmit, disabled: currentGroup.isStandard }
  ];

  // console.log("selectVirtualEnv", "currentGroup", currentGroup);

  return (
  <>
      <TwPage>
        <TwPage.Header isEnvGrpDep={false}>
          <TwPage.Header.Hdr.Fixed title="Interact Environment Group Settings" />
        </TwPage.Header>
        <TwFormEx
          buttons = {formButtons}
        >
          <TwFormEx.Section label="Settings" separator={true}>
            <CmpTextbox
              name="name"
              value={currentGroup.name}
              label={"Name"}
              disabled={currentGroup.isStandard}
              onChange={e => onChange(e.target)}
              error={errors.endpoint}
            />
            <CmpTextbox
              name="description"
              value={currentGroup.description}
              label={"Description"}
              disabled={currentGroup.isStandard}
              onChange={e => onChange(e.target)}
              error={errors.endpoint}
            />
          </TwFormEx.Section>

          <TwFormEx.Section label="Virtual settings" separator={true}>
            <div className="pt-5">
              <CmpToggle
                name="isVirtual"
                id="virtual-tgl"
                label={"Virtual Group"}
                value={currentGroup.isVirtual}
                disabled={!isNew}
                onChange={value => onChange({ name: 'isVirtual', value: value })}
                error={errors.isVirtual}
              />
            </div>
            {currentGroup.isVirtual && (
              <>
                <div className="pt-5">
                  <CmpLabel type={CmpLabel.types.info} text="Group connects to other centerpoint platform" />
                </div>
                <CmpTextbox
                  name="virtualEndpoint"
                  value={currentGroup.virtualEndpoint}
                  label={"Pages Manager Endpoint"}
                  onChange={e => onChange(e.target)}
                  error={errors.virtualEndpoint}
                />
                <CmpTextbox
                  name="virtualIdentityEndpoint"
                  value={currentGroup.virtualIdentityEndpoint}
                  label={"Identity Endpoint"}
                  onChange={e => onChange(e.target)}
                  error={errors.virtualIdentityEndpoint}
                />
                {isNew && (
                  <>
                    <CmpTextbox
                      name="virtualClientId"
                      value={currentGroup.virtualClientId}
                      label={"Client ID"}
                      onChange={e => onChange(e.target)}
                      error={errors.virtualClientId}
                    />
                    <CmpTextbox
                      name="virtualClientSecret"
                      value={currentGroup.virtualClientSecret}
                      label={"Client Secret"}
                      onChange={e => onChange(e.target)}
                      error={errors.virtualClientSecret}
                    />
                  </>
                )}
                <div className="flex">
                  <div className="flex-1">
                    <CmpTextbox
                      name="selectedGroup"
                      value={selectedGroup?.name}
                      label={"Group"}
                      disabled={true}
                      error={errors.selectedGroup}
                    />
                  </div>
                  <div className="mt-11 ml-4">
                    <CmpButton
                      variant={CmpButton.variants.secondary}
                      // disabled={((!isNew && !!currentGroup.id) || (isNew && (!currentGroup.virtualClientId || !currentGroup.virtualClientSecret))) || !currentGroup.virtualEndpoint || !currentGroup.virtualIdentityEndpoint}
                      disabled={((isNew && !currentGroup.id) && (!currentGroup.virtualClientId || !currentGroup.virtualClientSecret)) || !currentGroup.virtualEndpoint || !currentGroup.virtualIdentityEndpoint}
                      onClick={() => selectVirtualEnv()}
                      // ref={cancelButtonRef}
                      // className={cancelCls}
                    >
                      Select
                    </CmpButton>
                  </div>
                </div>
              </>
            )}
          </TwFormEx.Section>

          <TwFormEx.Section label="Status" separator={true}>
            <div className="pt-5">
              <CmpToggle
                name="isActive"
                id="active-tgl"
                label={"Activate"}
                value={currentGroup.isActive}
                disabled={currentGroup.isStandard}
                onChange={value => onChange({ name: 'isActive', value: value })}
                error={errors.isActive}
              />
            </div>
          </TwFormEx.Section>

        </TwFormEx>

        <SelectVirtualGroupModal title={'Select Virtual Group'} initialSelectedId={currentGroup.virtualGroupId} modalHelper={selectVirtualGroupModalHelper} onConfirm={handleSelectedVirtualGroup} />

      </TwPage>
    </>
  );
}

function SelectVirtualGroupModal({ modalHelper, initialSelectedId, title, onConfirm }) {
  const { classNames } = useClassNames();

  const [virtualEnvGroups, setVirtualEnvGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if (modalHelper.activeItem) {
      try {
        setIsLoading(true);

        const currentGroup = modalHelper.activeItem.currentGroup;
        //console.log("SelectVirtualGroupModal", "virtualIdentityEndpoint", currentGroup.virtualIdentityEndpoint, "virtualEndpoint", currentGroup.virtualEndpoint, "virtualClientId", currentGroup.virtualClientId, "virtualClientSecret", currentGroup.virtualClientSecret);
        let groups = await InteractApi.getAllVirtualGroups(currentGroup.virtualIdentityEndpoint, currentGroup.virtualEndpoint, currentGroup.virtualClientId, currentGroup.virtualClientSecret, currentGroup.id || "");
        const initSelGroup = groups?.find(g => g.id === initialSelectedId);
        //console.log("SelectVirtualGroupModal", "groups", groups, "initialSelectedId", initialSelectedId, "initSelGroup", initSelGroup);
        setSelectedGroup(initSelGroup);

        setVirtualEnvGroups(groups);
      } finally {
        setIsLoading(false);
      }
    }
  }, [modalHelper.activeItem])

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={() => { modalHelper.close() }}
      size={'large'}
      title={title}
    >
      { isLoading && (
        <>
          <div className="flex justify-center">
            <FontAwesomeIcon icon={faSpinner} className="text-white" size="4x" spin bounce />
          </div>
          <div className="flex justify-center mt-4">
            <CmpLabel text="Retrieving virtual groups from server..." />
          </div>
        </>
      )}
      { !isLoading && (
        <>
          <TwTable>
            <TwTable.Head>
              <TwTable.Header additionalClass={'sm:pl-6 h-14'}>
                Name
              </TwTable.Header>
            </TwTable.Head>
            <TwTable.Body additionalClass='text-left'>
              {virtualEnvGroups && virtualEnvGroups.map((item, idx) => (
                <TwTable.BodyRow key={`ff${item.id}-m`} additionalClass={classNames(
                  item.id === selectedGroup?.id
                    ? "bg-brand-blue text-white"
                    : "",
                    "h-12 hover:bg-gray-100 cursor-pointer"
                  )}
                >
                  <TwTable.BodyCol onClick={() => setSelectedGroup(item)}>
                    {item.name}
                  </TwTable.BodyCol>
                </TwTable.BodyRow>
              ))}
            </TwTable.Body>
          </TwTable>
        </>
      )}
      <div className="flex justify-end mt-6 sm:mt-8 space-x-2">
        <CmpButton
          variant={CmpButton.variants.secondary}
          className="justify-center"
          disabled={false}
          onClick={() => {
            modalHelper.close();
          }}
        >
          Cancel
        </CmpButton>
        <CmpButton
          variant={CmpButton.variants.primary}
          className="justify-center"
          disabled={isLoading || !selectedGroup}
          type={"submit"}
          onClick={() => {
            if (selectedGroup)
              onConfirm(selectedGroup)
          }}
        >
          Confirm
        </CmpButton>
      </div>
    </Modal>
  )
}