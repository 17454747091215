import React, { useEffect, useState, useContext, useRef, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import JSZip from 'jszip';

import { v4 as uuidv4 } from 'uuid';

import { EnvironmentGroupContext, InteractStatusContext, DebugContext, CurrentDataContext, OidcRoutesContext } from '../../../contexts/index.js';
import { useToastAction } from '../../../hooks/useToastAction.js';

import '../Environments/WizardSteps/arrowBlockStyles.scss';

import { useClassNames } from "@metaforcelabs/metaforce-core";

import { getInteractTemplateServerFiles, getInteractTemplateEnvironments } from "../../../api/interactTemplateEnvironment.js";
import { uploadInteractServerFileChunked, deleteInteractTemplateFile } from "../../../api/interactTemplateFile.js";
import { getInteractEnvironments } from "../../../api/interact";
import { downloadZipEx, setDownloadStatus } from "../../../api/storage.js";

import { LOGICFILEORIGINS, FILEDEPENDENCYTYPE, SERVERFILETYPE, getFileTypeName, getFileTypeExt } from "../../../utils/LogicFileUtilities";

import { CollapsableContainerCard, EnvironmentGroupContainerCard } from "../Environments/environmentGroupContainerCard.js"

import { Label as CmpLabel } from "../../../components/Label/index.js";
import { Select as CmpSelect } from "../../../components/Select/index.js";
import { Button as CmpButton } from "../../../components/Button/index.js";
import { TwPage } from "../../../components/TailwindPage/index.js";
import NestedTable from "../../../components/NestedTable";
import GenericModal from '../../../components/Modals/genericModal';
import { FileUpload as CmpFileUpload } from "../../../components/FileUpload";
import { Textbox as CmpTextbox } from "../../../components/Textbox";
import MenuContextListV2 from "../../../components/MenuContextListV2/index.js";
import { saveAs } from 'file-saver';

export default function ExtrasFileManager() {
  const pageId = "77512629-CA33-41AC-934E-EB60ACDB93FB";
  
  const history = useHistory();
  const { classNames } = useClassNames();
  const loadAction = useToastAction();

  const {environmentGroups} = useContext(EnvironmentGroupContext);
  const {interactEnvironments, dispatchUpdateInteractEnvironments} = useContext(InteractStatusContext);
  const { getViewSettings, dispatchViewSettingsUpdate } = useContext(CurrentDataContext);
  const { debugData} = useContext(DebugContext);
  const oidcRoutesContext = useContext(OidcRoutesContext);

  const [viewSettings, setViewSettings] = useState(getViewSettings(pageId));

  const [loadingBaseData, setLoadingBaseData] = useState(true);
  const [allTemplatesView, setAllTemplatesView] = useState([]);
  const [globalFilesView, setGlobalFilesView] = useState([]);
  const [allEnvironments, setAllEnvironments] = useState([]);

  const containerRef = useRef(null);

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      // console.log("ExtrasFileManager", "loadBaseData", "debugData", debugData);

      const tst_uuid = uuidv4("Blargh");
      // console.log("ExtrasFileManager", "loadBaseData", "tst_uuid", tst_uuid);

      const [srvAllEnvironments] = await Promise.all([getInteractEnvironments()]);
      setAllEnvironments(srvAllEnvironments);

    }, "Failed to load (DeploymentTest)")
  }

  useEffect(() => {
    loadBaseData();
  }, []);

  useEffect(async () => {
    // console.log("ExtrasFileManager", "useEffect[interactEnvironments]", "environmentGroups", environmentGroups, "allEnvironments", allEnvironments);
    if (!environmentGroups || !allEnvironments)
      return;

    let grpTpls = [];
    const globalFiles = await getInteractTemplateServerFiles(FILEDEPENDENCYTYPE.customer, oidcRoutesContext?.customerInfo?.customerId);
    await Promise.all(environmentGroups.map(async (group, idx) => {
      const tplEnvironments = await getInteractTemplateEnvironments(group.id);
      const groupFiles = await getInteractTemplateServerFiles(FILEDEPENDENCYTYPE.group, group.id);
      grpTpls[idx] = {group: group, files: groupFiles, environments: tplEnvironments};
    }));

    // console.log("ExtrasFileManager", "useEffect[interactEnvironments]", "grpTpls", grpTpls);
    // console.log("ExtrasFileManager", "useEffect[interactEnvironments]", "globalFiles", globalFiles);

    setAllTemplatesView(grpTpls);
    setGlobalFilesView(globalFiles);

    setLoadingBaseData(false);
  }, [environmentGroups, allEnvironments]);

  const setVs = (settings) => {
    setViewSettings(settings);
    dispatchViewSettingsUpdate(pageId, settings);
  }

  const onFileDelete = (file) => {
    loadBaseData();
  }

  //#region Fileupload Functions

  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileType, setFileType] = useState(SERVERFILETYPE.fontTrueType);
  const [fileExt, setFileExt] = useState(getFileTypeExt(SERVERFILETYPE.fontTrueType));
  const [fileComment, setFileComment] = useState("");
  const [dependencyType, setDependencyType] = useState(FILEDEPENDENCYTYPE.environment);
  const [dependencyId, setDependencyId] = useState(null);

  const showFileUpload = async (depType, depId) => {
    setDependencyType(depType);
    setDependencyId(depId)
    setFileComment("");
    setOpenFileUpload(true);
  }

  const onFileComment = async (e) => {
    setFileComment(e.target.value);
  }

  const onFileType = async (e) => {
    const ft = e.target.value;
    setFileType(ft);
    setFileExt(getFileTypeExt(ft));
  }

  const getFileContentAsBase64String = file => {
    return new Promise(resolve => {
      let content = "";
      let reader = new FileReader();

      reader.onload = () => {
        content = reader.result;
        resolve(content);
      };

      reader.readAsDataURL(file);
    });
  };

  const onFileSelected = async (file) => {
    setFileToUpload(file);
  }

  const onFileUpload = async (fileState, fileType, dependencyType, dependencyId, comment) => {
    var file = fileState.fileToUpload;
    try
    {
      const result = await getFileContentAsBase64String(file);

      await uploadInteractServerFileChunked(
        Number(fileType),
        Number(dependencyType),
        dependencyId,
        file.name,
        file.size,
        new Date(file.lastModified),
        comment || "",
        result.split(',')[1], LOGICFILEORIGINS.manual);
      await loadBaseData();
    }
    catch (err)
    {
      console.log("ERROR:", err);
    }
    finally
    {
      setFileToUpload(null);
      setOpenFileUpload(false);
    }
  };

  //#endregion

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed 
            title="File Manager" 
          />
        </TwPage.Header>

        <CmpLabel type={CmpLabel.types.warn} additionalClass="mb-8">
          <p className="font-bold">
              This page is intended as a Metaforce administration page and should not be used by others.
          </p>
          <p>
              Clicking a red button updates the database and should only be used by someone that understands the backend/database structure.
          </p>
        </CmpLabel>

        {loadingBaseData && (
          <div className='flex flex-wrap'>
            <CmpLabel text={"Loading files..."} additionalClass='font-bold h-6' />
          </div>
        )}

        {globalFilesView && !loadingBaseData && (
          <div className='mb-4'>
            <div className="relative">
              <CollapsableContainerCard headerJsx=
                {
                  <h3 className="font-semibold leading-6 text-xl mt-1 flex">
                    Global files
                  </h3>
                }
                defaultCollapsed={true}
              >
                <div className="px-4 py-5">
                  <FileList
                    filesView={globalFilesView}
                    getFileType={getFileTypeName}
                    showFileUpload={showFileUpload}
                    depType={FILEDEPENDENCYTYPE.customer}
                    depId={oidcRoutesContext?.customerInfo?.customerId || ""}
                    header=" "
                    onFileDelete={onFileDelete}
                  />
                </div>
              </CollapsableContainerCard>
            </div>
          </div>
        )}

        {allTemplatesView && allTemplatesView.map(data => (
          <div className='mb-4'>
            <FileManagerTestCard
              group={data.group}
              groupFilesView={data.files}
              interactEnvironments={data.environments}
              allEnvironments={allEnvironments}
              defaultCollapsed={true}
              viewSettings={viewSettings}
              setViewSettings={setVs}
              getFileType={getFileTypeName} 
              showFileUpload={showFileUpload}
              onFileDelete={onFileDelete}
            />
          </div>
        ))}

      </TwPage>

      <GenericModal open={openFileUpload} setOpen={setOpenFileUpload}
        onConfirm={() => onFileUpload({fileToUpload}, fileType, dependencyType, dependencyId, fileComment)}
        onCancel={() => {
          setOpenFileUpload(false);
          setFileToUpload(null);
        }}
        showCancelButton={true}
        confirmButtonDisabled={!fileToUpload || !fileComment}
        confirmButtonText={'Upload'}
        title="Upload logic file">
        <div className="mt-4">
          <CmpSelect
            name="type"
            value={fileType}
            label={"File type"}
            onChange={onFileType}
            options={Object.entries(SERVERFILETYPE).filter(([label, value]) => value != SERVERFILETYPE.logic && value != SERVERFILETYPE.backup).map(([label, value]) => ({name: getFileTypeName(value), value: value}))}
          />
          <CmpFileUpload
            name="serverFile"
            label={"Server file"}
            fileExtension={fileExt}
            onChange={onFileSelected}
          />
          {fileToUpload &&
            <CmpLabel text={`Selected file: ${fileToUpload.name}`} />
          }
          <CmpTextbox additionalClass="mt-4"
            name="type"
            value={fileComment}
            label="Comment"
            onChange={onFileComment}
           />
        </div>
      </GenericModal>
    </>
  );
}

function FileList({
  header,
  filesView,
  getFileType,
  depType,
  depId,
  showFileUpload,
  onFileDelete,
}) {

  const containerRef = useRef(null);

  const getFileActions = (file) => {
    let actions = [
        {
            name: "Delete",
            onClick: () => {
              deleteInteractTemplateFile(file.id);
              onFileDelete(file);
            },
            element: file
        }
    ];

    return actions;
  }

  var sortedFilesView = filesView?.sort((a, b) => {
    if (b.fileType == a.fileType)
      return b.fileName > a.fileName ? -1 : 1;
    return b.fileType - a.fileType;
  });
  var types = sortedFilesView.filter((value, index, array) => {
    var na = array.filter(i => i.fileType === value.fileType);
    return array.indexOf(na[0]) === index;
  }).map(i => i.fileType);
  // console.log("FileList", "types", types);
  const typedFiles = types.map(t => { return {type: t, files: sortedFilesView.filter(f => f.fileType === t)}; });
  // console.log("FileList", "typedFiles", typedFiles);

  return (
    <div className="px-4 pb-5">
      <div className="mb-4 flex flex-wrap items-center justify-between sm:flex-nowrap text-gray-900 dark:text-gray-300">
        <div className="flex flex-wrap w-full items-center justify-between sm:flex-nowrap text-gray-900 dark:text-gray-300">
        
        <h3 className="font-semibold leading-6 text-xl mt-1 flex">
          {header || "Files"}
        </h3>
        <div className="">
          <TwPage.Header.Hdr.Buttons>
              <div className="flex items-center">
                <CmpButton
                  variant={CmpButton.variants.primary}
                  disabled={false}
                  onClick={() => showFileUpload(depType, depId)}
                  className={""}
                >
                  Add File 
                </CmpButton>
              </div>
            </TwPage.Header.Hdr.Buttons>
          </div>
        </div>
      </div>
      <div>
        <NestedTable.Table>
          <NestedTable.TableHead>
            <NestedTable.TableHeader className={'w-10'}>
            </NestedTable.TableHeader>
            <NestedTable.TableHeader>
              Name
            </NestedTable.TableHeader>
            <NestedTable.TableHeader>
              Type
            </NestedTable.TableHeader>
            <NestedTable.TableHeader>
              <span className="sr-only">Edit</span>
            </NestedTable.TableHeader>
          </NestedTable.TableHead>
          <NestedTable.TableBody>
            {typedFiles?.map((item, idx) => (
              <Fragment key={item}>
                <NestedTable.CollapsableProvider defaultCollapsed={idx != 0}>
                  <NestedTable.SubHead canCollapse={true} title={'Click to expand/collapse'}>
                    <NestedTable.SubHeader>
                      {getFileType(item.type)}
                    </NestedTable.SubHeader>
                    <NestedTable.SubHeader>
                      Type
                    </NestedTable.SubHeader>
                    <NestedTable.SubHeader className="text-right sm:pr-3">
                    </NestedTable.SubHeader>
                  </NestedTable.SubHead>
                  <NestedTable.Collapsable>
                    {item.files?.map((file, idx) => (
                      <NestedTable.TableRow key={file.id} idx={idx}>
                        <NestedTable.TableCol>
                        </NestedTable.TableCol>
                        <NestedTable.TableCol>
                          {file.fileName}
                        </NestedTable.TableCol>
                        <NestedTable.TableCol title={"srv"} className={'flex space-x-2'}>
                          <span>{getFileType(file.fileType)}</span>
                        </NestedTable.TableCol>
                        <NestedTable.TableCol className="text-right sm:pr-3">
                          <MenuContextListV2
                            disableDeleteConfirm={true}
                            actions={getFileActions(file)}
                          />
                        </NestedTable.TableCol>
                      </NestedTable.TableRow>
                    ))}
                  </NestedTable.Collapsable>
                </NestedTable.CollapsableProvider>
              </Fragment>
            ))}
          </NestedTable.TableBody>
        </NestedTable.Table>
      </div>
    </div>
  );
}

function FileManagerTestCard({
  group,
  groupFilesView,
  interactEnvironments,
  defaultCollapsed,
  allEnvironments,
  viewSettings,
  setViewSettings,
  getFileType,
  showFileUpload,
  onFileDelete,
  collapsable = true
 }) {
  const containerRef = useRef(null);
  const oidcRoutesContext = useContext(OidcRoutesContext);

  const onItemCollapsedChanged = (itemId, isCollapsed) => {
    let vs = viewSettings;
    if (!vs)
      vs = {};

    if (vs.hasOwnProperty(group.id))
    {
      const oldVs = vs[group.id];
      oldVs[itemId] = {isCollapsed: isCollapsed};
      vs[group.id] = {...oldVs}
    }
    else
    {
      let labelData = vs.hasOwnProperty(itemId) ? vs[itemId] : {};
      labelData[itemId] = {isCollapsed: isCollapsed};
      vs[group.id] = labelData
    }
    setViewSettings(vs);
  }

  const isItemCollapsed = (itemId, defaultValue) => {
    // if (viewSettings?.hasOwnProperty(group.id))
    // {
    //   const ld = viewSettings[group.id];
    //   if (ld.hasOwnProperty(itemId))
    //   {
    //     return /*ld[itemId].isCollapsed ||*/ false;
    //   }
    // }
    // return defaultValue;
  }

  const getEnvName = (customerEnvironment) => {
    return allEnvironments.find(x => x.value === customerEnvironment)?.name || '';
  }

  const getFileActions = (file) => {
    let actions = [
        {
            name: "Delete",
            onClick: () => {
              // deleteInteractTemplateFile(file.id);
              // onFileDelete(file);
            },
            element: file
        }
    ];

    return actions;
  }


  const getCustomButtons = () => {
    let buttons = [];
    buttons.push(
      <>
        <CmpButton
          variant={CmpButton.variants.primary}
          disabled={false}
          onClick={() => showZipDownload()}
          className={""}
        >
          Download zip
        </CmpButton>
      </>
    );
    return buttons;
  }

  //#region Filedownload Functions

  const [openZipDownload, setOpenZipDownload] = useState(false);
  const [selectedEnvironmentId, setSelectedEnvironmentId] = useState(null);

  const showZipDownload = () => {
    setSelectedEnvironmentId(null);
    setOpenZipDownload(true);
  }

  const onZipDownload = () => {
    const customerId = oidcRoutesContext?.customerInfo?.customerId;
    const environmentId = selectedEnvironmentId;
    //"INTERACT-56548F"
    const serverId = interactEnvironments.find(e => e.id === environmentId)?.servers.find(s => s.enabled)?.serverId;

    console.log("onZipDownload", "environmentId", environmentId, "serverId", serverId);

    if (customerId && environmentId && serverId) {
      downloadZipEx(customerId, environmentId, serverId, (zipFile) => {
        console.log("onZipDownload", "zipFile", zipFile);
        JSZip.loadAsync(zipFile).then(function (zip) {
          return zip.file("_sessionData.json").async("string");
        }).then(function (text) {
          console.log("onZipDownload", "_sessionData.json", text);
          var json = JSON.parse(text);
          var sessionId = json.SessionId;
          var fileMap = json.FileMap;

          console.log("onZipDownload", "sessionId", sessionId, "fileMap", fileMap);

          var statusData = { data: Object.keys(fileMap).map(k => {
            return { logicFileId: fileMap[k], status: 0, message: "" };
          })};

          console.log("onZipDownload", "statusData", statusData);

          setDownloadStatus(sessionId, statusData);

        });
      });
      setOpenZipDownload(false);
      setSelectedEnvironmentId(null);
    }
  }
  
  const onSelectedEnvironment = async (e) => {
    setSelectedEnvironmentId(e.target.value);
  }
  // #endregion

  return (
    <>
      <div className="relative">
        <EnvironmentGroupContainerCard
          group={group}
          defaultCollapsed={defaultCollapsed}
          collapsable={true}
          visibleSettings={{showActiveState: false}}
          editableSettings={{
            showAddEnvBtn: false,
            getCustomButtons: getCustomButtons
        }}
        >
          {groupFilesView && /*groupFilesView.length > 0 &&*/ (
            <div className='mb-4'>
              <FileList
                filesView={groupFilesView}
                getFileType={getFileType}
                header="Group files"
                depType={FILEDEPENDENCYTYPE.group}
                depId={group.id}
                showFileUpload={showFileUpload}
                onFileDelete={onFileDelete}
              />
            </div>
          )}
          <div className="px-4 pb-5">
            <div ref={containerRef}>
              <NestedTable.Table>
                <NestedTable.TableHead>
                  <NestedTable.TableHeader className={'w-10'}>
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Name
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Type
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    <span className="sr-only">Edit</span>
                  </NestedTable.TableHeader>
                </NestedTable.TableHead>
                <NestedTable.TableBody>
                  {interactEnvironments?.map((item, idx) => (
                    <Fragment key={item}>
                      <NestedTable.CollapsableProvider defaultCollapsed={isItemCollapsed(item.customerEnvironment, idx != 0)} onCollapseChanged={(v) => onItemCollapsedChanged(item.customerEnvironment, v)} id={item.id}>
                        <NestedTable.SubHead canCollapse={true} title={'Click to expand/collapse'}>
                          <NestedTable.SubHeader>
                            {getEnvName(item.customerEnvironment)}
                          </NestedTable.SubHeader>
                          <NestedTable.SubHeader>
                            Environment
                          </NestedTable.SubHeader>
                          <NestedTable.SubHeader className="text-right sm:pr-3">
                          </NestedTable.SubHeader>
                        </NestedTable.SubHead>
                        <NestedTable.Collapsable>
                          {item.files?.map((file, idx) => (
                            <NestedTable.TableRow key={file.id} idx={idx}>
                              <NestedTable.TableCol>
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {file.fileName}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol className={'flex space-x-2'}>
                                <span>{getFileType(file.fileType)}</span>
                              </NestedTable.TableCol>
                              <NestedTable.TableCol className="text-right sm:pr-3">
                                <MenuContextListV2
                                  disableDeleteConfirm={true}
                                  actions={getFileActions(file)}
                                />
                              </NestedTable.TableCol>
                            </NestedTable.TableRow>
                          ))}
                        </NestedTable.Collapsable>
                      </NestedTable.CollapsableProvider>
                    </Fragment>
                  ))}
                </NestedTable.TableBody>
              </NestedTable.Table>
            </div>
          </div>
        </EnvironmentGroupContainerCard>
      </div>
    
      <GenericModal open={openZipDownload} setOpen={setOpenZipDownload}
        onConfirm={() => onZipDownload()}
        onCancel={() => {
          setOpenZipDownload(false);
          setSelectedEnvironmentId(null);
        }}
        showCancelButton={true}
        confirmButtonDisabled={!selectedEnvironmentId}
        confirmButtonText={'Download'}
        title="Download storage zip file">
        <div className="mt-4">
          <CmpSelect
            name="type"
            value={selectedEnvironmentId}
            label={"Environment"}
            onChange={onSelectedEnvironment}
            options={[{name: "", value: null}].concat(interactEnvironments?.map(e => ({name: getEnvName(e.customerEnvironment), value: e.id})) || [])}
          />
        </div>
      </GenericModal>
    </>
  );
 }