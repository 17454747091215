import React, { useEffect, useState, useRef } from 'react';
import { format } from "date-fns";

import { FileUpload as CmpFileUpload } from "../../../components/FileUpload/index.js";
import { Label as CmpLabel } from "../../../components/Label/index.js";
import { Textbox as CmpTextbox } from "../../../components/Textbox/index.js";
import GenericModal from '../../../components/Modals/genericModal.js';

export default function UploadLogicFileDialog({openFileUpload, setOpenFileUpload, onFileUpload, fileToUpload, setFileToUpload, onFileSelected, fileComment, onFileComment}) {
  return (
    <GenericModal open={openFileUpload} setOpen={setOpenFileUpload}
    onConfirm={() => onFileUpload({fileToUpload}, fileComment)}
    onCancel={() => {
      setOpenFileUpload(false);
      setFileToUpload(null);
    }}
    showCancelButton={true}
    confirmButtonDisabled={!fileToUpload || !fileComment}
    confirmButtonText={'Upload'}
    title="Upload logic file">
    <div className="mt-4">
      <CmpFileUpload
        name="templateFile"
        label={"Logic file"}
        fileExtension=".mfl"
        onChange={onFileSelected}
      />
      {fileToUpload &&
        <CmpLabel text={`Selected file: ${fileToUpload.name}`} />
      }
      <CmpTextbox additionalClass="mt-4"
        name="type"
        value={fileComment}
        label="Comment"
        onChange={onFileComment}
       />
    </div>
  </GenericModal>  );
}