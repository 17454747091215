import React, { useEffect, useState, useRef } from 'react';
import  { ExclamationIcon } from '@heroicons/react/solid'

import { useFeature } from '../../../hooks/useFeature';
import { useTemplateServerUpdate } from '../../../hooks/useTemplateServerUpdate.js';

import * as Constants from '../../../utils/constants'

import * as TemplateApi from "../../../api/interactTemplateEnvironment.js";
import { getServerNameTooltip, getServerTypeIcon, getServerTypeTooltip, getServerStatusIcon, getServerStatusTooltip, filterServerStatus, AddServerTableTooltipsToContext, addServerTableTooltipsToContext, renderServerTooltips } from "../../../utils/templateServerIconTooltipUtils";

import { Panel as CmpPanel } from "../../../components/Panel";
import { Label as CmpLabel } from "../../../components/Label";
import MenuContextList from "../../../components/MenuContextList";
import { TwTable } from "../../../components/TailwindTable";

export default function TemplatesOverviewPanel({tplEnvSettings, allCustomerEnvironments}) {
  return (
    <>
      <CmpPanel additionalClass='flex-grow lg:max-w-2xl mx-auto'>
        <CmpLabel text={allCustomerEnvironments.find(({ value }) => value === tplEnvSettings.customerEnvironment)?.name} additionalClass='text-lg font-bold' />

        <CmpLabel text={`Number of files: ${tplEnvSettings.files.length}`} additionalClass='mt-2 text-gray-700 dark:text-gray-400 ' />
        <div>
          <TemplatesOverviewTable tplEnvSettings={tplEnvSettings} />
        </div>
      </CmpPanel>
    </>
  );
}

export function TemplatesOverviewTable({tplEnvSettings, additionalClass = 'mt-6'}) {
  const { hasFeature } = useFeature();
  const { serversNeedUpdate, latestImageVer, updatedServer } = useTemplateServerUpdate();

  const refMenuContainer = useRef();

  const [hasCrudAccess, setHasCrudAccess] = useState(false);

  const updatableServers = serversNeedUpdate.hasOwnProperty(tplEnvSettings.environmentGroupId) ? serversNeedUpdate[tplEnvSettings.environmentGroupId] : {};

  useEffect(() => {
    var hasCrudAccess = hasFeature(Constants.templatesConfigurationFeatureCode);
    setHasCrudAccess(hasCrudAccess)
  }, []);

  const handleResetFailedDownloads = async (tplEnvSettings, server) => {
    await TemplateApi.resetFailedDownloads(tplEnvSettings.id, server.serverId);
  }

  return (
    <>
      {/* <AddServerTableTooltipsToContext /> */}
      <div className={`${additionalClass}`} ref={refMenuContainer}>
      <TwTable>
        <TwTable.Head>
          <TwTable.Header additionalClass={'uppercase sm:pl-6'}  data-for="test-tooltip" data-tip="Add new server">
            Name
          </TwTable.Header>
          <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
            Type
          </TwTable.Header>
          <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
            Status
          </TwTable.Header>
          <TwTable.Header></TwTable.Header>
        </TwTable.Head>
        <TwTable.Body>
          {tplEnvSettings.servers.filter(x => x.enabled) && tplEnvSettings.servers.filter(x => x.enabled).map((server) => (
            <>
            <TwTable.BodyRow key={server.serverId} id={server.serverId} useHover={false}>
              <TwTable.BodyCol
                additionalClass={'font-medium'}
                data-for="namedesc-tooltip"
                data-tip={getServerNameTooltip(server, updatableServers.hasOwnProperty(server.serverId.toString()) ? {newVer: latestImageVer, currVer: updatableServers[server.serverId.toString()] } : null)}
              >
                {/* {server.name} */}
                <div className="flex relative">
                  <div className="inline-flex">
                    {server.name}
                  </div>
                  {updatableServers.hasOwnProperty(server.serverId.toString()) &&
                    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white -top-2 -left-4">
                      <ExclamationIcon className="opacity-80 h-5 w-5 text-yellow-600 dark:text-yellow-200" aria-hidden="true" />
                    </div>
                  }
                  </div>
              </TwTable.BodyCol>
              <TwTable.BodyCol data-for="simple-tooltip" data-tip={getServerTypeTooltip(server.type)}>
                {getServerTypeIcon(server.type)}
              </TwTable.BodyCol>
              <TwTable.BodyCol data-for="dataarray-tooltip" data-tip={getServerStatusTooltip(server.status, server.statusMessage)}>
                {getServerStatusIcon(server.status)}
              </TwTable.BodyCol>
              <TwTable.BodyCol additionalClass={'w-2'}>
                <MenuContextList
                  actions={[
                    {
                      name: "Reset failed downloads",
                      disabled: filterServerStatus(server.status) != 3 || !hasCrudAccess,
                      onClick: () => handleResetFailedDownloads(tplEnvSettings, server)
                    }
                  ]}
                  handleDelete="false"
                  refContainer={refMenuContainer}
                />
              </TwTable.BodyCol>
              {renderServerTooltips()}
            </TwTable.BodyRow>
          </>
          ))}
        </TwTable.Body>
      </TwTable>
      </div>
    </>
  );
}
