import { TwUiToggleSettings } from "./components/Toggle";
import { TwUiFormSettings } from "./components/TailwindForm";
import { TwUiNavBarSettings } from "./components/TailwinNavBar";

import { featureFlags } from './utils/features.js'

const debugMode = featureFlags.debugFeatures;

const fullWidth = false;
const menuFullWidth = false;
export const tailwindUiSettings = {
  fullwidth: fullWidth,
  form: TwUiFormSettings(TwUiFormSettings.types.twoColumn),
  toggle: TwUiToggleSettings(TwUiToggleSettings.types.icon, TwUiToggleSettings.alignments.right, false, false),
  navbar: TwUiNavBarSettings(menuFullWidth ? TwUiNavBarSettings.types.fullwidth : TwUiNavBarSettings.types.maxWidth3x1, true, true, false)
}
