import React, { Fragment } from "react";
import { useRef, useState, useContext, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Badge, GenericModal, useClassNames, useModalHelper } from "@metaforcelabs/metaforce-core";
import { useHistory } from "react-router-dom";
import toast from 'react-hot-toast';
import useScreenType from "react-screentype-hook";

import { ChevronRightIcon } from "@heroicons/react/outline";
import { BanIcon, ExclamationIcon, InformationCircleIcon } from "@heroicons/react/solid";

import { CurrentDataContext, InteractStatusContext } from '../../../contexts';

import { useFeature } from "../../../hooks/useFeature";
import { useTemplateServerUpdate } from '../../../hooks/useTemplateServerUpdate.js';

import { updateEnvironmentOrder } from "../../../api/interactEnvironment";
import { saveEditDeploymentSettings } from "../../../api/deployment";

import { environmentsConfigurationFeatureCode } from "../../../utils/constants";
import { getServerTypeIcon, getServerTypeTooltip, TEMPLATESERVERTYPES } from "../../../utils/templateServerIconTooltipUtils";

import { TwPageHeaderButtons } from "../../../components/TailwindPage";
import { Button } from "../../../components/Button";
import MenuContextList from "../../../components/MenuContextList";
import NestedTable from "../../../components/NestedTable";
// import { Menu, MenuButton } from '../../../components/Menu';

import { EnvironmentGroupContainerCard } from "./environmentGroupContainerCard.js"
// import EnvironmentGroupModal from "./environmentGroupModal";
// import EnvironmentModal from "./environmentModal";
// import ServerModal from "./serverModal";
import ServerInformationDialog from "./serverInformationDialog.js"
import DeleteEnvironmentModal from "./deleteEnvironmentModal";
import DeleteServerModal from "./deleteServerModal";
import EditServerSettingsModal from "./editServerSettingsModal.js";
import MenuContextListV2 from "../../../components/MenuContextListV2/index.js";
import { Menu } from "@headlessui/react";

export const EnvironmentGroupCard = ({ group,
    defaultCollapsed,
    onEdit,
    allEnvironments,
    onToggleGroupStatus,
    onAddEnvironment,
    onOrderChange,
    onTemplateServerDelete,
    onEnvironmentDelete,
    onGroupDelete,
    viewSettings,
    setViewSettings,
    }) => {
    const containerRef = useRef(null);
    const { classNames } = useClassNames();
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
    const screenType = useScreenType({
        mobile: 100,
        tablet: 400,
        desktop: 640,
        largeDesktop: 1600,
      });

    const deleteEnvironmentModalHelper = useModalHelper();
    const deleteServerModalHelper = useModalHelper();
    const editServerSettingsModalHelper = useModalHelper();

    const headerRef = useRef(null);
    //console.log(group)
    const { hasFeature } = useFeature();
    const { currentDataId, currentDataPayload, originalDataPayload, dispatchCurrentDataEvent } = useContext(CurrentDataContext);
    const { deployEnabled } = useContext(InteractStatusContext);
    const { serversNeedUpdate, latestImageVer, updatedServer } = useTemplateServerUpdate();

    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [selectedServers, setSelectedServers] = useState([]);

    const history = useHistory();

    // useEffect(() => {
    //     console.log("screenType", screenType, "window.innerWidth", window?.innerWidth);
    // }, [screenType]);

    // const handleCollapse = () => {
    //     if (group.isActive) {
    //         setIsCollapsed(prev => !prev);
    //         onCollapseChanged(group, !isCollapsed);
    //     }
    // }

    const onCollapseChanged = (isCollapsed) => {
        let vs = viewSettings;
        if (!vs)
          vs = {};
        if (vs.hasOwnProperty(group.id))
        {
          const oldVs = vs[group.id];
          vs[group.id] = {...oldVs, isCollapsed: isCollapsed}
        }
        else
        {
          vs[group.id] = {isCollapsed: isCollapsed}
        }
        setViewSettings(vs);
    }

    const onItemCollapsedChanged = (itemId, isCollapsed) => {
        let vs = viewSettings;
        if (!vs)
          vs = {};
    
        if (vs.hasOwnProperty(group.id))
        {
          const oldVs = vs[group.id];
          oldVs[itemId] = {isCollapsed: isCollapsed};
          vs[group.id] = {...oldVs}
        }
        else
        {
          let labelData = vs.hasOwnProperty(itemId) ? vs[itemId] : {};
          labelData[itemId] = {isCollapsed: isCollapsed};
          vs[group.id] = labelData
        }
        setViewSettings(vs);
    }

    const isItemCollapsed = (itemId, defaultValue) => {
        if (viewSettings?.hasOwnProperty(group.id))
        {
          const ld = viewSettings[group.id];
          if (ld.hasOwnProperty(itemId))
          {
            return ld[itemId].isCollapsed || false;
          }
        }
        return defaultValue;
    }
    
    const handleToggleGroupStatus = (group, isActive) => {
        onToggleGroupStatus(group, isActive);
    }

    const handleAddEnvironment = () => {
        if (hasFeature(environmentsConfigurationFeatureCode)) {
            // history.push(`/environments/template/${env}`);
            history.push(`/environments/v2/${group.id}/new`);
        }
    }

    const handleEditEnvironment = (env) => {
        // console.log("handleEditEnvironment", "env", env);
        if (hasFeature(environmentsConfigurationFeatureCode)) {
            // history.push(`/environments/template/${env}`);
            history.push(`/environments/v2/${group.id}/${env.customerEnvironment}`);
        }
    }

    const handleMoveUp = async (env) => {
        const sortedEnvironments = [...group.environments].sort((a, b) => {
            return b.order - a.order;
        });

        const envToSwapOrder = sortedEnvironments.find(x => x.order < env.order);
        await updateEnvironmentOrder({ templateId1: env.template.id, templateId2: envToSwapOrder.template.id, directionUp: true })
        onOrderChange(group.id)
    }

    const canMoveUp = (env, groupEnvs) => {
        return env.order > 1;
    }

    const handleMoveDown = async (env) => {
        const sortedEnvironments = [...group.environments].sort((a, b) => {
            return a.order - b.order;
        });

        const envToSwapOrder = sortedEnvironments.find(x => x.order > env.order);
        await updateEnvironmentOrder({ templateId1: env.template.id, templateId2: envToSwapOrder.template.id, directionUp: false })
        onOrderChange(group.id)
    }

    const canMoveDown = (env, groupEnvs) => {
        const sortedEnvironments = [...groupEnvs].sort((a, b) => {
            return b.order - a.order;
        });
        return env.order !== sortedEnvironments[0].order;
    }

    const handleEditGroup = () => {
        onEdit(group.id);
    }

    const handleDeleteGroup = () => {
        onGroupDelete(group.id);
    }

    const getEnvName = (customerEnvironment) => {
        return allEnvironments.find(x => x.value === customerEnvironment)?.name || '';
    }

    const getGroupActions = () => {
        let actions = [];
        if (group.isActive) {
            actions.push({
                name: "Edit",
                onClick: () => { handleEditGroup() },
                disabled: group.isStandard || !hasFeature(environmentsConfigurationFeatureCode)
            });
            actions.push({
                name: "Disable",
                onClick: () => { handleToggleGroupStatus(group, false) },
                disabled: group.isStandard || !hasFeature(environmentsConfigurationFeatureCode)
            });
        } else {
            actions.push({
                name: "Activate",
                onClick: () => { handleToggleGroupStatus(group, true) },
                disabled: group.isStandard || !hasFeature(environmentsConfigurationFeatureCode)
            });
        }
        actions.push({
            name: group?.isVirtual ? "Disconnect" : "Delete",
            onClick: () => { handleDeleteGroup(group, false) },
            disabled: group.isStandard || !hasFeature(environmentsConfigurationFeatureCode)
        });
        return actions;
    }

    const handleDeleteEnv = (env) => {
        deleteEnvironmentModalHelper.setActivetItem(env);
        deleteEnvironmentModalHelper.open();

        // onEnvironmentDelete(env);
    }

    const handleConfirmDeleteEnv = (env) => {
        // onEnvironmentDelete(env);
        deleteEnvironmentModalHelper.close();
        onEnvironmentDelete(env);
    }

    const handleDeleteServer = (env, template, server) => {
        //console.log("handleDeleteServer", "template", template, "server", server);
        deleteServerModalHelper.setActivetItem({env: env, template: template, server: server});
        deleteServerModalHelper.open();
    }
   
    const handleConfirmDeleteServer = async (serverData) => {
        //console.log("handleConfirmDeleteServer", "serverData", serverData);
        deleteServerModalHelper.close();
        await onTemplateServerDelete(serverData.env, serverData.template, serverData.server);
    }

    const handleDeployAndCreateEnvironment = async () => {
        if (allEnvironments.find(({ value }) => group.environments.find(item => item.customerEnvironment === value) === undefined)) {
            dispatchCurrentDataEvent("history", history.location.pathname);
            history.push(`/environments/deployment/env/${group.id}`);
        } else {
            toast.error("It can not create a new Soap settings because all customer environments are already used.");
        }
    }

    const handleEditServer = (templateId, server) => {
        //console.log(server)
        if (hasFeature(environmentsConfigurationFeatureCode)) {
            history.push(`/templates/server/${templateId}/${server.serverId}`);
        }
    }

    const handleAddServer = (templateId) => {
        if (hasFeature(environmentsConfigurationFeatureCode)) {
            history.push(`/templates/server/${templateId}/new`);
        }
    }

    const handleCreateKubernetesServer = (templateId) => {
        dispatchCurrentDataEvent("history", history.location.pathname);
        history.push(`/environments/deployment/srv/${templateId}/0`);
    }

    const handleUpdateKubernetesServer = (templateId, serverId) => {
        dispatchCurrentDataEvent("history", history.location.pathname);
        history.push(`/environments/deployment/srv/${templateId}/1/${serverId}`);
    }

    const handleEditServerSettings = (env, template, server) => {
        // console.log("handleEditServerSettings", env, template, server);
        editServerSettingsModalHelper.setActivetItem({env: env, template: template, server: server});
        editServerSettingsModalHelper.open();
    }

    const handleUpdateServerSettings = (modifiedValues) => {
    //   console.log("handleUpdateServerSettings", "modifiedValues", modifiedValues);
      saveEditDeploymentSettings(modifiedValues.sessionId, modifiedValues);
    }

    const canDeployKubernetesServer = (env) => {
        return !!env?.template?.servers ? !env.template.servers.find(s => !!s.deploymentSessionId) : true;
    }

    const getEnvActions = (env) => {
        let actions = [{
            name: "Add Server manually",
            onClick: () => { handleAddServer(env.template.id) },
            disabled: !hasFeature(environmentsConfigurationFeatureCode),
            element: env
        },
        {
            name: "Add Kubernetes server",
            onClick: () => { handleCreateKubernetesServer(env.template.id) },
            disabled: !hasFeature(environmentsConfigurationFeatureCode) || !canDeployKubernetesServer(env) || !deployEnabled,
            element: env
        },
        {
            name: "Edit",
            onClick: () => {
                handleEditEnvironment(env);
            },
            disabled: !hasFeature(environmentsConfigurationFeatureCode),
            element: env
        }
        ]
        if (canMoveUp(env, group.environments)) {
            actions.push({
                name: "Move Up",
                onClick: () => {
                    handleMoveUp(env);
                },
                disabled: !canMoveUp(env, group.environments) || !hasFeature(environmentsConfigurationFeatureCode),
                element: env
            });
        }

        if (canMoveDown(env, group.environments)) {
            actions.push({
                name: "Move Down",
                onClick: () => {
                    handleMoveDown(env);
                },
                disabled: !canMoveDown(env, group.environments) || !hasFeature(environmentsConfigurationFeatureCode),
                element: env
            });
        }

        actions.push({
            name: "Delete",
            onClick: () => { handleDeleteEnv(env) },
            disabled: !hasFeature(environmentsConfigurationFeatureCode),
            element: env
        })
        return actions;
    }

    const getCreateActions = () => {
        return [
            {
                id: uuidv4(),
                name: 'Add manually',
                onClick: () => handleAddEnvironment(),
                disabled: !hasFeature(environmentsConfigurationFeatureCode),
                element: {}
            },
            {
                id: uuidv4(),
                name: 'Add Kubernetes server',
                // disabled: !canDeployKubernetes(),
                onClick: () => handleDeployAndCreateEnvironment(),
                disabled: !hasFeature(environmentsConfigurationFeatureCode) || !deployEnabled,
                element: {}
            }
        ];
    }

    const getServerIconColor = (srv) => {
        // const updatableServers = serversNeedUpdate.hasOwnProperty(group.id) ? serversNeedUpdate[group.id] : {};
        // if (!srv.enabled || updatableServers.hasOwnProperty(srv.serverId.toString()))
        //return "text-gray-400 dark:text-gray-600";
        return "text-gray-600 dark:text-gray-400";
    }

    const getServerTooltip = (srv) => {
        const updatableServers = serversNeedUpdate.hasOwnProperty(group.id) ? serversNeedUpdate[group.id] : {};
        const text = getServerTypeTooltip(srv.type);
        let info = "";
        if (!srv.enabled)
            info += "disabled";
        if (updatableServers.hasOwnProperty(srv.serverId.toString()))
            info += (!!info ? ", " : "") + "update required";
        return text + (!!info ? ` (${info})` : "");
    }

    const getServerIcons = (srv) => {
        const updatableServers = serversNeedUpdate.hasOwnProperty(group.id) ? serversNeedUpdate[group.id] : {};
        const updateReq = updatableServers.hasOwnProperty(srv.serverId.toString());

        return (
            <div className="flex">
                <div className={getServerIconColor(srv)}>{getServerTypeIcon(srv.type)}</div>
                {updateReq && <div className="ml-1 text-blue-500"><InformationCircleIcon className="h-5 w-5" aria-hidden="true" /></div>}
                {!srv.enabled && <div className="ml-1 text-yellow-500"><BanIcon className="h-5 w-5" aria-hidden="true" /></div>}
            </div>
        );
    }

    /*
    const getServerOverlayIcon = (srv) => {
      if (!srv.enabled)
        return (<div className="absolute bottom-0 left-0 text-red-500 dark:text-red-500"><BanIcon className="h-5 w-5" aria-hidden="true" /></div>);

      const updatableServers = serversNeedUpdate.hasOwnProperty(group.id) ? serversNeedUpdate[group.id] : {};
      if (updatableServers.hasOwnProperty(srv.serverId.toString()))
        return (<div className="absolute bottom-0 left-0 text-yellow-600 dark:text-yellow-200"><ExclamationIcon className="h-5 w-5" aria-hidden="true" /></div>);

      return (<></>);
    }
    */

    const getServerUpdateInfo = (server) => {
        const updatableServers = serversNeedUpdate.hasOwnProperty(group.id) ? serversNeedUpdate[group.id] : {};
        return updatableServers?.hasOwnProperty(server.serverId.toString()) ? { newVer: latestImageVer, currVer: updatableServers[server.serverId.toString()] } : null;
    }

    const openSrvInfo = async (interactTemplate, server) => {
        if (server) {
            setSelectedServers([{ template: interactTemplate, server: server }]);
        }
        setOpenInfoDialog(true);
    }

    const getSrvAction = (env, template, srv) => {
        let actions = [
            {
                name: "Edit",
                onClick: () => {
                    handleEditServer(template.id, srv);
                },
                disabled: !hasFeature(environmentsConfigurationFeatureCode),
                element: srv
            }
        ];

        if (srv.type === TEMPLATESERVERTYPES.container) {
            actions.push({
                name: "Update kubernetes server",
                onClick: () => handleUpdateKubernetesServer(template.id, srv.serverId),
                disabled: !hasFeature(environmentsConfigurationFeatureCode) || !getServerUpdateInfo(srv),
                element: srv
            });
            actions.push({
                name: "Kubernetes settings",
                onClick: () => handleEditServerSettings(env, template, srv),
                disabled: !hasFeature(environmentsConfigurationFeatureCode),
                element: srv
            });
        }
        actions.push({
            name: !!srv.deploymentSessionId ? "Information" : "Backups",
            onClick: () => openSrvInfo(template, srv),
            disabled: !hasFeature(environmentsConfigurationFeatureCode),
            element: srv
        });
        actions.push({
            name: "Delete",
            onClick: () => (handleDeleteServer(env, template, srv)),
            disabled: !hasFeature(environmentsConfigurationFeatureCode),
            element: srv
        });

        return actions;
    }

    return (
        <div className="relative">
            <EnvironmentGroupContainerCard
                group={group}
                defaultCollapsed={defaultCollapsed}
                collapsable={true}
                onCollapseChanged={onCollapseChanged}
                editableSettings={ group.isVirtual
                    ? {
                        showAddEnvBtn: false,
                        getGroupActions: getGroupActions,
                    }
                    : {
                        showAddEnvBtn: true,
                        getGroupActions: getGroupActions,
                        getCreateActions: getCreateActions
                    }
                }
            >
                <div className="px-4 pb-5">
                    <div ref={containerRef}>
                        <NestedTable.Table>
                            <NestedTable.TableHead>
                                <NestedTable.TableHeader className={'w-10'}>
                                </NestedTable.TableHeader>
                                <NestedTable.TableHeader>
                                    Name
                                </NestedTable.TableHeader>
                                <NestedTable.TableHeader>
                                    Type
                                </NestedTable.TableHeader>
                                {/* <NestedTable.TableHeader className={'hidden sm:table-cell'}>
                                    Created
                                </NestedTable.TableHeader>
                                <NestedTable.TableHeader className={'hidden sm:table-cell'}>
                                    Created By
                                </NestedTable.TableHeader> */}
                                <NestedTable.TableHeader>
                                    <span className="sr-only">Edit</span>
                                </NestedTable.TableHeader>
                            </NestedTable.TableHead>
                            <NestedTable.TableBody>
                                {
                                    group.environments?.map((item, idx) => (
                                        <Fragment key={item}>
                                        <NestedTable.CollapsableProvider defaultCollapsed={isItemCollapsed(item.customerEnvironment, idx != 0)} onCollapseChanged={(v) => onItemCollapsedChanged(item.customerEnvironment, v)}>
                                                <NestedTable.SubHead canCollapse={true} title={'Click to expand/collapse'}>
                                                    <NestedTable.SubHeader>
                                                        {getEnvName(item.customerEnvironment)}
                                                    </NestedTable.SubHeader>
                                                    {/* <NestedTable.SubHeader colSpan={screenType.isMobile || screenType.isTablet ? 1 : 3}> */}
                                                    <NestedTable.SubHeader>
                                                        Environment
                                                    </NestedTable.SubHeader>
                                                    <NestedTable.SubHeader className="text-right sm:pr-3">
                                                        { !group.isVirtual && (
                                                            <MenuContextListV2
                                                                actions={
                                                                    getEnvActions(item)
                                                                }
                                                                disableDeleteConfirm={true}
                                                            />
                                                        )}
                                                    </NestedTable.SubHeader>
                                                </NestedTable.SubHead>
                                                <NestedTable.Collapsable>
                                                    {item.template?.servers?.map((srv, idx) => (
                                                        <NestedTable.TableRow key={srv.serverId} idx={idx}>
                                                            <NestedTable.TableCol>
                                                            </NestedTable.TableCol>
                                                            <NestedTable.TableCol>
                                                                {srv.name}
                                                            </NestedTable.TableCol>
                                                            <NestedTable.TableCol title={getServerTooltip(srv)} className={'flex space-x-2'}>
                                                                <span>Server</span>
                                                                {getServerIcons(srv)}
                                                            </NestedTable.TableCol>
                                                            {/* <NestedTable.TableCol className={'hidden sm:table-cell'}>{srv.createdDate}</NestedTable.TableCol>
                                                            <NestedTable.TableCol className={'hidden sm:table-cell'}>{srv.createdBy}</NestedTable.TableCol> */}
                                                            <NestedTable.TableCol className="text-right sm:pr-3">
                                                                { !group.isVirtual && (
                                                                    <MenuContextListV2
                                                                        disableDeleteConfirm={true}
                                                                        actions={getSrvAction(item, item.template, srv)}
                                                                    />
                                                                )}
                                                            </NestedTable.TableCol>
                                                        </NestedTable.TableRow>
                                                    ))}
                                                </NestedTable.Collapsable>
                                            </NestedTable.CollapsableProvider>
                                        </Fragment>
                                    ))
                                }
                            </NestedTable.TableBody>
                        </NestedTable.Table>
                    </div>
                </div>

                {/* <EnvironmentGroupModal title={'Environment Group'} onSubmit={handleUpdateGroup} modalHelper={updateGroupModalHelper} group={group} />
                <EnvironmentModal title={'Environment'} onSubmit={handleAddEnvironment} modalHelper={addEnvironmentModalHelper} />
                <EnvironmentModal title={'Environment'} onSubmit={handleUpdateEnvironment} modalHelper={updateEnvironmentModalHelper} environment={updateEnvironmentModalHelper.activeItem} />
                <ServerModal title={'Server'} onSubmit={handleAddServer} modalHelper={addServerModalHelper} />
                <ServerModal title={'Server'} onSubmit={handleUpdateServer} modalHelper={updateServerModalHelper} server={updateServerModalHelper.activeItem} /> */}
                <DeleteEnvironmentModal title={'Delete Environment?'} modalHelper={deleteEnvironmentModalHelper} onConfirm={handleConfirmDeleteEnv} />
                <DeleteServerModal title={'Delete Server?'} modalHelper={deleteServerModalHelper} onConfirm={handleConfirmDeleteServer} />
                <EditServerSettingsModal title={'Server Settings'} modalHelper={editServerSettingsModalHelper} onConfirm={handleUpdateServerSettings} />

            </EnvironmentGroupContainerCard>

            <ServerInformationDialog
                 interactTemplate={selectedServers[0]?.template}
                 selectedServers={[selectedServers[0]?.server]}
                 openInfoDialog={openInfoDialog}
                 setOpenInfoDialog={setOpenInfoDialog}
            />
        </div>
   )
}