import toast, { Toaster as BaseToaster } from 'react-hot-toast';
import { CheckCircleIcon } from '@heroicons/react/outline'
import { XCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import Loader from './components/Loader';

export const Toaster = () => {
  return (
    <BaseToaster position="top-right">
      {(t) => {
        return (
          <div
            className={`${t.visible ? 'animate-enter' : 'animate-leave'
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 dark:bg-gray800 dark:ring-gray-300`}
          >
            {
              t.type === "loading" &&
              <>
                <div className="flex-1 w-0 p-4">
                  <div className="flex items-start items-center">
                    <div className="flex-shrink-0 pt-0.5">
                      <Loader />
                    </div>
                    <div className="ml-3 flex-1 ">
                      <p className="text-sm font-medium text-gray-900">
                        {t.message}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            }
            {
              t.type === 'success' &&
              <>
                <div className="flex-1 w-0 p-4">
                  <div className="flex items-start items-center">
                    <div className="flex-shrink-0 pt-0.5">
                      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex-1 ">
                      <p className="text-sm font-medium text-gray-900">
                        {t.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex border-l border-gray-200">
                  <button
                    onClick={() => toast.remove(t.id)}
                    className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-brand-blue hover:text-brand-blue-hover focus:outline-none focus:ring-2 focus:ring-gray-400"
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </>
            }
            {
              t.type === 'error' &&
              <>
                <div className="flex-1 w-0 p-4">
                  <div className="flex items-start items-center">
                    <div className="flex-shrink-0 pt-0.5">
                      <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex-1 ">
                      <p className="text-sm font-medium text-gray-900">
                        {t.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex border-l border-gray-200">
                  <button
                    onClick={() => { 
                      toast.remove(t.id) 
                      
                      if (t.message === 'Unable to save record. Record has been modified by another user.') {
                        window.location.reload()
                      }
                    }}
                    className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-brand-blue focus:outline-none focus:ring-2 focus:ring-gray-400"
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </>
            }
          </div>
        )
      }}
    </BaseToaster>
  )
}