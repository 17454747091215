import React, { useEffect, useContext } from 'react';
//import ReactHtmlParser from "react-html-parser";
import Tooltip from 'react-tooltip'

import { TooltipContext } from '../contexts';

import { renderSimpleTooltip, renderNameDescTooltip, renderDataArrayTooltip, addSimpleToolipToContext, addNameDescToolipToContext, addDataArrayToolipToContext } from './tooltipUtils';

import  { BanIcon, CloudIcon, ServerIcon, CheckCircleIcon, ArrowCircleDownIcon, ClockIcon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import  { ClockIcon as ClockIconOutline, QuestionMarkCircleIcon as QuestionMarkCircleIconOutline } from '@heroicons/react/outline'

export const TEMPLATESERVERSTATUSES =
{
  unknown: 0,
  downloading: 1,
  downloadOk: 2,
  downloadFailed: 3,
  downloadsPending: 4,
  notRespondingShort: 0x1000,
  notRespondingLong: 0x2000,
  neverAccessed: 0x4000
}

export const TEMPLATESERVERTYPES =
{
    vmware: 0,
    container: 1
}

export const getServerTypeIcon = (type) => {
  switch (parseInt(type)) {
    case TEMPLATESERVERTYPES.vmware:
      return <ServerIcon className="h-5 w-5" aria-hidden="true" />
    case TEMPLATESERVERTYPES.container:
      return <CloudIcon className="h-5 w-5" aria-hidden="true" />
    default:
      return <></>
  }
}

export const getServerTypeTooltip = (type) => {
  switch (parseInt(type)) {
    case TEMPLATESERVERTYPES.vmware:
      return ["Virtual machine"];
    case TEMPLATESERVERTYPES.container:
      return ["Kubernetes"];
    default:
      return [];
  }
}

export const getServerNameTooltip = (server, updateInfo) => {
  var text = `${server.name}||${server.description}`;
  if (!!updateInfo)
    text += `||${updateInfo.currVer}||${updateInfo.newVer}`
  return text;
}

export const getServerStatusTimeout = (status) => {
  let stat = parseInt(status);

  // console.log("getServerStatusTimeout", "stat", stat,
  //   "neverAccessed", (stat & TEMPLATESERVERSTATUSES.neverAccessed) === TEMPLATESERVERSTATUSES.neverAccessed,
  //   "notRespondingLong", (stat & TEMPLATESERVERSTATUSES.notRespondingLong) === TEMPLATESERVERSTATUSES.notRespondingLong,
  //   "notRespondingShort", (stat & TEMPLATESERVERSTATUSES.notRespondingShort) === TEMPLATESERVERSTATUSES.notRespondingShort);

  if ((stat & TEMPLATESERVERSTATUSES.neverAccessed) === TEMPLATESERVERSTATUSES.neverAccessed) { // neverAccessed
    // console.log("getServerStatusTimeout", "status", status, "stat", stat, "return 3");
    return 3;
  }
  if ((stat & TEMPLATESERVERSTATUSES.notRespondingLong) === TEMPLATESERVERSTATUSES.notRespondingLong) { // NotRespondingLong
    // console.log("getServerStatusTimeout", "status", status, "stat", stat, "return 2");
    return 2;
  }
  if ((stat & TEMPLATESERVERSTATUSES.notRespondingShort) === TEMPLATESERVERSTATUSES.notRespondingShort) { // NotRespondingShort
    // console.log("getServerStatusTimeout", "status", status, "stat", stat, "return 1");
    return 1;
  }
  return 0;
}

export const filterServerStatus = (status) => {
  let stat = parseInt(status);

  if ((stat & TEMPLATESERVERSTATUSES.neverAccessed) === TEMPLATESERVERSTATUSES.neverAccessed) { // NotRespondingLong
    stat -= TEMPLATESERVERSTATUSES.neverAccessed;
  }
  if ((stat & TEMPLATESERVERSTATUSES.notRespondingLong) === TEMPLATESERVERSTATUSES.notRespondingLong) { // NotRespondingLong
    stat -= TEMPLATESERVERSTATUSES.notRespondingLong;
  }
  if ((stat & TEMPLATESERVERSTATUSES.notRespondingShort) === TEMPLATESERVERSTATUSES.notRespondingShort) { // NotRespondingShort
    stat -= TEMPLATESERVERSTATUSES.notRespondingShort;
  }
  return stat;
}

export const getServerStatusIcon = (status, enabled = true) => {
  let stat = parseInt(status);

  if (!enabled)
    return (<div className='flex'><BanIcon className="h-5 w-5 text-yellow-600 dark:text-yellow-200" aria-hidden="true" /></div>);

  let nrStat = <></>;
  if ((stat & TEMPLATESERVERSTATUSES.neverAccessed) === TEMPLATESERVERSTATUSES.neverAccessed) {   // NotRespondingLong
    nrStat = <QuestionMarkCircleIconOutline className="h-5 w-5 text-red-600 dark:text-red-400" aria-hidden="true" />;
  }
  else if ((stat & TEMPLATESERVERSTATUSES.notRespondingLong) === TEMPLATESERVERSTATUSES.notRespondingLong) {   // NotRespondingLong
    nrStat = <ClockIcon className="h-5 w-5 text-red-600 dark:text-red-400" aria-hidden="true" />;
  }
  else if ((stat & TEMPLATESERVERSTATUSES.notRespondingShort) === TEMPLATESERVERSTATUSES.notRespondingShort) {   // NotRespondingShort
    nrStat = <ClockIcon className="h-5 w-5 text-yellow-600 dark:text-yellow-400" aria-hidden="true" />;
  }

  switch (filterServerStatus(stat)) {
    case TEMPLATESERVERSTATUSES.unknown: // Unknown
      return (<div className='flex'>{nrStat}<QuestionMarkCircleIcon className="h-5 w-5 text-gray-300 dark:text-gray-600" aria-hidden="true" /></div>);
    case TEMPLATESERVERSTATUSES.downloading: // downloading
      return (<div className='flex'>{nrStat}<ArrowCircleDownIcon className="h-5 w-5 text-blue-600 dark:text-blue-400" aria-hidden="true" /></div>);
    case TEMPLATESERVERSTATUSES.downloadOk: // DownloadOk
      return (<div className='flex'>{nrStat}<CheckCircleIcon className="h-5 w-5 text-green-600 dark:text-green-400" aria-hidden="true" /></div>);
    case TEMPLATESERVERSTATUSES.downloadFailed: // DownloadFailed
      return (<div className='flex'>{nrStat}<XCircleIcon className="h-5 w-5 text-red-600 dark:text-red-400" aria-hidden="true" /></div>);
    case TEMPLATESERVERSTATUSES.downloadsPending: // DownloadsPending
      return (<div className='flex'>{nrStat}<ClockIconOutline className="h-5 w-5 text-blue-600 dark:text-blue-400" aria-hidden="true" /></div>);
    default:
      return <></>
  }
}

export const getServerStatusTooltip = (status, statusMessage, enabled = true) => {
  let stat = parseInt(status);
  let tooltipData = [];

  if (!enabled)
  {
    tooltipData.push("Disabled");
    return tooltipData.join("||");
  }

  if ((stat & TEMPLATESERVERSTATUSES.neverAccessed) === TEMPLATESERVERSTATUSES.neverAccessed) { // NotRespondingLong
    tooltipData.push("Never accessed");
    tooltipData.push("");
  }
  else if ((stat & TEMPLATESERVERSTATUSES.notRespondingLong) === TEMPLATESERVERSTATUSES.notRespondingLong) { // NotRespondingLong
    tooltipData.push("Not responding");
    tooltipData.push("");
  }
  else if ((stat & TEMPLATESERVERSTATUSES.notRespondingShort) === TEMPLATESERVERSTATUSES.notRespondingShort) { // NotRespondingShort
    tooltipData.push("Not responding");
    tooltipData.push("");
  }

  switch (filterServerStatus(stat)) {
    case TEMPLATESERVERSTATUSES.unknown: // Unknown
      tooltipData.push("Unknown");
      return tooltipData.join("||");
    case TEMPLATESERVERSTATUSES.downloading: // downloading
      tooltipData.push("Downloading");
      return tooltipData.join("||");
    case TEMPLATESERVERSTATUSES.downloadOk: // DownloadOk
      tooltipData.push("Downloaded OK");
      return tooltipData.join("||");
    case TEMPLATESERVERSTATUSES.downloadFailed: // DownloadFailed
      tooltipData.push("Download Failed");
      if (statusMessage)
        tooltipData.push(statusMessage.replace("\n", "||"));
      return tooltipData.join("||");
      case TEMPLATESERVERSTATUSES.downloadsPending: // DownloadsPending
        tooltipData.push("Downloads pending");
      return tooltipData.join("||");
    default:
      return tooltipData.join("||");
  }
}

export const renderServerTooltips = () => {
  return (
    <>
      {renderSimpleTooltip()}
      {renderNameDescTooltip()}
      {renderDataArrayTooltip()}
    </>
  );
}

export const addServerTableTooltipsToContext = (addTooltipElementWithId) => {
  addSimpleToolipToContext(addTooltipElementWithId);
  addNameDescToolipToContext(addTooltipElementWithId);
  addDataArrayToolipToContext(addTooltipElementWithId);
}

export const AddServerTableTooltipsToContext = () => {
  const {addTooltipElementWithId} = useContext(TooltipContext);

  useEffect(() => {
    addServerTableTooltipsToContext(addTooltipElementWithId);
  }, []);

  return null;
}