import { XCircleIcon, ExclamationCircleIcon, ExclamationIcon } from "@heroicons/react/outline";

const VARIANTS = {
  info: 'info',
  warning: 'warning',
  error: 'error'
};

const Message = ({variant, message}) => {

  const types = [
    {variant: VARIANTS.error, backgroundColor: "bg-red-50", icon: XCircleIcon, iconColor: "text-red-400", textColor: "text-red-700" },
    {variant: VARIANTS.warning, backgroundColor: "bg-yellow-50", icon: ExclamationIcon, iconColor: "text-yellow-400", textColor: "text-yellow-700" },
    {variant: VARIANTS.info, backgroundColor: "bg-blue-50", icon: ExclamationCircleIcon, iconColor: "text-blue-400", textColor: "text-blue-700" },
  ];
  const type = types.find(x => x.variant === variant) ?? types[0];

  return (
    <div className={`rounded-md ${type.backgroundColor} p-4 m-4 mb-0`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {<type.icon
            className={`h-5 w-5 ${type.iconColor}`}
            aria-hidden="true"
          />}
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className={`text-sm ${type.textColor}`}>
            {message}
          </p>
        </div>
      </div>
    </div>
  );
}

Message.variants = VARIANTS;

export { Message }