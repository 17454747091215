import React, { useEffect, useState } from 'react'

import * as yup from "yup";

import { Form, Formik } from 'formik';

import { useClassNames, useToastAction } from "@metaforcelabs/metaforce-core";
import { getEditDeploymentSettings } from "../../../api/deployment";

import Modal from '../../../components/Modal';
import { Button } from '../../../components/Button';
import { Label } from '../../../components/Label';
import { Select } from '../../../components/Select';

import { FormikSelectInput } from '../../../components/Form/Formik/FormikSelect';

export default function EditServerSettingsModal({ modalHelper, title, onConfirm }) {
  const loadAction = useToastAction();

  const [editSettings, setEditSettings] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (modalHelper.activeItem) {
      // console.log("DeleteServerModal", "useEffect[modalHelper.activeItem]", "modalHelper.activeItem", modalHelper.activeItem);
      if (!!modalHelper.activeItem.server.deploymentSessionId) {
        setLoaded(false);
        loadAction.execute(async () => {
          // console.log("EditServerSettingsModal", "useEffect[modalHelper.activeItem]", "modalHelper.activeItem.server.deploymentSessionId", modalHelper.activeItem.server.deploymentSessionId);
          var settings = (await getEditDeploymentSettings(modalHelper.activeItem.server.deploymentSessionId));
          // console.log("EditServerSettingsModal", "useEffect[modalHelper.activeItem]", "settings", settings);
          setEditSettings(settings);
          setLoaded(true);
        }, "Failed to load (EditServerSettingsModal)");
      }
    }
  }, [modalHelper.activeItem])

  const handleSubmit = (values) => {
    // console.log("EditServerSettingsModal", "handleSubmit", "values", values);
    var items = Object.keys(values).map(key => {
      return {settingsId: key, value: values[key]};
    })
    var modifiedSettings = {sessionId: editSettings.sessionId, items: items};
    onConfirm(modifiedSettings);
    modalHelper.close();
  }

  const renderForm = () => {
    var values = {};
    editSettings?.items?.map(i => {
      values[i.settingsId.toString()] = i.value;
    });
    
    return (
      <div className="space-y-10 divide-y divide-gray-900/10">
        <Formik
          initialValues={values}
          validationSchema={
            yup.object().shape({
            })
          }
          onSubmit={handleSubmit}
        >
          {({ values, errors, ...props }) => (
            <Form>
              {renderSettings({values, errors, props})}
              <div className="flex justify-end mt-5 sm:mt-6 space-x-2">
              <Button
                variant={Button.variants.secondary}
                className="justify-center"
                disabled={false}
                onClick={() => {
                  modalHelper.close();
                }}
              >
                Cancel
              </Button>
              <Button
                variant={Button.variants.primary}
                className="justify-center"
                disabled={!loaded || !props.dirty}
                type={"submit"}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
        </Formik>
      </div>
    );
  }

  const renderSettings = ({ values, errors, ...props }) => {
    //console.log("renderSettings", "props", props, "values", values, "errors", errors);
    var items = editSettings?.items?.map(i => {
      if (!!i.validator && i.validator.type === 0) {
        let options = [];
        let idx = i.validator.fromValue;
        while (idx <= i.validator.toValue) {
          options.push({name: idx.toString(), value: idx});
          idx += i.validator.stepValue;
        }
        return (<FormikSelectInput
          label={i.name}
          name={i.settingsId}
          formikProps={props}
          options={[...options]}
        />);
      }
      else
        return <></>;
    });
    return (
      <>
        {items && items.map((item, i) => {
          return (
            <>
              {item}
            </>
          );
        })}
      </>
    )
  }

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={() => { modalHelper.close() }}
      size={'large'}
      title={!!modalHelper?.activeItem ? `${title} for server: ${modalHelper.activeItem.server.name}` : ""}
    >
      {!loaded && (
        <Label text={"Loading settings..."} additionalClass='font-bold h-10' />
      )}
      {loaded && (editSettings === null || !editSettings.items || editSettings.items.length === 0) && (
        <Label type={Label.types.info} additionalClass='font-bold h-10'>
          No settings for selected server
        </Label>
      )}
      {loaded && !!modalHelper?.activeItem &&
        renderForm()
      }
    </Modal>
  )
}
