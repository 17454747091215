import React, { useContext } from "react";

import { EnvironmentGroupContext } from "../contexts";

import { Label as CmpLabel } from "../components/Label"

export const useEnvironmentGroup = () => {
  const environmentGroupContext = useContext(EnvironmentGroupContext);

  return {
    dispatchEnvironmentGroupsChangeEvent: environmentGroupContext.dispatchEnvironmentGroupsChangeEvent,
    dispatchEnvironmentGroupsReloadEvent: environmentGroupContext.dispatchEnvironmentGroupsReloadEvent,
    environmentGroups: environmentGroupContext.environmentGroups,
    environmentGroupPanelEnabled:environmentGroupContext.environmentGroupPanelEnabled,
    dispatchEnableEnvironmentGroupPanelEvent: environmentGroupContext.dispatchEnableEnvironmentGroupPanelEvent,
  }
}