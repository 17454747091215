import React, { useEffect, useState, useContext, useRef, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import crypto from 'crypto'

import  { CheckIcon, ExclamationIcon, XCircleIcon, InformationCircleIcon } from '@heroicons/react/solid'

import { EnvironmentGroupContext, CurrentDataContext, OidcRoutesContext } from '../../../contexts/index.js';
  
import { useToastAction } from '../../../hooks/useToastAction.js';

import { downloadInteractStorageFile, getInteractTemplateFile, getInteractTemplateFileVersions } from "../../../api/interactTemplateFile.js";
import { getAllFileInfos } from "../../../api/extrasTemplateFile.js";
import { getInteractEnvironments } from "../../../api/interact";
import { getInteractTemplateServerFiles, getInteractTemplateEnvironments } from "../../../api/interactTemplateEnvironment.js";

import { FILEDEPENDENCYTYPE, getFileTypeName } from "../../../utils/LogicFileUtilities";
import { getSessionFromLs } from '../../../utils/extrasUtils.js'

import { ContainerCard, CollapsableContainerCard, EnvironmentGroupContainerCard } from "../Environments/environmentGroupContainerCard.js"

import { Label as CmpLabel } from "../../../components/Label/index.js";
import { TwPage } from "../../../components/TailwindPage/index.js";
import NestedTable from "../../../components/NestedTable";
import { Tabs as CmpTabs } from "../../../components/Tabs";
import { TwTable } from "../../../components/TailwindTable/index.js";
import MenuContextListV2 from "../../../components/MenuContextListV2/index.js";
import { forEach } from 'jszip';

export default function ExtrasFileTroubleshoot() {
  const pageId = "F922483E-51C7-430C-959F-7E12950234CB";

  const containerRef = useRef(null);

  const loadAction = useToastAction();

  const oidcRoutesContext = useContext(OidcRoutesContext);
  const {environmentGroups} = useContext(EnvironmentGroupContext);
  const { getViewSettings, dispatchViewSettingsUpdate } = useContext(CurrentDataContext);

  const [viewSettings, setViewSettings] = useState(getViewSettings(pageId));
  const [loadingBaseData, setLoadingBaseData] = useState(true);
  const [allEnvironments, setAllEnvironments] = useState([]);

  const [allFileInfosView, setAllFileInfosView] = useState([]);
  const [allFilesView, setAllFilesView] = useState([]);
  const [allOrphansView, setAllOrphansView] = useState([]);
  const [allDuplicatesView, setAllDuplicatesView] = useState([]);

  const [activeTab, setActiveTab] = useState("storageids");

  const tabs = [
    {
      name: "Storage Ids",
      tab: "storageids",
      current: activeTab === "storageids",
    },
    {
      name: "Files",
      tab: "files",
      current: activeTab === "files",
    },
    {
      name: "Duplicates",
      tab: "duplicates",
      current: activeTab === "duplicates",
    },
    {
      name: "Orphans",
      tab: "orphans",
      current: activeTab === "orphans",
    }
  ];

  const loadBaseData = async () => {
    if (!environmentGroups)
      return;

    loadAction.execute(async () => {

      const SALT = '$ome$alt';
      const pass = "s3pt!cFl4sh";
      const hash = crypto.createHmac('sha256', SALT)
        .update(pass)
        .digest('hex');
      const hash2 = crypto.createHmac('sha256', SALT)
        .update('blargh')
        .digest('hex');
      // console.log("ExtrasFileTroubleshoot", "loadBaseData", "hash", hash, "hash2", hash2);

      try {

        const [srvAllEnvironments] = await Promise.all([getInteractEnvironments()]);
        setAllEnvironments(srvAllEnvironments);

        // console.log("ExtrasFileTroubleshoot", "loadBaseData", "srvAllEnvironments", srvAllEnvironments, "environmentGroups", environmentGroups);

        const sessionId = getSessionFromLs();
        const [fileInfos] = await Promise.all([getAllFileInfos(sessionId)]);
        console.log("ExtrasFileTroubleshoot", "loadBaseData", "fileInfos", fileInfos);

        let flatFileInfos = flattenFileInfos(fileInfos);
        console.log("ExtrasFileTroubleshoot", "loadBaseData", "flatFileInfos", flatFileInfos, "fileInfos", fileInfos);

        const orphans = flatFileInfos.filter(f => getFileRefCount(f.envFileIds) === 0);
        // console.log("ExtrasFileTroubleshoot", "loadBaseData", "orphans", orphans);

        const duplicates = flatFileInfos.filter(f => getFileRefCount(f.envFileIds) > 1);
        // console.log("ExtrasFileTroubleshoot", "loadBaseData", "duplicates", duplicates);

        const globalFiles = await getInteractTemplateServerFiles(FILEDEPENDENCYTYPE.customer, oidcRoutesContext?.customerInfo?.customerId);
        let fileInfoGroupList = [];
        let fileGroupList = [];
        let duplicateGroupList = [];
        await Promise.all(environmentGroups.filter(g => !g.isVirtual).map(async (group, idx) => {
          const tplEnvironments = await getInteractTemplateEnvironments(group.id);

          try {
            let fileInfoList = [];
            let fileTemplateList = [];
            let duplicateTemplateList = [];
          
            const groupFiles = await getInteractTemplateServerFiles(FILEDEPENDENCYTYPE.group, group.id);

            await Promise.all(tplEnvironments.map(async (te) => {
              // console.log("ExtrasFileTroubleshoot", "   ...->tplEnvironments.map", "te", te);
      
              const filterFn = (fi) => !!(!!fi.envFileIds && !!fi.envFileIds.hasOwnProperty(te.id)) 
                ? (!!(fi.envFileIds[te.id]?.hasOwnProperty(fi.currentFileId) || false) ? fi.envFileIds[te.id] : null)
                : null;

              const fileList = flatFileInfos.filter(filterFn);
              // console.log("ExtrasFileTroubleshoot", "   ...->tplEnvironments.map", "fileList", fileList, "group", group, "te", te);
              const duplicateList = duplicates.filter(filterFn);
              // console.log("ExtrasFileTroubleshoot", "   ...->tplEnvironments.map", "duplicateList", duplicateList, "group", group, "te", te);

              if (fileList.length > 0)
                fileTemplateList.push({environment: te, files: fileList});
              if (duplicateList.length > 0)
                duplicateTemplateList.push({environment: te, files: duplicateList});

              var lstTmp = await Promise.all(fileList.map(async (fi) => { return {fileInfo: fi, files: await getFilesFromFileInfo(fi, te.files, te.id), environment: te, environments: tplEnvironments} }));
              fileInfoList = fileInfoList.concat(lstTmp);
            }));

            // console.log("ExtrasFileTroubleshoot", "   ...->tplEnvironments.map", "fileTemplateList", fileTemplateList, "group", group);
            // console.log("ExtrasFileTroubleshoot", "   ...->tplEnvironments.map", "duplicateTemplateList", duplicateTemplateList, "group", group);
            // console.log("ExtrasFileTroubleshoot", "   ...->tplEnvironments.map", "fileInfoList", fileInfoList, "group", group);

            const groupFilesList2 = fileInfos.filter(fi => {
              const noEnvFiles = fi.envFileIds?.hasOwnProperty("") ? fi.envFileIds[""] : [];
              return groupFiles.find(gf => Object.keys(noEnvFiles).find(x => x === gf.id));
            });
            if (fileInfoList.length > 0) {
              const lstTmp = await Promise.all(groupFilesList2.map(async (fi) => { return {fileInfo: fi, files: await getFilesFromFileInfo(fi, groupFiles)} }));
              fileInfoGroupList.push({group: group, files: fileInfoList, groupFiles: lstTmp});
            }
            const groupFilesList = flatFileInfos.filter(fi => {
              const noEnvFiles = fi.envFileIds?.hasOwnProperty("") ? fi.envFileIds[""] : [];
              return groupFiles.find(gf => Object.keys(noEnvFiles).find(x => x === gf.id))
            });
            if (fileTemplateList.length > 0 || groupFilesList.length > 0)
              fileGroupList.push({group: group, environments: fileTemplateList, groupList: groupFilesList, groupFiles: groupFiles});
            const groupDuplicatesList = duplicates.filter(fi => {
              const noEnvFiles = fi.envFileIds?.hasOwnProperty("") ? fi.envFileIds[""] : [];
              return duplicates.find(gf => Object.keys(noEnvFiles).find(x => x === gf.id))
            });
            if (duplicateTemplateList.length > 0)
              duplicateGroupList.push({group: group, environments: duplicateTemplateList, groupList: groupDuplicatesList, groupFiles: groupFiles});

          } catch (err) {
            console.log("ExtrasFileTroubleshoot", "loadBaseData->environmentGroups.filter(g => !g.isVirtual).map", "ERROR!!", err);
          }
        }));

        // console.log("ExtrasFileTroubleshoot", "useEffect[interactEnvironments]", "fileGroupList", fileGroupList);
        // console.log("ExtrasFileTroubleshoot", "useEffect[interactEnvironments]", "duplicateGroupList", duplicateGroupList);
        // console.log("ExtrasFileTroubleshoot", "useEffect[interactEnvironments]", "globalFiles", globalFiles);
        // console.log("ExtrasFileTroubleshoot", "useEffect[interactEnvironments]", "fileInfoGroupList", fileInfoGroupList);

        const globalFilesList = fileInfos.filter(fi => {
          const noEnvFiles = fi.envFileIds?.hasOwnProperty("") ? fi.envFileIds[""] : [];
          return globalFiles?.find(gf => Object.keys(noEnvFiles).find(x => x === gf.id));
        });
        setAllFilesView({groupList: fileGroupList, globalList: globalFilesList, globalFiles: globalFiles});
        const globalDuplicatesList = duplicates.filter(fi => {
          const noEnvFiles = fi.envFileIds?.hasOwnProperty("") ? fi.envFileIds[""] : [];
          return globalFiles?.find(gf => Object.keys(noEnvFiles).find(x => x === gf.id));
        });
        setAllDuplicatesView({groupList: duplicateGroupList, globalList: globalDuplicatesList, globalFiles: globalFiles});
        setAllOrphansView(orphans);
        const globalFileInfosList = await Promise.all(globalFilesList.map(async (fi) => { return {fileInfo: fi, files: await getFilesFromFileInfo(fi, globalFiles)} }));
        setAllFileInfosView({groupList: fileInfoGroupList, globalList: globalFileInfosList});
      } finally {
        setLoadingBaseData(false);
      }

    }, "Failed to load (ExtrasFileTroubleshoot)")
  }

  useEffect(() => {
    loadBaseData();
  }, [environmentGroups]);

  const getFilesFromFileInfo = async (fileInfo, files, environmentId = "") => {
    // console.log("getFilesFromFileInfo", "fileInfo", fileInfo, "files", files, "environmentId", environmentId);
    try {
      if (!fileInfo.envFileIds || !fileInfo.envFileIds.hasOwnProperty(environmentId) || !files)
        return {};
      let data = [];
      const fileId = fileInfo.currentFileId;
      let file = files.find(f => f.id === fileId);
      const fv = !!fileId ? await getInteractTemplateFileVersions(fileId) : [];
      const versions = fv.map(v => {
        return {id: v.id, file: v.record, version: v};
      });
      versions.push({id: '', file: file, version: null});
      const version = !!file ? versions.find(v => v.file.id === fileId && v.version?.id === fileInfo.currentVersionId) : null;
      // console.log("   --->", "file", file, "version", version);
      file = version?.file || file;
      if (!!file) {
        file.version = version || null;
      }
      data.push(file);
      // console.log("   --->", "data", data, "fileInfo", fileInfo);
      return data.filter(f => !!f);
    }
    catch (err) {
      console.log("getFilesFromFileInfo", "ERROR!", err);
    }
  }

  const getFileRefCount = (envFileIds) => {
    const keysEnv = Object.keys(envFileIds);
    // console.log("getFileRefCount", "keysEnv", keysEnv);
    let count = 0;
    keysEnv?.map(ek => {
      const fileIds = envFileIds[ek];
      const keys = Object.keys(envFileIds[ek]);
      // console.log("   --->", "keys", keys);
      keys?.map(k => {
        const c = fileIds[k].length;
        count += c;
      });
    });
    // console.log("getFileRefCount", "count", count);
    return count;
  }

  const setVs = (settings) => {
    setViewSettings(settings);
    dispatchViewSettingsUpdate(pageId, settings);
  }

  const flattenFileInfos = (fileInfos) => {
    let newfileInfos = [];

    // console.log("flattenFileInfos", "fileInfos", fileInfos);

    fileInfos.map(fileInfo => {
      // console.log("flattenFileInfos", "fileInfo", fileInfo);
      if (!!fileInfo.envFileIds) {
        Object.keys(fileInfo.envFileIds).map(e => {
          const files = fileInfo.envFileIds[e];
          const fids = Object.keys(files);
        
          // console.log("flattenFileInfos", "fids", fids);

          let newFi = Object.assign({}, fileInfo);

          newFi.currentFileId = !!fids && fids.length > 0 ? fids[0] : null;
          const vids = !!newFi.currentFileId ? files[newFi.currentFileId] : null;

          // console.log("flattenFileInfos", "newFi.currentFileId", newFi.currentFileId, "vids", vids);

          newFi.currentVersionId = !!vids && vids.length ? vids[0] : null;
          // console.log("flattenFileInfos", "newFi", newFi, "vids", vids);
          newfileInfos.push(newFi);

          if ((fids?.length == 1 && vids?.length > 1) || fids?.length > 1) {
            console.log("flattenFileInfos (2)", "fids", fids, "vids", vids);
            fids.map((cfid, index) => {
              const cvids = files[cfid];
              if ((index === 0 && cvids.length > 1) || index > 0) {
                cvids.map((cv, vidx) => {
                  if (!(index === 0 && vidx === 0)) {
                    let newFiVer = Object.assign({}, newFi);
                    newFiVer.assignedFileInfo = fileInfo;
                    newFiVer.currentVersionId = cv;

                    // console.log("flattenFileInfos", "fileInfo", fileInfo, "newFi", newFiVer);

                    newfileInfos.push(newFiVer);
                  }
                });
              }
            });
          } 
        });
      }
    });
    return newfileInfos;
  }

  const getOrphanFileActions = (fileInfo) => {
    let actions = [
      {
          name: "Download",
          onClick: () => {
            downloadInteractStorageFile(fileInfo.filename, fileInfo.filename);
          },
          element: fileInfo
      }
  ];

  return actions;

  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "storageids":
        return (
          <>
            {(!allFileInfosView?.groupList || (allFileInfosView.groupList.length === 0 && allFileInfosView.globalList.length === 0)) && (
              <CmpLabel text="No storage id data found" additionalClass='mt-6' type={CmpLabel.types.info} />
            )}
            {allFileInfosView?.groupList && allFileInfosView.groupList.map(data => (
              <div className='mb-4'>
                <FileInfosGroupContainerCard
                  group={data.group}
                  fileInfoList={data.files}
                  allEnvironments={allEnvironments}
                  defaultCollapsed={true}
                  viewSettings={viewSettings}
                  setViewSettings={setVs}
                />
              </div>
            ))}

          </>
        );
      case "files":
        return (
          <>
            {(!allFilesView?.groupList || (allFilesView.groupList.length === 0 && allFilesView.globalList.length === 0)) && (
              <CmpLabel text="No files found" additionalClass='mt-6' type={CmpLabel.types.info} />
            )}

            {allFilesView?.globalList && allFilesView.globalList.length > 0 && (
              <div className='mb-4'>
                <div className="relative">
                  <CollapsableContainerCard headerJsx=
                    {
                      <h3 className="font-semibold leading-6 text-xl mt-1 flex">
                        Global files
                      </h3>
                    }
                    defaultCollapsed={true}
                  >
                    <div className="px-4 py-5">
                      <FileList
                        filesView={allFilesView.globalList}
                        filesList={allFilesView.globalFiles}
                        getFileType={getFileTypeName}
                        header=" "
                      />
                    </div>
                  </CollapsableContainerCard>
                </div>
              </div>
            )}

            {allFilesView?.groupList && allFilesView.groupList.map(data => (
              <div className='mb-4'>
                <FilesGroupContainerCard
                  group={data.group}
                  interactEnvironments={data.environments}
                  allEnvironments={allEnvironments}
                  defaultCollapsed={true}
                  viewSettings={viewSettings}
                  setViewSettings={setVs}
                />
              </div>
            ))}
          </>
        );
      case "duplicates":
        return (
          <>
            {(!allDuplicatesView?.groupList || (allDuplicatesView.groupList.length === 0 && allDuplicatesView.globalList.length === 0)) && (
              <CmpLabel text="No duplicates found" additionalClass='mt-6' type={CmpLabel.types.info} />
            )}

            {allDuplicatesView?.globalList && allDuplicatesView.globalList.length > 0 && (
              <div className='mb-4'>
                <div className="relative">
                  <CollapsableContainerCard headerJsx=
                    {
                      <h3 className="font-semibold leading-6 text-xl mt-1 flex">
                        Global files
                      </h3>
                    }
                    defaultCollapsed={true}
                  >
                    <div className="px-4 py-5">
                      <FileList
                        filesView={allDuplicatesView.globalList}
                        filesList={allDuplicatesView.globalFiles}
                        getFileType={getFileTypeName}
                        header=" "
                      />
                    </div>
                  </CollapsableContainerCard>
                </div>
              </div>
            )}

            {allDuplicatesView?.groupList && allDuplicatesView.groupList.map(data => (
              <div className='mb-4'>
                <FilesGroupContainerCard
                  group={data.group}
                  interactEnvironments={data.environments}
                  allEnvironments={allEnvironments}
                  defaultCollapsed={true}
                  viewSettings={viewSettings}
                  setViewSettings={setVs}
                />
              </div>
            ))}
          </>
        );
      case "orphans":
        return (
          <>
            {(!allOrphansView || allOrphansView.length === 0) && (
              <CmpLabel text="No orphans found" type={CmpLabel.types.info} />
            )}
            {allOrphansView && allOrphansView.length > 0 && (
              <CollapsableContainerCard
                collapsable={false}
                headerRef={containerRef}
                defaultCollapsed={false}
              >
              <div className="relative">
              <div className='mb-4'>
                <TwTable additionalClass='mt-2 mb-6'>
                  <TwTable.Head>
                    <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                      Name
                    </TwTable.Header>
                    <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                      Storage File ID
                    </TwTable.Header>
                    <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                      Size
                    </TwTable.Header>
                    <TwTable.Header additionalClass='w-10' />
                  </TwTable.Head>
                  <TwTable.Body>
                    {allOrphansView.map((fileInfo) => (
                      <TwTable.BodyRow key={fileInfo.id}>
                        <TwTable.BodyCol additionalClass={'font-medium'}>
                          {fileInfo.filename}
                        </TwTable.BodyCol>
                        <TwTable.BodyCol>
                          {fileInfo.id}
                        </TwTable.BodyCol>
                        <TwTable.BodyCol>
                          {fileInfo.length}
                        </TwTable.BodyCol>
                        <TwTable.BodyCol>
                          <MenuContextListV2
                            actions={getOrphanFileActions(fileInfo)}
                          />
                        </TwTable.BodyCol>
                      </TwTable.BodyRow>
                    ))}
                  </TwTable.Body>
                </TwTable>
              </div>
              </div>
              </CollapsableContainerCard>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed 
            title="File Troubleshoot" 
          />
        </TwPage.Header>

        <CmpLabel type={CmpLabel.types.warn} additionalClass="mb-8">
          <p className="font-bold">
              This page is intended as a Metaforce administration page and should not be used by others.
          </p>
          <p>
              Clicking a red button updates the database and should only be used by someone that understands the backend/database structure.
          </p>
        </CmpLabel>

        {loadingBaseData && (
          <div className='flex flex-wrap'>
            <CmpLabel text={"Loading files..."} additionalClass='font-bold h-6' />
          </div>
        )}

        {!loadingBaseData && (
          <>
            <CmpTabs tabs={tabs} onSelectedTab={(tab) => setActiveTab(tab)} />

            <div className='mt-8'>
              {renderActiveTab()}
            </div>
          </>
        )}
      </TwPage>
    </>
  );
}

function FileList({
  header,
  filesView,
  filesList,
  getFileType,
}) {

  const containerRef = useRef(null);

  const getFileInfoActions = (fileInfo, file) => {
    let actions = [
        {
            name: "Download",
            onClick: () => {
              downloadInteractStorageFile(fileInfo.filename, file?.fileName || fileInfo.filename);
            },
            element: {file: file, fileInfo: fileInfo}
        }
    ];

    return actions;
  }
  
  // console.log("FileList", "filesView", filesView);

  const updatedFilesView = filesView?.map(f => {
    return {info: f, file: filesList.find(ff => ff.id === f.currentFileId)}
  });

  // console.log("FileList", "updatedFilesView", updatedFilesView);

  const sortedFilesView = updatedFilesView?.sort((a, b) => {
    if (b.file.fileType == a.file.fileType)
      return b.file.fileName > a.file.fileName ? -1 : 1;
    return b.file.fileType - a.file.fileType;
  });
  const types = sortedFilesView.filter((value, index, array) => {
    var na = array.filter(i => i.file.fileType === value.file.fileType);
    return array.indexOf(na[0]) === index;
  }).map(i => i.file.fileType);
  console.log("FileList", "types", types);
  const typedFiles = types.map(t => { return {type: t, files: sortedFilesView.filter(f => f.file.fileType === t)}; });
  console.log("FileList", "typedFiles", typedFiles);

  return (
    <div className="px-4 pb-5">
      <div className="mb-4 flex flex-wrap items-center justify-between sm:flex-nowrap text-gray-900 dark:text-gray-300">
        <div className="flex flex-wrap w-full items-center justify-between sm:flex-nowrap text-gray-900 dark:text-gray-300">
          <h3 className="font-semibold leading-6 text-xl mt-1 flex">
            {header || "Files"}
          </h3>
        </div>
      </div>
      <div>
        <NestedTable.Table>
          <NestedTable.TableHead>
            <NestedTable.TableHeader className={'w-10'}>
            </NestedTable.TableHeader>
            <NestedTable.TableHeader>
              Name
            </NestedTable.TableHeader>
            <NestedTable.TableHeader>
              Type
            </NestedTable.TableHeader>
            <NestedTable.TableHeader>
              Version ID
            </NestedTable.TableHeader>
            <NestedTable.TableHeader>
              Storage ID
            </NestedTable.TableHeader>
            <NestedTable.TableHeader>
              Storage File ID
            </NestedTable.TableHeader>
            <NestedTable.TableHeader>
              Size
            </NestedTable.TableHeader>
            <NestedTable.TableHeader>
              <span className="sr-only">Edit</span>
            </NestedTable.TableHeader>
          </NestedTable.TableHead>
          <NestedTable.TableBody>
            {typedFiles?.map((item, idx) => (
              <Fragment key={item}>
                <NestedTable.CollapsableProvider defaultCollapsed={idx != 0}>
                  <NestedTable.SubHead canCollapse={true} title={'Click to expand/collapse'}>
                    <NestedTable.SubHeader>
                      {getFileType(item.type)}
                    </NestedTable.SubHeader>
                    <NestedTable.SubHeader>
                      Type
                    </NestedTable.SubHeader>
                    <NestedTable.SubHeader />
                    <NestedTable.SubHeader />
                    <NestedTable.SubHeader />
                    <NestedTable.SubHeader />
                    <NestedTable.SubHeader className="text-right sm:pr-3" />
                  </NestedTable.SubHead>
                  <NestedTable.Collapsable>
                    {item.files?.map((fileData, idx) => (
                      <NestedTable.TableRow key={fileData.info.id} idx={idx}>
                        <NestedTable.TableCol>
                        </NestedTable.TableCol>
                        <NestedTable.TableCol>
                          {fileData.file.fileName}
                        </NestedTable.TableCol>
                        <NestedTable.TableCol title={"srv"} className={'flex space-x-2'}>
                          <span>{getFileType(fileData.file.fileType)}</span>
                        </NestedTable.TableCol>
                        <NestedTable.TableCol>
                          {fileData.info.currentVersionId}
                        </NestedTable.TableCol>
                        <NestedTable.TableCol>
                          {fileData.info.filename}
                        </NestedTable.TableCol>
                        <NestedTable.TableCol>
                          {fileData.info.id}
                        </NestedTable.TableCol>
                        <NestedTable.TableCol>
                          {fileData.info.length}
                        </NestedTable.TableCol>
                        <NestedTable.TableCol className="text-right sm:pr-3">
                          <MenuContextListV2
                            disableDeleteConfirm={true}
                            actions={getFileInfoActions(fileData.info, fileData.file)}
                          />
                        </NestedTable.TableCol>
                      </NestedTable.TableRow>
                    ))}
                  </NestedTable.Collapsable>
                </NestedTable.CollapsableProvider>
              </Fragment>
            ))}
          </NestedTable.TableBody>
        </NestedTable.Table>
      </div>
    </div>
  );
}

function FileInfosGroupContainerCard({
  group,
  fileInfoList,
  defaultCollapsed,
  allEnvironments,
  viewSettings,
  setViewSettings,
}) {
  const containerRef = useRef(null);

  const [mappedFileInfoList, setMappedFileInfoList] = useState([]);

  const oidcRoutesContext = useContext(OidcRoutesContext);
  
  useEffect(() => {
    if (!fileInfoList)
      return;

    // Add flattend data to each item
    console.log("useEffect[fileInfoList]", "fileInfoList", fileInfoList);
    let mappedData = [];
    let processedIndexes = [];
    fileInfoList.map((item, index, arr) => {
      if (processedIndexes.indexOf(index) < 0)
      {
        const duplicates = arr.filter(f => f.fileInfo.filename === item.fileInfo.filename).map(f => { return {item: f, index: arr.indexOf(f)}; });
        // console.log("   --->fileInfoList.map", "item.fileInfo.filename", item.fileInfo.filename, "index", index, "duplicates", duplicates);

        let mappedFiles = [];
        duplicates.map(di => {
          di.item.files.map(f => {
            mappedFiles.push({file: f, environment: di.item.environment});
          });
          processedIndexes.push(di.index);
        });
        mappedData.push({fileInfo: item.fileInfo, files: mappedFiles})
      }
    });
    console.log("   --->", "mappedData", mappedData);
    setMappedFileInfoList(mappedData);
  }, [fileInfoList]);

  const onItemCollapsedChanged = (itemId, isCollapsed) => {
    let vs = viewSettings;
    if (!vs)
      vs = {};

    if (vs.hasOwnProperty(group.id))
    {
      const oldVs = vs[group.id];
      oldVs[itemId] = {isCollapsed: isCollapsed};
      vs[group.id] = {...oldVs}
    }
    else
    {
      let labelData = vs.hasOwnProperty(itemId) ? vs[itemId] : {};
      labelData[itemId] = {isCollapsed: isCollapsed};
      vs[group.id] = labelData
    }
    setViewSettings(vs);
  }

  const isItemCollapsed = (itemId, defaultValue) => {
    // if (viewSettings?.hasOwnProperty(group.id))
    // {
    //   const ld = viewSettings[group.id];
    //   if (ld.hasOwnProperty(itemId))
    //   {
    //     return /*ld[itemId].isCollapsed ||*/ false;
    //   }
    // }
    // return defaultValue;
  }

  const getEnvName = (customerEnvironment) => {
    return allEnvironments.find(x => x.value === customerEnvironment)?.name || '';
  }

  const getFileActions = (fileInfo, environment) => {
    let actions = [
        {
            name: "Download",
            onClick: () => {
              downloadInteractStorageFile(fileInfo.filename, getFilename(fileInfo.currentFileId, environment) + (!!fileInfo.currentVersionId ? `_${fileInfo.currentVersionId}` : ''));
            },
            element: fileInfo
        }
    ];

    return actions;
  }

  const getFilename = (fileId, environment) => {
    if (!!environment.files || environment.files.length > 0) {
      const ff = environment.files.find(f => f.id === fileId);
      if (!!ff)
        return ff.fileName;
    }
    return fileId.toString();
  }


  const flattenFileIdsStatusIcon = (files) => {
    if (!files)
      return (<></>);
     
    const defClass = 'h-5 w-5 mr-2 ';

    const dupIds = files.map(item => item.environment.id).filter((item, index, arr) => arr.indexOf(item) !== index);
    console.log("flattenFileIdsStatusIcon", "dupIds", dupIds);

    const isErr = files.length === 0;
    const isWarn = files.length > 1 && dupIds.length > 0;
    const isOk = files.length === 1 || dupIds.length === 0;

    if (isErr)
      return <XCircleIcon className={defClass + 'text-red-500'}/>;
    if (isWarn)
      return <ExclamationIcon className={defClass + 'text-yellow-500'}/>;
    // if (hasSettingSeverity(envGrpId, setting.customerEnvironment, validationSeverity.info))
    //   return <><CheckIcon className='h-5 w-5 text-green-500'/><InformationCircleIcon className='h-5 w-5 text-blue-500'/></>;
    if (isOk)
      return <CheckIcon className={defClass + 'text-green-500'}/>;

    return (<></>);
  }

  return (
    <>
      <div className="relative">
        <EnvironmentGroupContainerCard
          group={group}
          defaultCollapsed={defaultCollapsed}
          collapsable={true}
          visibleSettings={{showActiveState: false}}
          editableSettings={{
            showAddEnvBtn: false
          }}
        >
          <div className="px-4 pb-5">
            {fileInfoList?.groupList && (
              <div className='mb-4'>
                <div className="relative">
                  <CollapsableContainerCard headerJsx=
                    {
                      <h3 className="font-semibold leading-6 text-xl mt-1 flex">
                        Group files
                      </h3>
                    }
                    defaultCollapsed={true}
                  >
                    <div className="px-4 py-5">
                      <FileList
                        filesView={fileInfoList.groupList}
                        filesList={fileInfoList.groupFiles}
                        getFileType={getFileTypeName}
                        header=" "
                      />
                    </div>
                  </CollapsableContainerCard>
                </div>
              </div>
            )}

            <div ref={containerRef}>
              <NestedTable.Table>
                <NestedTable.TableHead>
                  <NestedTable.TableHeader className={'w-10'}>
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Name
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Type
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Environment
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    File ID
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Version ID
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Comment
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Storage ID
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Storage File ID
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Size
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    <span className="sr-only">Edit</span>
                  </NestedTable.TableHeader>
                </NestedTable.TableHead>
                <NestedTable.TableBody>
                  {mappedFileInfoList?.map((item, idx) => (
                    <Fragment key={item}>
                      <NestedTable.CollapsableProvider defaultCollapsed={isItemCollapsed(item.customerEnvironment, idx != 0)} onCollapseChanged={(v) => onItemCollapsedChanged(item.customerEnvironment, v)} id={item.id}>
                        <NestedTable.SubHead canCollapse={true} title={'Click to expand/collapse'}>
                          <NestedTable.SubHeader>
                            <div className='flex'>
                              {flattenFileIdsStatusIcon(item.files)}
                              {`${item.fileInfo.filename} (${!!item.files ? item.files.length : 0})`}
                            </div>
                          </NestedTable.SubHeader>
                          <NestedTable.SubHeader>
                            FileInfo
                          </NestedTable.SubHeader>
                          <NestedTable.SubHeader />
                          <NestedTable.SubHeader />
                          <NestedTable.SubHeader />
                          <NestedTable.SubHeader />
                          <NestedTable.SubHeader />
                          <NestedTable.SubHeader />
                          <NestedTable.SubHeader />
                          <NestedTable.SubHeader className="text-right sm:pr-3" />
                        </NestedTable.SubHead>
                        <NestedTable.Collapsable>
                          {!!item.files && item.files.map((fileItem, idx) => (
                            <NestedTable.TableRow key={fileItem.id} idx={idx}>
                              <NestedTable.TableCol>
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {fileItem.file?.fileName || fileItem}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {fileItem.file?.version ? "Version file" : "File"}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {getEnvName(fileItem.environment.customerEnvironment)}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {fileItem.file?.id}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {fileItem.file?.version?.id}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {fileItem.file?.comment || ""}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {fileItem.file?.storageId || ""}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {fileItem.file?.id || ""}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {item.fileInfo.length}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol className="text-right sm:pr-3">
                                {/* <MenuContextListV2
                                  actions={getFileActions(fileInfo, item.environment)}
                                /> */}
                              </NestedTable.TableCol>
                            </NestedTable.TableRow>
                          ))}
                        </NestedTable.Collapsable>
                      </NestedTable.CollapsableProvider>
                    </Fragment>
                  ))}
                </NestedTable.TableBody>
              </NestedTable.Table>
            </div>
          </div>
        </EnvironmentGroupContainerCard>
      </div>
    </>
  );
}

function FilesGroupContainerCard({
  group,
  interactEnvironments,
  defaultCollapsed,
  allEnvironments,
  viewSettings,
  setViewSettings,
 }) {
  const containerRef = useRef(null);
  const oidcRoutesContext = useContext(OidcRoutesContext);

  const onItemCollapsedChanged = (itemId, isCollapsed) => {
    let vs = viewSettings;
    if (!vs)
      vs = {};

    if (vs.hasOwnProperty(group.id))
    {
      const oldVs = vs[group.id];
      oldVs[itemId] = {isCollapsed: isCollapsed};
      vs[group.id] = {...oldVs}
    }
    else
    {
      let labelData = vs.hasOwnProperty(itemId) ? vs[itemId] : {};
      labelData[itemId] = {isCollapsed: isCollapsed};
      vs[group.id] = labelData
    }
    setViewSettings(vs);
  }

  const isItemCollapsed = (itemId, defaultValue) => {
    // if (viewSettings?.hasOwnProperty(group.id))
    // {
    //   const ld = viewSettings[group.id];
    //   if (ld.hasOwnProperty(itemId))
    //   {
    //     return /*ld[itemId].isCollapsed ||*/ false;
    //   }
    // }
    // return defaultValue;
  }

  const getEnvName = (customerEnvironment) => {
    return allEnvironments.find(x => x.value === customerEnvironment)?.name || '';
  }

  const getFileActions = (fileInfo, environment) => {
    let actions = [
        {
            name: "Download",
            onClick: () => {
              downloadInteractStorageFile(fileInfo.filename, getFilename(fileInfo.currentFileId, environment) + (!!fileInfo.currentVersionId ? `_${fileInfo.currentVersionId}` : ''));
            },
            element: fileInfo
        }
    ];

    return actions;
  }

  const getFilename = (fileId, environment) => {
    if (!!environment.files || environment.files.length > 0) {
      const ff = environment.files.find(f => f.id === fileId);
      if (!!ff)
        return ff.fileName;
    }
    return fileId?.toString() || "";
  }

  return (
    <>
      <div className="relative">
        <EnvironmentGroupContainerCard
          group={group}
          defaultCollapsed={defaultCollapsed}
          collapsable={true}
          visibleSettings={{showActiveState: false}}
          editableSettings={{
            showAddEnvBtn: false
          }}
        >
          <div className="px-4 pb-5">
            {interactEnvironments?.groupList && (
              <div className='mb-4'>
                <div className="relative">
                  <CollapsableContainerCard headerJsx=
                    {
                      <h3 className="font-semibold leading-6 text-xl mt-1 flex">
                        Group files
                      </h3>
                    }
                    defaultCollapsed={true}
                  >
                    <div className="px-4 py-5">
                      <FileList
                        filesView={interactEnvironments.groupList}
                        filesList={interactEnvironments.groupFiles}
                        getFileType={getFileTypeName}
                        header=" "
                      />
                    </div>
                  </CollapsableContainerCard>
                </div>
              </div>
            )}

            <div ref={containerRef}>
              <NestedTable.Table>
                <NestedTable.TableHead>
                  <NestedTable.TableHeader className={'w-10'}>
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Name
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Type
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Version ID
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Storage ID
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Storage File ID
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    Size
                  </NestedTable.TableHeader>
                  <NestedTable.TableHeader>
                    <span className="sr-only">Edit</span>
                  </NestedTable.TableHeader>
                </NestedTable.TableHead>
                <NestedTable.TableBody>
                  {interactEnvironments?.map((item, idx) => (
                    <Fragment key={item}>
                      <NestedTable.CollapsableProvider defaultCollapsed={isItemCollapsed(item.customerEnvironment, idx != 0)} onCollapseChanged={(v) => onItemCollapsedChanged(item.customerEnvironment, v)} id={item.id}>
                        <NestedTable.SubHead canCollapse={true} title={'Click to expand/collapse'}>
                          <NestedTable.SubHeader>
                            {getEnvName(item.environment.customerEnvironment)}
                          </NestedTable.SubHeader>
                          <NestedTable.SubHeader>
                            Environment
                          </NestedTable.SubHeader>
                          <NestedTable.SubHeader />
                          <NestedTable.SubHeader />
                          <NestedTable.SubHeader />
                          <NestedTable.SubHeader />
                          <NestedTable.SubHeader className="text-right sm:pr-3" />
                        </NestedTable.SubHead>
                        <NestedTable.Collapsable>
                          {item.files?.map((fileInfo, idx) => (
                            <NestedTable.TableRow key={fileInfo.id} idx={idx}>
                              <NestedTable.TableCol>
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {getFilename(fileInfo.currentFileId, item.environment)}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {!!fileInfo.currentVersionId ? "Version file" : "File"}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {fileInfo.currentVersionId}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {fileInfo.filename}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {fileInfo.id}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol>
                                {fileInfo.length}
                              </NestedTable.TableCol>
                              <NestedTable.TableCol className="text-right sm:pr-3">
                                <MenuContextListV2
                                  actions={getFileActions(fileInfo, item.environment)}
                                />
                              </NestedTable.TableCol>
                            </NestedTable.TableRow>
                          ))}
                        </NestedTable.Collapsable>
                      </NestedTable.CollapsableProvider>
                    </Fragment>
                  ))}
                </NestedTable.TableBody>
              </NestedTable.Table>
            </div>
          </div>
        </EnvironmentGroupContainerCard>
      </div>
    </>
  );
}