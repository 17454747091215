import { Form, Formik, useFormik, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { FormikTextInput } from '../../../components/Form/Formik/FormikTextInput'
import { FormikCheckbox } from '../../../components/Form/Formik/FormikCheckbox'
import { DocumentAddIcon, DocumentIcon } from '@heroicons/react/outline'
import { Button } from '../../../components/Button'
import { FileUpload } from '../../../components/FileUpload'
import { Label } from '../../../components/Label'
import * as yup from "yup";
import { TwFormEx } from '../../../components/TailwindForm'
import { Password } from '../../../components/Password'

export default function SoapSettingsForm({ environment }) {
    const props = useFormikContext();
    const getFileContentAsBase64String = file => {
        return new Promise(resolve => {
            let content = "";
            let reader = new FileReader();

            reader.onload = () => {
                content = reader.result;
                resolve(content);
            };

            reader.readAsDataURL(file);
        });
    };

    useEffect(() => {
        //console.log("SoapSettingsForm", "props.values?.soap?.certificatePassword", props.values?.soap?.certificatePassword);
    }, [])

    const handleFileUpload = async (e, name, handleChange) => {
        const fileData = await getFileContentAsBase64String(e);
        const parts = fileData.split(',')
        const cert = parts[1]
        handleChange({ target: { name, value: cert } });
    }

    return (
        <div className='space-y-4'>
            <TwFormEx.Section label={'Endpoint'}>
                <FormikTextInput formikProps={props} name={"soap.endpoint"} />
            </TwFormEx.Section>
            <TwFormEx.Section label={'Certificate'}>
                <FormikCheckbox label={'Enable'} name={'soap.certificateEnabled'} formikProps={props} />
                {
                    props.values?.soap?.certificateEnabled && (
                        <>
                            {
                                !props.values?.soap?.certificate ? (
                                    <FileUpload
                                        name="soap.certificate"
                                        label={"Certificate"}
                                        fileExtension=".pfx"
                                        onChange={e => {
                                            handleFileUpload(e, 'soap.certificate', props.handleChange);
                                            // props.handleChange(e);
                                        }}                                        
                                        error={props.errors.soap?.certificate}
                                    />
                                ) : (
                                    <div className="sm:col-span-4 space-y-2">
                                        <Label >Certificate</Label>
                                        <div className='flex items-end'><DocumentIcon className='text-gray-700 h-6 w-6' /><Label>Certificate.pfx</Label></div>

                                        <Button
                                            size={Button.sizes.sm}
                                            variant={Button.variants.secondary}
                                            className="justify-center"
                                            disabled={false}
                                            onClick={() => {
                                                props.handleChange({ target: { name: 'soap.certificate', value: '' } })
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                )
                            }
                            <div className="mt-2">
                                <Password
                                    name="soap.certificatePassword.value"
                                    value={props.values?.soap?.certificatePassword?.value || ''}

                                    onChange={e => {
                                        props.handleChange(e)
                                        // props.handleChange({ target: { name: 'soap.certificatePassword.valueChanged', value: true } })
                                        // props.handleChange({ target: { name: 'soap.certificatePassword.hasValue', value: false } })
                                    }}
                                    showEyeButton={props.values?.soap?.certificatePassword?.valueChanged === true || false}
                                    placeHolder={props.values?.soap?.certificatePassword?.hasValue === true ? "***" : ''}
                                    error={props.errors?.soap?.certificatePassword?.value}
                                />
                                {/* <FormikTextInput label={'Certificate Password'} name={"soap.certificatePassword.value"} /> */}
                            </div>
                        </>
                    )
                }
            </TwFormEx.Section>
            <TwFormEx.Section label={'PDF/A'}>
                <FormikCheckbox label={'Enforce for all documents'} name={'soap.forcePDFAOnAllDocuments'} formikProps={props} />
            </TwFormEx.Section>
            {/* <div className="sm:w-4/6 flex items-center justify-end gap-x-4 px-4 py-4 sm:px-8">
                <Button
                    variant={Button.variants.secondary}
                    className="justify-center"
                    disabled={false}
                    onClick={() => {
                        // modalHelper.close();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant={Button.variants.primary}
                    className="justify-center"
                    disabled={false}
                    type={"submit"}
                >
                    Save
                </Button>
            </div> */}
        </div>
    )
}
