
import { get, post, BaseUrl } from "./api";

export const getInteractEnvironments = async () => {
    // return await get(`api/InteractSOAPClient/environments`, BaseUrl.Centerpoint, null);
    return await get(`api/InteractSOAPClient/environments`);
}

export const getSoapSettingsByCustomerId = async (environmentGroupId) => {
    return await get(!!environmentGroupId 
        ? `api/InteractSOAPClient/all/${environmentGroupId}`
        : `api/InteractSOAPClient/all`
    );
}

export const getSoapSettings = async (id) => {
    return await get(`api/InteractSOAPClient/${id}`);
}

export const createSoapSettings = async (settings) => {
    return await post( `api/InteractSOAPClient`, settings);
}

export const updateSoapSettings = async (settings) => {
    return await post(
        `api/InteractSOAPClient/update`,
        settings
    );
}

export const deleteSoapSettings = async (ids) => {
    return await post(
        `api/InteractSOAPClient/delete`,
        ids
    );
}


export const getEnvironmentGroupsByCustomerId = async () => {
    return await get(`api/InteractEnvironmentGroup/all`);
}

export const getEnvironmentGroup = async (id) => {
    return await get(`api/InteractEnvironmentGroup/${id}`);
}

export const createEnvironmentGroup = async (settings) => {
    return await post( `api/InteractEnvironmentGroup`, settings);
}

export const updateEnvironmentGroup = async (settings) => {
    return await post(
        `api/InteractEnvironmentGroup/update`,
        settings
    );
}
export const toggleEnvironmentGroupStatus = async (data) => {
    return await post(
        `api/InteractEnvironmentGroup/togglestatus`,
        data
    );
}

export const deletetEnvironmentGroups = async (ids) => {
    return await post(
        `api/InteractEnvironmentGroup/delete`,
        ids
    );
}

export const getAllVirtualGroups = async (identityEndPoint, endPoint, clientId, clientSecret, localGroupId = "") => {
    return await post(
        `api/InteractEnvironmentGroup/virtual/all`,
        {
            virtualEndpoint: endPoint,
            virtualIdentityEndpoint: identityEndPoint,
            virtualClientId: clientId,
            virtualClientSecret: clientSecret,
            localGroupId: localGroupId
        }
    );
}