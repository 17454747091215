import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import classNames from 'classnames';

import  { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import  { InformationCircleIcon, ExclamationCircleIcon, ExclamationIcon, CheckCircleIcon } from '@heroicons/react/outline'

import { Button as CmpButton } from "../Button";
import { ButtonGroup as CmpButtonGroup } from "../ButtonGroup"

const labelTypes = {
  success: "success",
  error: "error",
  warn:"warn",
  info: "info",
  default: "default",
  simple: "simple"
}

export const labelColorClasses = (type) => {
  switch (type) {
    case labelTypes.success:
      return "bg-green-100 text-green-800";
    case labelTypes.error:
      return 'bg-red-100 text-red-800';
    case labelTypes.warn:
      return "bg-yellow-100 text-gray-800";
    case labelTypes.info:
      return "bg-blue-100 text-blue-800";
    case labelTypes.default:  
      return "text-gray-800 dark:text-gray-300";
    case labelTypes.simple:
    default:
      return "";
  }
}

export const labelBorderClasses = (type) => {
  switch (type) {
    case labelTypes.success:
      return "border-2 rounded-md border-green-800";
    case labelTypes.error:
      return 'border-2 rounded-md border-red-800';
    case labelTypes.warn:
      return "border-2 rounded-md border-gray-800 dark:border-gray-600";
    case labelTypes.info:
      return "border-2 rounded-md border-blue-800";
    case labelTypes.default:  
    case labelTypes.simple:  
    default:
      return "";
  }
}

export const labelGetIcons = (type) => {
  switch (type) {
    case labelTypes.success:
      return <CheckCircleIcon className="h-5 w-5 my-auto" aria-hidden="true" />;
    case labelTypes.error:
      return <ExclamationCircleIcon className="h-5 w-5 my-auto" aria-hidden="true" />;
    case labelTypes.warn:
      return <ExclamationIcon className="h-5 w-5 my-auto" aria-hidden="true" />;
    case labelTypes.info:
      return <InformationCircleIcon className="h-5 w-5 my-auto" aria-hidden="true" />;
    case labelTypes.default:  
    case labelTypes.simple:  
    default:
      return <></>;
    }
}

export const labelRequiresBorder = (type) => {
  return type === labelTypes.success || type === labelTypes.error || type === labelTypes.warn || type === labelTypes.info;
}

const Label = ({
  children,
  text,
  disabled,
  additionalClass = '',
  textClass = 'text-sm font-medium',
  type = labelTypes.default,
  useIcon = true,
  as = 'div',
  ...props }) => {
  const As = as;

  const hasIconAndBorder = labelRequiresBorder(type);
  return (
    <>
    { hasIconAndBorder &&
        <As className={`flex ${text || children ? " gap-2" : "" } p-2 ${labelColorClasses(type)} ${labelBorderClasses(type)} ${additionalClass}`} {...props}>
          {useIcon && labelGetIcons(type)}
          <As
            className={classNames(
              textClass,
              `my-auto`,
              disabled && 'opacity-50'
            )}
          >
          {text ?? children}
        </As>
      </As>
    }
    { !hasIconAndBorder &&
       <As
        className={classNames(
          textClass,
          `${labelColorClasses(type)} ${additionalClass}`,
          disabled && 'opacity-50'
        )}
        {...props}
      >
          {text ?? children}
      </As>
    }
    </>
  );
}

Label.types = labelTypes;

const ScrollingLabel = ({
  messages,
  autoScroll = false,
  step = 2,
  additionalClass = '',
  type = labelTypes.default,
  useIcon = true,
  as = 'div',
  ...props }) => {
    const [animate, setAnimate] = useState(true);
    const [wrapWidth, setWrapWidth] = useState(0);
    const [offset, setOffset] = useState(0);
    const wrapRef = useRef(null);
    const dataRef = useRef(null);

    useEffect(() => {
      // console.log("useEffect[animate]", messages, animate);
      if (-offset > Math.floor(wrapWidth / 2)) 
        setOffset(0);
    }, [animate]);

    useEffect(() => {
      // console.log("useEffect[dataRef.current?.clientWidth]", messages, animate);
      if (autoScroll)
        setAnimate((animate) => {

          // console.log("useEffect[dataRef.current?.clientWidth] - setAnimate", animate, dataRef.current.clientWidth, wrapRef.current.parentElement.clientWidth, (dataRef.current.clientWidth * (animate ? 2 : 1)) > wrapRef.current.parentElement.clientWidth, dataRef.current);
         return (dataRef.current.clientWidth * (animate ? 2 : 1)) > wrapRef.current.parentElement.clientWidth;
        });

      setWrapWidth(dataRef.current.clientWidth + 14);
    }, [dataRef.current?.clientWidth]);

    useEffect(() => {
      // console.log("useEffect[wrapWidth, animate]", messages, animate);
      if (!animate)
        return;

      let aninationFrameNo = 0;
      let start;
      const move = () => {
        aninationFrameNo = window.requestAnimationFrame((timeStamp) => {
          if (start === undefined) {
            start = timeStamp;
          }
          const elapsed = timeStamp - start;

          if (elapsed > 20)
          {
            start = undefined;
            setOffset((offset) => {


              if (-offset > Math.floor(wrapWidth / 2)) {
                return 0;
              }
              return offset - step;
            });
          }
          move();
        });
      };
      move();
      return () => {
        window.cancelAnimationFrame(aninationFrameNo);
      };
    }, [wrapWidth, animate]);
    
  return (
    <Label as={as} type={type} useIcon={useIcon} additionalClass={additionalClass} {...props}>
      <div className="overflow: hidden; w-full">
        <div className="flex" ref={wrapRef}
          style={{
            width: `${wrapWidth}px`,
            transform: `translateX(${offset}px)`
          }}
        >
          <div className="flex gap-6 flex-row" ref={dataRef}>
          {messages.map((msg, i) => (
            <div className="w-fit whitespace-nowrap" key={i}>
              {msg}
            </div>
          ))}
          {/* We add the messages twice to get a smoother overlap (if animating) */}
          {animate && messages.map((msg, i) => (
            <div className="w-fit whitespace-nowrap" key={i}>
              {msg}
            </div>
          ))}
          </div>
        </div>
      </div>
    </Label>
  );
}

ScrollingLabel.types = labelTypes;


const TwCollapsableLabel = ({children, label, onCollapseChanged, defaultCollapsed = false, collapsable = true, additionalClass = '', ...props }) => {

  const [expanded, setExpanded] = useState(!defaultCollapsed);

  const setExp = (value) => {
    if (onCollapseChanged)
      onCollapseChanged(!value);
    setExpanded(value);
  }

  const createButtons = ()  => {
    let buttons = [];
    if (collapsable) {
      buttons.push({
        content: <>
          <span className="sr-only">Edit</span>
          {expanded &&
            <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
          }
          {!expanded &&
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          }
          </> ,
        onClick: () => setExp(!expanded),
      });
    }

    return buttons;
  }

  return (
    <>
      <div className='max-w-full mt-8'>
        {/* <div className='flex mb-4'> */}
        <div className='flex'>
          <Label text={label} additionalClass={`text-lg font-bold  ${additionalClass}`} />
          <div className="flex-1 border-t-2 border-gray-900/10 dark:border-gray-600 mx-4 my-auto" />
          <CmpButtonGroup buttons={createButtons()} />
        </div>
        {expanded && (
          <>
          {children}
          </>
        )}
      </div>
    </>
  ); 
}

export { Label, ScrollingLabel, TwCollapsableLabel }