import React, { useContext } from "react";

import { InteractStatusContext } from "../contexts";

export const useTemplateServerUpdate = () => {
  const templateServerUpdateContext = useContext(InteractStatusContext);

  return {
    serversNeedUpdate: templateServerUpdateContext.serversNeedUpdate,
    latestImageVer: templateServerUpdateContext.latestImageVer,
    updatedServer: templateServerUpdateContext.updatedServer,
  }
}