import React, { useEffect, useState } from 'react'
import Modal from '../../../components/Modal';
import { Button } from '../../../components/Button';
import { Label } from '../../../components/Label';

export default function DeleteServerModal({ modalHelper, title, onConfirm }) {
  const [kubernetesServers, setKubernetesServers] = useState([])
  const [isConfirmingK8sDelete, setIsConfirmingK8sDelete] = useState(false);

  useEffect(() => {
    if (modalHelper.activeItem) {
      //console.log("DeleteServerModal", "modalHelper.activeItem", modalHelper.activeItem);
      const kubernetesServerSearch = !!modalHelper.activeItem.server.deploymentSessionId;
      //console.log("DeleteServerModal", "kubernetesServerSearch", kubernetesServerSearch);
      setIsConfirmingK8sDelete(false);
      setKubernetesServers(kubernetesServerSearch ? [kubernetesServerSearch] : []);
    }
  }, [modalHelper.activeItem])

  const removeDeploymentsBody = () => {
    return (
      <div className='space-y-4'>
        <Label type={Label.types.warn} additionalClass="mb-4">
          <p className="font-bold">
            Deleting the server(s) will delete the deployment(s) from Kubernetes.
          </p>
          <p>
            You can not undo this. The entire server and it's content will be permanently erased!
          </p>
        </Label>
        <Label as="p">
          The selected Interact Template Server(s) that has Kubernetes deployments connected.
        </Label>
        <Label as="p">
          Are you sure you want to delete the selected Interact Template Server(s)?
        </Label>
      </div>
    )
  }

  const renderDefault = () => {
    return (
      <div className="mt-4">
        <Label as="p">
          Are you sure you want to delete the selected Interact Template Server(s)?
        </Label>
      </div>
    )
  }

  return (
    <Modal
      isOpen={modalHelper.isOpen}
      onClose={() => { modalHelper.close() }}
      size={'large'}
      title={title}
    >
      {
        isConfirmingK8sDelete ? removeDeploymentsBody() :
          renderDefault()
      }
      <div className="flex justify-end mt-5 sm:mt-6 space-x-2">
        <Button
          variant={Button.variants.secondary}
          className="justify-center"
          disabled={false}
          onClick={() => {
            modalHelper.close();
          }}
        >
          Cancel
        </Button>
        <Button
          variant={Button.variants.primary}
          className="justify-center"
          disabled={false}
          type={"submit"}
          onClick={() => {
            if (kubernetesServers.length > 0 && !isConfirmingK8sDelete) {
              setIsConfirmingK8sDelete(true);
            } else {
              setIsConfirmingK8sDelete(false);
              onConfirm(modalHelper.activeItem);
            }
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}
