import { Menu, Transition } from "@headlessui/react";
import { DotsHorizontalIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { useClassNames } from "@metaforcelabs/metaforce-core";
import React, { Fragment } from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Modal from "../Modal";

export default function MenuContextListV2({ actions, element, deleteType, customActionComponents, disableDeleteConfirm = false, menuButton: MenuButton = null }) {
    const classNames = useClassNames();
    const [showModal, setShowModal] = useState(false)
    const deleteAction = actions.find(item => item.name === "Delete");

    const onDeleteClick = async () => {
        setShowModal(false)
        await deleteAction.onClick(element)
    }

    return (
        <>
            <Menu as="div" className="ml-3 inline-block text-left">
                <div>
                    {
                        MenuButton ? (<MenuButton />) : (
                            <Menu.Button className="-my-2 p-1 flex items-center focus:outline-none rounded-md">
                                <span className="sr-only">Open options</span>
                                <DotsVerticalIcon className="h-5 w-6" aria-hidden="true" />
                            </Menu.Button>)
                    }
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg ring-1 ring-opacity-5 focus:outline-none z-10 bg-white ring-black dark:bg-gray-700 dark:divide-gray-500 dark:ring-gray-600">
                        <div className="py-1">
                            {actions && actions.filter(m => !m.hidden).map((action, i) => (
                                <Menu.Item key={i}>
                                    {({ active }) => (
                                        action.href ?
                                            (
                                                action.external ? (
                                                    <Link
                                                        target={"_blank"}
                                                        to={action.href}
                                                        className={classNames.classNames(
                                                            active ? 'bg-gray-100 text-red-900 dark:bg-gray-900 dark:text-gray-100' : 'text-gray-700 dark:text-gray-300',
                                                            action.lineAfter && "border-b border-gray-200 py-3",
                                                            'w-full flex px-4 py-2 text-sm font-semibold'

                                                        )}>
                                                        {action.icon &&
                                                            <action.icon
                                                                className={classNames.classNames(
                                                                    active ? 'text-gray-900 dark:text-gray-100' : 'text-gray-700 dark:text-gray-300',
                                                                    'h-5 w-5 mr-3 text-gray-700 dark:text-gray-300'
                                                                )}
                                                            />
                                                        }
                                                        {action.name}
                                                    </Link>
                                                )
                                                    :
                                                    (
                                                        <NavLink
                                                            to={action.href}
                                                            className={classNames.classNames(
                                                                active ? 'bg-gray-100 text-red-900 dark:bg-gray-900 dark:text-gray-100' : 'text-gray-700 dark:text-gray-300',
                                                                action.lineAfter && "border-b border-gray-200 py-3",
                                                                'w-full flex px-4 py-2 text-sm font-semibold'

                                                            )}>
                                                            {action.icon &&
                                                                <action.icon
                                                                    className={classNames.classNames(
                                                                        active ? 'text-gray-900 dark:text-gray-100' : 'text-gray-700 dark:text-gray-300',
                                                                        'h-5 w-5 mr-3 text-gray-700 dark:text-gray-300'
                                                                    )}
                                                                />
                                                            }
                                                            {action.name}
                                                        </NavLink>
                                                    )
                                            )

                                            :
                                            <button
                                                key={`action-${i}`}
                                                type="button"
                                                className={classNames.classNames(
                                                    active ? 'bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-100' : 'text-gray-700 dark:text-gray-300',
                                                    action.lineAfter && "border-b border-gray-200 py-3",
                                                    'w-full flex px-4 py-2 text-sm font-semibold'
                                                )}
                                                disabled={action.disabled}
                                                onClick={action.name === 'Delete' && !disableDeleteConfirm ? () => setShowModal(true) : action.onClick}
                                            >
                                                {action.icon &&
                                                    <action.icon className={classNames.classNames(
                                                        active ? 'text-gray-900 dark:text-gray-100' : 'text-gray-500',
                                                        'h-5 w-5 text-gray-500 mr-3')}
                                                    />
                                                }
                                                {action.name}
                                            </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                        {
                            customActionComponents && customActionComponents.map((customActionComponent, i) => {
                                return <Menu.Item key={i}>{customActionComponent}</Menu.Item>
                            })
                        }
                    </Menu.Items>
                </Transition>
            </Menu>
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                size={'medium'}
                title={
                    <>
                        Do you want to delete <span className="text-lg leading-6 font-medium text-indigo-600">{element?.name}</span> {deleteType}?
                    </>
                }
            >
                <div className="mt-5 sm:mt-6">
                    <button
                        type="button"
                        className="inline-flex justify-center w-auto rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 text-base font-medium text-white sm:text-sm"
                        onClick={(event) => onDeleteClick(event)}
                    >
                        Delete
                    </button>
                    <button
                        type="button"
                        className="mt-3 ml-2 w-auto inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setShowModal(false)}
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
        </>
    );
}