import React from 'react';

import * as Constants from '../../../utils/constants'

import { Label as CmpLabel } from "../../../components/Label";
import { ButtonGroup } from "../../../components/ButtonGroup"
import { TwTable } from "../../../components/TailwindTable";

export default function Dashboard_WebEditor ({ setting, createButtons, isExpanded, history }) {
  return (
    <>
      { setting.webeditorSettings &&
        <div className='my-4 border-t-2 border-gray-900/10 dark:border-gray-700 max-w-full'>
          <div className='flex h-10 mt-4'>
            <CmpLabel text="WebEditor" additionalClass='flex-1 text-lg font-bold my-auto' />
            <ButtonGroup buttons={createButtons(setting.customerEnvironment.value, setting.webeditorSettings.data, "environments/webeditor", Constants.environmentsConfigurationFeatureCode)} />
          </div>
          { isExpanded(setting.customerEnvironment.value, setting.webeditorSettings.data.id) &&
          <TwTable additionalClass='mt-4 pb-2'>
            <TwTable.Head>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Property
              </TwTable.Header>
              <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                Value
              </TwTable.Header>
            </TwTable.Head>
            <TwTable.Body>
              {setting.webeditorSettings.tableData && setting.webeditorSettings.tableData.map((rowData) => (

                <TwTable.BodyRow key="soap_endpoint">
                  <TwTable.BodyCol additionalClass={'font-medium'}>
                    {rowData.key}:
                  </TwTable.BodyCol>
                  <TwTable.BodyCol>
                    {rowData.value}
                  </TwTable.BodyCol>
                </TwTable.BodyRow>
              ))}
            </TwTable.Body>
          </TwTable>
        }
        </div>
      }
    </>
  );
}