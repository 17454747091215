import { CheckIcon, DocumentAddIcon, PencilAltIcon } from "@heroicons/react/outline"
import { useState } from "react";
import { useClassNames } from "../../../hooks/useClassNames";
import { format } from "date-fns";
import Changes from "./changes";

import { Button as CmpButton } from "../../../components/Button";
import { TimeLineList} from "../../../components/TimeLineList";

import { TwFormEx } from "../../../components/TailwindForm";

export const History = ({ document, onCancel, onSubmit }) => {
  const classNames = useClassNames();
  const [changes, setChanges] = useState();
  const [openChanges, setOpenChanges] = useState(false);
  let timeline = []

  const iconFromType = (type) => {
    switch (type) {
      case 2:
        return PencilAltIcon
      case 3:
        return CheckIcon  
      
      default:
      case 1:
        return DocumentAddIcon        
    }
  }

  const backgroundFromType = (type) => {
    switch (type) {
      case 2:
        return 'bg-blue-500'
      case 3:
        return 'bg-green-500'  
      
      default:
      case 1:
        return 'bg-gray-400'        
    }
  }

  const contentFromType = (type) => {
    switch (type) {
      case 2:
        return 'updated document'
      case 3:
        return 'published document'  
      
      default:
      case 1:
        return 'created document'        
    }
  }

  const handleOpenChanges = (changes) => {
    setOpenChanges(true);
    setChanges(changes);
  }

  document?.history?.forEach(item => {
    timeline.push({
      id: item.Id,
      content: contentFromType(item.type),
      target: item.updatedByName,
      changes: item.changes,
      onClick: () => handleOpenChanges(item.changes),
      date: format(
        new Date(item.updatedAt),
        "MM/dd/yyyy - HH:mm"
      ),
      datetime: item.updatedAt,
      icon: iconFromType(item.type),
      iconBackground: backgroundFromType(item.type),
    })
  });
  
  const handleCancel = async () => {
    onCancel();
  }

  const formButtons = [
    {text: "Cancel", variant: CmpButton.variants.secondary, onClick: handleCancel},
    {text: "Ok", disabled: true}
  ];
  
  return (
    <>
      <TwFormEx
        buttons = {formButtons}
      >
        <TwFormEx.Section.Full first={true}>
          <TimeLineList timeline={timeline.reverse()} />
        </TwFormEx.Section.Full>
      </TwFormEx>

      {
        changes && changes.length > 0 &&
          <Changes changes={changes} open={openChanges} setOpen={setOpenChanges} />
      }
    </>
  )
}