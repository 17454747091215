import React, { useEffect, useContext } from "react";
import { useHistory } from 'react-router-dom';

import { BadgeCheckIcon as DoneIcon } from '@heroicons/react/outline';

import { useToastAction } from '../../../../hooks/useToastAction';

import { CurrentDataContext } from '../../../../contexts';

import * as DeploymentApi from "../../../../api/deployment";

import { Button as CmpButton } from "../../../../components/Button";
import { Label as CmpLabel } from "../../../../components/Label";

const WizardStepResult = props => {
  const history = useHistory();

  const {currentDataId, currentDataPayload, dispatchCurrentDataEvent} = useContext(CurrentDataContext);

  const deployAction = useToastAction();

  useEffect(async () => {
    // console.log("WizardStepResult", "props", props);
    if (!props.isActive)
      return;

    //console.log("WizardStepResult", "props", props);
  }, [props.isActive]);


  const onClose = async () => {
    if (!!props.onBeforeClose)
      await props.onBeforeClose();
    if (currentDataId === "history" && !!currentDataPayload)
    {
      dispatchCurrentDataEvent("history", null);
      history.push(currentDataPayload);
    }
  }

  const onCreateTestPage = (id) => {
    //console.log("onCreateTestPage", "id", id, "data", data);
    if (!!id)
    {
      deployAction.execute(async () => {
        var result = await DeploymentApi.createDeploymentTestDocument(id);

        //console.log("onCreateTestPage", "result", result);

        let pdfWindow = window.open("");
        pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64," + result +"'></iframe>");
      }, "Failed to create test document");
    }
  }

  const data = props?.data;
  const savedDbSessionId = data?.deploymentResult?.savedDbSessionId;
  const sessionResult = data?.deploymentResult?.sessionResult;
  const interactResult = data?.deploymentResult?.interactResult;

  //console.log("WizardStepResult", "data", data, "data.deploymentResult", data.deploymentResult);

  return (
    <div>
      <div className="flex">
        <CmpLabel text="Succesfully deployed container to Kubernetes" additionalClass="text-lg" />
        <div className="flex-1" />
        <CmpButton
          variant={CmpButton.variants.primary}
          onClick={() => onCreateTestPage(savedDbSessionId)}
        >
          Create Test Page
        </CmpButton>
      </div>

      {/* Kubernetes */}
      <div className="flex mt-4">
        <div className="mr-4 my-auto">
          <DoneIcon width={30} height={30} className="text-green-400" />
        </div>
        <div className="flex-1">
          <CmpLabel text={data.action === "update" 
            ? "Updated Interact container in Kubernetes cluster" 
            : "Deployed Interact container to Kubernetes cluster"} additionalClass="text-base"
          />
          <CmpLabel><span className="mr-1">&bull;</span>{`Image version: ${sessionResult?.configuration?.values[".Values.image.version"]}`}</CmpLabel>
          <CmpLabel><span className="mr-1">&bull;</span>{`Added storage on Azure: ${sessionResult?.storage?.storageName}`}</CmpLabel>
          <CmpLabel><span className="mr-1">&bull;</span>{`Deployed Interact version ${interactResult?.engineVersion}`}</CmpLabel>
        </div>
      </div>

      {/* SOAP Environment */}
      {data?.soapSettings && (
      <div className="flex mt-4">
        <div className="mr-4 my-auto">
          <DoneIcon width={30} height={30} className="text-green-400" />
        </div>
        <div className="flex-1">
          <CmpLabel text={`${data?.templateAction || "Created"} SOAP environment`} additionalClass="text-base" />
          <CmpLabel><span className="mr-1">&bull;</span>{`Environment: ${data?.customerEnvironment}`}</CmpLabel>
          <CmpLabel><span className="mr-1">&bull;</span>{`Endpoint: ${sessionResult?.endpoint}`}</CmpLabel>
        </div>
      </div>
      )}

      {/* Template */}
      <div className="flex mt-4">
        <div className="mr-4 my-auto">
          <DoneIcon width={30} height={30} className="text-green-400" />
        </div>
        <div className="flex-1">
          <CmpLabel text={`${data?.templateAction || "Created"} Template environment`} additionalClass="text-base" />
          <CmpLabel><span className="mr-1">&bull;</span>{`Environment: ${data?.customerEnvironment}`}</CmpLabel>
          <CmpLabel><span className="mr-1">&bull;</span>{`Server: ${interactResult?.computerName}`}</CmpLabel>
        </div>
      </div>

      <div className="flex mt-8">
        <div className="flex-1" />
        <CmpButton
          variant={CmpButton.variants.primary}
          className='w-40'
          onClick={onClose}
        >
          Close
        </CmpButton>
        <div className="flex-1" />
      </div>
    </div>
  );
}

export default WizardStepResult;