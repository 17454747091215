import React from 'react';
import Tooltip from 'react-tooltip'

import {Label as CmpLabel} from '../components/Label'

export const renderSimpleTooltip = () => {
  return (
    <Tooltip id="simple-tooltip" place="top" type="dark" effect="float" />
  );
}

export const renderServerUpdateLabel = (currVer, newVer) => {
  return (<CmpLabel type={CmpLabel.types.warn}>
    <p className="font-bold">Server needs to be updated.</p>
    <p className="italic">{`Current version: ${currVer ?? "N/A"}`}</p>
    <p className="italic">{`New version: ${newVer ?? "N/A"}`}</p>
  </CmpLabel>);
}

export const renderNameDescTooltip = () => {
  return (
    <Tooltip id="namedesc-tooltip" place="top" type="dark" effect="float" aria-haspopup='true'
      getContent={(dataTip) => {
        const words = dataTip?.split('||') || ["", ""];
        return (
          <div className='mb-2'>
            <p className="text-base">{words[0]}</p>
            <p className="italic">{words[1]}</p>
            {words.length > 3 && renderServerUpdateLabel(words[2], words[3])}
          </div>
        );
       }
      }
    />
  );
}

export const renderDataArrayTooltip = () => {
  return (
    <Tooltip id="dataarray-tooltip" place="top" type="dark" effect="float" aria-haspopup='true'
      getContent={(dataTip) => {
        const rows = dataTip?.split('||');
        return (
          <div>
            {rows && rows.map((row) => (<p className=''>{!row ? <>&nbsp;</> : row}</p>))}
          </div>);
        }
      }
    />  
  );
}

export const renderHtmlTooltip = (getContent) => {
  return (
    <Tooltip id="html-tooltip" place="top" type="dark" effect="float" aria-haspopup='true'
      getContent={getContent}
    />  
  );
}

export const addSimpleToolipToContext = (addTooltipElementWithId) => {
  addTooltipElementWithId("simple-tooltip", (dataTip) => dataTip);
}

export const addNameDescToolipToContext = (addTooltipElementWithId) => {
  addTooltipElementWithId("namedesc-tooltip", (dataTip) => {
    if (!dataTip)
      return null;

    const words = dataTip?.split('||') || ["", ""];
    return (<div><p className="text-base">{words[0]}</p>
    <p className="italic">{words[1]}</p></div>);
  });
}

export const addDataArrayToolipToContext = (addTooltipElementWithId) => {
  addTooltipElementWithId("dataarray-tooltip", (dataTip) => {
    if (!dataTip)
      return null;

    const rows = dataTip?.split('||');
    return (
      <div>
        {rows && rows.map((row) => (<p className=''>{!row ? <>&nbsp;</> : row}</p>))}
      </div>);
    });
}