import GenericModal from '../../../components/Modals/genericModal';
import { Button as CmpButton} from '../../../components/Button';

export default function ActiveConfirmation({ open, setOpen, environment, disabled, onSubmit }) {

  const handleOnSubmit = (activate) => {
    onSubmit(activate);
    setOpen(false);
  }

  return (
    <GenericModal open={open} setOpen={setOpen}
      onConfirm={() => handleOnSubmit(true)}
      onCancel={() => setOpen(false)}
      showCancelButton={true}
      confirm={true}
      confirmButtonText={'Activate'}
      title="Activation"
      additionalButton={{ label: "Deactive", type: CmpButton.variants.primary, disabled: disabled, onClick: () => handleOnSubmit(false) }}
    >
      <div className="mt-4">
        <p className="text-sm text-gray-500">
          Are you sure you want to activate/deactivate the selected documents in <span className="font-bold">{environment}</span>.
        </p>
      </div>
    </GenericModal>
  );
}