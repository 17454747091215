import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react'

import { Button as CmpButton } from "../../components/Button";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function GenericModal({ open, setOpen, onConfirm, onCancel, confirmButtonText, cancelButtonText, showCancelButton, title, children, showConfirmButton = true, confirmButtonDisabled = false, additionalButton = null }) {
    const canShowCancelButton = !!showCancelButton
    const cancelButtonRef = useRef(null);

    let cancelCls = "col-span-3";
    let okCls = canShowCancelButton ? "col-span-3" : "col-span-4 col-start-2";
    let addonBtn = additionalButton;
    if (addonBtn)
    {
        cancelCls = "col-span-2";
        addonBtn.className = "col-span-2 col-start-3"
        okCls = canShowCancelButton ? "col-span-2  col-start-5" : "col-span-2 col-start-5";
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={setOpen}
            >
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom w-2/3 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-sm sm:w-full sm:p-6 dark:bg-gray-900">
                            <div>
                                <div className="text-center">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                                        {title}
                                    </Dialog.Title>
                                    {children}
                                </div>
                            </div>
                            <div className="mt-8 grid grid-cols-6 gap-4">
                                {
                                    showCancelButton && (<CmpButton
                                        variant={CmpButton.variants.secondary}
                                        disabled={false}
                                        onClick={() => onCancel?.call()}
                                        ref={cancelButtonRef}
                                        className={cancelCls}
                                    >
                                        {cancelButtonText || 'Cancel'}
                                    </CmpButton>)  
                                }
                                {
                                    addonBtn && (<CmpButton
                                        variant={addonBtn.variant || CmpButton.variants.primary}
                                        disabled={addonBtn.disabled || false}
                                        onClick={() => addonBtn.onClick?.call()}
                                        className={addonBtn.className || ""}
                                    >
                                        {addonBtn.label} 
                                    </CmpButton>)
                                }
                                {
                                    showConfirmButton && (<CmpButton
                                        variant={CmpButton.variants.primary}
                                        disabled={confirmButtonDisabled}
                                        onClick={() => onConfirm?.call()}
                                        className={okCls}
                                    >
                                        {confirmButtonText || 'Confirm'} 
                                    </CmpButton>)
                                }
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


export default GenericModal;
