/* This example requires Tailwind CSS v2.0+ */
import { useRef } from 'react'

import GenericModal from '../../../components/Modals/genericModal';
import { TwTable } from "../../../components/TailwindTable";

export default function Changes({changes, open, setOpen}) {
  const cancelButtonRef = useRef(null)

  return (
    <GenericModal open={open} setOpen={setOpen}
      onConfirm={() => setOpen(false)}
      showCancelButton={false}
      confirmButtonText={'Close'}
      title="Document changes"
    >
      <TwTable>
        <TwTable.Head>
          <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
            Property
          </TwTable.Header>
          <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
            Old Value 
          </TwTable.Header>
          <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
            New Value
          </TwTable.Header>
        </TwTable.Head>
        <TwTable.Body>
          {changes && changes.map((change, changeIdx) => (
            <TwTable.BodyRow key={change.id} >
              <TwTable.BodyCol additionalClass="font-medium text-gray-900 dark:text-gray-100" >
                {change.type}
              </TwTable.BodyCol>
              <TwTable.BodyCol>
                {change.oldValue}
              </TwTable.BodyCol>
              <TwTable.BodyCol>
                {change.newValue}
              </TwTable.BodyCol>
            </TwTable.BodyRow>
          ))}
        </TwTable.Body>
      </TwTable>
    </GenericModal>

    // <Transition.Root show={open} as={Fragment}>
    //   <Dialog
    //     as="div"
    //     auto-reopen="true"
    //     className="fixed z-10 inset-0 overflow-y-auto"
    //     initialFocus={cancelButtonRef}
    //     onClose={setOpen}
    //   >
    //     <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    //       <Transition.Child
    //         as={Fragment}
    //         enter="ease-out duration-300"
    //         enterFrom="opacity-0"
    //         enterTo="opacity-100"
    //         leave="ease-in duration-200"
    //         leaveFrom="opacity-100"
    //         leaveTo="opacity-0"
    //       >
    //         <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    //       </Transition.Child>

    //       {/* This element is to trick the browser into centering the modal contents. */}
    //       <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
    //         &#8203;
    //       </span>
    //       <Transition.Child
    //         as={Fragment}
    //         enter="ease-out duration-300"
    //         enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    //         enterTo="opacity-100 translate-y-0 sm:scale-100"
    //         leave="ease-in duration-200"
    //         leaveFrom="opacity-100 translate-y-0 sm:scale-100"
    //         leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    //       >
    //         <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
    //           <div className="bg-white px-4 pt-5 pb-4 sm:p-8 sm:pb-4">
    //             <div className="">
    //               <div className="mt-3 text-center sm:mt-0 sm:mx-2 sm:text-left">
    //                 <Dialog.Title as="h3" className="text-lg leading-6 pb-2 font-medium text-gray-900">
    //                   Document changes
    //                 </Dialog.Title>
    //                 <div className="mt-2">
                    
    //                   <table className="min-w-full divide-y divide-gray-200 border border-gray-200 sm:rounded-lg">
    //                     <thead className="bg-gray-50">
    //                       <tr>
    //                         <th
    //                           scope="col"
    //                           className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
    //                         >
    //                           Property
    //                         </th>
    //                         <th
    //                           scope="col"
    //                           className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
    //                         >
    //                           Old Value
    //                         </th>
    //                         <th
    //                           scope="col"
    //                           className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
    //                         >
    //                           New Value
    //                         </th>
    //                       </tr>
    //                     </thead>
    //                     <tbody>
    //                     {changes.map((change, changeIdx) => (
    //                       <tr key={change.id} className={changeIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
    //                         <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{change.type}</td>
    //                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{change.oldValue}</td>
    //                         <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{change.newValue}</td>
    //                       </tr>
    //                     ))}
    //                     </tbody>
    //                   </table>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className=" px-4 py-5 sm:px-6 sm:flex sm:flex-row-reverse">
    //             <button
    //               type="button"
    //               className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-brand-blue text-base font-medium text-white hover:bg-brand-blue-hover sm:mr-3 sm:w-auto sm:text-sm"
    //               onClick={() => setOpen(false)}
    //             >
    //               Close
    //             </button>
    //           </div>
    //         </div>
    //       </Transition.Child>
    //     </div>
    //   </Dialog>
    // </Transition.Root>
  )
}
