import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
//import { format } from "date-fns";

import * as yup from "yup";

import { useToastAction } from '../../../hooks/useToastAction';
import { useEnvironmentGroup } from '../../../hooks/useEnvironmentGroup';

import { CurrentDataContext } from '../../../contexts';

import * as InteractApi from "../../../api/interact";
import * as TemplateApi from "../../../api/interactTemplateEnvironment.js";

import { Button as CmpButton } from "../../../components/Button";
import { Textbox as CmpTextbox } from "../../../components/Textbox";
import { Select as CmpSelect } from "../../../components/Select";
import { TwToggle as CmpToggle } from "../../../components/Toggle";
import { TwPage } from "../../../components/TailwindPage";
import { TwFormEx } from "../../../components/TailwindForm";

export default function TemplateServer() {
  const { envId } = useParams();
  const { settingsId } = useParams();
  const history = useHistory();

  const {currentDataId, currentDataPayload, dispatchCurrentDataEvent} = useContext(CurrentDataContext);

  const [errors, setErrors] = useState({});
  const [interactTemplate, setInteractTemplate] = useState(null);
  const [interactServer, setInteractServer] = useState(null);
  const [customerEnvironment, setCustomerEnvironment] = useState(null);

  const loadAction = useToastAction();
  const saveAction = useToastAction();

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      const allEnvironments = await InteractApi.getInteractEnvironments();

      const currPlId = currentDataPayload?.id || "";
      const template = currentDataId === 'template' && ((envId === "new" && currPlId === "") || (envId === currPlId))
        ? currentDataPayload
        : await TemplateApi.getInteractTemplateEnvironment(envId);

      var custEnv = allEnvironments.find(({value}) => value === parseInt(template.customerEnvironment)).name;
      setCustomerEnvironment(custEnv);

      const server = settingsId !== "new" 
        ? template.servers.find(({ serverId }) => serverId === settingsId)
        : 
        {
          serverId: uuidv4(),
          name: "",
          description: "",
          type: 0,
          status: 0,
          enabled: false
        };

      if (settingsId === "new")
      {
        if (!template.servers)
          template.servers = [];
      }

      setInteractTemplate(template);
      setInteractServer(server);
    }, "Failed to load")
  }

  useEffect(() => {
    loadBaseData();
  }, [])

  const onChange = ({ name, value }) => {
    setInteractServer(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSave = async () => {
    if (settingsId === "new") {
      interactTemplate.servers.push(interactServer);
    } else {
      const servers = interactTemplate.servers.map((s) => {
        if (s.serverId === interactServer.serverId)
          return interactServer;
        else
          return s;
      });
      interactTemplate.servers = servers;
    }
    
    await TemplateApi.updateInteractTemplateEnvironment(interactTemplate);
    dispatchCurrentDataEvent("template", interactTemplate);
    history.goBack();
  }

  let schema = yup.object().shape({
    description: yup.string(),
    name: yup.string().required("Server name is required"),
  });

  const validate = async () => {
    var isValid = false

    await schema
        .validate(interactServer, { abortEarly: false })
        .then(function () {
            isValid = true;
            setErrors({});
        })
        .catch(function (err) {
            var newErrors = {}

            err?.inner?.forEach(element => {
                newErrors[element.path] = `${element.errors[0]}`
            });

            setErrors(newErrors);
        });

    return isValid;
  }

  const handleSubmit = async () => {
    var isValid = await validate();
    if (isValid) {
      handleSave();
    }
  }

  const typeEnumValues = [
    {name: "VMWare", value: 0},
    {name: "Container", value: 1}
  ]

  const formButtons = [
       {text: "Cancel", variant: CmpButton.variants.secondary, onClick: () => history.goBack()},
       {text: "Ok", onClick: handleSubmit}
    ];


  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed title="Interact Logic file Environment Server" />
        </TwPage.Header>
        <TwFormEx
          buttons = {formButtons}
        >
          <TwFormEx.Section label="Customer Environment" separator={false}>
              <CmpTextbox
                name="customerEnvironment"
                value={customerEnvironment}
                label={"Customer Environment"}
                disabled={true}
            />
          </TwFormEx.Section>

          <TwFormEx.Section label="Status" separator={true}>
            <div className="pt-5">
              <CmpToggle
                  name="enabled"
                  id="enabled-srv"
                  label={"Enabled"}
                  value={interactServer?.enabled}
                  onChange={value => onChange({ name: 'enabled', value: value })}
                  error={errors.enabled}
              />
            </div>
          </TwFormEx.Section>

          <TwFormEx.Section label="Data" separator={true}>
            <div className="pt-5">
              <CmpTextbox
                name="name"
                label={"Name"}
                value={interactServer?.name || ""}
                onChange={e => onChange(e.target)}
                error={errors.name}
              />
              <CmpTextbox
                name="description"
                label={"Description"}
                value={interactServer?.description || ""}
                onChange={e => onChange(e.target)}
                error={errors.description}
              />
              <CmpSelect
                name="type"
                value={interactServer?.type}
                label={"Server type"}
                onChange={e => onChange(e.target)}
                error={errors.type}
                options={typeEnumValues}
              />
            </div>
          </TwFormEx.Section>
        </TwFormEx>
      </TwPage>
    </>
  );
}
