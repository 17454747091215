import React from 'react';
import  { EyeIcon } from '@heroicons/react/outline'

import * as Constants from '../../../utils/constants'

import { TemplatesOverviewTable } from "../Templates/overviewPanel";

import { Label as CmpLabel } from "../../../components/Label";
import { Button as CmpButton } from "../../../components/Button";
import { ButtonGroup } from "../../../components/ButtonGroup"

export default function Dashboard_Template({ setting, createButtons, isExpanded, history }) {
  return (
    <>
      { setting.templateSettings &&
        <div className='my-4 border-t-2 border-gray-900/10 dark:border-gray-700 max-w-full'>
          <div className='flex h-10 mt-4'>
            <CmpLabel text="Template" additionalClass='flex-1 text-lg font-bold mr-4' />
            <ButtonGroup buttons={createButtons(setting.customerEnvironment.value, setting.templateSettings, "environments/template", Constants.templatesConfigurationFeatureCode)} />
          </div>
          { isExpanded(setting.customerEnvironment.value, setting.templateSettings.id) &&
            <>
              <div className='flex my-2'>
                <CmpLabel text={`Number of files: ${setting.templateSettings.fileCount}`} additionalClass='my-auto text-gray-700 dark:text-gray-400 mr-4' />
                <CmpButton
                  variant={CmpButton.variants.secondary}
                  onClick={() => history.push(`/templates/publish/${setting.templateSettings.order}/fo`)}
                >
                  <span className="sr-only">Edit</span>
                  <EyeIcon className="h-3 w-3" aria-hidden="true" />
                </CmpButton>
              </div>
              <div className='pb-8'>
                <TemplatesOverviewTable tplEnvSettings={setting.templateSettings} additionalClass='mt-4'/>
              </div>
            </>
          }
        </div>
      } 
    </>
  );
}