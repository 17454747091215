import { useCallback, useEffect, useState } from "react";

import * as TemplateApi from "../api/interactTemplateEnvironment.js";

const interval = (delay = 0) => /** @param {() => void} callback */ (
  callback
) =>
  useEffect(() => {
    const id = setInterval(callback, delay);

    return () => clearInterval(id);
  }, [callback]);

const use30Second = interval(30000);

export const useUpdateServerStatusTimer = ({
  initiallyRunning = false
} = {}) => {
  const [serverStatuses, setServerStatuses] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [running, setRunning] = useState(initiallyRunning);
  const [tickDate, setTickDate] = useState(null);

  const tick = useCallback(
    async () => {
        setTickDate(Date.now());
        //console.log("useUpdateServerStatusTimer", "tick:", "templates", templates);
        if (!(templates?.length > 0))
          return;

        let statuses = [];
        await Promise.all(templates.map(async (template) => {
          try {
            const tpl = await TemplateApi.getInteractTemplateEnvironmentNoFiles(template.id);

            if (!!tpl?.servers)
            {
              const srvStatuses = tpl.servers.map(server => { return { id: server.serverId, name: server.name, enabled: server.enabled, status: server.status, statusMessage: server.statusMessage }; });

              if (srvStatuses?.length > 0)
              {
                statuses.push({template: template.id, statuses: srvStatuses, fileCount: tpl.fileCount});
              }
            }
          } catch (err) {
            console.log("useUpdateServerStatusTimer ERROR:", err);
          }
        }));

        //console.log("useUpdateServerStatusTimer", "tick:", "statuses", statuses);
        setServerStatuses(statuses);
      },
    [running]
  );

  const start = () => setRunning(true);
  const pause = () => setRunning(false);
  const reset = () => setServerStatuses([]);
  const stop = () => {
    pause();
    reset();
  };

  use30Second(tick);

  return { pause, reset, running, serverStatuses, setTemplates, start, stop, tickDate };
};
