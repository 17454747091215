import { v4 as uuidv4 } from 'uuid';
import { isEqual, isPast, isFuture } from "date-fns";

export const LOGICFILESTATUSES =
{
  unknown: "unknown",
  equal: "equal",
  fromOnly: "fromOnly",
  toOnly: "toOnly",
  fromNewer: "fromNewer",
  toNewer: "toNewer",
}

export const LOGICFILEORIGINS =
{
  metatool: 0,
  manual: 1
}

export const LOGICFILEPUBLISHSTATE =
{
  draft: 0,
  published: 1,
}

export const FILEDEPENDENCYTYPE =
{
    customer: 0,
    group: 1,
    environment: 2
}

export const SERVERFILETYPE =
{
    fontTrueType: 0,
    fontConfig: 1,
    license: 2,
    printConfig: 3,
    ICCProfile: 4,
    lexars: 5,
    logic: 6,
    backup: 7
}

export const getFileTypeName = (fileType) => {
  switch (fileType) {
    case SERVERFILETYPE.fontTrueType: 
      return "Font file";
    case SERVERFILETYPE.fontConfig: 
      return "Font config file";
    case SERVERFILETYPE.license:
      return "License file";
    case SERVERFILETYPE.printConfig: 
      return "Print config file";
    case SERVERFILETYPE.ICCProfile: 
      return "ICC profile file";
    case SERVERFILETYPE.lexars: 
      return "Lexars file";
    case SERVERFILETYPE.logic: 
      return "Logic file";
    case SERVERFILETYPE.backup: 
      return "Server Backup file";
  }

  return "Unknown filetype";
}

export const getFileTypeExt = (fileType) => {
  switch (Number(fileType)) {
    case SERVERFILETYPE.fontTrueType: 
      return ".ttf";
    case SERVERFILETYPE.fontConfig: 
      return ".xml";
    case SERVERFILETYPE.license:
      return ".xml";
    case SERVERFILETYPE.printConfig: 
      return ".xml";
    case SERVERFILETYPE.ICCProfile: 
      return ".icm";
    case SERVERFILETYPE.lexars: 
      return ".xml";
    case SERVERFILETYPE.logic: 
      return ".mfl";
    case SERVERFILETYPE.backup: 
      return ".zip";
  }

  return "Unknown filetype";
}

export const getFileStatusesBetweenTemplates = (fromTemplate, toTemplate, onAddStatus, doSort = true) => {
  // Add files from FROM first
  let fileStatus = fromTemplate
    ? fromTemplate.files.map((fromFile) => {
      const toFile = toTemplate.files.find(({fileName}) => fileName === fromFile.fileName);

      let status = LOGICFILESTATUSES.unknown;

      const timeDiff = toFile ? new Date(toFile.fileDate).getTime() - new Date(fromFile.fileDate).getTime() : undefined;

      if (!toFile)
        status = LOGICFILESTATUSES.fromOnly;
      else if (timeDiff === 0)
        status = LOGICFILESTATUSES.equal;
      else if (timeDiff < 0)
        status = LOGICFILESTATUSES.fromNewer;
      else if (timeDiff > 0)
        status = LOGICFILESTATUSES.toNewer;

      var fileStatusData = { id: uuidv4(), fileName: fromFile.fileName, from: fromFile, to: toFile, status: status };
      if (onAddStatus)
        onAddStatus(fileStatusData);

      return fileStatusData;
    })
    : [];

  // Add missing files from TO (should be none)
  toTemplate.files.map((toFile) => {
    const file = fileStatus.find((x) => x.fileName === toFile.fileName);
    if (!file)
    {
      var fileStatusData = { id:uuidv4(), fileName: toFile.fileName, from: undefined, to: toFile, status: LOGICFILESTATUSES.toOnly };
      if (onAddStatus)
        onAddStatus(fileStatusData);

      fileStatus.push(fileStatusData)
    }
  });

  return !doSort 
    ? fileStatus 
    : fileStatus.sort((a, b) => {
        if (a.from && b.from)
        {
          var dateA = Date.parse(a.from.fileDate), dateB = Date.parse(b.from.fileDate);
          return dateB - dateA;
        }
        if (!a.from && !b.from && a.to && b.to)
        {
          var dateA = Date.parse(a.to.fileDate), dateB = Date.parse(b.to.fileDate);
          return dateB - dateA;
        }
      });
}