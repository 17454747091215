import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function Modal({ isOpen, onClose, title = null, size = null, children }) {
  let widthClass = 'max-w-sm';
  if (size === 'large') {
    widthClass = 'max-w-4xl';
  }
  else if (size === 'medium') {
    widthClass = 'max-w-lg';
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={isOpen} onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle ${widthClass} sm:w-full sm:p-6 dark:bg-gray-900`}>
              {
                title &&
                <div div className="pb-4">
                  <div className="mt-2 flex items-center justify-between">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                      {title}
                    </Dialog.Title>
                  </div>
                </div>
              }
              {children}
              {/* Need some sort of button or else the modal throws 'There are no focusable elements inside the <FocusTrap />' for some reason */}
              <button style={{ float: 'right' }} />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}