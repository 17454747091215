import { useClassNames } from "../../hooks/useClassNames";

import { SearchIcon } from "@heroicons/react/outline";

/**
 * @param {*} Search 
 */
export const Search = ({ id, label, name, placeholder, onChange, disabled, error }) => {
  const { classNames } = useClassNames();

  return (
    <>
      <div className="sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3 sm:py-1">
        <div className="sm:col-span-2">
          <div className="relative flex items-start">
            <div className="w-96 mt-3 sm:mt-0">
              {label &&
              <label htmlFor={name} className="sr-only sm:ml-4">
                {label}
              </label>
              }
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  id={id}
                  name={name}
                  className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-brand-blue focus:border-brand-blue sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-brand-blue dark:focus:border-brand-blue"
                  placeholder="Search"
                  type="search"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          {
            error &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {error}
            </p>
          }
        </div>
      </div>
    </>
  );
}