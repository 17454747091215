import { useClassNames } from '@metaforcelabs/metaforce-core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Fragment } from 'react'
import MenuContextList from '../MenuContextList';
import { CollapsableContext } from './contexts';
import { ChevronRightIcon } from '@heroicons/react/outline';

const Table = ({ children, className }) => {
    const { classNames } = useClassNames();
    return (
        <div className="flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className={"ring-1 ring-gray-200 dark:ring-gray-600 sm:rounded-lg overflow-hidden"}>
                        <table className={classNames("min-w-full", className)}>
                            {children}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

const TableHead = ({ children, className }) => {
    const { classNames } = useClassNames();
    return (
        <thead className={classNames("bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-400 uppercase", className)}>
            <tr>
                {children}
            </tr>
        </thead>
    )
}

const TableHeader = ({ children, className }) => {
    const { classNames } = useClassNames();
    return (
        <th scope="col" className={classNames("py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3 border-b border-gray-200 dark:border-gray-600", className)}>
            {children}
        </th>
    )
}

const TableBody = ({ children, className }) => {
    const { classNames } = useClassNames();
    return (
        <tbody className={classNames("bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-300", className)}>
            {children}
        </tbody>
    )
}

const SubHead = ({ children, className, level = 0, canCollapse = false, active,...props  }) => {
    const { classNames } = useClassNames();
    const collapseContext = useContext(CollapsableContext);


    const handleClick = () => {
        if (!canCollapse || !collapseContext) return;

        collapseContext.setIsCollapsed(prev => !prev);
    }

    return (
        <tr
            className={classNames("dark:border-gray-700 bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200 border-t border-gray-300", className)} {...props}
        >
            {
                canCollapse && (
                    <SubHeader
                        className={'cursor-pointer'}
                        onClick={handleClick}
                    >
                        <div className='flex'>
                            {level > 0 &&
                                <div className={`h-4 w-${level * 4}`}></div>
                            }
                            <ChevronRightIcon className={classNames('h-4 w-4', collapseContext.isCollapsed ? '' : 'transform rotate-90')} />
                        </div>
                    </SubHeader>
                )
            }
            {children}

        </tr>
    )
}

const SubHeader = ({ children, className, colSpan = 1, ...props }) => {
    const { classNames } = useClassNames();
    return (
        <th
            colSpan={colSpan}
            scope="colgroup"
            className={classNames("py-2 pl-4 pr-3 text-left text-sm font-semibold sm:pl-3", className)}
            {...props}
        >
            {children}
        </th>
    )
}

const TableRow = ({ children, key, idx, className, canCollapse = false, level = 0, ...props }) => {
    const { classNames } = useClassNames();
    const collapseContext = useContext(CollapsableContext);


    const handleClick = () => {
        if (!canCollapse || !collapseContext) return;

        collapseContext.setIsCollapsed(prev => !prev);
    }

    return (
        <tr
            key={key}
            className={classNames(idx === 0 ? 'border-gray-300 dark:border-gray-500' : ' border-gray-200 dark:border-gray-500', 'border-t dark:hover:bg-gray-800 hover:bg-gray-50', className)}
            {...props}
        >
            {
                canCollapse && (
                    <TableCol
                        className={'cursor-pointer'}
                        onClick={handleClick}
                    >
                        <ChevronRightIcon className={classNames('h-4 w-4', collapseContext.isCollapsed ? '' : 'transform rotate-90')} />
                    </TableCol>
                )
            }
            {children}
        </tr>
    )
}

const TableCol = ({ children, indent, className, ...props }) => {
    const { classNames } = useClassNames();
    return (
        <td className={classNames("whitespace-nowrap py-2 pr-3 text-sm font-medium", indent ? 'pl-4 sm:pl-8' : 'pl-4 sm:pl-3', className)}
            {...props}
        >
            {children}
        </td>
    )
}

const CollapsableProvider = ({ children, onCollapseChanged, id, defaultCollapsed = true, level = 0 }) => {

    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

    useEffect(() => {
        //console.log("CollapsableProvider", "useEffect[isCollapsed]", "isCollapsed", isCollapsed, "level", level, "id", id);
        if (onCollapseChanged)
            onCollapseChanged(isCollapsed);
    }, [isCollapsed])

    return (
        <CollapsableContext.Provider
            value={{
                isCollapsed,
                setIsCollapsed,
                id,
                level
            }}
        >
            {children}
        </CollapsableContext.Provider>
    )
}


const Collapsable = ({ children, level = 0 }) => {
    const collapseContext = useContext(CollapsableContext);
    const lv = level;

    if (collapseContext) {

        //console.log("Collapsable", "collapseContext", collapseContext, "level", level);

        return collapseContext.isCollapsed ? (
            <></>
        ) : (children)
    }

    return (
        { children }
    )
}


export const Example = ({ }) => {
    const { classNames } = useClassNames();
    const containerRef = useRef(null);

    const locations = [
        {
            name: 'Edinburgh',
            people: [
                { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
                { name: 'Courtney Henry', title: 'Designer', email: 'courtney.henry@example.com', role: 'Admin' },
                { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
                { name: 'Courtney Henry', title: 'Designer', email: 'courtney.henry@example.com', role: 'Admin' },
                { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
                { name: 'Courtney Henry', title: 'Designer', email: 'courtney.henry@example.com', role: 'Admin' },
            ],
        },
        {
            name: 'St. Andrews',
            people: [
                { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
                { name: 'Courtney Henry', title: 'Designer', email: 'courtney.henry@example.com', role: 'Admin' },
                { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
                { name: 'Courtney Henry', title: 'Designer', email: 'courtney.henry@example.com', role: 'Admin' },
                { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
                { name: 'Courtney Henry', title: 'Designer', email: 'courtney.henry@example.com', role: 'Admin' },
            ],
        },
    ]

    return (
        <div ref={containerRef}>
            <Table>
                <TableHead>
                    <TableHeader>
                        Name
                    </TableHeader>
                    <TableHeader>
                        Title
                    </TableHeader>
                    <TableHeader>
                        Email
                    </TableHeader>
                    <TableHeader>
                        Role
                    </TableHeader>
                    <TableHeader>
                        <span className="sr-only">Edit</span>
                    </TableHeader>
                </TableHead>
                <TableBody>
                    {locations.map((location) => (
                        <Fragment key={location.name}>
                            <SubHead>
                                <SubHeader>
                                    {location.name}
                                </SubHeader>
                            </SubHead>
                            {location.people.map((person, idx) => (
                                <TableRow key={person.email} idx={idx}>
                                    <TableCol>
                                        {person.name}
                                    </TableCol>
                                    <TableCol >{person.title}</TableCol>
                                    <TableCol>{person.email}</TableCol>
                                    <TableCol>{person.role}</TableCol>
                                    <TableCol className="text-right sm:pr-3">
                                        <MenuContextList
                                            actions={[
                                                {
                                                    name: "Edit",
                                                    onClick: () => { },
                                                    disabled: false
                                                }, {
                                                    name: "Delete",
                                                    onClick: () => { },
                                                    disabled: false
                                                }
                                            ]}
                                            handleDelete="false"
                                            refContainer={containerRef}
                                        />
                                    </TableCol>
                                </TableRow>
                            ))}
                        </Fragment>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

const NestedTable = {
    Example,
    Table,
    TableHead,
    TableHeader,
    SubHead,
    SubHeader,
    TableBody,
    TableRow,
    TableCol,
    CollapsableProvider,
    Collapsable
};

export default NestedTable;


