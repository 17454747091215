import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format } from "date-fns";

import { v4 as uuidv4 } from 'uuid';

import { EnvironmentGroupContext, InteractStatusContext, DebugContext } from '../../../contexts/index.js';

import { useToastAction } from '../../../hooks/useToastAction.js';

import * as DeploymentTemplateApi from "../../../api/deployTemplate.js";

import './styles.scss';

import { Label as CmpLabel, TwCollapsableLabel } from "../../../components/Label/index.js";
import { Button as CmpButton } from "../../../components/Button/index.js";
import { TwPage } from "../../../components/TailwindPage/index.js";
import { TwTable } from "../../../components/TailwindTable/index.js";

// import Stepper from "../../../components/Stepper/stepper.js";
// import {useStepper} from "../../../hooks/useStepper.js";

export default function ExtrasTemplates() {
  const history = useHistory();

  const {interactEnvironments, dispatchUpdateInteractEnvironments} = useContext(InteractStatusContext);
  const { debugData} = useContext(DebugContext);

  const [allDeploymentTemplates, setAllDeploymentTemplates] = useState([]);
  const [allDeploymentTemplatesView, setAllDeploymentTemplatesView] = useState([]);
  const [allDeploymentTemplateItems, setAllDeploymentTemplateItems] = useState([]);
  const [deploymentResult, setDeploymentResult] = useState(null);
  const [deploymentError, setDeploymentError] = useState(null);
  const [deployStatus, setDeployStatus] = useState({active: false, update: false, forceRestart: false});

  const [loadingBaseData, setLoadingBaseData] = useState(true);

  const resetDeploymentData = () => {
    setDeploymentResult(null);
    setDeploymentError(null);
    setDeployStatus({active: false, update: false, forceRestart: false});
  }

  const loadAction = useToastAction();
  const resetAction = useToastAction();

  const hasCrudAccess = true;

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      console.log("DeploymentTest", "loadBaseData", "debugData", debugData);

      const tst_uuid = uuidv4("Blargh");
      console.log("DeploymentTest", "loadBaseData", "tst_uuid", tst_uuid);

      // Force reload (and revalidation) of the InteractEnvironments
      await dispatchUpdateInteractEnvironments();

      const templateItems = await DeploymentTemplateApi.getDeploymentTemplateItems();

      const templates = await DeploymentTemplateApi.getDeploymentTemplates();
      const newTemplates = templates.map(t => {
        const newItems = t.items.map(i => {
          return {ref: i, item: templateItems.find(ti => ti.id === i.referenceId)};
        });
        var newTemplate = {
          ...t,
          ['items']: newItems
        };
        return newTemplate;
      });

      console.log("DeploymentTest", "loadBaseData", "newTemplates", newTemplates);

      setAllDeploymentTemplatesView(newTemplates);

      console.log("DeploymentTest", "loadBaseData", "templateItems", templateItems, "templates", templates, "newTemplates", newTemplates);

      setAllDeploymentTemplates(templates);
      setAllDeploymentTemplateItems(templateItems);
      setLoadingBaseData(false);
    }, "Failed to load (DeploymentTest)")
  }

  useEffect(() => {
    resetDeploymentData();
    loadBaseData();
  }, []);

  useEffect(() => {
    console.log("DeploymentTest", "useEffect{interactEnvironments]", "interactEnvironments", interactEnvironments);
  }, [interactEnvironments]);
  
  useEffect(async () => {
    if (deployStatus.active === true) {
      if (deployStatus.forceRestart === true) {
        setDeployStatus(prev => {
          return { ...prev, ["active"]: false }
        });
      }
    } else {
      if (deployStatus.forceRestart === true) {
        setDeployStatus(prev => {
          return { ...prev, ["active"]: true, ["forceRestart"]: false }
        });
      }
    }
  }, [deployStatus]);

  const handleResetTemplateAndItems = async () => {
    resetAction.execute(async () => {
      await DeploymentTemplateApi.resetDeploymentTemplates();
      setAllDeploymentTemplateItems([]);
      setAllDeploymentTemplates([]);
      await loadBaseData();
    }, "Failed to reset templates", "Reset the templates succesfully");
  }

  const handleSetForceUpdate = async () => {
    resetAction.execute(async () => {
      await DeploymentTemplateApi.setForceUpdate();
      setAllDeploymentTemplateItems([]);
      setAllDeploymentTemplates([]);
      await loadBaseData();
    }, "Failed to set force update", "Set force update succesfully");
  }

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed 
            title="Deployment Templates" 
          />
        </TwPage.Header>

        <CmpLabel type={CmpLabel.types.warn} additionalClass="mb-8">
          <p className="font-bold">
              This page is intended as a Metaforce administration page and should not be used by others.
          </p>
          <p>
              Clicking a red button updates the database and should only be used by someone that understands the backend/database structure.
          </p>
        </CmpLabel>

        {loadingBaseData && (
          <div className='flex flex-wrap'>
            <CmpLabel text={"Loading deployment template..."} additionalClass='font-bold h-6' />
          </div>
        )}
        {allDeploymentTemplatesView && allDeploymentTemplatesView.length > 0 && (
          <>
            <div className="flex mt-2">
              <div className="flex-1" />
              <CmpButton
                variant={CmpButton.variants.custom}
                className="justify-center bg-red-500 text-gray-200 hover:bg-red-600 hover:bg-text-white ring-red-900 ring-opacity-5"
                onClick={handleResetTemplateAndItems}
              >
                Reset Templates and template items
              </CmpButton>
              <CmpButton
                variant={CmpButton.variants.custom}
                className="ml-4 justify-center bg-red-500 text-gray-200 hover:bg-red-600 hover:bg-text-white ring-red-900 ring-opacity-5"
                onClick={handleSetForceUpdate}
              >
                Set Force Update (for all customers)
              </CmpButton>
            </div>
            <TwTable additionalClass='mt-6'>
              <TwTable.Head>
                <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                  Name
                </TwTable.Header>
                <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                  Description 
                </TwTable.Header>
                <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                  Created 
                </TwTable.Header>
                <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                  Template Items 
                </TwTable.Header>
                <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                  Files count 
                </TwTable.Header>
              </TwTable.Head>
              <TwTable.Body>
              { allDeploymentTemplatesView && allDeploymentTemplatesView.map((template) => (
                  <TwTable.BodyRow key={template.id} useHover={hasCrudAccess}>
                    <TwTable.BodyCol additionalClass={'font-medium'}>
                      {template.name}
                    </TwTable.BodyCol>
                    <TwTable.BodyCol>
                      {template.description}
                    </TwTable.BodyCol>
                    <TwTable.BodyCol>
                    {format(
                        new Date(template.createdDate),
                        "MM/dd/yyyy - HH:mm"
                      )}
                    </TwTable.BodyCol>
                    <TwTable.BodyCol>
                      <ul>
                      {template.items && template.items.map((item) => (
                        <li>{item.ref.referenceType} - {item.item?.name}</li>
                      ))}
                      </ul>
                    </TwTable.BodyCol>
                    <TwTable.BodyCol>
                      <div className='flex flex-col'>
                        <div>Total count: {template.files.length}</div>
                        <div>Font files: {template.files.filter(x => x.name.toLowerCase().endsWith('.ttf')).length}</div>
                        <div>Template files: {template.files.filter(x => x.name.toLowerCase().endsWith('.mfl')).length}</div>
                      </div>
                    </TwTable.BodyCol>
                  </TwTable.BodyRow>
                ))}
              </TwTable.Body>
            </TwTable>

            {/* Template items */}
            <TwTable additionalClass='mt-6'>
              <TwTable.Head>
                <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                  Name
                </TwTable.Header>
                <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                  Description 
                </TwTable.Header>
                <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                  Created 
                </TwTable.Header>
                <TwTable.Header additionalClass={'uppercase sm:pl-6'}>
                  Dependencies 
                </TwTable.Header>
              </TwTable.Head>
              <TwTable.Body>
              {allDeploymentTemplateItems.map((templateItem) => (
                  <TwTable.BodyRow key={templateItem.id} useHover={hasCrudAccess}>
                    <TwTable.BodyCol additionalClass={'font-medium'}>
                      {templateItem.name}
                    </TwTable.BodyCol>
                    <TwTable.BodyCol>
                      {templateItem.description}
                    </TwTable.BodyCol>
                    <TwTable.BodyCol>
                      {format(
                        new Date(templateItem.createdDate),
                        "MM/dd/yyyy - HH:mm"
                      )}
                    </TwTable.BodyCol>
                    <TwTable.BodyCol>
                      <ul>
                      {templateItem.dependencies && templateItem.dependencies.map((dependency) => (
                        <li>{dependency.dependencyType} - {dependency.typeName.slice(0, dependency.typeName.indexOf(','))}</li>
                      ))}
                      </ul>
                    </TwTable.BodyCol>
                  </TwTable.BodyRow>
                ))}
              </TwTable.Body>
            </TwTable>
          </>
        )}

      </TwPage>
    </>
  );
}