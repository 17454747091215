import { useClassNames } from "../../hooks/useClassNames";

/**
 * @param {*} SidebarTextbox 
 */
export const Textbox = ({ label, placeholder, value, defaultValue, name, onChange, disabled, error, additionalClass='' }) => {
  const classNames = useClassNames();

  return (
    <>
      <div className={`space-y-1 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3 sm:py-1 ${additionalClass}`}>
        <div>
          <label
            htmlFor="project_name"
            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2 dark:text-gray-300"
          >
            {label}
          </label>
        </div>
        <div className="sm:col-span-2">
          <input
            type="text"
            name={name}
            value={value}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder}
            className={classNames.classNames(
              "block w-full shadow-sm sm:text-sm focus:ring-brand-blue focus:border-brand-blue border-gray-300 rounded-md disabled:opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-brand-blue dark:focus:border-brand-blue ",
                error 
                ? "placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 border-red-300 text-red-900 "
                : ""
            )}
          />
          {
            error && 
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {error}
            </p>
          }
        </div>
        
      </div>
      
    </>
  );
}