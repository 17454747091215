import React, { useContext } from "react";
import { useClassNames } from "../../hooks/useClassNames";
import { Switch } from '@headlessui/react';
import { TailwindUiContext } from '../../contexts';

/**
 * @param {*} Toggle 
 */
export const Toggle = ({ label, description, value, onChange, disabled, error, fill = false, ...props }) => {
  const { classNames } = useClassNames();

  return (
    <>
      <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3 sm:py-1" {...props}>
        <div className="sm:col-span-2">
          <Switch.Group as="div" className={`flex items-center ${fill ? 'justify-between' : ''}`} disabled={disabled}>
            <span className={fill ? "flex-grow flex flex-col" : "mr-6"}>
              <Switch.Label as="span" className="text-sm font-medium text-gray-900 dark:text-gray-100" passive>
                {label}
              </Switch.Label>
              {
                description &&
                <Switch.Description as="span" className="text-sm text-gray-500">
                  {description}
                </Switch.Description>
              }
            </span>
            <Switch
              checked={value}
              onChange={onChange}
              className={classNames(
                value ? 'bg-brand-blue' : 'bg-gray-200 dark:bg-gray-700',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  value ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </Switch>
          </Switch.Group>
          {
            error &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {error}
            </p>
          }
        </div>

      </div>

    </>
  );
}

//////////////////////////////////////////////////////////////////////////////////////

const TwLabelDesc = ({settings, label, description, disabled}) => {
  const spanClass = "text-sm " + (settings?.alignment !== ALIGNMENTS.right 
    ? (settings?.fill
      ? "flex-grow flex flex-col"
      : "mr-6")
    : "ml-3");
  return (
    <span className={spanClass}>
      <Switch.Label as="span" className="font-medium text-gray-900 dark:text-gray-200" passive>
        {label}
      </Switch.Label>
      {
        description && (settings?.showDescription || true) &&
        <Switch.Description as="span" className="text-gray-500">
          {description}
        </Switch.Description>
      }
    </span>
  );
}

const TwToggleGroup = ({children, settings, label, description, disabled}) => {
  return (
    <Switch.Group as="div" className={`flex items-center ${settings?.fill ? 'justify-between' : ''}`} disabled={disabled}>
      { settings?.alignment !== ALIGNMENTS.right &&
        <TwLabelDesc settings={settings} label={label} description={description} disabled={disabled} />
      }
      {children}
      { settings?.alignment === ALIGNMENTS.right &&
        <TwLabelDesc settings={settings} label={label} description={description} disabled={disabled} />
      }
    </Switch.Group>
  );
}

const ALIGNMENTS = {
  left: 'left',
  right: 'right'
};
const TYPES = {
  simple: 'simple',
  short: 'short',
  icon: 'icon'
};

const TwUiToggleSettings = (type, alignment, showDescription = false, fillRow = false ) => {
  return {
    type,
    alignment,
    showDescription,
    fillRow
  };
}

TwUiToggleSettings.alignments = ALIGNMENTS;
TwUiToggleSettings.types = TYPES;

export { TwUiToggleSettings }

export const TwToggle = ({ label, description, value, onChange, disabled, error, fill = false, ...props }) => {
  const { classNames } = useClassNames();
  const tailwindUiContext = useContext(TailwindUiContext);
  const settings = tailwindUiContext?.toggle;

  const switchClasses =
    (value ? 'bg-brand-blue' : 'bg-gray-200 dark:bg-gray-700') + 
    (settings?.type === TYPES.short
      ? ' group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2' 
      : ' relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-blue focus:ring-offset-2');
  const iconExtraClass = settings?.type === TYPES.icon ? 'relative ' : '';
  const span1Classes =
    (value ? 'translate-x-5' : 'translate-x-0') + 
    (settings?.type === TYPES.short
      ? ' pointer-events-none absolute h-full w-full rounded-md bg-white' 
      : ' pointer-events-none ' + iconExtraClass + 'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out');

  return (
    <>
      <div className="space-y-1 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3 sm:py-1" {...props}>
        <div className="sm:col-span-2">
            <TwToggleGroup settings={settings} label={label} description={description} disabled={disabled} fill={fill}>
            <Switch
              checked={value}
              onChange={onChange}
              disabled={disabled}
              className={classNames(
                switchClasses
              )}
            >
              { settings?.type !== TYPES.icon 
                ? <span aria-hidden="true" className={classNames(span1Classes)} />
                : <>
                <span aria-hidden="true" className={classNames(span1Classes)}>
                  <span
                    className={classNames(
                      value ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    className={classNames(
                      value ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
                </>
              }
              { settings?.type === TYPES.short &&
                <>
                <span
                  aria-hidden="true"
                  className={classNames(
                    value ? 'bg-indigo-600' : 'bg-gray-200 dark:bg-gray-700',
                    'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
                  )}
                />
                <span
                  aria-hidden="true"
                  className={classNames(
                    value ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
                  )}
                />
                </>
              }
            </Switch>
          </TwToggleGroup>
          {
            error &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {error}
            </p>
          }
        </div>

      </div>

    </>
  );
}
