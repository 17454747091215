import { get, post, BaseUrl } from "./api";

const controller = "ExtrasTemplateFile";

export const getAllFileInfos = async (sessionId) => {
  return await get(`api/${controller}/${sessionId}/allfileinfos`);
}

export const getFileInfo = async (sessionId, fileId) => {
  return await get(`api/${controller}/${sessionId}/fileinfo/${fileId}`);
}
