import React, { useEffect, useState, useContext } from 'react';

import { useToastAction } from '../../hooks/useToastAction.js';

import * as InteractApi from "../../api/interact.js";

import { EnvironmentGroupContext } from '..';

export const EnvironmentGroupContextProvider = ({ children }) => {
  const [ environmentGroups, setEnvironmentGroups ] = useState(null);
  const [ environmentGroupPanelEnabled, setEnvironmentGroupPanelEnabled ] = useState(true);
 
  const loadAction = useToastAction();

  let loadingEnvironmentGroups = false;
   
  useEffect(() => {
    //console.log("EnvironmentGroupContextProvider", "useEffect[]", "calling loadEnvironmentGroups");
    loadEnvironmentGroups();
  }, []);

  useEffect(() => {
    //console.log("EnvironmentGroupContextProvider", "useEffect[environmentGroups]", "environmentGroups", environmentGroups);
  }, [environmentGroups]);

  const loadEnvironmentGroups = async (callback) => {
    if (!loadingEnvironmentGroups) {
      loadingEnvironmentGroups = true;

      try {
        //console.log("EnvironmentGroupContextProvider", "loadEnvironmentGroups");

        const groups = await InteractApi.getEnvironmentGroupsByCustomerId();
        setEnvironmentGroups(groups);

        if (!!callback)
          callback(groups);

        //console.log("EnvironmentGroupContextProvider", "loadEnvironmentGroups", "groups", groups);
      }
      finally
      {
        loadingEnvironmentGroups = false;
      }
      // console.log("EnvironmentGroupContextProvider", "loadEnvironmentGroups");
      // InteractApi.getEnvironmentGroupsByCustomerId().then((groups) => {
      //   setEnvironmentGroups(groups);
      //   loadingEnvironmentGroups = false;
      //   if (!!callback)
      //     callback(groups);
      //   console.log("EnvironmentGroupContextProvider", "loadEnvironmentGroups", "groups", groups);
      // });
    }
  }

  const dispatchEnvironmentGroupsReloadEvent = (callback) => {
    //console.log("EnvironmentGroupContextProvider", "dispatchEnvironmentGroupsReloadEvent", "calling loadEnvironmentGroups");
    loadEnvironmentGroups(callback);
  }

  const dispatchEnvironmentGroupsChangeEvent = (environmentGroups) => {
    setEnvironmentGroups(environmentGroups);
  }

  const dispatchEnableEnvironmentGroupPanelEvent = (enable) => {
    setEnvironmentGroupPanelEnabled(enable);
  }

  return (
    <EnvironmentGroupContext.Provider
      value={{
        environmentGroups,
        dispatchEnvironmentGroupsChangeEvent,
        dispatchEnvironmentGroupsReloadEvent,
        environmentGroupPanelEnabled,
        dispatchEnableEnvironmentGroupPanelEvent
      }}
    >
      {children}
    </EnvironmentGroupContext.Provider>
  );
}
