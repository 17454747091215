import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { InteractStatusContext } from '../../../contexts';

import { useToastAction } from '../../../hooks/useToastAction.js';
import { useEnvironmentGroup } from '../../../hooks/useEnvironmentGroup.js';
import { useTemplateServerUpdate } from '../../../hooks/useTemplateServerUpdate.js';

import * as TemplateApi from "../../../api/interactTemplateEnvironment.js";
import * as InteractApi from "../../../api/interact.js";
import * as DeploymentTemplateApi from "../../../api/deployTemplate.js";
import * as DeploymentApi from "../../../api/deployment.js";

import WizardStepUserInput, {wizardStepUserInput_LoadRequiredData, wizardStepUserInput_UpdateProps} from './WizardSteps/deploymentUserInput.js'
import WizardStepKubernetesDeploy from './WizardSteps/deploymentKubernetesDeploy.js'
import WizardStepResult from './WizardSteps/deploymentResult.js'

import { Panel as CmpPanel } from "../../../components/Panel/index.js";
import Wizard, {WizardNav} from '../../../components/Wizard/index.js'
import { TwPage } from "../../../components/TailwindPage/index.js";

export const setTemporatyDeploymentIdToServer = async (tplEnvironment, tplServerId, deploymentId) => {
  console.log("setTemporatyDeploymentIdToServer", "tplEnvironment", tplEnvironment, "tplServerId", tplServerId, "deploymentId", deploymentId);

  const server = tplEnvironment.servers.find(s => s.serverId === tplServerId);

  console.log("setTemporatyDeploymentIdToServer", "server", server);

  if (server.status === 5) {
    server.deploymentSessionId = deploymentId || "";
    if (!!server.deploymentSessionId)
      server.status = 0;
    const interactTemplateResult = await TemplateApi.updateInteractTemplateEnvironment(tplEnvironment);
  }
}

export default function EnvironmentsCreateDeploymentSrv() {
  const { settingsId, update, serverId } = useParams();
  const history = useHistory();
  const { serversNeedUpdate, latestImageVer, updatedServer } = useTemplateServerUpdate();

  const loadAction = useToastAction();

  const { dispatchUpdateInteractEnvironments } = useContext(InteractStatusContext);

  // const { currentEnvironmentGroupId } = useEnvironmentGroup();

  const isUpdate = update === "1" && !!serverId;
  const [wizardData, setWizardData] = useState({action: isUpdate ? "update" : "create"});
  const [wizardCurrentStep, setWizardCurrentStep] = useState(1);

  const refWizard = useRef();

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      const tplEnvironment = await TemplateApi.getInteractTemplateEnvironmentNoFiles(settingsId);
      const currentEnvironmentGroupId = tplEnvironment.environmentGroupId;

      //console.log("EnvironmentsCreateDeploymentSrv", "loadBaseData", "isUpdate", isUpdate, "update", update, update === "1", "wizardData", wizardData, "currentEnvironmentGroupId", currentEnvironmentGroupId);

      updateWizardData("tplEnvironment", tplEnvironment);
      updateWizardData("currentEnvironmentGroupId", currentEnvironmentGroupId);

      if (isUpdate)
      {
        const templates = await DeploymentTemplateApi.getDeploymentTemplates();

        const tplEnvironment = await TemplateApi.getInteractTemplateEnvironment(settingsId);
        const server = tplEnvironment.servers.find(s => s.serverId === serverId);
        
        const data = await wizardStepUserInput_LoadRequiredData(currentEnvironmentGroupId);

        const depData = await DeploymentApi.getDeploymentSession(server.deploymentSessionId);

        updateWizardData("server", server);
        const deploymentName = depData.deploymentName;
        updateWizardData("deploymentName", deploymentName);

        const ceNum = Number(tplEnvironment.customerEnvironment);
        const serverIdentifier = "";
        const soapSettingsTmp = data.allSoapSettings.find(settings => Number(settings.customerEnvironment) === ceNum);
        const customerEnvironment = Number(tplEnvironment?.customerEnvironment ?? 0);

        const extraKeyValueMap = [
          {key: "tplEnvironment", value: tplEnvironment},
          {key: "server", value: server},
        ]

        const tpl = templates.find(x => x.id === depData.templateId) || templates[0];
        
        wizardStepUserInput_UpdateProps({data: wizardData, update: updateWizardData},
          currentEnvironmentGroupId,
          data.customerEnvironments,
          tpl,
          deploymentName,
          serverIdentifier,
          soapSettingsTmp,
          extraKeyValueMap
        );
        
        // This must be set last as an useEffect in deploymentUserInput reacts on it
        updateWizardData("EnvironmentsCreateDeploymentSrv", "loadBaseData", "tplEnvironment", tplEnvironment);
        setWizardCurrentStep(2);
      } else {
        const serverId = uuidv4();
        updateWizardData("tplServerId", serverId);
        tplEnvironment.servers.push({
          serverId: serverId,
          name: `${serverId}`,
          description: `Pages server in Kubernetes container`,
          type: 1,
          status: 5, // Deploying
          enabled: false,
          deploymentSessionId : ""
        });
    
        const interactTemplateResult = await TemplateApi.updateInteractTemplateEnvironment(tplEnvironment);
        //tplEnvironment = interactTemplateResult.object;
      }
    }, "Failed to load")
  }

  useEffect(async () => {
    await loadBaseData();
  }, []);

  const handleStepChange = (stats) => {
    // console.log("handleStepChange", "stats.activeStep", stats.activeStep);
    setWizardCurrentStep(stats.activeStep);
  }

  const updateWizardData = (key, value) => 
  {
    setWizardData(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  
  const onBeforeClose = async () => {
    // console.log("onBeforeClose", "wizardCurrentStep", wizardCurrentStep);
  }

  const onDeployError = async (props) => {
    const data = props.data;
    if (!isUpdate && !!data.tplEnvironment && !!data.tplServerId) {
      data.tplEnvironment.servers = data.tplEnvironment.servers.filter(s => s.serverId != data.tplServerId);

      await TemplateApi.updateInteractTemplateEnvironment(data.tplEnvironment);

      await dispatchUpdateInteractEnvironments();
    }
  }

  const onDeploymentIdSet = async (props, deploymentId) => {
    const data = props.data;
    if (!isUpdate && !!data.tplEnvironment && !!data.tplServerId) {
      await setTemporatyDeploymentIdToServer(data.tplEnvironment, data.tplServerId, deploymentId);
    }
  }

  const postDeploy = async (props, deploymentResult) => {
    const data = props.data;

    // console.log("EnvironmentsCreateDeploymentSrv", "loadBaseData", "isUpdate", isUpdate, "update", update, update === "1", "wizardData", wizardData);

    let interactTemplate = data.tplEnvironment;

    if (isUpdate)
    {
      props.update("deploymentResult", deploymentResult);
      props.update("soapAction", "Updated");
      props.update("templateAction", "Updated");
      props.update("interactTemplate", interactTemplate);

      updatedServer(data.currentEnvironmentGroupId, data.server);
      return;
    } else {
      if (!!data.tplEnvironment && !!data.tplServerId)
        await setTemporatyDeploymentIdToServer(data.tplEnvironment, data.tplServerId, "");
    }

    // Update the newly created server
    const server = interactTemplate.servers.find(s => s.serverId === data.tplServerId);
    if (!!server) {
      server.name = deploymentResult.interactResult.computerName;
      server.description = `Pages server (version ${deploymentResult.interactResult.engineVersion}) in Kubernetes container`;
      server.deploymentSessionId = deploymentResult.savedDbSessionId
      server.enabled = true;

      await TemplateApi.updateInteractTemplateEnvironment(interactTemplate);
    }

    let soapSettings = data.soapSettings;
    soapSettings.endpoint = deploymentResult.sessionResult.endpoint;

    const soapSettingsNew = await InteractApi.updateSoapSettings(soapSettings);
    await dispatchUpdateInteractEnvironments();
    
    props.update("deploymentResult", deploymentResult);
    props.update("soapAction", "Updated");
    props.update("templateAction", "Updated");
    props.update("interactTemplate", interactTemplate);
  }

  const wizardSteps = isUpdate ? [WizardStepUserInput, WizardStepKubernetesDeploy, WizardStepResult] : [WizardStepUserInput, WizardStepKubernetesDeploy, WizardStepResult];

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed title={isUpdate ? "Upgrade the PAges Kubernetes Container" : "Deploy Pages Kubernetes Container and Add Server"} />
        </TwPage.Header>
        
        <CmpPanel>
          <Wizard
            ref={refWizard}
            nav={
              <WizardNav 
                steps={wizardSteps}
                currentStep={wizardCurrentStep}
              />
            }
            onStepChange={handleStepChange}
            isLazyMount
          >
            <WizardStepUserInput
              currentStep={wizardCurrentStep}
              data={wizardData}
              update={updateWizardData}
           />
            <WizardStepKubernetesDeploy
              data={wizardData}
              update={updateWizardData}
              onDeploymentIdSet={onDeploymentIdSet}
              postDeploy={postDeploy}
              onDeployError={onDeployError}
              currentStep={wizardCurrentStep}
              onBeforeClose={onBeforeClose}
          />
            <WizardStepResult
              data={wizardData}
              history={history}
              currentStep={wizardCurrentStep}
              onBeforeClose={onBeforeClose} 
          />
          </Wizard>
        </CmpPanel>

        </TwPage>
    </>
  );
}
