import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from 'react-router-dom';

import { useToastAction } from '../../../../hooks/useToastAction';

import { OidcRoutesContext, CurrentDataContext, EnvironmentGroupContext } from '../../../../contexts';

import * as DeploymentTemplateApi from "../../../../api/deployTemplate";
import * as DeploymentApi from "../../../../api/deployment";
import * as InteractApi from "../../../../api/interact";
import * as InteractEnvironmentApi from "../../../../api/interactEnvironment";

import { Select as CmpSelect } from "../../../../components/Select";
import { Button as CmpButton } from "../../../../components/Button";
import { Textbox as CmpTextbox } from "../../../../components/Textbox";
import { Label as CmpLabel, TwCollapsableLabel } from "../../../../components/Label";
import { Panel as CmpPanel } from "../../../../components/Panel";

const WizardStepUserInput = props => {
  const history = useHistory();

  const {currentDataId, currentDataPayload} = useContext(CurrentDataContext);
  const oidcRoutesContext = useContext(OidcRoutesContext);
  const environmentGroupContext = useContext(EnvironmentGroupContext);

  const loadAction = useToastAction();

  const [errors, setErrors] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [allCustomerEnvironments, setAllCustomerEnvironments] = useState([])
  const [allSoapSettings, setAllSoapSettings] = useState([]);
  const [soapSettings, setSoapSettings] = useState(null);
  const [allDeploymentTemplates, setAllDeploymentTemplates] = useState([]);
  const [deploymentTemplate, setDeploymentTemplate]= useState(null);
  const [companyAbbreviation, setCompanyAbbreviation] = useState(null);
  const [hasCompanyAbbreviation, setHasCompanyAbbreviation] = useState(true);
  const [envGrpAbbreviation, setEnvGrpAbbreviation] = useState(null);
  const [hasEnvGrpAbbreviation, setHasEnvGrpAbbreviation] = useState(true);
  const [environmentOptions, setEnvironmentOptions] = useState([]);
  const [serverIdentifier, setServerIdentifier]= useState(null);
  const [forceContinue, setForceContinue]= useState(false);
  const [gotoNextStep, setGotoNextStep]= useState(false);

  const abbrTypeCmp = 0;    // 0 = DeploymentAbbreviationType.CompanyName
  const abbrTypeEnvgrp = 1; // 1 = DeploymentAbbreviationType.EnvironmentGroupName

  const minCmpAbbrChars = 3;
  const maxCmpAbbrChars = 6;
  const minEnvGrpAbbrChars = 3;
  const maxEnvGrpAbbrChars = 6;

  const currentEnvironmentGroupId = props.data.currentEnvironmentGroupId;
  const currentEnvironmentGroup = environmentGroupContext.environmentGroups.find(g => g.id === currentEnvironmentGroupId);

  useEffect(async () => {
    if (!props.isActive)
      return;

    loadAction.execute(async () => {
      if (props.isActive && !!currentEnvironmentGroupId)
      {
        const data = await wizardStepUserInput_LoadRequiredData(currentEnvironmentGroupId);

        var cmpAbbr = data.deploymentAbbrevations.find(a => a.abbreviationType === abbrTypeCmp)?.abbreviation;
        // console.log("WizardStepUserInput", "loadAction", "cmpAbbr", cmpAbbr);
        setHasCompanyAbbreviation(!!cmpAbbr);
        if (!cmpAbbr) {
          let name = oidcRoutesContext.customerInfo.name;
          const regex = /[^a-zA-Z0-9]/g;
          if (/[ ]/.test(name)) {
            const words = name.split(' ');
            const p1 = words[0].replaceAll(regex, "").slice(0, maxCmpAbbrChars-2);
            const p2 = words[words.length - 1].slice(-2).replaceAll(regex, "");
            name = `${p1}${p2}`;
          }
          else 
            name = name.replaceAll(regex, "").slice(0, maxCmpAbbrChars);
          cmpAbbr = name;
        }
        setCompanyAbbreviation(cmpAbbr);

        var envGrpAbbr = data.deploymentAbbrevations.find(a => a.abbreviationType === abbrTypeEnvgrp && a.itemId === currentEnvironmentGroupId)?.abbreviation;
        // console.log("WizardStepUserInput", "loadAction", "envGrpAbbr", envGrpAbbr);
        setHasEnvGrpAbbreviation(!!envGrpAbbr);
        if (!envGrpAbbr) {
          let name = currentEnvironmentGroup.name;
          if (currentEnvironmentGroup.isStandard)
            name = "Std";
          else {
            const regex = /[^a-zA-Z0-9]/g;
            if (/[ ]/.test(name)) {
              const words = name.split(' ');
              const p1 = words[0].replaceAll(regex, "").slice(0, maxEnvGrpAbbrChars-2);
              const p2 = words[words.length - 1].slice(-2).replaceAll(regex, "");
              name = `${p1}${p2}`;
            }
            else 
              name = name.replaceAll(regex, "").slice(0, maxEnvGrpAbbrChars);
          }
          envGrpAbbr = name;
        }
        setEnvGrpAbbreviation(envGrpAbbr);

        setAllCustomerEnvironments(data.allEnvironments);
        setEnvironmentOptions(data.customerEnvironments);
        setSoapSettings(data.currSoapSetting);
        setAllSoapSettings(data.allSoapSettings);  // This must be set after setSoapSettings
        setAllDeploymentTemplates(data.deploymentTemplates);

        if (data.deploymentTemplates.length > 0)
          setDeploymentTemplate(data.deploymentTemplates[0]);

        if (props.data.action === "update")
        {
          //setForceContinue(true);
        }

        setLoaded(true);
      }
    }, "Failed to load (WizardStepUserInput)")
  }, [props.isActive, currentEnvironmentGroupId]);

  useEffect(async () => {
    //console.log("WizardStepUserInput", "useEffect[props.data.tplEnvironment, allSoapSettings]", "props.data.tplEnvironment", props.data.tplEnvironment, "allSoapSettings", allSoapSettings);
    if (!!props.data.tplEnvironment && allSoapSettings.length > 0) {
      const settings = allSoapSettings.find(s => Number(s.customerEnvironment) === Number(props.data.tplEnvironment.customerEnvironment))
      if (settings)
      {
        console.log("WizardStepUserInput", "useEffect[props.data.tplEnvironment, allSoapSettings]", "settings", settings);
        setSoapSettings(settings);

        //console.log("WizardStepUserInput", "useEffect[props.data.tplEnvironment, allSoapSettings]", "props", props);
        if (props.data.action === "update")
        {
          //console.log("WizardStepUserInput", "useEffect[props.data.tplEnvironment, allSoapSettings]", "executing onContinue()");
          await onContinue();
        }
      }
    }
  }, [props.data.tplEnvironment, allSoapSettings]);

  useEffect(async () => {
    const ce = Number(soapSettings?.customerEnvironment || -1);
    var cen = allCustomerEnvironments.find(e => e.value === ce)?.name;
    if (!cen)
      return;

    let name = oidcRoutesContext.customerInfo.name;
    const regex = /[^a-zA-Z0-9]/g;
    if (/[ ]/.test(name)) {
      const words = name.split(' ');
      const p1 = words[0].replaceAll(regex, "").slice(0, 4);
      const p2 = words[words.length - 1].slice(-2).replaceAll(regex, "");
      name = `${p1}${p2}`;
    }
    else 
      name = name.replaceAll(regex, "").slice(0, 6);

    const found = cen.match(/[A-Z0-9]/g);
    if (found.length > 1)
    {
      const letters = found.map(c => {
        const idx = cen.indexOf(c);
        return ({letter: c, index: idx, isNum: /[0-9]/g.test(c)});
      });

      let newCen = "";
      letters.map((itm, index, data) => {
        const sliceCnt = (index < (data.length - 1) ? (data[index + 1].isNum ? 3 : 2) : 2);
        const part = cen.slice(itm.index, itm.index + (itm.isNum ? 1 : sliceCnt));
        newCen += part;
      });
      cen = newCen;
    }
    else
      cen = cen.slice(0, 4);

    if (/[a,e,i,o,u,y]/g.test(cen.slice(-1)))
      cen = cen.slice(0, cen.length - 1);

    // console.log("setcompanyAbbreviation", `${name}`.toLowerCase())
    //setServerIdentifier(`${name}`.toLowerCase());
    // setCompanyAbbreviation(`${name}`.toLowerCase());
  }, [soapSettings]);

  useEffect(() => {
    if (forceContinue)
    {
      onContinue();
      setForceContinue(false);
    }
  }, [forceContinue]);
  
  const onChange = ({ name, value }, isPassword = false ) => {
    if (isPassword)
    {
      setSoapSettings(prevState => ({
        ...prevState,
        ["certificatePassword"]: {...prevState.certificatePassword, ["valueChanged"]: !!value}
      }));
      setSoapSettings(prevState => ({
        ...prevState,
        ["certificatePassword"]: {...prevState.certificatePassword, ["value"]: value}
      }));
    } else {
      setSoapSettings(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const onBack = () => {
    if (currentDataId === "history" && !!currentDataPayload)
      history.push(currentDataPayload);
  }

  const onContinue = async () => {
    //console.log("WizardStepUserInput", "onContinue", "props", props, "deploymentTemplate", deploymentTemplate, "currentEnvironmentGroupId", currentEnvironmentGroupId);

    if (!oidcRoutesContext.customerInfo.customerId || !deploymentTemplate || !currentEnvironmentGroupId)
      return;

    let envId = soapSettings.customerEnvironment;
    let soapSettingsTmp = soapSettings;
    let customerEnvironment = Number(soapSettings?.customerEnvironment ?? 0);
    if (props.data.tplEnvironment)
    {
      const ceNum = Number(props.data.tplEnvironment.customerEnvironment);
      soapSettingsTmp = allSoapSettings.find(settings => Number(settings.customerEnvironment) === ceNum);
      customerEnvironment = ceNum;
      envId = ceNum;
    }
    const deploymentName = `${companyAbbreviation}-${envGrpAbbreviation}-${envId}`;

    if (!hasCompanyAbbreviation) {
      try {
        await DeploymentApi.setDeploymentAbbrevation(companyAbbreviation, abbrTypeCmp); // 0 = DeploymentAbbreviationType.CompanyName
        setErrors(prevState => ({
          ...prevState,
          ["companyAbbreviation"]: null
        }));
      }
      catch (err) {
        console.log("WizardStepUserInput", "onContinue", "err", err);
        setErrors(prevState => ({
          ...prevState,
          ["companyAbbreviation"]: err?.customErrorMessage || err?.message || "Unknown error while saving company name abbreviation"
        }));
        return;
      }
    }
    if (!hasEnvGrpAbbreviation) {
      try {
        await DeploymentApi.setDeploymentAbbrevation(envGrpAbbreviation, abbrTypeEnvgrp, currentEnvironmentGroupId);
        setErrors(prevState => ({
          ...prevState,
          ["envGrpAbbreviation"]: null
        }));
      }
      catch (err) {
        setErrors(prevState => ({
          ...prevState,
          ["envGrpAbbreviation"]: err?.customErrorMessage || err?.message || "Unknown error while saving environment group name abbreviation"
        }));
        // setErrors(prevState =>{
        //   console.log("prevState", prevState);
        //   return ({
        //   ...prevState,
        //   ["envGrpAbbreviation"]: err ?? "Unknown error while saving environment group name abbreviation"
        // });});
        return;
      }
    }

    wizardStepUserInput_UpdateProps(props,
      currentEnvironmentGroupId,
      environmentOptions,
      deploymentTemplate,
      deploymentName,
      companyAbbreviation,
      soapSettingsTmp
    );

    setGotoNextStep(true);
  }

  useEffect(async () => {
    if (gotoNextStep)
    {
      setGotoNextStep(false);
      if (!!props.onBeforeContinue)
        await props.onBeforeContinue(props);
      props.nextStep();
    }
  }, [gotoNextStep]);

  // useEffect(() => {
  //   console.log("companyAbbreviation", companyAbbreviation);
  // }, [companyAbbreviation]);

  // useEffect(() => {
  //   console.log("envGrpAbbreviation", envGrpAbbreviation);
  // }, [envGrpAbbreviation]);

  return (props.data.action === "update" 
    ? <>
      <CmpLabel text="Loading information about deployment" additionalClass="text-base" />
    </>
    : <>
      {/* <TwCollapsableLabel label="Abbreviations" additionalClass='' defaultCollapsed={!loaded || (!!hasCompanyAbbreviation && !!hasEnvGrpAbbreviation)} collapsable={!loaded || (!!hasCompanyAbbreviation && !!hasEnvGrpAbbreviation)} > */}
      <TwCollapsableLabel label="Abbreviations" additionalClass='' collapsable={!loaded || (!!hasCompanyAbbreviation && !!hasEnvGrpAbbreviation)} >
      {/* <CmpPanel>
        <CmpLabel text="Abbreviations" additionalClass="text-base" /> */}
        {(!hasCompanyAbbreviation || !hasEnvGrpAbbreviation) && (
          <CmpLabel type={CmpLabel.types.info} additionalClass=''>
            The abbreviations is used for namespace in Kubernetes and Storage for enable a more readable namespace for the deployment
            {!hasCompanyAbbreviation && (
              <>
              <br/>
              {`The company name abbreviation must be unique and between ${minCmpAbbrChars}-${maxCmpAbbrChars} characters`}
              </>
            )}
            {!hasEnvGrpAbbreviation && (
              <>
              <br/>
              {`The environment group name abbreviation must be unique and between ${minEnvGrpAbbrChars}-${maxCmpAbbrChars} characters`}
              </>
            )}
          </CmpLabel>
        )}
        <CmpTextbox
          name="companyAbbreviation"
          value={companyAbbreviation}
          label='Company name'
          onChange={e => {setCompanyAbbreviation(e.target.value); }}
          disabled={!!hasCompanyAbbreviation || !loaded}
          error={errors.companyAbbreviation}
        />
        <CmpTextbox
          name="envGrpAbbreviation"
          value={envGrpAbbreviation}
          label='Environment group name'
          onChange={e => {setEnvGrpAbbreviation(e.target.value); }}
          disabled={!!hasEnvGrpAbbreviation || !loaded}
          error={errors.envGrpAbbreviation}
        />
        {!hasCompanyAbbreviation && (
          <CmpLabel type={CmpLabel.types.warn} additionalClass='mt-4'>
            The abbreviations is saved in the database and cannot be changed later on
          </CmpLabel>
        )}
      {/* </CmpPanel> */}
      </TwCollapsableLabel>

      {!props.data.tplEnvironment && (
        <CmpSelect
          name="customerEnvironment"
          value={soapSettings?.customerEnvironment}
          label={"Customer Environment"}
          onChange={e => onChange(e.target)}
          error={errors.customerEnvironment}
          options={environmentOptions}
        />
      )}
      {allDeploymentTemplates && allDeploymentTemplates.length > 1 && (
        <CmpSelect
            name="deploymentTemplate"
            value={deploymentTemplate}
            label={"Deployment Template"}
            onChange={e => setDeploymentTemplate(e.target.value)}
            error={errors.deploymentTemplate}
            options={allDeploymentTemplates}
        />
      )}

      <div className="flex mt-8">
        <div className="flex-1" />
          <CmpButton
            variant={CmpButton.variants.secondary}
            className='w-40 mr-4'
            onClick={onBack}
          >
            Back
          </CmpButton>
          <CmpButton
            variant={CmpButton.variants.primary}
            className='w-40'
            disabled={!companyAbbreviation || !envGrpAbbreviation || (!hasCompanyAbbreviation && (companyAbbreviation.length < minCmpAbbrChars || companyAbbreviation.length > maxCmpAbbrChars))}
            // disabled={!hasCrudAccess}
            onClick={onContinue}
          >
            Continue
          </CmpButton>
        <div className="flex-1" />
      </div>
    </>
  );
}

export default WizardStepUserInput;


export const wizardStepUserInput_LoadRequiredData = async (environmentGroupId) => {

  // console.log("wizardStepUserInput_LoadRequiredData", "environmentGroupId", environmentGroupId);

  const allEnvironments = await InteractApi.getInteractEnvironments();
  const customerEnvironments = await InteractEnvironmentApi.getUnusedEvironments(environmentGroupId);

  //console.log("wizardStepUserInput_LoadRequiredData", "allEnvironments", allEnvironments, "customerEnvironments", customerEnvironments);

  const deploymentAbbrevations = await DeploymentApi.getDeploymentAbbrevations();

  // console.log("wizardStepUserInput_LoadRequiredData", "deploymentAbbrevations", deploymentAbbrevations);

  const allSoapSettings = await InteractApi.getSoapSettingsByCustomerId(environmentGroupId);

  // console.log("wizardStepUserInput_LoadRequiredData", "allSoapSettings", allSoapSettings);

  const currSoapSetting = {
      endpoint: "",
      certificate: null,
      certificatePassword: {value: "", hasValue: false, valueChanged: false},
      certificateEnabled: false,
      customerEnvironment: "",
      forcePDFAOnAllDocuments: false,
      selected: false,
      environmentGroupId: environmentGroupId
    };

  if (!currSoapSetting.id && customerEnvironments.length > 0) {
    currSoapSetting.customerEnvironment = customerEnvironments[0].value;
    currSoapSetting.certificateEnabled = false;
  }

  // console.log("wizardStepUserInput_LoadRequiredData", "currSoapSetting", currSoapSetting);

  const deploymentTemplates = await DeploymentTemplateApi.getDeploymentTemplates();

  // console.log("wizardStepUserInput_LoadRequiredData", "deploymentTemplates", deploymentTemplates);

  return {
    allEnvironments: allEnvironments,
    allSoapSettings: allSoapSettings,
    currSoapSetting: currSoapSetting,
    customerEnvironments: customerEnvironments,
    deploymentTemplates: deploymentTemplates,
    deploymentAbbrevations: deploymentAbbrevations
  };
}

export const wizardStepUserInput_UpdateProps = (props,
  environmentGroupId,
  environmentOptions,
  deploymentTemplate,
  deploymentName,
  companyAbbreviation,
  soapSettings,
  extraKeyValueMap = null) => {

  props.update("template", deploymentTemplate);
  props.update("deploymentName", deploymentName);
  props.update("soapSettings", soapSettings);
  props.update("customerEnvironment", Number(soapSettings.customerEnvironment));
  props.update("currentEnvironmentGroupId", environmentGroupId);
  props.update("environmentOptions", environmentOptions);
  props.update("companyAbbreviation", companyAbbreviation.toLowerCase());

  if (!!extraKeyValueMap)
    extraKeyValueMap.map(kv => props.update(kv.key, kv.value));
}