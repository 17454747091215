import { get, post, put, download, destroy } from "./api";

const controller = 'DeploymentTemplate';

export const getDeploymentTemplates = async () => {
  return await get(`${controller}/all`);
}

export const deleteDeploymentTemplate = async (templateId) => {
  return await destroy(`${controller}/${templateId}`);
}

export const getDeploymentTemplateItems = async () => {
  return await get(`${controller}/items/all`);
}

export const deleteDeploymentTemplateItem = async (itemId) => {
  return await destroy(`${controller}/items/${itemId}`);
}

export const resetDeploymentTemplates = async () => {
  return await destroy(`${controller}/reset`);
}

export const setForceUpdate = async () => {
  return await destroy(`${controller}/fupd`);
}
