import { useClassNames } from "../../hooks/useClassNames";

import { Button as CmpButton } from "../../components/Button";

export const TimeLineList = ({timeline}) => {
  const { classNames } = useClassNames();

  return (
    <div className="w-full flow-root px-10 lg:px-20">
      <ul className="-mb-8">
        {timeline.map((event, eventIdx) => (
          <li key={event.id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200 dark:bg-gray-600" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      event.iconBackground,
                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-gray-600'
                    )}
                  >
                    <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                  </span>
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      <span className="text-black dark:text-gray-100">{event.target}</span> {' '}{event.content}
                    </p>
                    {
                      event?.changes?.length > 0 &&
                      <div className="mt-1">
                        <CmpButton
                          size={CmpButton.sizes.xs}
                          variant={CmpButton.variants.secondary}
                          onClick={() => event.onClick()}
                        >
                          See changes
                        </CmpButton>
                        </div>
                    }
                  </div>
                  <div className="text-right text-sm whitespace-nowrap text-gray-500 dark:text-gray-400">
                    <time dateTime={event.datetime}>{event.date}</time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>  
  );
}