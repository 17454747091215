import { validateExtrasSession } from '../api/extrasSession.js'

const keyLs = 'extrasSessionId';

export  const getSessionFromLs = () => {
  var sessionId = localStorage.getItem(keyLs) || "";
  return sessionId;
}

export const setSessionToLs = (sessionId, exFunc) => {
  localStorage.setItem(keyLs, sessionId);
  if (exFunc)
    exFunc(sessionId);
}

export const setSessionIfExistsinLs = async (exFunc) => {
  var sessionId = getSessionFromLs();
  var isActive = sessionId?.length > 0;
  var isValid = isActive ? await validateExtrasSession(sessionId) : false;
  if (isValid) {
    setSessionToLs(sessionId, exFunc);
  } else {
    setSessionToLs("", exFunc);
  }
}