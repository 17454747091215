import React, { useRef, useState, useEffect } from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { Badge, useClassNames, useModalHelper } from "@metaforcelabs/metaforce-core";

import { TwPage } from "../../../components/TailwindPage";
import { Label } from "../../../components/Label";
import MenuContextListV2 from "../../../components/MenuContextListV2/index.js";
import { Menu } from "@headlessui/react";

export const ContainerCard = ({
  children,
  headerRef,
  ...props }) => {

  return (
    <div className="overflow-hidden rounded-lg bg-white dark:bg-gray-700 shadow" ref={headerRef} {...props}>
      {children}
    </div>
  )
}

export const CollapsableContainerCard = ({
  children,
  headerJsx,
  buttonsJsx,
  headerRef,
  defaultCollapsed,
  onCollapseChanged,
  collapsable = true,
  ...props }) => {

  const { classNames } = useClassNames();
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  useEffect(() => {
    if (!!onCollapseChanged)
      onCollapseChanged(isCollapsed);
  }, [isCollapsed]);
 
  const handleCollapse = () => {
    setIsCollapsed(prev => !prev);
  }

  return (
    <ContainerCard headerRef={headerRef} {...props}>
      <div className="px-4 py-3 sm:px-6" >
        <div className="px-4 sm:px-6" >
          {/* <div className="-mt-4 -ml-4 mb-4 font-bold text-gray-500 text-lg">Group</div> */}
          <div className="-ml-4 flex flex-wrap items-center justify-between sm:flex-nowrap text-gray-900 dark:text-gray-300">
            <div className="flex items-center cursor-pointer mb-2 -mt-3"
              title={'Click to expand/collapse'}
              onClick={handleCollapse}
            >
              <div className="pr-4 mt-5">
                {collapsable && (<ChevronRightIcon className={classNames("h-5 w-5", isCollapsed ? '' : 'transform rotate-90')} />)}
                {!collapsable && (<div className="h-5 w-5" />)}
              </div>
              <div className="mt-4">
                {headerJsx}
              </div>
            </div>
            {buttonsJsx}
          </div>
        </div>
      </div>
      { !isCollapsed && (
        <>
          <div className="px-4 pb-5">
            {children}
          </div>
        </>
      )}
    </ContainerCard>
  )
}

export const EnvironmentGroupContainerCard = ({
  children,
  group,
  defaultCollapsed,
  visibleSettings,
  editableSettings,
  onCollapseChanged,
  collapsable = true,
  ...props }) => {

  const headerRef = useRef(null);
  const showActiveState = !!visibleSettings ? visibleSettings.showActiveState : true;
  const { classNames } = useClassNames();

//editableSettings.getGroupActions
//editableSettings.getCreateActions
//editableSettings.onEdit
//editableSettings.onToggleGroupStatus
//editableSettings.showAddEnvBtn
//editableSettings.getCustomButtons

  // useEffect(() => {
  //   console.log("EnvironmentGroupContainerCard", "editableSettings", editableSettings, "group", group);
  // }, [])

  const headerJsx = <>
    <div className="flex space-x-4 items-end">
      <h3 className={classNames("text-md font-semibold leading-6", group?.isVirtual === true ? "bg-yellow-300 text-black -ml-2 px-2 text-opacity-80" : "text-black dark:text-gray-500 text-opacity-50")}>
        {group?.isVirtual === true ? "Virtual Group" : "Group"}
      </h3>
      <div className="flex-1" />
    </div>
    <div className="flex space-x-4 items-end">
      <h3 className="font-semibold leading-6 text-2xl mt-1 flex">
        {group.name}
      </h3>
      {group?.isVirtual && (
        <Label text={`[${group.virtualEndpoint}]`} />
      )}
      { showActiveState && (
        group.isActive ? (<Badge text={"Active"} type="success" />) : (
          <Badge text={"Disabled"} type="default" />
        )
      )}
    </div>
  </>;

  const buttonsJsx = 
  <>
    <div className="flex">
      { editableSettings?.getCustomButtons && editableSettings.getCustomButtons()?.length > 0 && (
        <div className="-mr-5">
          <TwPage.Header.Hdr.Buttons>
            <div className="flex items-center">
              { editableSettings.getCustomButtons().map((jsx) => (
                <>{jsx}</>
              ))}
            </div>
          </TwPage.Header.Hdr.Buttons>
        </div>
      )}
      { editableSettings 
        && editableSettings.getGroupActions && editableSettings.getGroupActions().length > 0 
        && (!editableSettings.showAddEnvBtn || (editableSettings.showAddEnvBtn && editableSettings.getCreateActions && editableSettings.getCreateActions().length > 0)) 
        && (
        <div className="-mr-5">
          <TwPage.Header.Hdr.Buttons>
            <div className="flex items-center">
              <div className="" />
              {
                editableSettings.showAddEnvBtn && group.isActive && (
                  <MenuContextListV2
                    actions={editableSettings.getCreateActions().filter(x => x != null)}
                    menuButton={() => (
                    <Menu.Button className="text-sm font-semibold focus:z-10 inline-flex items-center rounded-md px-3 py-2 bg-brand-blue hover:bg-brand-blue-hover text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 sm:mt-0 sm:text-sm xs:text-xs">
                        <span className="sr-only">Open user menu</span>
                        Add Environment
                    </Menu.Button>)}
                    deleteType={`environment group '${group.name}'`}
                    //disabled={!hasFeature(environmentsConfigurationFeatureCode)}
                  />
                )
              }
              <div className="mt-1">
                <MenuContextListV2
                  actions={
                    editableSettings.getGroupActions()
                  }
                  deleteType={`environment group '${group.name}'`}
                  //refContainer={headerRef}
                />
              </div>
            </div>
          </TwPage.Header.Hdr.Buttons>
        </div>
      )}
    </div>
  </>;

  return (
    <CollapsableContainerCard
      headerRef={headerRef}
      headerJsx={headerJsx}
      buttonsJsx={buttonsJsx}
      defaultCollapsed={defaultCollapsed}
      onCollapseChanged={onCollapseChanged}
      collapsable={collapsable}
      {...props}
    >
      {children}
    </CollapsableContainerCard>
  );
}
