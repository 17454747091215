import { get, post, BaseUrl } from "./api";

export const createExtrasSession = async (password) => {
  return await get(`api/ExtrasSession/create/${password}`);
}

export const validateExtrasSession = async (sessionId) => {
  return await get(`api/ExtrasSession/validate/${sessionId}`);
}

export const closeExtrasSession = async (sessionId) => {
  return await get(`api/ExtrasSession/close/${sessionId}`);
}