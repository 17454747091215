import React, { useEffect } from "react";
import { findMatchedKey } from './keyEvents';

let exclusiveHandlers = [];

const KeyboardEventHandler  = ({
  handleKeys = [],
  handleEventType = 'keydown',
  onKeyEvent = () => null,
  handleFocusableElements = false,
  isDisabled = false,
  isExclusive = false,
  allowOnlyChildEventForChildren = true,
  children = null
}) => {

  let childrenContainer = null;

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('keydown', handleKeyboardEvent, false);
      document.addEventListener('keyup', handleKeyboardEvent, false);
      document.addEventListener('keypress', handleKeyboardEvent, false);

      if (isExclusive && !isDisabled) {
        registerExclusiveHandler();
      }
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('keydown', handleKeyboardEvent, false);
        document.removeEventListener('keyup', handleKeyboardEvent, false);
        document.removeEventListener('keypress', handleKeyboardEvent, false);
      }
  
      deregisterExclusiveHandler();
    }
  }, []);

  const registerExclusiveHandler = () => {
    deregisterExclusiveHandler();
    exclusiveHandlers.unshift(this);
  }

  const deregisterExclusiveHandler = () => {
    if (exclusiveHandlers.includes(this)) {
      exclusiveHandlers = exclusiveHandlers.filter(h => h !== this);
    }
  }

  const handleKeyboardEvent = (event) => {
    //µconsole.log("KeyboardEventHandler", "handleKeyboardEvent", "event", event);

    if (isDisabled) {
      return false;
    }

    const isEventTypeMatched = handleEventType === event.type;

    // console.log("KeyboardEventHandler", "handleKeyboardEvent", "isEventTypeMatched", isEventTypeMatched);

    if (!isEventTypeMatched) {
      return false;
    }

    const exclusiveHandlerInPlace = exclusiveHandlers.length > 0;
    const isExcluded = exclusiveHandlerInPlace && exclusiveHandlers[0] !== this;

    // console.log("KeyboardEventHandler", "handleKeyboardEvent", "exclusiveHandlerInPlace", exclusiveHandlerInPlace, "isExcluded", isExcluded);

    if (isExcluded) {
      return false;
    }

    // console.log("KeyboardEventHandler", "handleKeyboardEvent", "childrenContainer", childrenContainer);

    const isEligibleEvent = event.target === document.body || handleFocusableElements;
    const isChildrenEvent = childrenContainer && childrenContainer.contains(event.target);
    const isValidSource = children ? (allowOnlyChildEventForChildren ? isChildrenEvent : isChildrenEvent || isEligibleEvent) : isEligibleEvent;

    // console.log("KeyboardEventHandler", "handleKeyboardEvent", "isEligibleEvent", isEligibleEvent, "isChildrenEvent", isChildrenEvent, "isValidSource", isValidSource);

    if (!isValidSource) {
      return false;
    }

    const matchedKey = findMatchedKey(event, handleKeys);

    //console.log("KeyboardEventHandler", "handleKeyboardEvent", "matchedKey", matchedKey);

    if (matchedKey) {
      onKeyEvent(matchedKey, event);
      return true;
    }

    return false;
  }

  return children 
    ? (
      <span ref={ e => {
        childrenContainer = e;
      }}>
        {children}
      </span>
    ) 
    : null;
}

export default KeyboardEventHandler;