
import { get, post, destroy, BaseUrl} from './api';

export const getAttributesForEnvironment = async (environment, environmentGroupId) => {
    return await get(!!environmentGroupId 
        ? `api/attributestore/document/g/${environmentGroupId}/${environment}`
        : `api/attributestore/document/${environment}`,
    );
}
 
export const compareDocument = async (document, environmentGroupId) => {
    return await get(!!environmentGroupId 
        ? `api/attributestore/document/g/${environmentGroupId}/compare/${document.id}`
        : `api/attributestore/document/compare/${document.id}`,
    );
}

export const save = async (selectedDocuments) => {
    return await post(
        `api/attributestore/document`, 
        selectedDocuments, 
    );
}

export const publish = async (environment, selectedDocuments, environmentGroupId) => {
    return await post(!!environmentGroupId 
        ? `api/attributestore/document/g/${environmentGroupId}/publish/${environment}`
        : `api/attributestore/document/publish/${environment}`, 
        selectedDocuments, 
    );
}

export const deleteDocuments = async (documentsToDelete, environmentGroupId) => {
    return await post(!!environmentGroupId 
        ? `api/attributestore/document/g/${environmentGroupId}/delete`
        : `api/attributestore/document/delete`, 
        documentsToDelete, 
    );
}

export const load = async (environment, environmentToLoad, environmentGroupId) => {
    return await post(!!environmentGroupId 
        ? `api/attributestore/document/g/${environmentGroupId}/load/${environment}/${environmentToLoad}`
        : `api/attributestore/document/load/${environment}/${environmentToLoad}`,
        null,
    );
}
