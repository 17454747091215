import { useRef } from 'react'

import GenericModal from '../../../components/Modals/genericModal';

export default function LoadConfirmation({open, setOpen, loadFromEnvironment, onSubmit}) {
  const cancelButtonRef = useRef(null);

  const handleOnSubmit = () => {
    onSubmit();
    setOpen(false);
  }

  return (
    <GenericModal open={open} setOpen={setOpen}
      onConfirm={handleOnSubmit}
      onCancel={() => setOpen(false)}
      showCancelButton={true}
      confirmButtonText={'Load'}
      title={`Load from ${loadFromEnvironment}`} >
      <div className="mt-4">
        <p className="text-sm text-gray-500">
          Are you sure you want to load all documents from <span className="font-bold">{loadFromEnvironment}</span>? 
        </p>
        <p className="mt-2 text-sm text-gray-500">
          <span className="font-bold">Note: </span>All documents from the currrent environment will be overwritten. 
        </p>
      </div>
    </GenericModal>
  )
}