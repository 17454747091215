
const VARIANTS = {
  default: 'default',
  colored: 'colored',
};

const COLORS = {
  default: 'default',
  red: 'red',
  green: 'green',
  yellow: 'yellow',
  blue: 'blue',
};

const Panel = ({ children, variant = VARIANTS.default, color = COLORS.default, additionalClass = '',  ...props }) => {
  const getColors = () => {
    switch(variant)
    {
      case VARIANTS.colored:
        break;
      case VARIANTS.default:
      default:
        color = COLORS.default;
        break;
    }
    switch(color)
    {
      case COLORS.red:
        return "`mf-panel-red ring-red-900 bg-red-200 text-red-700 dark:ring-red-900 dark:bg-red-200 dark:text-red-700";
      case COLORS.green:
        return "mf-panel-green ring-green-900 bg-green-200 text-green-700 dark:ring-green-900 dark:bg-green-200 dark:text-green-700";
      case COLORS.blue:
        return "mf-panel-green ring-blue-900 bg-blue-200 text-blue-700 dark:ring-blue-900 dark:bg-blue-200 dark:text-blue-700";
      case COLORS.yellow:
        return "mf-panel-yellow ring-yellow-900 bg-yellow-200 text-yellow-700 dark:ring-yellow-900 dark:bg-yellow-200 dark:text-yellow-700";
      case COLORS.default:
      default:
        return "mf-panel-default ring-black bg-white dark:ring-gray-700 dark:bg-gray-900";
    }
  }

  return (
    <div
    // text-sm leading-6 font-medium text-gray-900
      className={`mf-panel shadow ring-1 overflow-hidden ring-opacity-5 rounded-lg overflow-hidden ${getColors()} ${additionalClass}`}
      {...props}
    >
      <div className="px-4 py-5 sm:p-6 h-full">
        {children}
      </div>
    </div>
  );
}

Panel.variants = VARIANTS;
Panel.colors = COLORS;

export { Panel }
