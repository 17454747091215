import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import  { CheckIcon, ExclamationIcon, XCircleIcon, InformationCircleIcon } from '@heroicons/react/solid'

import { InteractStatusContext, CurrentDataContext } from '../../../contexts/index.js';

import { useFeature } from "../../../hooks/useFeature";

import { environmentsConfigurationFeatureCode } from "../../../utils/constants";

import Modal from "../../../components/Modal";
import { Button } from "../../../components/Button";
import { Panel } from "../../../components/Panel";
import { Label } from "../../../components/Label";

export default function DashboardEventModal({ modalHelper }) {
  const history = useHistory();

  const { hasFeature } = useFeature();

  const {
    latestImageVer,
    validationStatus,
    validationSeverity,
    validationObjectTypes} = useContext(InteractStatusContext);

  const { dispatchCurrentDataEvent } = useContext(CurrentDataContext);

  // Flatten the result list - We don't need the objecttype separation here
  const result = modalHelper.activeItem?.setting?.result;
  let events = [];
  if (result)
  {
    const keys = Object.keys(result).sort((a, b) => {
      return a - b;
    });
    keys.map(x => result[x].map(i => events.push(i)));
  }
  //console.log("DashboardEventModal", "events", events);

  const getPanelColor = (severity) => {
    switch (severity)
    {
      case validationSeverity.good:
        return Panel.colors.green;
      case validationSeverity.warning:
        return Panel.colors.yellow;
      case validationSeverity.info:
        return Panel.colors.blue;
      case validationSeverity.error:
        return Panel.colors.red;
    }
    return Panel.colors.default;
  }

  const getTextColorClass = (severity) => {
    return "text-gray-700";
  }

  const renderStatusIcon = (severity) => {
    // console.log("renderStatusIcon", "envGrpId", envGrpId, "setting", setting, "validationResults", validationResults);
    if (severity === validationSeverity.error)
      return <XCircleIcon className='h-5 w-5 text-gray-900'/>;
    if (severity === validationSeverity.warning)
      return <ExclamationIcon className='h-5 w-5 text-gray-900'/>;
    if (severity === validationSeverity.info)
      return <InformationCircleIcon className='h-5 w-5 text-gray-900'/>;
    if (severity === validationSeverity.good)
      return <CheckIcon className='h-5 w-5 text-gray-900'/>;
    return <></>;
  }

  const getEventHeader = (event) => {
    // console.log("DashboardEventModal", "getEventHeader", "event", event, "event.status", event.status, "event.objectType", event.objectType);

    if (event.status === validationStatus.newK8sImageAvailable && event.objectType === validationObjectTypes.templateServer)
      return `Server ${event.object.srv.name} should be updated`
    if ((event.status === validationStatus.running && event.objectType === validationObjectTypes.templateServer) 
        || (event.status === validationStatus.serverStatusOk && event.objectType === validationObjectTypes.templateServer))
      return `Server ${event.object.srv.name} is running without remarks`
    if (event.status === validationStatus.serverStatusDownloadPending && event.objectType === validationObjectTypes.templateServer)
      return `Server ${event.object.srv.name} has pending downloads`
    if (event.status === validationStatus.serverStatusDownloading && event.objectType === validationObjectTypes.templateServer)
      return `Server ${event.object.srv.name} is downloading logic files`
    if (event.status === validationStatus.serverStatusUnknown && event.objectType === validationObjectTypes.templateServer)
      return `Server ${event.object.srv.name} reported an unknown status`
    if (event.status === validationStatus.serverStatusDownloadFailed && event.objectType === validationObjectTypes.templateServer)
      return `Server ${event.object.srv.name} reported a failed download`
    if (event.status === validationStatus.serverTimeout && event.objectType === validationObjectTypes.templateServer)
      return `Server ${event.object.srv.name} has timed out`
    if (event.status === validationStatus.noActiveServers && event.objectType === validationObjectTypes.template)
      return "No active servers"
  }

  const getEventMessage = (event) => {
    if (event.status === validationStatus.newK8sImageAvailable && event.objectType === validationObjectTypes.templateServer) {
      return (
        <>
          <p>There is a new image version ({latestImageVer}) available</p>
          <p>The server {event.object?.name} should be updated</p>
        </>
      );
    }
    if ((event.status === validationStatus.running && event.objectType === validationObjectTypes.templateServer) 
        || (event.status === validationStatus.serverStatusOk && event.objectType === validationObjectTypes.templateServer)) {
      return (
        <>
          <p>The server {event.object?.name} haven't reported any events</p>
        </>
      );
    }
    if (event.status === validationStatus.serverStatusUnknown && event.objectType === validationObjectTypes.templateServer){
      return (
        <>
          <p>Might occure on VM-ware server with an incorrect configured StatusService</p>
        </>
      );
    }

    if (event.status === validationStatus.serverTimeout && event.objectType === validationObjectTypes.templateServer) {
      return (
        <>
          <p>The server {event.object?.name} has timed out</p>
        </>
      );
    }
    if (event.status === validationStatus.noActiveServers && event.objectType === validationObjectTypes.template) {
      return (
        <>
          <p>The environment has no active servers</p>
        </>
      );
    }
  }

  const renderEventActionButton = (name, action) => {
    return (
      <div className="flex mt-2">
        <div className="flex-1" />
        <Button
          variant={Button.variants.secondary}
          className="justify-center"
          disabled={false}
          onClick={action}
        >
          {name}
        </Button>
      </div>
    );

  }

  const getEventAction = (event) => {
    if (event.status === validationStatus.newK8sImageAvailable && event.objectType === validationObjectTypes.templateServer &&
      hasFeature(environmentsConfigurationFeatureCode)) {
      return renderEventActionButton("Update", () => handleUpdateKubernetesServer(event.object.tpl.id, event.object.srv.serverId));
    }
    if (event.status === validationStatus.noActiveServers && event.objectType === validationObjectTypes.template &&
      hasFeature(environmentsConfigurationFeatureCode)) {
      return renderEventActionButton("Configure", () => handleConfigureServers(event.object.id));
    }
    if (event.status === validationStatus.serverTimeout && event.objectType === validationObjectTypes.templateServer &&
      hasFeature(environmentsConfigurationFeatureCode)) {
      return renderEventActionButton("Troubleshoot", () => handleTroubleshootServer(event.object.tpl.id, event.object.srv.serverId));
    }
  }

  // TODO: Move this to utils
  const handleUpdateKubernetesServer = (templateId, serverId) => {
    dispatchCurrentDataEvent("history", history.location.pathname);
    history.push(`/environments/deployment/srv/${templateId}/1/${serverId}`);
  }

  const handleConfigureServers = (templateId) => {
    // console.log("DashboardEventModal", "handleConfigureServers", "templateId", templateId);
    history.push(`/environments/v2`);
  }

  const handleTroubleshootServer = (templateId, serverId) => {
    // console.log("DashboardEventModal", "handleTroubleshootServer", "templateId", templateId, "serverId", serverId);
    history.push(`/templates/server/tbs/${templateId}/${serverId}`);
  }

  return (
    <Modal
        isOpen={modalHelper.isOpen}
        onClose={() => { modalHelper.close() }}
        size={'medium'}
        title={modalHelper.activeItem?.title || ""}
    >
      <>
        <div className="flex flex-col gap-2" style={{maxHeight: "500px"}}>
          {events.map(event => (
            <Panel variant={Panel.variants.colored} color={getPanelColor(event.severity)}>
              <div className="flex flex-row gap-4">
                <div className="h-full my-auto">
                  {renderStatusIcon(event.severity)}
                </div>
                <div className="flex-1 flex-col">
                  <Label type={Label.types.simple} textClass="text-base font-semibold" additionalClass={`flex-1 ${getTextColorClass(event.severity)}`}>
                    {getEventHeader(event)}
                  </Label>
                  <Label type={Label.types.simple} additionalClass={`flex-1 italic mt-2 ${getTextColorClass(event.severity)}`}>
                    {getEventMessage(event)}
                  </Label>
                  <div>
                    {getEventAction(event)}
                  </div>
                </div>
              </div>
            </Panel>
          ))}
        </div>
        <div className="flex justify-end mt-5 sm:mt-6 space-x-2">
          <Button
              variant={Button.variants.primary}
              className="justify-center"
              disabled={false}
              onClick={() => {
                  modalHelper.close();
              }}
          >
              Close
          </Button>
        </div>
      </>
    </Modal>
  )
}