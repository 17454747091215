import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import '../Environments/WizardSteps/arrowBlockStyles.scss';

import { useClassNames } from "@metaforcelabs/metaforce-core";

import { Label as CmpLabel } from "../../../components/Label/index.js";
import { TwPage } from "../../../components/TailwindPage/index.js";
import classNames from 'classnames';

export default function ExtrasSandbox() {
  const history = useHistory();
  const { classNames } = useClassNames();

  return (
    <>
      <TwPage>
        <TwPage.Header>
          <TwPage.Header.Hdr.Fixed 
            title="Sandbox" 
          />
        </TwPage.Header>

        <CmpLabel type={CmpLabel.types.warn} additionalClass="mb-8">
          <p className="font-bold">
              This page is intended as a Metaforce administration page and should not be used by others.
          </p>
          <p>
              Clicking a red button updates the database and should only be used by someone that understands the backend/database structure.
          </p>
        </CmpLabel>


      </TwPage>
    </>
  );
}
