import React, { useState, useEffect, useContext } from "react";
import { format } from "date-fns";

import { DebugContext } from '../../../contexts';

import { useToastAction } from '../../../hooks/useToastAction.js';

import * as TemplateApi from "../../../api/interactTemplateEnvironment.js";
import * as TemplateFileApi from "../../../api/interactTemplateFile.js";
import * as DeploymentApi from "../../../api/deployment.js";

import {renderServerUpdateLabel} from "../../../utils/tooltipUtils.js"

import MenuContextList from "../../../components/MenuContextList/index.js";
import GenericModal from '../../../components/Modals/genericModal.js';
import { Label as CmpLabel } from '../../../components/Button';
import { Button as CmpButton } from '../../../components/Button';
import { TwTable } from "../../../components/TailwindTable/index.js";

export default function ServerInformationDialog({interactTemplate,
    selectedServers,
    openInfoDialog,
    setOpenInfoDialog}) {
  const [paginationTotalCount, setPaginationTotalCount] = useState(0);
  const [paginationCurrentPage, setPaginationCurrentPage] = useState(0);
  const [paginationItemsPerPage, setPaginationItemsPerPage] = useState(5);

  const [currentDialogBackupData, setCurrentDialogBackupData] = useState(null);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [serverUpdateInfo, setServerUpdateInfo] = useState(null);

  const [openDeleteFileConfirmation, setOpenDeleteFileConfirmation] = useState(false);

  const loadAction = useToastAction();
  const deleteAction = useToastAction();

  const debugContext = useContext(DebugContext);

  // Only in debug - Start
  const [debugMode, ] = useState(debugContext.debug);
  // Only in debug - End

  const loadData = async () => {
    loadAction.execute(async () => {
      let server = selectedServers[0];
      if (!!server.deploymentSessionId) {
        const deploySession = await DeploymentApi.getDeploymentSession(server.deploymentSessionId);
        const deployVersions = await DeploymentApi.getDeploymentSessionVersions(server.deploymentSessionId);

        //console.log("loadData", "deploySession", deploySession, "deployVersions", deployVersions);

        let deploySessions = [{
          id: deploySession.id,
          createdDate: deploySession.createdDate,
          updatedDate: deploySession.updatedDate,
          createdBy: deploySession.createdBy,
          updatedBy: deploySession.updatedBy,
          metadata: deploySession.metadata,
          record: deploySession
        }].concat(deployVersions ?? []);

        //console.log("loadData", "deploySessions", deploySessions);

        deploySessions = deploySessions.sort((a, b) => {
          let dateA = Date.parse(a.createdDate), dateB = Date.parse(b.createdDate);
          return dateA - dateB;
        });
        setPaginationTotalCount(deploySessions.length);
        setCurrentDialogBackupData({server, backups: deploySessions, pageData: deploySessions.slice(0, paginationItemsPerPage)});
      }
      else
      {
        let backups = await TemplateApi.getInteractTemplateServerBackups(interactTemplate.id, server.serverId);
        backups = backups.sort((a, b) => {
          let dateA = Date.parse(a.fileDate), dateB = Date.parse(b.fileDate);
          return dateB - dateA;
        });
        setPaginationTotalCount(backups.length);
        setCurrentDialogBackupData({server, backups, pageData: backups.slice(0, paginationItemsPerPage)});
      }
    }, "Failed to load Server Backups")
  }

  useEffect(() => {
    if (openInfoDialog) {
      loadData();
    }
    else {
      setCurrentDialogBackupData(null);
    }
  }, [openInfoDialog]);

  useEffect(() => {
    const start = paginationCurrentPage * paginationItemsPerPage;
    setCurrentDialogBackupData(prevState => ({
      ...prevState,
      ["pageData"]: prevState?.backups?.slice(start, start + paginationItemsPerPage)
    }));
  }, [paginationCurrentPage, paginationItemsPerPage]);

  // useEffect(() => {
  //   console.log("serverUpdateInfo", serverUpdateInfo);
  // }, [serverUpdateInfo]);

  const downloadFile = async (file) => {
    await TemplateFileApi.downloadServerBackupFile(interactTemplate.id, file.id, file.fileName);
  }

  const openDelete = async (file) => {
    setFileToDelete(file);
    setOpenDeleteFileConfirmation(true);
  }

  const handleDelete = async () => {
    if (!fileToDelete)
      return;
    deleteAction.execute(async () => {
      const fileId = fileToDelete.id;
      setOpenDeleteFileConfirmation(false);
      setFileToDelete(null);

      await TemplateFileApi.deleteServerBackupFile(interactTemplate.id, fileId);
      await loadData();
    }, "Failed to delete Server Backup file", "Server Backup deleted")
  }

  const renderDeploymentData = () => {
    return (
      <>
      <TwTable>
        <TwTable.Head>
          <TwTable.Header additionalClass={'sm:pl-6 h-14'}>
            Name
          </TwTable.Header>
          <TwTable.Header additionalClass={'sm:pl-6 h-14'}>
            Image version
          </TwTable.Header>
          <TwTable.Header additionalClass={'sm:pl-6'}>
            Created
          </TwTable.Header>
          <TwTable.Header/>
        </TwTable.Head>
        <TwTable.Body additionalClass='text-left'>
          {currentDialogBackupData?.pageData && currentDialogBackupData.pageData.map((version, index) => (
            <>
            <TwTable.BodyRow key={`ff${version.id}-m`} additionalClass="h-12" useHover={false}>
            <TwTable.BodyCol>
                {version.record.deploymentName}
              </TwTable.BodyCol>
              <TwTable.BodyCol>
                {version.record.configuration.values[".Values.image.version"]}
              </TwTable.BodyCol>
              <TwTable.BodyCol>
                {version.createdDate && format(
                  new Date(version.createdDate),
                  "MM/dd/yyyy - HH:mm"
                )}
              </TwTable.BodyCol>
              <TwTable.BodyCol additionalClass={'w-2'}>
                {/* <MenuContextList
                  actions={[{
                      name: "Download",
                      onClick: () => downloadFile(file),
                    },{
                      name: "Remove",
                      onClick: () => openDelete(file),
                    }]
                  }
                  handleDelete="false"
                  bottomPos={currentDialogBackupData.pageData.findIndex(i => i === file) >= currentDialogBackupData.pageData.length - 1}
                /> */}
              </TwTable.BodyCol>
            </TwTable.BodyRow>
            </>
          ))}
        </TwTable.Body>
        {paginationTotalCount > paginationItemsPerPage && (
          <TwTable.Foot>
            <TwTable.FootCol colSpan="20">
              <TwTable.Pagination 
                numPages={Math.ceil(paginationTotalCount / paginationItemsPerPage)} 
                currPage={paginationCurrentPage} 
                setCurrPage={setPaginationCurrentPage} 
                itemsPerPage={paginationItemsPerPage} 
                setItemPerPage={setPaginationItemsPerPage} 
                maxItems={paginationTotalCount} 
                boundaryCount={2} 
              />
            </TwTable.FootCol>
          </TwTable.Foot>
        )}
      </TwTable>
      </>
    );
  }

  const renderBackupData = () => {
    return (
      <TwTable>
        <TwTable.Head>
          <TwTable.Header additionalClass={'sm:pl-6 h-14'}>
            Name
          </TwTable.Header>
          <TwTable.Header additionalClass={'sm:pl-6'}>
            Created
          </TwTable.Header>
          <TwTable.Header/>
        </TwTable.Head>
        <TwTable.Body additionalClass='text-left'>
          {currentDialogBackupData?.pageData && currentDialogBackupData.pageData.map((file, index) => (
            <>
            <TwTable.BodyRow key={`ff${file.id}-m`} additionalClass="h-12" useHover={false}>
              <TwTable.BodyCol>
                {file.fileName}
              </TwTable.BodyCol>
              <TwTable.BodyCol>
                {file.createdDate && format(
                  new Date(file.createdDate),
                  "MM/dd/yyyy - HH:mm"
                )}
              </TwTable.BodyCol>
              <TwTable.BodyCol additionalClass={'w-2'}>
                <MenuContextList
                  actions={[{
                      name: "Download",
                      onClick: () => downloadFile(file),
                    },{
                      name: "Remove",
                      onClick: () => openDelete(file),
                    }]
                  }
                  handleDelete="false"
                  bottomPos={currentDialogBackupData.pageData.findIndex(i => i === file) >= currentDialogBackupData.pageData.length - 1}
                />
              </TwTable.BodyCol>
            </TwTable.BodyRow>
            </>
          ))}
        </TwTable.Body>
        {paginationTotalCount > paginationItemsPerPage && (
          <TwTable.Foot>
            <TwTable.FootCol colSpan="20">
              <TwTable.Pagination 
                numPages={Math.ceil(paginationTotalCount / paginationItemsPerPage)} 
                currPage={paginationCurrentPage} 
                setCurrPage={setPaginationCurrentPage} 
                itemsPerPage={paginationItemsPerPage} 
                setItemPerPage={setPaginationItemsPerPage} 
                maxItems={paginationTotalCount} 
                boundaryCount={2} 
              />
            </TwTable.FootCol>
          </TwTable.Foot>
        )}
      </TwTable>
    );
  }

  return (
    <>
      {/* Server Backup dialog */}
      <GenericModal open={openInfoDialog} setOpen={setOpenInfoDialog}
        onConfirm={() => {
          //setCurrentDialogDownloadsData(null);
          setOpenInfoDialog(false);
        }}
        showCancelButton={false}
        confirmButtonText={'Ok'}
          title={ (!currentDialogBackupData?.server?.deploymentSessionId ? "Server Backups: " : "Deployments: ") + `${currentDialogBackupData?.server?.name || ""}`}
      >
        <div className="flex gap-8 mt-4 flow-root">
          <div className='flex-auto mb-4'>
            {!currentDialogBackupData?.server?.deploymentSessionId && renderBackupData()}
            {currentDialogBackupData?.server?.deploymentSessionId && renderDeploymentData()}
          </div>
        </div>
      </GenericModal>

      {/* Delete Backup File Dialog */}
      <GenericModal open={openDeleteFileConfirmation} setOpen={setOpenDeleteFileConfirmation}
        onConfirm={handleDelete}
        onCancel={() => {
          setOpenDeleteFileConfirmation(false);
          setFileToDelete(null);
        }}
        showCancelButton={true}
        confirmButtonText={'Delete'}
        title={`Delete Server Backup File: ${fileToDelete?.fileName || ""}`}>
        <div className="mt-4">
          <p className="text-sm text-gray-500">
            Are you sure you want to delete the selected Server Backup File?
          </p>
        </div>
      </GenericModal>
    </>
  );
}