import React, { useEffect, useState, useRef } from 'react';
import { format } from "date-fns";

import  { CloudUploadIcon as ServerUploadIcon, UploadIcon as ManualUploadIcon, ArrowCircleRightIcon as FromOnlyIcon, BanIcon as ToOnlyIcon } from '@heroicons/react/outline'

import { LOGICFILESTATUSES, LOGICFILEORIGINS } from "../../../utils/LogicFileUtilities";

import { TwTable } from "../../../components/TailwindTable";

export default function TemplateFileTableRow({fileName, fileId, fileData, status, addDivider, cbElem, statusElem, menuElem}) {
 
  fileName = fileName || fileData.fileName;
  fileId = fileId || fileData.id;

  const getStatusFileIcon = (file, status) => {
    switch(status)
    {
      case LOGICFILESTATUSES.fromOnly:
        return <FromOnlyIcon className="h-5 w-5" aria-hidden="true" />;
      case LOGICFILESTATUSES.toOnly:
        return <ToOnlyIcon className="h-5 w-5" aria-hidden="true" />;
      }
  }

  const getFileOriginIcon = (file, status) => {
    const statusIcon = getStatusFileIcon(file, status);
    if (!file)
      return statusIcon || <div className='h-5 w-5' />
    if (file.fileOrigin === LOGICFILEORIGINS.manual)
      return <ManualUploadIcon className="h-5 w-5" aria-hidden="true" />
    return <ServerUploadIcon className="h-5 w-5" aria-hidden="true" />
  }

  return (
  <>
      <TwTable.BodyRow key={`ff${fileId}-m`} additionalClass="h-12" useHover={false}>
        {cbElem && (
          <TwTable.BodyCol additionalClass={'w-2 px-3 xl:px-6'} rowSpan="4">
            {cbElem}
          </TwTable.BodyCol>
        )}
        <TwTable.BodyCol additionalClass='px-3 xl:px-6 pb-0 flex-1'>
          <div className='flex gap-2'>
            {status && getFileOriginIcon(fileData, status)}
            {fileName}
          </div>
        </TwTable.BodyCol>
        <TwTable.BodyCol additionalClass='px-3 xl:px-6 pb-0'>
          {fileData?.fileDate && format(
            new Date(fileData.fileDate),
            "MM/dd/yyyy - HH:mm"
          )}
        </TwTable.BodyCol>
        {statusElem && (
        <TwTable.BodyCol additionalClass='px-3 xl:px-6 pb-0'>
          {statusElem}
        </TwTable.BodyCol>
        )}
        {menuElem && (
        <TwTable.BodyCol additionalClass={'px-3 xl:px-6 w-2 text-gray-500 dark:text-gray-200'} rowSpan="4">
          {menuElem}
        </TwTable.BodyCol>
        )}
      </TwTable.BodyRow>

      <TwTable.BodyRow key={`ff${fileId}-tst`} additionalClass="" addDivider={false} useHover={false}>
        <TwTable.BodyCol colSpan="3" additionalClass='px-3 xl:px-6 pt-0 pb-2 italic'>
            Uploaded by: {fileData?.uploadedBy}
          </TwTable.BodyCol>
      </TwTable.BodyRow>

      <TwTable.BodyRow key={`ff${fileId}-vi`} additionalClass="" addDivider={false} useHover={false}>
        <TwTable.BodyCol colSpan="3" additionalClass='px-3 xl:px-6 pt-0 pb-2 italic'>
            Version: {fileData?.versionInfo}
          </TwTable.BodyCol>
      </TwTable.BodyRow>

      <TwTable.BodyRow key={`ff${fileId}-cm`} additionalClass="h-6" addDivider={addDivider} useHover={false}>
        <TwTable.BodyCol colSpan="3" additionalClass='px-3 xl:px-6 pt-0 italic'>
            {fileData?.comment}
          </TwTable.BodyCol>
      </TwTable.BodyRow>
    </>
  );
}