import { Field, ErrorMessage } from 'formik';


export const FormikTextInput = ({
  name,
  label,
  formikProps,
  type = null,
  placeholder = null,
  required = false,
  horizontal = false,
  disabled = false
}) => {
  const hasError = formikProps?.errors && formikProps.errors[name];
  const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500'
  // console.log(formikProps?.errors)
  const readError = () => {
    const nameParts = name.split('.');
    let error = formikProps?.errors;
    nameParts.forEach(element => {
      if (error && typeof (error) === 'object') {
        error = error[element];
      }

    });
    return error
  }
  return (
    <div className={`${horizontal && "flex items-center"}`}>
      <label htmlFor={name} className={`${horizontal && "w-20"} block text-sm font-medium text-gray-700`}>
        {label}
        {required && <span className="text-red-400"> *</span>}
      </label>
      <div className="mt-1 w-full">
        <Field
          type={type || "text"}
          name={name}
          disabled={disabled}
          className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400 ${hasError ? errorClasses : ''}`}
          placeholder={placeholder}
        />
        <div className='text-red-500'>{readError()}</div>
        {/* <ErrorMessage name={name} component="div" className="text-red-500" /> */}
      </div>
    </div>
  )
}