import { config } from "./config";

export const clearOidcSession = () => {
    let oidcUserKey = "";
    for (let index = 0; index < sessionStorage.length; index++) {
        const sessionKey = sessionStorage.key(index);
        console.log(sessionKey)
        if (sessionKey.startsWith("oidc.user:")) {
            oidcUserKey = sessionKey;
            break;
        }

    }
    if (oidcUserKey) {
        sessionStorage.removeItem(oidcUserKey);
    }
}

export const changeCompany = () => {
    clearOidcSession();
    let url = config.identityBaseUrl;
    var lastChar = url.substr(-1);
    if (lastChar === "/") {
        url = url.substring(0, url.length - 1);
    }
    window.location.href = url + "/customerresolve";
}