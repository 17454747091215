import React, { useEffect, useState, useContext } from 'react';

import _ from "lodash"; // cool kids know _ is low-dash

import { CurrentDataContext } from '..';

export const CurrentDataContextProvider = ({ children }) => {
  const [currentDataId, setCurrentDataId] = useState('');
  const [currentDataPayload, setCurrentDataPayload] = useState(null);
  const [originalDataPayload, setOriginalDataPayload] = useState(null);
  const [viewSettings, setViewSettings] = useState({});

  const dispatchCurrentDataEvent = (id, payload) => {
    setCurrentDataId(id);
    setCurrentDataPayload(payload);
    if ((!id && !payload) || !originalDataPayload) {
      setOriginalDataPayload(_.cloneDeep(payload));
    }
  };

  const getViewSettings = (pageId) => {
    return viewSettings && viewSettings.hasOwnProperty(pageId)
      ? viewSettings[pageId]
      : null;
  }

  const dispatchViewSettingsUpdate = (pageId, settings) => {
    viewSettings[pageId] = settings;

    // console.log("CurrentDataContextProvider", "dispatchViewSettingsUpdate", "viewSettings", viewSettings);
    setViewSettings(viewSettings);
  }

  return (
    <CurrentDataContext.Provider
      value={{
        currentDataId,
        currentDataPayload,
        originalDataPayload,
        dispatchCurrentDataEvent,
        getViewSettings,
        dispatchViewSettingsUpdate
      }}
    >
      {children}
    </CurrentDataContext.Provider>
  );
}